import {
  GET_PROJECTS_LIST,
  SET_TEMP_PROJECT_LIST,
  SET_SORT_PROJECT_LIST,
  GET_PROJECT_ACCT_LEADERSHIP_DETAILS,
  GET_PROJECT_BOI,
  GET_PROJECT_CONDITIONAL_DIMENSION,
  GET_PROJECT_REJECTED_DIMENSION,
  GET_PROJECT_CA_DETAILS,
  GET_PROJECT_CA_FILE_DATA,
  SET_PROJECT_DROPDOWN_LIST,
  SET_NEW_PROJECT_DETAILS,
  SET_SELECTED_PROJECT_DETAILS,
  GET_PROGRAM_BOI_LIST,
  GET_PROJECT_EDIT_BOI,
  SET_SELECTED_REPORTED_YEAR,
  SET_REPORTED_YEAR_LIST,
  SET_SELECTED_PROJECT,
  CLEAR_ALL_PROJECT_FIELDS,
  SET_ORIG_PROJECT_BOI,
  SET_PROJECT_SFA_PARAM,
  SET_PROJECT_SFA_PAYLOAD,
  SET_PROJECT_SFA,
  SET_PROJECT_RESEND_PAYLOAD,
  SET_PROJECT_UPLOAD_FILE,
  SET_PROJECT_UPLOAD,
  GET_PROJECT_STAKEHOLDERS,
  SHOW_SELECT_PROJECT,
  SAVE_ALREADY_EXIST,
  FETCH_ASSET_AND_DELIVEREDBY_LIST,
  SET_BUSINESS_ADVISOR_LIST_BY_CLIENT
} from '../Constants';
// import { history } from '../store';
import { HttpGet, HttpPostText, HttpPost, GetSubMenuId } from '../Utilities/HTTPUtil';
import { IsValid, IsValidStr, IsValidNum, HasProperty } from '../Utilities/Validations';
import { formatAMPM, TryGetNodeAlt } from '../Utilities/Formatter';
import { setEmailError, setToasterParam } from './Shared.Action';
import { LogPPCUserAction } from '../Utilities/AccessManagementUtil';
import { PPC_NEW_PROJ, PROJ_ID_VAL_SETT } from '../Constants/Modules'
import { filter } from 'react-dom-factories';

export const searchProjectList = (value) => async (dispatch, state) => {
  let projectList = state().ProjectConfigurationData.projectList;
  let tempProjectList = state().ProjectConfigurationData.tempProjectList;
  let filteredData = projectList.filter((x) =>
    x.ClientProjectName.toLowerCase().includes(value.toLowerCase())
  );
  if (IsValidStr(value)) {
    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: filteredData,
    });
  } else {
    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: tempProjectList,
    });
  }
}
export const getsortedProjectList = (data) => async (dispatch, state) => {
  let projectList = state().ProjectConfigurationData.tempProjectList;
  if (IsValid(data)) {
    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: data,
    });
  } else {
    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: projectList,
    });
  }
}
export const getLandingProjectsList = () => async (dispatch, state) => {
  let projectList = state().ProjectConfigurationData.projectList;
  dispatch({
    type: GET_PROJECTS_LIST,
    projectList: projectList,
  });
}
export const getProjectProgramDetails = (programId, isResetYear) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Details Page");
    const data = !IsValid(programId)
      ? []
      : await HttpGet(`ProjectConfiguration/GetProgramProjectsDetail?programId=${programId}&SubMenuID=${subMenuId}&isduplicate=${sessionStorage.getItem('copy' )|| 'false'}`);
    let projectList = [];
    let reportedYearsList =
      data.ReportedYears.length > 0
        ? data.ReportedYears.map((x) => {
          return { label: x, value: x };
        })
        : [];
    const selectedReportedYear = state().ProjectConfigurationData.selectedReportedYear;


    data.ProjectList.map((project) => {
      if (project.CreatedUser === 'System generated') {
        project.ProjectCreator = 'System generated';
      }
    });


    let userGenerated = data.ProjectList.filter(
      (x) => String(x.CreatedUser).toLowerCase() !== 'system generated'
    );
    let systemGenerated = data.ProjectList.filter(
      (x) => String(x.CreatedUser).toLowerCase() === 'system generated'
    );

    projectList = [...systemGenerated, ...userGenerated];

    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: projectList,
    });
    dispatch({
      type: SET_TEMP_PROJECT_LIST,
      tempProjectList: projectList,
    });

    dispatch({
      type: SET_REPORTED_YEAR_LIST,
      reportedYearsList: reportedYearsList,
    });
    dispatch({
      type: SET_SELECTED_REPORTED_YEAR,
      selectedReportedYear: reportedYearsList,
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const clearProjectList = () => async (dispatch) => {
  try {
    const projectList = [];

    dispatch({
      type: GET_PROJECTS_LIST,
      projectList: projectList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectStakeHolders = (clientID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Config Module landing page");
    const data = !IsValid(clientID)
      ? []
      : await HttpGet(
        `NewProjectIdentifiedValueSetting/GetProjectDeliveryCenterandStakeHolders?clientID=${clientID}&SubMenuID=${subMenuId}`
      );

    let projectDropdownList = {
      deliveryCenterList: [],
      projectLeadList: [],
    };

    let tempListProjL = [];
    data.ClientStakeHolders.map((item) => {
      if (item.ProfileID === 9) {
        //Project Lead
        if (tempListProjL.indexOf(item.UserEmailID) === -1) {
          tempListProjL.push(item.UserEmailID);
          projectDropdownList.projectLeadList.push({
            label: item.UserEmailID,
            text: item.UserEmailID,
            value: item.UserEmailID,
          });
        }
      }
    });

    let tempDelCntr = [];
    data.DeliveryCenters.map((item) => {
      if (tempDelCntr.indexOf(item.deliveryCenterID) === -1) {
        tempDelCntr.push(item.deliveryCenterID);
        projectDropdownList.deliveryCenterList.push({
          label: item.deliveryCenterName,
          text: item.deliveryCenterName,
          value: item.deliveryCenterID,
        });
      }
    });

    dispatch({
      type: SET_PROJECT_DROPDOWN_LIST,
      projectDropdownList: projectDropdownList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setNewProjectDetails = (data) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_NEW_PROJECT_DETAILS,
      newProjectDetails: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setSelectedProjectdetails = (data, module) => async (dispatch, state) => {
  try {
    dispatch(getProjectStakeholdersNew(data.ProjectID, module));
    dispatch({
      type: SET_SELECTED_PROJECT_DETAILS,
      selectedProjectDetails: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const saveAsDraftProjectIdentified = (body) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProjectIdentifiedValueSetting/SaveAsDraftProgramIdentifiedValues?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      // history.push('/ProgramProjectConfig/Projects');
      if (!IsValid(TryGetNodeAlt(state().ProjectConfigurationData.selectedProjectDetails, undefined, 'ProjectID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: Postdata.Code,
          projStatus: PPC_NEW_PROJ,
          module: PROJ_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );

      dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      );
      return true;
    }
    else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendForApprovalProjectIdentified = (body) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProjectIdentifiedValueSetting/SendNotificationForProjectApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      // history.push('/ProgramProjectConfig/Projects');
      if (!IsValid(TryGetNodeAlt(state().ProjectConfigurationData.selectedProjectDetails, undefined, 'ProjectID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: Postdata.Code,
          projStatus: PPC_NEW_PROJ,
          module: PROJ_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was sent for approval!',
        })
      );

      dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      );
      return true;
    }
    else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};


/* New Project Action for Save and Close */
export const saveAndCloseProjectIdentified = (body) => async (dispatch, state) => {

  try {
    let subMenuId = GetSubMenuId("Project Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProjectIdentifiedValueSetting/SaveAndCloseProject?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      // history.push('/ProgramProjectConfig/Projects');
      if (!IsValid(TryGetNodeAlt(state().ProjectConfigurationData.selectedProjectDetails, undefined, 'ProjectID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: Postdata.Code,
          projStatus: PPC_NEW_PROJ,
          module: PROJ_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was saved successfully!',
        })
      );

      dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      );
      dispatch(
        getBusinessAdvisorListByClientID()
      )
      return true;
    }
    else {
      dispatch(
        setEmailError({
          show: true,
          message: Postdata.Message,
        })
      );

      return false;
    }
  } catch (err) {
    console.log(err);
  }
};
export const saveAndReportProjectIdentified = (body) => async (dispatch, state) => {
  try {
    let responseObj = {
      isSuccess: false,
      projectID: 0
    }

    let subMenuId = GetSubMenuId("Project Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProjectIdentifiedValueSetting/SaveAndReportProject?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      // history.push('/ProgramProjectConfig/Projects');
      if (!IsValid(TryGetNodeAlt(state().ProjectConfigurationData.selectedProjectDetails, undefined, 'ProjectID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
          ClientProjectID: Postdata.Code,
          projStatus: PPC_NEW_PROJ,
          module: PROJ_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was saved successfully!',
        })
      )
      // If success, get Project ID from return to build URL to redirect to RVD module
      let projID = IsValid(Postdata.Code) ? parseInt(Postdata.Code) : 0;
      responseObj = {
        isSuccess: true,
        projectID: projID
      }
      /* dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      ); */
      return responseObj;
    }
    else {
      dispatch(
        setEmailError({
          show: true,
          message: Postdata.Message,
        })
      )

      return responseObj;
    }
  } catch (err) {
    console.log(err);
  }
};

export const editProject = (body, historyProps) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Identified Value Settings");
    let Postdata = await HttpPostText(`NewProjectIdentifiedValueSetting/EditProject?SubMenuID=${subMenuId}`, body);
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was updated successfully!',
        })
      );

      dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      );
    }
    if (Postdata === 'Project Name already exists') {
      dispatch({
        type: SAVE_ALREADY_EXIST
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getProgramBOIList = (programId) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Details Page");
    const data = !IsValid(programId)
      ? []
      : await HttpGet(
        `NewProjectIdentifiedValueSetting/GetProgramBOIs?clientProgramID=${programId}&SubMenuID=${subMenuId}`
      );
    dispatch({
      type: GET_PROGRAM_BOI_LIST,
      programBOIList: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectBOI = (projectID) => async (dispatch, state) => {
  try {
    projectID =
      projectID === undefined
        ? state().ProjectConfigurationData.selectedProjectDetails.ProjectID
        : projectID;
    let subMenuId = GetSubMenuId("Project Details Page");
    let progIdentifiedResData = await HttpGet(
      `ProjectConfiguration/GetProjectDimensionStatus?projectId=${projectID}&SubMenuID=${subMenuId}`
    );
    let dimensionList = [];
    let conditionalDimensionList = [];
    let selectedProjectDetails = state().ProjectConfigurationData.selectedProjectDetails;
    let userInfo = state().userInformation;
    let projectSFA = [];

    // program identified
    if (Object.keys(progIdentifiedResData).length > 0) {
      progIdentifiedResData.map((dimensionItem, dimensionIndex) => {
        dimensionList.push({
          dimensionName: dimensionItem.CoreDimensionName,
          dimensionID: dimensionItem.CoreDimensionID,
          dimensionReason: dimensionItem.CoreDimensionReason,
          dimensionStatus: dimensionItem.CoreDimensionStatus,
          dimensionTarget: dimensionItem.CoreDimensionTarget,
          subDimensionList: [],
        });

        if (Object.keys(dimensionItem.SubDimensionList).length > 0) {
          progIdentifiedResData[dimensionIndex].SubDimensionList.map(
            (subDimensionItem, subDimensionIndex) => {
              dimensionList[dimensionIndex].subDimensionList.push({
                subDimensionName: subDimensionItem.SubDimensionName,
                subDimensionID: subDimensionItem.SubDimensionID,
                subDimensionReason: subDimensionItem.SubDimensionReason,
                subDimensionStatus: subDimensionItem.SubDimensionStatus,
                subDimensionTarget: subDimensionItem.SubDimensionTarget,
                boiList: [],
              });

              if (Object.keys(subDimensionItem.BOIList).length > 0) {
                progIdentifiedResData[dimensionIndex].SubDimensionList[
                  subDimensionIndex
                ].BOIList.map((boiItem, boiIndex) => {
                  dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList.push({
                    boiID: boiItem.BOIID, //
                    boiName: boiItem.BOIName, //
                    paramValue: boiItem.BOITarget, //
                    oldParamValue: boiItem.BOITarget,
                    uom: boiItem.UOM, //
                    comment: boiItem.BOIReason, //
                    description: boiItem.BOIDescription, //
                    boiDescription: boiItem.BOIDescription,
                    boiRealizedValue: boiItem.BOIRealizedValue,
                    boiStatus: boiItem.BOIStatus, //
                    isActive: true,
                    boiClientProjectTargetID: boiItem.ClientProjectTargetID, //
                    boiRejectionRecipient: boiItem.RejectionRecipient, //
                    boiCode: boiItem.BOICode, //
                    aggregationLogic: boiItem.AggregationLogic,
                    aggregationLogicMasterID: boiItem.AggregationLogicMasterID,
                    ClientName: boiItem.ClientName,
                    offeringName: boiItem.Offering,
                    leadingIndicator: boiItem.LeadingIndicator,
                    boiDefinition: boiItem.MetricCalculation,
                    boiDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                    IsCustom: boiItem.IsCustom,
                    isBOIUsedInRVD: boiItem.IsBOIUsedInRVD,
                    customParameterList: boiItem.CustomParameterList,
                    reasonForNoBOIDefinition: boiItem.ReasonForNoBOIDefinition,
                    reasonForNoAggregationLogic: boiItem.ReasonForNoAggregationLogic,
                    subofferingID: boiItem.SubOfferingID,
                    suboffering: boiItem.SubOfferingName,
                    boiAdditionComment: boiItem.BOIAdditionComment,
                    // boiWheelVisibility: boiItem.ProgramWheelVisibility === 1 ? boiItem.WheelVisibility : false,//
                    boiWheelVisibility: boiItem.BOIStatus === "" ? boiItem.ProgramWheelVisibility : boiItem.WheelVisibility,
                    aggregatedDeliveredValue: boiItem.DeliveredValue,
                    parameterList: boiItem.paramlist ? boiItem.paramlist : [],
                    boiOldComment: boiItem.BOIReason, //do not change
                    isDashboardVisibiltyUpdated: boiItem.IsDashboardVisibiltyUpdated === 1,
                    programWheelVisibility: boiItem.ProgramWheelVisibility === 1,
                    programBOIStatus: boiItem.ProgramBOIStatus,
                  });
                });
              }
            }
          );
        }
      });
    }

    conditionalDimensionList = dimensionList
      .map((e) => {
        const subD = e.subDimensionList
          .map((e2) => {
            const tBoiL = e2.boiList.filter((e3) => {
              return e3.boiStatus === 'Conditional Approval';
            });
            return { ...e2, boiList: tBoiL };
          })
          .filter((e22) => {
            return e22.boiList.length > 0;
          });

        return { ...e, subDimensionList: subD };
      })
      .filter((e4) => {
        return e4.subDimensionList.length > 0;
      });

    projectSFA = {
      projectID: selectedProjectDetails.ProjectID,
      comments: '',
      mailTo: selectedProjectDetails.ProjectApprover,
      projectCreator: selectedProjectDetails.ProjectCreator,
      projectApprover: selectedProjectDetails.ProjectApprover,
      userName: userInfo.userName,
      ClientProjectTargetList: [],
    };

    dispatch({
      type: GET_PROJECT_BOI,
      dimensionList: dimensionList,
    });
    dispatch({
      type: SET_ORIG_PROJECT_BOI,
      origDimensionList: dimensionList,
    });

    dispatch({
      type: GET_PROJECT_CONDITIONAL_DIMENSION,
      conditionalDimensionList: conditionalDimensionList,
    });

    dispatch({
      type: GET_PROJECT_REJECTED_DIMENSION,
      rejectedDimensionList: dimensionList,
    });

    dispatch({
      type: GET_PROJECT_EDIT_BOI,
      editDimensionList: dimensionList,
    });

    dispatch({
      type: SET_PROJECT_SFA,
      projectSFA: projectSFA,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setProjectDimensionList = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROJECT_BOI,
      dimensionList: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectAcctLeadershipDetails = (projectID) => async (dispatch, state) => {
  try {
    projectID =
      projectID === undefined
        ? state().ProjectConfigurationData.selectedProjectDetails.ProjectID
        : projectID;
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let approvalReqResData = await HttpGet(
      `ProjectAccountLeaderShipApproval/GetProjectApprovalRequestStatus?projectID=${projectID}&SubMenuID=${subMenuId}`
    );
    let approvalReqData = [];

    // approval req
    approvalReqResData.map((item) => {
      if (HasProperty(item, 'ClientProjectID')) {
        let date = new Date(item.SubmittedTS);
        let _local = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - _local);
        let _date = `${String(date.getMonth()).length === 1 ? '0' : ''}${date.getMonth() + 1}/${String(date.getDate()).length === 1 ? '0' : ''
          }${date.getDate()}/${date.getFullYear()}`;
        let _time = formatAMPM(date);
        approvalReqData.push({
          pName: item.ClientProjectName,
          lead1: item.RequestCreatedBy,
          lead2: item.RequestApprovedBy,
          submittedDate: _date,
          submittedTime: _time,
          status: item.StatusName,
          resendCounter: item.ResendCounter,
          requestorComment: item.RequestorComment,
        });
      }
    });

    dispatch({
      type: GET_PROJECT_ACCT_LEADERSHIP_DETAILS,
      arsDetails: approvalReqData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProjectCADetails = (ProgramID) => async (dispatch, state) => {
  try {
    let projectID = state().ProjectConfigurationData.selectedProjectDetails.ProjectID;
    let statusID = state().ProjectConfigurationData.selectedProjectDetails.StatusId;

    let data = await HttpGet(
      `ProjectClientAlignment/getClientAlignmentProjectDetails?clientProjectID=${projectID}&statusID=${statusID}`
    );
    let tDetails = {};
    let fileData = [];
    let date = '';

    if (data.projectDetails !== undefined && Object.keys(data.projectDetails).length > 0) {
      for (var i = 0; i < 3; i++) {
        let fileName =
          i === 0
            ? data.projectDetails[0].FirstFileName
            : i === 1
              ? data.projectDetails[0].SecondFileName
              : data.projectDetails[0].ThirdFileName;
        if (fileName !== null) {
          fileData.push({
            column: i + 1,
            fileName: fileName,
            attachmentID: data.projectDetails[0].AttachmentID,
          });
        }
      }

      if (HasProperty(data.projectDetails[0], 'ApprovedOn')) {
        date = new Date(data.projectDetails[0].ApprovedOn);
        let _local = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - _local);
        date = `${String(date.getMonth()).length === 1 ? '0' : ''}${date.getMonth() + 1}/${String(date.getDate()).length === 1 ? '0' : ''
          }${date.getDate()}/${date.getFullYear()}`;
      }

      tDetails.approvedBy =
        data.projectDetails[0].ApprovedBy === null ? '' : data.projectDetails[0].ApprovedBy;
      tDetails.approvedOn = data.projectDetails[0].ApprovedOn === null ? '' : date;
      tDetails.approverComments =
        data.projectDetails[0].ApprovedBy === 'System Approved'
          ? 'System Approved'
          : data.projectDetails[0].Comment === null
            ? ''
            : data.projectDetails[0].Comment;
      tDetails.isSubmit = data.projectDetails[0].IsSubmit === null ? false : data.projectDetails[0].IsSubmit //if null approver closed project (new data)
    }

    dispatch({
      type: GET_PROJECT_CA_FILE_DATA,
      projectCAFileDetails: fileData,
    });

    dispatch({
      type: GET_PROJECT_CA_DETAILS,
      projectCADetails: tDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onChangeReportedYear = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_REPORTED_YEAR,
      selectedReportedYear: value,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setSelectedProject = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_PROJECT,
      selectedProject: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearAllProjectFields = () => async (dispatch, state) => {
  try {
    let data = state().ProjectConfigurationData;
    data.selectedProjectDetails = {};
    data.selectedProject = {};
    data.newProjectDetails = {
      projectName: { value: '', isError: false },
      suboffering: [],
      projectDescription: '',
      offering: '',
      deliveryLocation: [],
      targetEndDate: '',
      ioapprover: {},
      projectcreator: {},
      projectapprover: {},
      deliveredBy: {},
      assetName: [],
      cxLead: [],
      oaLead: [],
      businessAdvisor: [],
      cxlList: [],
      oalList: [],
      baList: []
    };

    data.projectProgramDetails = {};
    data.dimensionList = [];
    data.origDimensionList = [];
    data.conditionalDimensionList = [];
    data.rejectedDimensionList = [];
    dispatch({
      type: CLEAR_ALL_PROJECT_FIELDS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const updateRejectedDimensions = (rejected) => async (dispatch, state) => {
  try {
    let dimensionList = [];
    let isValid = true;
    dimensionList = rejected
      .map((e) => {
        const subD = e.subDimensionList
          .map((e2) => {
            const tBoiL = e2.boiList.filter((e3) => {
              if (e3.isActive !== 0) {
                if (e3.paramValue !== '' ? !IsValidNum(Number.parseFloat(e3.paramValue)) : true) {
                  isValid = false;
                }
              }
              return e3.isActive === 0;
            });
            return { ...e2, boiList: tBoiL };
          })
          .filter((e22) => {
            return e22.boiList.length > 0;
          });

        return { ...e, subDimensionList: subD };
      })
      .filter((e4) => {
        return e4.subDimensionList.length > 0;
      });
    dispatch({
      type: GET_PROJECT_REJECTED_DIMENSION,
      rejectedDimensionList: dimensionList,
    });

    dispatch({
      type: GET_PROJECT_EDIT_BOI,
      editDimensionList: rejected,
    });
    setTimeout(() => {
      if (document.getElementById('expandAll')) {
        document.getElementById('expandAll').click();
      }
    }, 300);
    return isValid;
  } catch (err) {
    console.log(err);
  }
};

export const changeRejectedProjectValue = (data, type) => async (dispatch, state) => {
  try {
    let isProgram = type !== undefined ? (type === 'program' ? true : false) : '';
    let projectID = state().ProjectConfigurationData.selectedProjectDetails.ProjectID;
    let param = [];
    let isValid = true;
    data.map((dimension, dimensionIndex) => {
      dimension.subDimensionList.map((subDimension, subDimensionIndex) => {
        subDimension.boiList.map((boi, boiIndex) => {
          if (boi.paramValue !== '' ? !IsValidNum(Number.parseFloat(boi.paramValue)) : true) {
            isValid = false;
          }
          param.push({
            ClientProjectID: projectID,
            ClientProjectTargetID: boi.boiClientProjectTargetID, //
            CoreDimensionID: dimension.dimensionID, //
            SubDimensionID: subDimension.subDimensionID, //
            BOIID: boi.boiID, //
            BoiName: boi.boiName, //
            BOITarget: boi.paramValue, //
            UOM: boi.uom, //
            BOIStatus: boi.boiStatus, //
            approver: '',
            rejectionRecipient: '',
            BOIReason: boi.comment,
            IsActive: boi.isActive,
          });
        });
      });
    });
    dispatch({
      type: GET_PROJECT_REJECTED_DIMENSION,
      rejectedDimensionList: data,
    });

    dispatch({
      type: SET_PROJECT_SFA_PARAM,
      rejectedSFA: param,
    });
    return isValid;
  } catch (err) {
    console.log(err);
  }
};

export const sendEditReject = (comment, historyProps) => async (dispatch, state) => {
  try {
    let projSFAPayload = state().ProjectConfigurationData.projSFAPayload;
    let projDetails = state().ProjectConfigurationData.selectedProjectDetails;
    let rejectedSFA = state().ProjectConfigurationData.rejectedSFA;
    projSFAPayload.Comments = comment;
    let configuratorsList = state().ProgramConfigurationData.ConfiguratorsList
    dispatch({
      type: SET_PROJECT_SFA_PAYLOAD,
      projSFAPayload: projSFAPayload,
    });

    let body = state().ProjectConfigurationData.projSFAPayload;
    body = {
      ...body,
      IsEdited: projDetails.IsEdited ? 1 : 0,
      ClientProjectTargetList: rejectedSFA//.map((e) => {
      // return {
      //   ...e,
      //   BOIStatus: e.BOIStatus === 'Rejected' ? '' : e.BOIStatus,
      //   BOIReason: e.BOIStatus === 'Rejected' ? '' : e.BOIReason,
      // };
      // })
      ,
      projectCreatorProfileId: configuratorsList.filter(x => x.label === body.projectCreator)[0].id,
    };
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProjectIdentifiedValueSetting/SendRejectedForApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was sent for approval!',
        })
      );

    }
  } catch (e) {
    console.log(e);
  }
};
export const sendSADEditReject = (historyProps) => async (dispatch, state) => {
  try {
    const comment = '';
    let projSFAPayload = state().ProjectConfigurationData.projSFAPayload;
    projSFAPayload.Comments = comment;
    projSFAPayload.ClientProjectTargetList = projSFAPayload.ClientProjectTargetList.map((e) => {
      let boi = { ...e };
      if (e.IsActive === 0) {
        boi = {
          ...e,
          BOIReason: '',
          BOIDeletionReason: '',
        };
      }
      return { ...boi };
    });

    dispatch({
      type: SET_PROJECT_SFA_PAYLOAD,
      projSFAPayload,
    });

    let body = projSFAPayload;
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProjectIdentifiedValueSetting/SaveAsDraftRejectedProjectBOIsForApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );

    }
  } catch (e) {
    console.log(e);
  }
};

export const sendEditApproved = (comment, list, historyProps) => async (dispatch, state) => {
  try {
    let projSFAPayload = state().ProjectConfigurationData.projSFAPayload;
    projSFAPayload.Comments = comment;
    projSFAPayload.BOIDescrip = comment;
    let loggedInUserName = projSFAPayload.userName;

    dispatch({
      type: SET_PROJECT_SFA_PAYLOAD,
      projSFAPayload: projSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProjectDetails = state().ProjectConfigurationData.newProjectDetails;
    let projectDetails = state().ProjectConfigurationData.selectedProjectDetails;
    let userInfo = state().userInformation;
    let configuratorsList = state().ProgramConfigurationData.ConfiguratorsList

    let subOfferingList = state().ProjectConfigurationData.newProjectDetails.suboffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: projectDetails.ClientProgramID ? projectDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = projectDetails.OperationsAccountLead;

    let body = {
      projectID: projectDetails.ProjectID ? projectDetails.ProjectID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,
      offeringID: newProjectDetails.offering,
      clientOfferingID: newProjectDetails.offering,
      clientProjectName: newProjectDetails.projectName.value,
      reportingYear: 0,
      ProjectCreator: newProjectDetails.projectcreator.label,
      projectCreatorProfileId: configuratorsList.filter(x => x.label === newProjectDetails.projectcreator.label)[0].id,
      ProjectApprover: projectDetails.ProjectApprover,
      ioApprover: newProjectDetails.ioapprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProjectSubOfferingList: subOfferingListTemp,
      clientProjectTargetList: list,
      DeliveredBy: newProjectDetails.deliveredBy.label
    };
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProjectIdentifiedValueSetting/SendForApprovalForApprovedProject?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was sent for approval!',
        })
      );

    }
  } catch (e) {
    console.log(e);
  }
};
export const sendSADEditApproved = (list, historyProps) => async (dispatch, state) => {
  try {
    const comment = '';
    let projSFAPayload = state().ProjectConfigurationData.projSFAPayload;
    projSFAPayload.Comments = comment;
    let loggedInUserName = projSFAPayload.userName;

    dispatch({
      type: SET_PROJECT_SFA_PAYLOAD,
      projSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProjectDetails = state().ProjectConfigurationData.newProjectDetails;
    let projectDetails = state().ProjectConfigurationData.selectedProjectDetails;
    let userInfo = state().userInformation;

    let subOfferingList = state().ProjectConfigurationData.newProjectDetails.suboffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: projectDetails.ClientProgramID ? projectDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = projectDetails.OperationsAccountLead;

    let body = {
      projectID: projectDetails.ProjectID ? projectDetails.ProjectID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,
      offeringID: newProjectDetails.offering,
      clientOfferingID: newProjectDetails.offering,
      clientProjectName: newProjectDetails.projectName.value,
      reportingYear: 0,
      ProjectCreator: newProjectDetails.projectcreator.label,
      ProjectApprover: opsAcctLead,
      ioApprover: newProjectDetails.ioapprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProjectSubOfferingList: subOfferingListTemp,
      clientProjectTargetList: list,
      DeliveredBy: newProjectDetails.deliveredBy.label
    };
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProjectIdentifiedValueSetting/SaveAsDraftForApprovedProject?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );

    }
  } catch (e) {
    console.log(e);
  }
};

export const setProjSFAPayload = () => async (dispatch, state) => {
  //change
  try {
    let projSFAPayload = state().ProjectConfigurationData.projectSFA;
    let rejectedSFA = state().ProjectConfigurationData.rejectedSFA;

    projSFAPayload.ClientProjectTargetList = rejectedSFA;

    dispatch({
      type: SET_PROJECT_SFA_PAYLOAD,
      projSFAPayload: projSFAPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const findDeletedDimension = () => async (dispatch, state) => {
  //change
  try {
    let tState = state().ProjectConfigurationData;
    let rejectedDimensionList = tState.editDimensionList;
    let projectID = tState.selectedProjectDetails.ProjectID;

    let editDimensionList = tState.editDimensionList;
    let rejectedSFA = tState.rejectedSFA;
    let eDimID = [];
    rejectedSFA = [];

    editDimensionList.map((eDim) => {
      eDim.subDimensionList.map((eSubDim) => {
        eSubDim.boiList.map((eBoi) => {
          eDimID = [...eDimID, eBoi.boiID];
        });
      });
    });

    rejectedDimensionList.map((dim, dimIndex) => {
      dim.subDimensionList.map((subD, subDimIndex) => {
        subD.boiList.map((boi, boiIndex) => {
          rejectedSFA.push({
            ClientProjectID: projectID,
            ClientProjectTargetID: boi.boiClientProjectTargetID, //
            CoreDimensionID: dim.dimensionID, //
            SubDimensionID: subD.subDimensionID, //
            BOIID: boi.boiID, //
            BOIName: boi.boiName, //
            BOITarget: boi.paramValue, //
            UOM: boi.uom, //
            BOIStatus: boi.boiStatus, //
            approver: '',
            rejectionRecipient: '',
            BOIReason: boi.comment,
            IsActive: boi.isActive,
            Description: boi.description,
          });
        });
      });
    });

    // Get Deleted BOIs on Save As Draft
    let subMenuId = GetSubMenuId("");
    let data = await HttpGet(
      `ProjectConfiguration/GetInActiveProjectDimensionStatus?projectId=${projectID}&SubMenuID=${subMenuId}`
    );

    if (data.length > 0) {
      data.map((dim) => {
        dim.SubDimensionList.map((subD) => {
          subD.BOIList.map((boi) => {
            let isExist = rejectedSFA.filter((x) => x.BOIID === boi.BOIID);
            if (isExist.length === 0) {
              rejectedSFA.push({
                ClientProjectID: projectID,
                ClientProjectTargetID: boi.ClientProjectTargetID, //
                CoreDimensionID: dim.CoreDimensionID, //
                SubDimensionID: subD.SubDimensionID, //
                BOIID: boi.BOIID, //
                BOIName: boi.BOIName, //
                BOITarget: boi.BOITarget, //
                UOM: boi.UOM, //
                BOIStatus: boi.BOIStatus, //
                approver: '',
                rejectionRecipient: '',
                BOIReason: boi.BOIReason,
                IsActive: boi.IsActive ? 1 : 0,
                Description: boi.BOIDescription,
                IsDeletedOnSAD: boi.IsDeletedOnSAD ? boi.IsDeletedOnSAD : '',
              });
            }
          });
        });
      });
    }

    dispatch({
      type: SET_PROJECT_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setDeleteComment = (data, id) => async (dispatch, state) => {
  try {
    let rejectedSFA = state().ProjectConfigurationData.rejectedSFA;
    rejectedSFA.map((reject) => {
      if (reject.BOIID === id) {
        reject.BOIReason = data;
        reject.BOIDeletionReason = data;
      }
    });

    dispatch({
      type: SET_PROJECT_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setEditComment = (data, id) => async (dispatch, state) => {
  try {
    let rejectedSFA = state().ProjectConfigurationData.rejectedSFA;
    rejectedSFA.map((reject) => {
      if (reject.BOIID === id) {
        reject.BOIReason = data;
      }
    });

    dispatch({
      type: SET_PROJECT_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setResendPayload = () => async (dispatch, state) => {
  try {
    let projResendPayload = {};

    projResendPayload.projectID = state().ProjectConfigurationData.selectedProjectDetails.ProjectID;
    projResendPayload.projectLead = state().ProjectConfigurationData.arsDetails.lead1;
    projResendPayload.userName = state().userInformation.enterpriseID;
    projResendPayload.operationsAccountLead = state().ProjectConfigurationData.arsDetails.lead2;
    projResendPayload.status = state().ProjectConfigurationData.selectedProjectDetails.StatusId;
    projResendPayload.comments = '';

    dispatch({
      type: SET_PROJECT_RESEND_PAYLOAD,
      projResendPayload: projResendPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setResendComment = (comment) => async (dispatch, state) => {
  try {
    let projResendPayload = state().ProjectConfigurationData.projResendPayload;

    projResendPayload.comments = comment;

    dispatch({
      type: SET_PROJECT_RESEND_PAYLOAD,
      projResendPayload: projResendPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendProjResend = (historyProps) => async (dispatch, state) => {
  try {
    let projResendPayload = state().ProjectConfigurationData.projResendPayload;
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `ProjectAccountLeaderShipApproval/RequestResendForApproval?SubMenuID=${subMenuId}`,
      projResendPayload
    );
    if (Postdata === 'success') {
      historyProps.push('/ProgramProjectConfig/Projects');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Project was re-sent for approval!',
        })
      );

      dispatch(
        getProjectProgramDetails(
          state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        )
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const setProjUploadFlag = (flag) => async (dispatch, state) => {
  try {
    let projUpload = state().ProjectConfigurationData.projUpload;
    projUpload.Flag = flag;

    dispatch({
      type: SET_PROJECT_UPLOAD,
      projUpload: projUpload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setProjUploadComment = (comment) => async (dispatch, state) => {
  try {
    let projUpload = state().ProjectConfigurationData.projUpload;
    projUpload.Comment = comment;

    dispatch({
      type: SET_PROJECT_UPLOAD,
      projUpload: projUpload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setProjUploadFiles = (files) => async (dispatch, state) => {
  try {
    let file = [];
    for (var i = 0; i < files.length; i++) {
      file = file.concat(files[i]);
    }
    dispatch({
      type: SET_PROJECT_UPLOAD_FILE,
      projUploadFile: file,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getProjectStakeholdersNew = (projectId, module) => async (dispatch, state) => {
  try {
    let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : "";
    let data = await HttpGet(`EmailService/GetProjectEmailRecipients?clientProjectId=${projectId}${IsValidStr(module) ? `&SubMenuID=${subMenuId}` : ""}`);
    dispatch({
      type: GET_PROJECT_STAKEHOLDERS,
      projectStakeholders: data.ProjectStakeholders,
    });
  } catch (err) {
    console.log(err);
  }
};

export const showSelectProject = (showSelect) => async (dispatch, state) => {
  try {
    dispatch({
      type: SHOW_SELECT_PROJECT,
      showSelectProjectView: showSelect,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setDeletedBOIFromModal = (data) => async (dispatch, state) => {
  try {
    let rejectedSFA = state().ProjectConfigurationData.rejectedSFA;
    data.map((x) => {
      let isExist = rejectedSFA.filter((e) => e.BOIID === x.BOIID);

      if (isExist.length === 0 && x.BOIStatus !== '') {
        rejectedSFA.push(x);
      }
    });
    dispatch({
      type: SET_PROJECT_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAssetNameAndDeliveredByList = () => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Config Module landing page");
    const data = await HttpGet(`NewProjectIdentifiedValueSetting/GetAssetsNameAndDeliveredBy?SubMenuID=${subMenuId}`)
    let dDownList = state().ProjectConfigurationData.assetAndDeliveredByList;
    let assetsNameList = []
    let deliveredByList = []

    data.AssestsName.map(i => {
      assetsNameList.push({
        label: i.AssetsName,
        text: i.AssetsName,
        value: i.ID
      })
    })

    data.DeliveredBy.map(i => {
      deliveredByList.push({
        label: i.DeliveredBy,
        text: i.DeliveredBy,
        value: i.ID
      })
    })

    dDownList.assetsNameList = assetsNameList
    dDownList.deliveredByList = deliveredByList

    dispatch({
      type: FETCH_ASSET_AND_DELIVEREDBY_LIST,
      assetAndDeliveredByList: dDownList
    })
  }
  catch (e) {
    console.log(e)
  }
}

export const getBusinessAdvisorListByClientID = () => async (dispatch, state) => {
  try {
    let clientID = state().SharedData.selectedClient.value
    let businessAdvisorList = [];

    let data = await HttpGet(`ProjectIdentifiedValueSetting/GetBusinessAdvisorsByClientID?clientID=${clientID}`);

    if (Object.keys(data).length > 0) {
      data.forEach((ba) => {
        businessAdvisorList.push({
          text: ba.userName,
          label: ba.userName,
          value: ba.ID,
        });
      });
      dispatch({
        type: SET_BUSINESS_ADVISOR_LIST_BY_CLIENT,
        businessAdvisorList: businessAdvisorList,
      });
    }
  } catch (err) {
    console.log(err);
  }
};