import * as Msal from "msal";
// MSAL imports
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

const Utilities = (function () {
  var webHttps = window.webConfig.web;
  var apiHttps = window.webConfig.api;
  var msalConfig = window.webConfig.msalConfig;

  var isDevoloperMode = window.webConfig.isDeveloperMode;

  // MSAL Implementation
  // var msalInstance = new Msal.UserAgentApplication(msalConfig);
  var msalInstance = new PublicClientApplication(msalConfig);

  // var access_token = localStorage.getItem('adal.idtoken');
  var access_token = localStorage.getItem("access_token");

  var dealID = null;
  var dealName = "";

  var setToken = function (token) {
    access_token = token;
  };

  var setDeal = function (id, name) {
    dealID = id;
    dealName = name;
  };

  var getWebHttps = function () {
    return webHttps;
  };

  var getApiHttps = function () {
    return apiHttps;
  };

  var getToken = async function () {
    // access_token = localStorage.getItem("access_token");

    // return access_token;
    const account = msalInstance.getActiveAccount();
        if (!account) {
            // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        //always call the acquiretokensilent first before calling any API
        const response = await msalInstance.acquireTokenSilent({
            ...window.webConfig.msalConfig.auth,
            account: account
        }).catch(err => {
            //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
            //always check if the error is something that requires auth login interaction 
            if (err instanceof InteractionRequiredAuthError) {
                return msalInstance.acquireTokenPopup({
                    ...window.webConfig.msalConfig.auth,
                    account: msalInstance.getActiveAccount()
                }).catch(err => {
                    console.log('Error getting popup token: ' + JSON.stringify(err))
                });
            }
        });
        return (response ? response.accessToken : '');
  };

  var getDeal = function () {
    return {
      id: dealID,
      name: dealName,
    };
  };

  var headers = {
    "Cache-Control":"no-store",
    pragma: "no-cache",
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken(),
  };

  var getHeaders = function () {
    return headers;
  };

  var setSessionStorage = function (storageName, value) {
    if (typeof Storage !== "undefined") {
      localStorage.setItem(storageName, JSON.stringify(value));
      return true;
    }
    return null;
  };

  var getSessionStorage = function (storageName) {
    if (typeof Storage !== "undefined") {
      return localStorage.getItem(storageName);
    }
    return null;
  };

  var isNotEmpty = function (value) {
    switch (value) {
      case null:
        return false;
      case undefined:
        return false;
      case "":
        return false;
      default:
        return true;
    }
  };

  //Validations
  var isEmpty = function (value) {
    switch (value) {
      case null:
        return false;
      case undefined:
        return false;
      case "":
        return false;
      default:
        return true;
    }
  };

  var validateWebStorage = function (storageName) {
    var storageValue = null;
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem(storageName) !== null) {
        storageValue = localStorage.getItem(storageName);
      }
    } else {
      return null;
    }
    return storageValue;
  };

  var validateNameField = function (value) {
    var re = /^[a-zA-Z0-9.,-_ \u00f1\u00d1]*$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateWBSNumberField = function (value) {
    var re = /^[a-zA-Z0-9]{6,8}$/;
    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateAlphanumericField = function (value) {
    var re = /^[A-Za-z0-9]*$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateAlphanumericFieldForNames = function (value) {
    var re = /^[A-Za-z0-9.,-\w\s]*$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateAlphanumericWithSpecialCharactersField = function (value) {
    var re = /^[a-zA-Z0-9!@#$&()\\-`.+,/;"\w\s]*$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateEmailField = function (value) {
    var re = /.+@.+/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };
  /* Optional Contact Number */
  var validateContactNumber = function (value) {
    var re = /^[0-9]+$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  /* Required Contact Number - Should have a value*/
  var validateReqContactNumber = function (value) {
    var re = /^[0-9]+$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  /* Optional Contact Number */
  var validateDecimal = function (value) {
    // var re = /^[0-9.]*$/
    var re = /^(\d{0,4})(\.[0-9]{0,4})?$/;

    if (value === null || value === "") {
      return 0;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateDecimalValue = function (value) {
    // var re = /^[0-9.]*$/
    var re = /(^[0-9]{0,4}\.[0-9]{1,4}$)|(^[0-9]{0,4}$)/;

    if (value === null || value === "") {
      return 0;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateEIDField = function (value) {
    var re = /^[a-zA-Z.]*$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateUploadedFile = function (value) {
    // var re = /[^0-9A-Za-z._\-\s*)([\]:\\]/

    if (value === null || value === "") {
      return 1;
    } else {
      // return (re.test(value)) ? 2 : 0;
      return 0;
    }
  };

  var validateFileExtension = function (value, id) {
    let allowedPptExt = ["ppt", "pptx"];
    let allowedExt = [
      "doc",
      "docx",
      "ppt",
      "pptx",
      "xls",
      "xlsx",
      "htm",
      "html",
      "msg",
    ];

    var ext = value.split(".").pop().toLowerCase();

    if (id === "formClientAcknowledgementEmail") {
      if (value === null || value === "") {
        return 0;
      } else {
        return allowedExt.indexOf(ext) > -1 ? 0 : 2;
      }
    } else if (id === "form4BlockerCaseStudy") {
      if (value === null || value === "") {
        return 0;
      } else {
        return allowedPptExt.indexOf(ext) > -1 ? 0 : 2;
      }
    }
  };

  /*Employee ID - Should have a value*/
  var validateEmployeeIDField = function (value) {
    var re = /^[0-9]+$/;

    if (value === null || value === "") {
      return 1;
    } else {
      return re.test(value) ? 0 : 2;
    }
  };

  var validateFreeText = function (value) {
    var re1 = /^$|^([a-zA-Z0-9 \\\/""'#,;%^*$?!.()@:`&-_{}~]+)$/;
    var re2 = /(\<(?:[^:]+:)?script\>.*?\<\/(?:[^:]+:)?script\>)|(\<(?:[^:]+:)?script\/>.*?\<\/(?:[^:]+:)?script\>)|(\<.*?(?:[^:]+:)?\/.*?(?:[^:]+:)?\>)/;
    var returnValue = false;
    if (re1.test(value)) {
      returnValue = !re2.test(value);
    } else {
      returnValue = re1.test(value);
    }
    return returnValue;
  };

  var DownloadableFiles = function (fileName, URL, token, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", URL);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    //For Testing
    xhr.setRequestHeader( "Cache-Control","no-store");
    xhr.setRequestHeader("pragma", "no-cache");

    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        var url = window.URL || window.webkitURL;
        var downloadUrl = url.createObjectURL(this.response);
        var a = document.createElement("a");
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(this.response, fileName);
        } else if (typeof a.download === "undefined") {
          window.location = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
        if (callback) {
          callback();
        }
      }
    };
    xhr.send();
  };

  var validateGeneralFileExtension = function (value, accepts) {
    let ext = value.name.split(".").pop().toLowerCase();
    if (value === null || value === "") {
      return 1;
    } else {
      return accepts.indexOf(ext) > -1 ? 0 : 2;
    }
  };

  var validateFileSize = function (value) {
    if (value === null || value === "") {
      return 1;
    } else {
      return value < 10000000 ? 0 : 2;
    }
  };

  var validateBulkRequestFileSize = function (value) {
    if (value === null || value === "") {
      return 1;
    } else {
      return value < 5000000 ? 0 : 2;
    }
  };

  var validateImageFileExtension = function (value) {
    let accepts = ["png", "jpg", "jpeg", "bmp"];
    let ext = value.name.split(".").pop().toLowerCase();
    if (value === null || value === "") {
      return 1;
    } else {
      return accepts.indexOf(ext) > -1 ? 0 : 2;
    }
  };

  var validateImageFileSize = function (value) {
    let size = value.size;
    if (size === null || size === "" || size === 0) {
      return 1;
    } else {
      return size < 1000000 ? 0 : 2;
    }
  };
  let idleTime;
  let handleUserActions = () => {
    window.onmousemove = resetTimer; // catches mouse movements
    window.onmousedown = resetTimer; // catches mouse movements
    window.onclick = resetTimer; // catches mouse clicks
    window.onscroll = resetTimer; // catches scrolling
    window.onkeypress = resetTimer; //catches keyboard actions
  };
  let resetTimer = () => {
    clearTimeout(idleTime);
    idleTime = setTimeout(stopSession, 3600000); // time is in milliseconds (60 mins of idle time)
  };
  let stopSession = () => {
    localStorage.setItem("logout", true);
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('logout-event', 'logout' + Math.random());
    window.location.href = Utilities.environment.msalConfig.auth.logout;
  };

  let handleLogout = () => {
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-event') { 
            stopSession();
            setTimeout(clearStorage(), 1000);
        }
    });
  }

  let clearStorage = () => {
      localStorage.clear();
      sessionStorage.clear()
  }

  let keys = () => {
    return {
      passPhrase: "Pa55Phra53",
      ivSize: 128,
      saltSize: 256,
      keySize: 256,
      iterations: 1000,
    };
  }

  return {
    environment: {
      msalInstance: msalInstance,
      msalConfig: msalConfig,
      isDevoloperMode: isDevoloperMode,
    },
    setToken,
    setDeal,
    getWebHttps,
    getApiHttps,
    getToken,
    getDeal,
    getHeaders,
    setSessionStorage,
    getSessionStorage,
    isEmpty: isEmpty,
    ValidateNameField: validateNameField,
    ValidateWBSNumberField: validateWBSNumberField,
    ValidateAlphanumericField: validateAlphanumericField,
    ValidateAlphanumericFieldForNames: validateAlphanumericFieldForNames,
    ValidateContactNumber: validateContactNumber,
    ValidateReqContactNumber: validateReqContactNumber,
    ValidateEmailField: validateEmailField,
    ValidateDecimal: validateDecimal,
    ValidateWebStorage: validateWebStorage,
    ValidateEIDField: validateEIDField,
    isNotEmpty: isNotEmpty,
    ValidateDecimalValue: validateDecimalValue,
    ValidateUploadedFile: validateUploadedFile,
    ValidateFileExtension: validateFileExtension,
    DownloadableFiles: DownloadableFiles,
    ValidateGeneralFileExtension: validateGeneralFileExtension,
    ValidateFileSize: validateFileSize,
    validateBulkRequestFileSize: validateBulkRequestFileSize,
    ValidateEmployeeIDField: validateEmployeeIDField,
    ValidateAlphanumericWithSpecialCharactersField: validateAlphanumericWithSpecialCharactersField,
    ValidateImageFileExtension: validateImageFileExtension,
    ValidateImageFileSize: validateImageFileSize,
    ValidateFreeText: validateFreeText,
    handleUserActions: handleUserActions,
    handleLogout, stopSession,
    keys
    // logout
  };
})();

export default Utilities;
