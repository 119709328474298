import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ADHOC_REP, CANNED_REP } from '../../Constants/Modules';

import CVMModal from '../Shared/CVMModal/CVMModal';
import { LogUserSiteAction } from '../../Utilities/AccessManagementUtil';
import { GetToken } from '../../Utilities/UserSession';
import { HasValue, IsValid } from '../../Utilities/Validations';
import { getUserTimeZone } from '../../Utilities/Formatter';

import CheckCircle from '../../Static/images/svg/CheckCircle.svg';
import CannedReports from '../../Static/images/svg/CannedReports.svg';
import AdhocReport from '../../Static/images/svg/AdhocReport.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './ReportingModule.css';
const ReportingModule = (function () {
  class ReportingModulePresentational extends Component {
    constructor(props) {
      super(props);
      
    }
  
    render() {
      return (
        <React.Fragment>
          <CVMModal
            show={this.props.showDataExtractModal}
            onCloseModal={
              // this.props.dataExtractStatus === 'completed' ||
              //   this.props.dataExtractStatus === 'failed'
              //   ? 
                this.props.onClickCloseModal
               // : ''
            }
            size="s"
            customclass="data-extract-modal-container"
            content={
              <React.Fragment>
                {this.props.dataExtractStatus === 'downloading' && (
                  <div className="download-content">
                    <div className="progress-animation">
                      <div className="loading-animation">
                        {/* <div className='lds-ripple'>
                          <div></div>
                          <div></div>
                        </div> */}
                        <Spinner animation="border" size="lg" className="data-extract-spinner" />
                        <div className="loading-text">{this.props.text}</div>
                      </div>
                    </div>
                    <h6>
                      Data Extraction is in progress and may take few mins to complete. System will
                      notify you once extraction is complete.
                    </h6>
                  </div>
                )}
                {this.props.dataExtractStatus === 'completed' && (
                  <div className="data-extract-completed">
                    <img className="data-dowload-completed-img" src={CheckCircle} alt="completed" />
                    <h6>Data Extracted successfully</h6>
                  </div>
                )}
                {this.props.dataExtractStatus === 'failed' && (
                  <div className="data-extract-failed">
                    <FontAwesomeIcon icon={faExclamationCircle} color="#a100ff" size="xl" />
                    <h6>Data Extraction Failed</h6>
                  </div>
                )}
              </React.Fragment>
            }
          />
          <Row className="tileContainer">
            <div className="tileCanned">
              <Col md={10} sm={10}>
                <div className="img">
                  <img src={CannedReports} alt="CannedReports" />
                </div>
                <div className="d">Canned Reports</div>
                <div className="a">Reports</div>
                <div className="c">Access Value delivered and adoption reports from here.</div>
              </Col>
              <div className="leftbutton" onClick={() => LogUserSiteAction({ module: CANNED_REP })}>
                <a
                  href="https://app.powerbi.com/groups/50077dac-bf61-4278-aa03-39ecf43e91a4/reports/31cf796f-8362-4ec1-a574-93f0721bba73/ReportSection2436016300531384038b?bookmarkGuid=Bookmark5f7c5a29d4cc2fa6e5f7"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
            {this.props.isSuperAdmin ? (
              <div className="tileAdhoc">
                <Col md={10} sm={10}>
                  <div className="img">
                    <img src={AdhocReport} alt="AdhocReport" />
                  </div>
                  <div className="d">Adhoc Reports</div>
                  <div className="b">Download Data Extract</div>
                  <div className="c">
                    Download data extract in excel format to create adhoc reports
                  </div>
                </Col>
                <div className="leftbutton" onClick={this.props.onClickOpenModal}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            ) : (
              ''
            )}
          </Row>
        </React.Fragment>
      );
    }
  }
  class ReportingModuleContainer extends Component {
    constructor() {
      super();
      this.myRef = React.createRef();
      this.state = {
        showDataExtractModal: false,
        dataExtractStatus: '',
        
      };
    }
    onClickOpenModal = () => {
      LogUserSiteAction({ module: ADHOC_REP })
      this.setState({
        showDataExtractModal: true,
        dataExtractStatus: 'downloading',
      });
      this.downloadDataExtract();
    };
    HttpGetDownloadFile = async (url, filename, callback, options) =>{
      this.myRef.current=new AbortController();
      let token = await GetToken();
      options = {
        ...options,
        signal:this.myRef.current.signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          'Cache-Control':'no-store',
          pragma: 'no-cache',
          Authorization: 'Bearer ' + token,
        },
      };
    
      url = new URL(window.webConfig.api + url);
      let responseData;
      await fetch(url, options)
        .then((response) => {
          responseData = response;
          if (response.status === 200) {
            return response.blob();
          }
          return;
        })
        .then((blob) => {
          let a = document.createElement('a');
          let isIE = !!document.documentMode;
          if (isIE) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            let urlResponse = URL.createObjectURL(blob);
            a.href = urlResponse;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            if (HasValue(callback)) callback();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    
      return responseData;
    }
    onClickCloseModal = () => {
      this.myRef.current&&this.myRef.current.abort();
      this.setState({
        showDataExtractModal: false,
        dataExtractStatus: '',
      });
    };

    downloadDataExtract = async () => {
      try {
        let tz = getUserTimeZone();
        let isNegative = tz.includes('-');
        tz = tz.replace('-', '').replace('+', '');

        let data = await this.HttpGetDownloadFile(
          `DataExtractReportDownload/GetExtractReportData?UTCOffset=${tz}&isNegative=${isNegative}`,
          'SVM Admin Extract Report Template'
        );
        if (data.status === 200) {
          this.setState({
            dataExtractStatus: 'completed',
          });
        } else {
          this.setState({
            dataExtractStatus: 'failed',
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    render() {
      const { isSuperAdmin } = this.props.userInformation;
      return (
        <ReportingModulePresentational
          isSuperAdmin={isSuperAdmin}
          showDataExtractModal={this.state.showDataExtractModal}
          dataExtractStatus={this.state.dataExtractStatus}
          onClickOpenModal={this.onClickOpenModal}
          onClickCloseModal={this.onClickCloseModal}
        />
      );
    }
  }

  return ReportingModuleContainer;
})();

export default connect(
  (state) => {
    return {
      userInformation: state.userInformation,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, {}), dispatch)
)(ReportingModule);
