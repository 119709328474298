import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HttpGetDownloadFile, HttpGet, HttpPost } from '../../Utilities/HTTPUtil';
import { isValidURL } from "../../Utilities/Validations";
import { Row, Col } from 'react-bootstrap';
// import { history } from '../../store';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ContactUs from '../../Static/images/svg/ContactUs.svg';
import HelpSupport from '../../Static/images/svg/HelpSupport.svg';
import Logout from '../../Static/images/svg/Logout.svg';
import Reports from '../../Static/images/svg/Reports.svg';
import Download from '../../Static/images/360cvm/download-purple.svg';
import UploadSupportDocs from '../../Static/images/svg/UploadSupportDocs.svg';
import {
    showProfileButton,
    onShowUploadSupport,
} from '../../Actions/Shared.Action';

const ProfileMenu = (function () {
    class ProfileMenuPresentational extends Component {
        render() {
            let hr = <hr style={{ margin: '0.5em 0em', borderTop: '0.5px solid rgba(0,0,0,.1)', display: 'block' }} />;
            let isDemoAccnt = this.props.userInformation.userProfiles[0].ProfileID === 14;
            return (
                <Row id="profile-pop" onClick={(e) => e.stopPropagation()} >
                     
                        <Col md={6} xs={12}>
                           {!isDemoAccnt && <Row
                                className="profile-main-section"
                                onClick={() => this.props.handleReports()}
                            >
                                <div><img alt="" src={Reports} /></div>
                                <div>
                                    <div>Reports</div>
                                    <div>View Report</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                    />
                                </div>
                            </Row>}
                            <Row
                                className="profile-main-section"
                                onClick={(e) => this.props.handleLogout(e)}
                            >
                                <div><img alt="" src={Logout} /></div>
                                <div>
                                    <div>Logout</div>
                                    <div>Logout from SVM tool</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                    />
                                </div>
                            </Row>
                            {this.props.userInformation.isSuperAdmin && !isDemoAccnt && (
                                <Row
                                    className="profile-main-section"
                                    onClick={() => { this.props.onShowUploadSupport(true) }}
                                >
                                    <div><img alt="" src={UploadSupportDocs} /></div>
                                    <div>
                                        <div>Upload Support Document</div>
                                        <div>Access Support Document Module</div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="profile-menu-chevron"
                                        />
                                    </div>
                                </Row>
                            )}
                        </Col>
                    
                    <Col md={6} xs={12}>
                        <Row className="profile-main-section profile-cursor">
                            <div><img alt="" src={HelpSupport} /></div>
                            <div>
                                <div>Help & Support</div>
                                <div>Download Files</div>
                            </div>
                        </Row>
                        <Row className="profile-sub-section">
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleToolUserManual(e)}
                            >
                                <div>
                                    <div>Tool User Manual</div>
                                    <div>SVM User Manual (.pptx)</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                        onClick={() => { }}
                                    />
                                </div>
                            </div>
                            <div>{hr}</div>
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleToolOSRecord(e)}
                            >
                                <div>
                                    <div>Tool Orientation Session Record</div>
                                    <div>Media Exchange Recording (.mp4)</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                        onClick={() => { }}
                                    />
                                </div>
                            </div>
                            <div>{hr}</div>
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleDownloadRACI(e)}
                            >
                                <div>
                                    <div>SynOps Value Meter RACI</div>
                                    <div>Download RACI file (.pptx)</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                    />
                                </div>
                            </div>
                            <div>{hr}</div>
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleDownloadCST(e)}
                            >
                                <div>
                                    <div>Client Story Template</div>
                                    <div>Download template (.pptx)</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                    />
                                </div>
                            </div>
                        </Row>
                    </Col>
                    {/* <Col md={!isDemoAccnt ? 4 : 6} xs={12}>
                        <Row className="profile-main-section profile-cursor">
                            <div><img alt="" src={ContactUs} /></div>
                            <div>
                                <div>Contact Us</div>
                                <div>SynOps Value Meter Admins</div>
                            </div>
                        </Row>
                        <Row className="profile-sub-section">
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleAccessBPSPortal(e)}
                            >
                                <div>
                                    <div>Request for Access</div>
                                    <div>Access BPS portal</div>
                                    <div onClick={(e) => this.props.handleUserManual(e)}>
                                        <img alt="" src={Download} style={{ height: '16px' }} /> Access Request User Guide.pptx
                                    </div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"
                                        onClick={() => { }}
                                    />
                                </div>
                            </div>
                            <div>{hr}</div>
                            <div
                                className="sub-section-container"
                                onClick={(e) => this.props.handleEmailtoAdmin(e)}
                            >
                                <div>
                                    <div>SynOps Value Meter Admin</div>
                                    <div>Share feedbacks/ inputs via mail</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="profile-menu-chevron"

                                    />
                                </div>
                            </div>
                        </Row>
                       
                    </Col> */}
                </Row>
            );
        }
    }

    class ProfileMenuContainer extends Component {
        constructor(props) {
            super(props);
            this.state = {
                supportDocsDetails: [],
                supportLinksDetails: []
            };
        }

        componentDidMount = async () => {
            let docsDetails = await HttpGet('ProfileMenu/GetSupportDocuments');
            let linksDetails = await HttpGet('ProfileMenu/GetSupportLinks');
            this.setState({
                supportDocsDetails: docsDetails,
                supportLinksDetails: linksDetails
            })
        }

        handleDownloadRACI = (e) => {
            e.stopPropagation();
            let details = [];
            details = this.state.supportDocsDetails.filter(i => i.DocumentType == 2)[0];
            let downloadFileName = `${details.FileName}`
            HttpGetDownloadFile(`ProfileMenu/DownloadSupportDocuments?ID=${details.ID}`, downloadFileName);
        }

        handleDownloadCST = (e) => {
            e.stopPropagation();
            let details = [];
            details = this.state.supportDocsDetails.filter(i => i.DocumentType == 3)[0];
            let downloadFileName = `${details.FileName}`
            HttpGetDownloadFile(`ProfileMenu/DownloadSupportDocuments?ID=${details.ID}`, downloadFileName);
        }

        handleUserManual = (e) => {
            e.stopPropagation();
            let downloadFileName = 'Access Request User Guide.pptx'
            HttpGetDownloadFile('ProfileMenu/DownloadUserManual', downloadFileName);
        }

        handleAccessBPSPortal = (e) => {
            e.stopPropagation();
            let url = 'https://bps-portal.accenture.com';
            window.open(url, '_blank').focus();
        }

        handleEmailtoAdmin = (e) => {
            e.stopPropagation();
            window.open('mailto:SVM.admin@accenture.com');
        }

        handleLogout = (e) => {
            e.stopPropagation();
            this.props.onLogoutButtonClicked()
        }

        handleToolUserManual = (e) => {
            e.stopPropagation();
            let details = [];
            details = this.state.supportLinksDetails.filter(i => i.DocumentType == 0)[0];
            if (isValidURL(details.Link)){
                window.open(details.Link, '_blank').focus();
            }
        }

        handleToolOSRecord = (e) => {
            e.stopPropagation();
            let details = [];
            details = this.state.supportLinksDetails.filter(i => i.DocumentType == 1)[0];
            if (isValidURL(details.Link)){
                window.open(details.Link, '_blank').focus();
            }
        }

        handleReports = () => {
            this.props.showProfileButton(false);
            this.props.history.push('/ReportingModule');
        }

        onShowUploadSupport = (show) => {
            this.props.showProfileButton(false);
            this.props.onShowUploadSupport(show);
        }

        render() {
            { }
            return (
                <ProfileMenuPresentational
                    handleUserManual={this.handleUserManual}
                    handleAccessBPSPortal={this.handleAccessBPSPortal}
                    handleEmailtoAdmin={this.handleEmailtoAdmin}
                    handleLogout={this.handleLogout}
                    handleToolUserManual={this.handleToolUserManual}
                    handleToolOSRecord={this.handleToolOSRecord}
                    handleReports={this.handleReports}
                    handleDownloadRACI={this.handleDownloadRACI}
                    handleDownloadCST={this.handleDownloadCST}
                    onShowUploadSupport={this.onShowUploadSupport}
                    userInformation={this.props.userInformation}
                />
            );
        }
    }
    return ProfileMenuContainer;
})();

export default connect(
    (state) => {
        return {
            userInformation: state.userInformation,
        };
    },
    (dispatch) =>
        bindActionCreators(
            Object.assign(
                {},
                {
                    showProfileButton,
                    onShowUploadSupport,
                }
            ),
            dispatch
        )
)(withRouter(ProfileMenu));
