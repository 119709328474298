import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { GrooveTabComponent, GrooveIconSvg } from 'tfo-groove';

import { SUB_MOD_DASHBOARD, ACC_PROG, ACC_PROJ } from '../../Constants/Modules';
import CVMModal from '../Shared/CVMModal/CVMModal';
import CVMToasterNotif from '../Shared/CVMToasterNotif/CVMToasterNotif';

import {
  CheckModuleAccess, DeleteCallbackURL, DeleteDistiURL,
  DeleteReferrer, LogUserSiteAction
} from '../../Utilities/AccessManagementUtil';
import { TryGetObjValueAlt, TryGetNodeAlt } from '../../Utilities/Formatter';
import { HasValue, IsValidStr, IsValid, IsObjEmpty } from '../../Utilities/Validations';

// import { history } from '../../store';
import { withRouter } from "react-router-dom";
import {
  getClientList,
  onSearch,
  showClientDropdown,
  setSelectedClient,
  explicitSetClientList,
  setToasterParam,
  showLeftSideBar
} from '../../Actions/Shared.Action';
import { onClickFavorite } from '../../Actions/ClientConfiguration.Action';
import {
  unsetClientDetails
} from '../../Actions/Dashboard.Action';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

import "svm-dashboard/dist/assets/css/Dashboard.css";
import './Dashboard.css';

import { GetAuthObj } from '../../Utilities/HTTPUtil';
import { GenRVDURLProg, GenPPCProg } from '../ProgramProjectConfiguration/PPCUtil';

// import { DashboardLandingView, SummaryWheelView, DashboardDetailedView } from '../DashboardWheelSVM/index';
import { DashboardLandingView, SummaryWheelView, DashboardDetailedView } from 'svm-dashboard';
//import { DataEvent } from '../../Utilities/DataEvents';

const Dashboard = (function () {
  class DashboardPresentational extends Component {
    render() {
      const noDataMessage = {
        header: "No Client Data",
        body: "Please select Program and Reporting year."
      }

      return (
        <>
          <CVMToasterNotif />
          {this.props.clientList && (
            <CVMModal
              title={'Select Client'}
              show={this.props.show}
              onCloseModal={this.props.onCloseModal}
              size={this.props.size}
              content={
                <ModalContent
                  modalActiveKey={this.props.modalActiveKey}
                  onChangeModalTab={this.props.onChangeModalTab}
                  clientList={this.props.clientList}
                  onClickFavorite={this.props.onClickFavorite}
                  onSearch={this.props.onSearch}
                  filterKeyword={this.props.filterKeyword}
                  onSelectClient={this.props.onSelectClient}
                  selectedTab={this.props.selectedTab}
                />
              }
            />
          )}
          {
            !this.props.hasNoAccess ? (
              <div className="data-visualisation-container svm-tool">
                {
                  !this.props.isStart && (
                    <DashboardLandingView
                      dbDimensionList={this.props.dbDimensionList}

                      onStart={this.props.onStart}
                      // Dropdown
                      selectedClient={this.props.selectedClient}
                      isInitialized={this.props.isInitialized}
                      isStart={this.props.isStart}
                      isKnowMore={this.props.isKnowMore}
                      // displayFilterLabel={true} // Optional: For CC use
                      isHideRightSection={true}

                      dashboardClientDetails={this.props.dashboardClientDetails}
                      dashboardData={this.props.dashboardData}
                      programOptions={this.props.programOptions}
                      selectedProgram={this.props.selectedProgram}
                      yearOptions={this.props.yearOptions}
                      selectedYear={this.props.selectedYear}
                      // For auto select functionality
                      isDBAutoSelect={this.props.isDBAutoSelect}
                      progID={this.props.progID}
                      reportingYr={this.props.reportingYr}

                      // Actions
                      onClickBackBtn={this.props.onClickBackBtn}
                      createLog={this.props.createLog} // Optional: Logger function for SVM
                      enableLogger={true}
                      getAuthObj={this.props.getAuthObj}

                      // Callback
                      fetchDashboardDataCallback={this.props.fetchDashboardDataCallback}
                      fetchClientDetailsCallback={this.props.fetchClientDetailsCallback}
                      onSelectProgramCallback={this.props.onSelectProgramCallback}
                      setSelectedReportingYearCallback={this.props.setSelectedReportingYearCallback}
                      getDashboardDimensionsCallback={this.props.getDashboardDimensionsCallback}
                      setCommentaryRequiredDataCallback={this.props.setCommentaryRequiredDataCallback}

                      //QC
                      showQCfunctionality={this.props.showQCfunctionality} // Optional, default to false
                      handleShowCommentIconCallback={this.props.handleShowCommentIconCallback}
                      getBOIDimensionMappingListsCallback={this.props.getBOIDimensionMappingListsCallback}
                      programID={this.props.programID}

                      // UserInformation
                      enterpriseID={this.props.enterpriseID} // default to "", Current User
                      isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
                      userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
                      userInfo={this.props.UserInformation}
                    />
                  )
                }


                {this.props.isStart &&
                  (this.props.isKnowMore ? (
                    <DashboardDetailedView
                      dbDimensionList={this.props.dbDimensionList} // main data - DashboardProjectDetails
                      dimensionSubDimension={this.props.dimensionSubDimension} // main data - DashboardDimGraph
                      programID={this.props.programID}
                      dashboardCommentary={this.props.dashboardCommentary}
                      reportingYear={this.props.reportingYear}
                      programFiscalYearRange={this.props.programFiscalYearRange}

                      // UserInformation
                      enterpriseID={this.props.enterpriseID} // default to "", Current User
                      isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
                      userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
                      userInfo={this.props.UserInformation}

                      showBackButton={true} // default to false
                      showCommentary={true} // Optional, default to false, Set to false or remove for non-SVM
                      noDataMessage={noDataMessage}

                      // Dropdown
                      selectedClient={this.props.selectedClient}
                      isInitialized={this.props.isInitialized}
                      isStart={this.props.isStart}
                      isKnowMore={this.props.isKnowMore}
                      // displayFilterLabel={true} // Optional: For CC use
                      isHideRightSection={true}

                      dashboardClientDetails={this.props.dashboardClientDetails}
                      dashboardData={this.props.dashboardData}
                      programOptions={this.props.programOptions}
                      selectedProgram={this.props.selectedProgram}
                      yearOptions={this.props.yearOptions}
                      selectedYear={this.props.selectedYear}
                      // For auto select functionality
                      isDBAutoSelect={this.props.isDBAutoSelect}
                      progID={this.props.progID}
                      reportingYr={this.props.reportingYr}
                      // Actions
                      onClickBackBtn={this.props.onClickBackBtn}
                      createLog={this.props.createLog} // Logger function for SVM
                      enableLogger={true}  // default to false
                      getAuthObj={this.props.getAuthObj}

                      // Callback
                      fetchDashboardDataCallback={this.props.fetchDashboardDataCallback}
                      fetchClientDetailsCallback={this.props.fetchClientDetailsCallback}
                      onSelectProgramCallback={this.props.onSelectProgramCallback}
                      setSelectedReportingYearCallback={this.props.setSelectedReportingYearCallback}
                      getDashboardDimensionsCallback={this.props.getDashboardDimensionsCallback}
                      setCommentaryRequiredDataCallback={this.props.setCommentaryRequiredDataCallback}

                      //QC
                      showQCfunctionality={this.props.showQCfunctionality} // Optional, default to false
                      handleShowCommentIconCallback={this.props.handleShowCommentIconCallback}
                      getBOIDimensionMappingListsCallback={this.props.getBOIDimensionMappingListsCallback}
                    />
                  ) : (
                    <SummaryWheelView
                      dimensionSubDimension={this.props.dimensionSubDimension} // main data
                      dashboardProgramDetails={this.props.dashboardProgramDetails}

                      onKnowMore={this.props.onKnowMore}
                      // isGlobal={false} - default to false when no value
                      // isGlobalPreview={false} - default to false when no value 
                      displayDownloadReport={true} // Optional, default to false, Set to false or remove for non-SVM
                      displayKnowMore={true} // - default to false
                      programFiscalYearRange={this.props.programFiscalYearRange}
                      rvdLink={this.props.goToRVDProgLevel} // rvdLink for qc modal
                      ppcLink={this.props.goToPPCProgLevel} // ppcLink for dashboard
                      // Dropdown
                      selectedClient={this.props.selectedClient}
                      isInitialized={this.props.isInitialized}
                      isStart={this.props.isStart}
                      isKnowMore={this.props.isKnowMore}
                      // displayFilterLabel={true} // Optional: For CC use
                      isHideRightSection={true}

                      dashboardClientDetails={this.props.dashboardClientDetails}
                      dashboardData={this.props.dashboardData}
                      programOptions={this.props.programOptions}
                      selectedProgram={this.props.selectedProgram}
                      yearOptions={this.props.yearOptions}
                      selectedYear={this.props.selectedYear}
                      subOfferingList={this.props.dashboardCommentary.subOfferingList}
                      programStakeHolders={this.props.dashboardCommentary.selectedProgram}
                      // For auto select functionality
                      isDBAutoSelect={this.props.isDBAutoSelect}
                      progID={this.props.progID}
                      reportingYr={this.props.reportingYr}

                      // Actions
                      onClickBackBtn={this.props.onClickBackBtn}
                      createLog={this.props.createLog} // Logger function for SVM
                      enableLogger={true}  // default to false
                      getAuthObj={this.props.getAuthObj}

                      // Callback
                      fetchDashboardDataCallback={this.props.fetchDashboardDataCallback}
                      fetchClientDetailsCallback={this.props.fetchClientDetailsCallback}
                      onSelectProgramCallback={this.props.onSelectProgramCallback}
                      setSelectedReportingYearCallback={this.props.setSelectedReportingYearCallback}
                      getDashboardDimensionsCallback={this.props.getDashboardDimensionsCallback}
                      setCommentaryRequiredDataCallback={this.props.setCommentaryRequiredDataCallback}

                      //QC
                      showQCfunctionality={this.props.showQCfunctionality} // Optional, default to false
                      showQCheckCommentModal={this.props.showQCheckCommentModal}
                      shouldShowCommentIcon={this.props.shouldShowCommentIcon}
                      handleShowCommentIconCallback={this.props.handleShowCommentIconCallback}
                      getBOIDimensionMappingListsCallback={this.props.getBOIDimensionMappingListsCallback}
                      BOIMappingListAllOfferings={this.props.BOIMappingListAllOfferings}
                      handleQCCheckComment={this.props.handleQCCheckComment}
                      programID={this.props.programID}
                      // UserInformation
                      enterpriseID={this.props.enterpriseID} // default to "", Current User
                      isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
                      userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
                      userInfo={this.props.UserInformation}
                    />
                  ))}
              </div>
            ) : (
              <div>
                <Row className="access_denied_container">
                  <Col md={12} xs={12}>
                    <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                    <div className="access_denied_content">
                      <h1>Access Denied</h1>
                      <p>
                        You don't have the necessary user privileges to view the client that you are
                        attempting to access.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          }
        </>
      );
    }
  }

  class ModalContent extends Component {
    render() {
      const tab_data = [
        {
          tab_label: 'All Clients',
          tab_value: 0,
          tab_id: 'all-tab',
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList
                    .filter((x) => !x.isClientDataEmpty)
                    .filter((x) =>
                      x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <Col lg={4} md={6} key={index}>
                          <div className="cvm-project-tile">
                            <div
                              className="client-details-select"
                              onClick={() => this.props.onSelectClient(item)}
                            >
                              <div className="project-logo">
                                <img src={item.icon} alt="dashboard" />
                              </div>
                              <div className="project-name label14-medium-midnight">
                                {item.label}
                              </div>
                            </div>
                            <div className={`star-container ${item.favorite ? 'no-filter' : ''}`}>
                              <div onClick={() => this.props.onClickFavorite(item)}>
                                <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          ),
        },
        {
          tab_label: 'Favorites',
          tab_value: 1,
          tab_id: 'favorites-tab',
          tab_content: (
            <div className="cvm-tab-content">
              <div className="cvm-project-tile-container">
                <Row>
                  {this.props.clientList
                    .filter((x) => !x.isClientDataEmpty)
                    .filter(
                      (x) =>
                        x.label.toLowerCase().includes(this.props.filterKeyword.toLowerCase()) &&
                        x.favorite
                    )
                    .map((item, index) => {
                      return (
                        <Col lg={4} md={6} key={index}>
                          <div className="cvm-project-tile">
                            <div
                              className="client-details-select"
                              onClick={() => this.props.onSelectClient(item)}
                            >
                              <div className="project-logo">
                                <img src={item.icon} alt="dashboard" />
                              </div>
                              <div className="project-name label14-medium-midnight">
                                {item.label}
                              </div>
                            </div>
                            <div className={`star-container ${item.favorite ? 'no-filter' : ''}`}>
                              <div onClick={() => this.props.onClickFavorite(item)}>
                                <FontAwesomeIcon icon={item.favorite ? solidStar : regularStar} />
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          ),
        },
      ];
      return (
        <div>
          <div className="search-bar-container">
            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input
                type="text"
                name="name"
                placeholder="Search..."
                maxlength="255"
                onChange={(e) => this.props.onSearch(e)}
              />
            </div>
          </div>
          <GrooveTabComponent
            theme="purple"
            tab_data={tab_data}
            selectedIndex={this.props.selectedTab}
          />
        </div>
      );
    }
  }

  class DashboardContainer extends Component {
    constructor() {
      super();
      this.state = {
        modalActiveKey: 1,
        show: false,
        size: 'md',
        selectedTab: -1,
        clientValue: 0,
        selectedYear: '',
        selectedProgram: '',
        startButton: true,
        isStart: true,
        isKnowMore: false,
        isInitialized: false,
        dashboardProgram: [],
        dashboardData: [],
        dashboardClientDetails: {},
        reportingYear: [],
        // Dashboard data converted to state
        programFiscalYearRange: {
          min: 0,
          max: 0,
        },
        dbDimensionList: undefined,
        dimensionSubDimension: [],
        programID: '',
        dashboardProgramDetails: {},
        dashboardCommentary: {},
        // For auto select functionality
        isDBAutoSelect: false,
        progID: 0,
        reportingYr: 0,
        tempURLParams: {
          progID: 0,
          reportingYr: 0
        },
        hasNoAccess: false,
        shouldShowCommentIcon: false,
        showQCheckCommentModal: false,
        BOIMappingListAllOfferings: [] // <-- being passed only in Summary Wheel View. If needed, pass this to other comp
      };
    }
    componentDidMount = async () => {
      let dbSearchParams = window.location.search;
      DeleteCallbackURL();
      DeleteReferrer();
      DeleteDistiURL();

      // For auto select functionality
      let urlParams = await this.getURLParams(dbSearchParams);
      if (IsValid(urlParams.clientID)) {
        if (!window.location.pathname.toLowerCase().includes('/dashboard')) return;

        await this.loadDBFromURL(urlParams);
        return;
      }

      this.setState(
        {
          isStart: false,
        },
        () => {
          this.clearDashboard();
        }
      );

      const client = TryGetObjValueAlt(this.props.SharedData, 0, 'selectedClient.value')
      if (document.visibilityState === 'visible') {
        this.createLog({
          projectID: null
        });
      }

      this.props.showClientDropdown();
      await this.displayModalOnLoad();
      this.props.explicitSetClientList(this.props.DashboardData.dashboardClients || []);

      if (client > 0) {
        this.setState(
          {
            clientValue: client,
            isInitialized: false
          }
        );
      }
      this.props.history.push("/Dashboard");
    };

    componentWillUnmount = () => {
      this.props.unsetClientDetails()
    }

    componentDidUpdate = (prevProps) => {
      if (
        this.state.dashboardProgram.length === 0 &&
        this.state.selectedProgram !== ''
      ) {
        this.setState({
          selectedYear: '',
          selectedProgram: '',
          isStart: false,
          isKnowMore: false,
          hasNoAccess: false
        });
      }

      if (this.state.clientValue !== this.props.SharedData.selectedClient.value) {
        this.setState({
          clientValue: this.props.SharedData.selectedClient.value,
          hasNoAccess: false
        },
          () => {
            this.clearDashboard();
          });
      }

      if (this.props.DashboardData.dashboardClients && this.state.selectedTab === -1) {
        this.setState({
          selectedTab:
            this.props.DashboardData.dashboardClients.filter((x) => x.favorite).length > 0 ? 1 : 0,
        });
      }

      if (this.props.DashboardData.dashboardClients !== prevProps.DashboardData.dashboardClients) {
        this.props.explicitSetClientList(this.props.DashboardData.dashboardClients);
      }
    };

    getURLParams = async (dbSearchParams) => {
      let urlParams = {
        clientID: 0,
        progID: 0,
        reportingYr: 0
      }
      try {
        if (IsValid(dbSearchParams)) {
          let baseParams = [];
          let clientID, progID, reportingYr;
          //GET & SET URL DATA
          baseParams = dbSearchParams.split('&');
          if (baseParams.length > 0) {
            const urlSearchParams = new URLSearchParams(dbSearchParams);
            const params = Object.fromEntries(urlSearchParams.entries());

            /* let tmpClientID = baseParams[0].split('?clientid=')[1];
            clientID = IsValidStr(tmpClientID) ? parseInt(tmpClientID) : 0;
            let tmpReportingYear = baseParams[1].split('reportingyear=')[1];
            reportingYr = IsValidStr(tmpReportingYear) ? parseInt(tmpReportingYear) : 0;
            let tmpProgID = baseParams[2].split('programid=')[1];
            progID = IsValidStr(tmpProgID) ? parseInt(tmpProgID) : 0; */

            clientID = IsValidStr(params.clientid) ? parseInt(params.clientid) : 0;
            reportingYr = IsValidStr(params.reportingyear) ? parseInt(params.reportingyear) : 0;
            progID = IsValidStr(params.programid) ? parseInt(params.programid) : 0;

            urlParams = {
              clientID: clientID,
              progID: progID,
              reportingYr: reportingYr
            }
          }

        }
        return urlParams;
      } catch (e) {
        console.log("Error parsing URL params.");
        return urlParams;
      }
    }

    loadDBFromURL = async (urlParams) => {
      try {
        if (urlParams.clientID > 0) {
          //VERIFY CLIENT
          const clientList = await this.props.getClientList()
          const client = clientList.find(e => { return urlParams.clientID === e.value })
          //IF CLIENT IS VALID
          if (HasValue(client)) {
            //SET CLIENT DETAILS
            this.createLog({ programID: urlParams.progID })
            await this.onSelectClient(client);

            this.props.showClientDropdown();
            this.setState({
              isDBAutoSelect: true,
              tempURLParams: {
                progID: urlParams.progID,
                reportingYr: urlParams.reportingYr
              }
            })
          } else {
            this.props.showClientDropdown();
            this.setState({
              hasNoAccess: true
            })
          }
          this.props.history.push("/Dashboard");
          return;
        }
      } catch (e) {
        console.log("Error loading data from URL.")
      }
    }

    displayModalOnLoad = async () => {
      const path = window.location.pathname.toLowerCase();
      if (path === '/dashboard') {
        if (Object.keys(this.props.SharedData.selectedClient).length > 0) {
          if (
            this.props.SharedData.selectedClient.label === '' ||
            this.props.SharedData.selectedClient.label === undefined
          ) {
            this.setState({ show: true });
          } else {
            this.setState({ show: false });
          }
        } else {
          this.setState({ show: !this.props.SharedData.isLeftPanel });
        }
      } else {
        this.setState({ show: !this.props.SharedData.isLeftPanel });
      }

    };

    onChangeModalTab = (event) => {
      let selectedActiveKey = event;
      this.setState({ modalActiveKey: selectedActiveKey });
    };

    onCloseModal = () => {
      this.setState({ show: !this.state.show }, () => {
        this.props.history.push('/');
        this.props.showClientDropdown();
      });
    };

    onSearch = (event) => {
      const value = event.target.value;
      this.setState({ filterKeyword: value });
    };

    onSelectClient = async (item) => {
      this.setState(
        {
          show: false,
          selectedYear: '',
          isInitialized: false
        },
        () => {
          this.clearDashboard();
          this.props.setSelectedClient(item);
          this.setState({ clientValue: item.value });
        }
      );
      this.createLog({
        programID: null,
      });
    };

    onSelectReportingYear = async (e) => {
      /* console.log("onSelectReportingYear will be deprecated"); */
    };

    onSelectProgram = async (e) => {
      /* console.log("onSelectProgram will be deprecated"); */
    };

    onStart = () => {
      this.setState({ isStart: true });
      const programID = this.state.programID;
      this.createLog({ programID });
    };

    createLog = (props) => {
      const client = TryGetObjValueAlt(this.props.SharedData, 0, 'selectedClient.value');

      LogUserSiteAction({
        module: SUB_MOD_DASHBOARD,
        ShouldLogPPCID: true,
        clientID: client,
        ClientProgramID: props.programID,
        ClientProjectID: props.projectID,
        progStatus: HasValue(props.programID) && !HasValue(props.projectID) ? ACC_PROG : null,
        projStatus: HasValue(props.projectID) ? ACC_PROJ : null
      });
    }

    onKnowMore = () => {
      this.setState({ isKnowMore: true });
    };

    onClickBackBtn = () => {
      this.setState({ isKnowMore: false }, () => {

      });
    };

    GetAuthObj = async () => {
      const data = GetAuthObj();
      return data;
    }

    clearDashboard = async (key) => {
      try {
        switch (key) {
          case 'reportingYear':
            this.setState({
              dimensionSubDimension: [],
              programID: '',
              dbDimensionList: undefined,
            });
            break;
          case 'program':
            this.setState({
              dimensionSubDimension: [],
              programID: '',
              dbDimensionList: undefined,
            });
            break;
          default:
            this.setState({
              reportingYear: [],
              dashboardProgram: [],
              dimensionSubDimension: [],
              programID: '',
              dbDimensionList: undefined,
            });
            break;
        }
      } catch (err) {
        console.log(err);
      }
    }

    // Callback functions
    fetchDashboardDataCallback = async (data) => {
      if (data) {
        //Filter list based on user Profile -> For Quality Checker only
        const selectedClientValue = this.props.SharedData.selectedClient.value;
        //DataEvent("dashboardLanding", this.props.SharedData.selectedClient.text);

        const QCUserProfiles = this.props.UserInformation.userProfiles
          .filter(prog => prog.ClientMasterId === selectedClientValue && prog.ProfileID === 16);

        // Filter for other User Profiles excluding QC(26), Global Guest User(13) and Demo User (14) OR if SuperAdmin
        const nonQCProfiles = this.props.UserInformation.userProfiles
          .filter(prog => (prog.ClientMasterId === selectedClientValue && [13, 14, 16].indexOf(prog.ProfileID) === -1) || (prog.ProfileID === 1));
        let dashBoardDataQC = [];

        // If user has QC profile and no other non-QC Profile
        if (QCUserProfiles.length !== 0 && nonQCProfiles.length === 0) {
          dashBoardDataQC = data.dashboardProgram.filter(val => {
            return QCUserProfiles.some(val2 => {
              return val.offeringId === val2.OfferingID
            })
          })
          this.setState({
            dashboardProgram: dashBoardDataQC, //dashboardProgram 
            dashboardData: data.dashboardData
          })
        } else {
          this.setState({
            dashboardProgram: data.dashboardProgram, //dashboardProgram 
            dashboardData: data.dashboardData
          })
        }

        // For auto select functionality
        if (this.state.isDBAutoSelect) {
          this.setState({
            // isStart: true,
            progID: this.state.tempURLParams.progID,
            reportingYr: this.state.tempURLParams.reportingYr,
          })
        }
      }
    }

    fetchClientDetailsCallback = async (data) => {
      if (data) {
        this.setState({
          dashboardClientDetails: data.dashboardClientDetails,
          isInitialized: true,
        })
      }
    }

    onSelectProgramCallback = async (data, selProgram) => {
      if (data) {
        this.setState({
          reportingYear: data.reportingYear// reportingYear
        })

        if (this.state.isStart) {
          let selectedYear = this.state.selectedYear;
          let tmpselectedYear = "";
          let isSelYrAvail = true;
          if (!IsObjEmpty(selectedYear)) {
            let tempRepYrArr = data.reportingYear ? data.reportingYear : [] ;
            let repYears = tempRepYrArr.map((year) => { return year.value[0]});
            
            let selectedYearVal = TryGetNodeAlt(selectedYear, [-1], 'value');
            isSelYrAvail = repYears.indexOf(selectedYearVal[0]) !== -1;


            if (!isSelYrAvail) {
              // Get Max year available from reportingYear if selected is not available
              let maxYear = Math.max(...repYears);
              if (IsValid(maxYear)) {
                tmpselectedYear = {
                  label: [maxYear],
                  value: [maxYear],
                }
              }
            }
          }

          this.setState({
            selectedYear: isSelYrAvail ? selectedYear : tmpselectedYear,
            selectedProgram: selProgram
          });
        } else {
          let selectedYear = this.state.selectedYear;
          let tmpselectedYear = "";
          let isSelYrAvail = true;
          if (!IsObjEmpty(selectedYear)) {
            let tempRepYrArr = data.reportingYear ? data.reportingYear : [] ;
            let repYears = tempRepYrArr.map((year) => { return year.value[0]});
            
            let selectedYearVal = TryGetNodeAlt(selectedYear, [-1], 'value');
            isSelYrAvail = repYears.indexOf(selectedYearVal[0]) !== -1;


            if (!isSelYrAvail) {
              // Get Max year available from reportingYear if selected is not available
              let maxYear = Math.max(...repYears);
              if (IsValid(maxYear)) {
                tmpselectedYear = {
                  label: [maxYear],
                  value: [maxYear],
                }
              }
            }
          }
          this.setState({
            selectedYear: isSelYrAvail ? selectedYear : tmpselectedYear,
            selectedProgram: selProgram,
          });
        }
      }
    }

    setSelectedReportingYearCallback = async (e) => {
      if (!this.state.startButton) {
        this.clearDashboard('reportingYear');
      }
      if (this.state.selectedProgram === '') {
        this.setState({
          selectedYear: e,
          startButton: false,
          isStart: false,
        });
      } else {
        this.setState(
          {
            selectedYear: e,
          },
          () => {
            if (this.state.isStart) {
              // this.props.setToasterParam({
              //   show: true,
              //   type: 'success',
              //   message: 'Program data loaded successfully.',
              // });
            }
          }
        );
      }
    }

    getDashboardDimensionsCallback = async (data) => {
      if (data) {
        //DataEvent("dashboardViewWheel", this.props.SharedData.selectedClient.text);
        this.setState({
          programFiscalYearRange: data.programFiscalYearRange,
          dbDimensionList: data.dbDimensionList,
          dimensionSubDimension: data.dimensionSubDimension,
          programID: data.programID,
          dashboardProgramDetails: data.dashboardProgramDetails
        })
        // For auto select functionality
        if (this.state.isDBAutoSelect) {
          this.setState({
            isDBAutoSelect: false,
            isStart: true
          })
        }
      }
    }

    setCommentaryRequiredDataCallback = async (data) => {
      if (data) {
        this.setState({
          dashboardCommentary: data.dashboardCommentary
        })
      }
    }

    handleShowCommentIconCallback = async (shouldShowCommentIcon) => {
      this.setState({
        shouldShowCommentIcon: false,
      });

      if (this.state.isStart) {
        this.props.setToasterParam({
          show: true,
          type: 'success',
          message: 'Program data loaded successfully.',
        });
      }
    };

    getBOIDimensionMappingListsCallback = async (boiMappingList) => {
      this.setState({
        BOIMappingListAllOfferings: boiMappingList
      });
    };

    handleQCCheckComment = (show) => {
      // let shouldShow = show === undefined ? !this.state.showQCheckCommentModal : show;
      this.setState({ showQCheckCommentModal: !this.state.showQCheckCommentModal });
      this.props.showLeftSideBar();
    };


    goToRVDProgLevel = () => {
      const { selectedClient } = this.props.SharedData;
      const rvdLink = GenRVDURLProg(selectedClient.label, this.state.programID);
      window.location.href = rvdLink;
    }

    goToPPCProgLevel = () => {
      const { selectedClient } = this.props.SharedData;
      const ppcLink = GenPPCProg(selectedClient.label, this.state.programID);
      window.location.href = ppcLink;
    }


    render() {
      const { filterKeyword } = this.props.ClientConfigurationData;

      const {
        dashboardClients
      } = this.props.DashboardData;

      const {
        enterpriseID,
        isSuperAdmin,
        userProfiles
      } = this.props.UserInformation;

      const { onClickFavorite, onSearch, UserInformation } = this.props;

      const { modules } = this.props.HomeData;

      const { selectedClient } = this.props.SharedData;

      if (this.state.dashboardProgram.length === 1) {
        this.state.selectedProgram = this.state.dashboardProgram[0];
      }
      if (this.state.reportingYear.length === 1) {
        this.state.selectedYear = this.state.reportingYear[0];
      }
      return modules.length > 0 ? (
        CheckModuleAccess(modules) || (dashboardClients ? dashboardClients.length > 0 : false) ? (

          <DashboardPresentational
            modalActiveKey={this.state.modalActiveKey}
            onChangeModalTab={this.onChangeModalTab}
            show={this.state.show}
            size={this.state.size}
            onCloseModal={this.onCloseModal}
            clientList={dashboardClients}
            onClickFavorite={onClickFavorite}
            onSearch={onSearch}
            filterKeyword={filterKeyword}
            UserInformation={UserInformation}
            onSelectClient={this.onSelectClient}
            selectedTab={this.state.selectedTab}
            hasNoAccess={this.state.hasNoAccess}

            // Selected Client Initial Data
            selectedClient={selectedClient}
            isInitialized={this.state.isInitialized}
            dashboardClientDetails={this.state.dashboardClientDetails}
            // For auto select functionality
            isDBAutoSelect={this.state.isDBAutoSelect}
            progID={this.state.progID}
            reportingYr={this.state.reportingYr}
            // Dropdown data for Program and Reporting Year used in reusable components
            programOptions={this.state.dashboardProgram}
            yearOptions={this.state.reportingYear}
            // View conditions
            isStart={this.state.isStart}
            startButton={this.state.startButton}
            isKnowMore={this.state.isKnowMore}
            // Action for Viewing and Dropdown Filters
            onStart={this.onStart} // Dashboard Landing View Specific
            onKnowMore={this.onKnowMore} // Summary Wheel specific
            onClickBackBtn={this.onClickBackBtn}
            createLog={this.createLog}
            getAuthObj={this.GetAuthObj}

            // Dashboard Shared Data            
            selectedProgram={this.state.selectedProgram}
            selectedYear={this.state.selectedYear}
            dashboardData={this.state.dashboardData}
            dimensionSubDimension={this.state.dimensionSubDimension}
            dbDimensionList={this.state.dbDimensionList}

            // Dasboard Detailed View Specific data
            programID={this.state.programID}
            reportingYear={this.state.reportingYear}
            programFiscalYearRange={this.state.programFiscalYearRange}
            dashboardCommentary={this.state.dashboardCommentary}
            enterpriseID={enterpriseID} // Optional for non-svm
            isSuperAdmin={isSuperAdmin} // Optional for non-svm
            userProfiles={userProfiles} // Optional for non-svm
            // Summary Wheel View Specific data            
            dashboardProgramDetails={this.state.dashboardProgramDetails}

            // Callbacks
            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
            onSelectProgramCallback={this.onSelectProgramCallback}
            setSelectedReportingYearCallback={this.setSelectedReportingYearCallback}
            getDashboardDimensionsCallback={this.getDashboardDimensionsCallback}
            setCommentaryRequiredDataCallback={this.setCommentaryRequiredDataCallback}
            //QC
            showQCfunctionality={true} // Main flag to disable QC functionalities on Dashboard
            showQCheckCommentModal={this.state.showQCheckCommentModal}
            shouldShowCommentIcon={false}
            BOIMappingListAllOfferings={this.state.BOIMappingListAllOfferings}
            handleShowCommentIconCallback={this.handleShowCommentIconCallback}
            getBOIDimensionMappingListsCallback={this.getBOIDimensionMappingListsCallback}
            handleQCCheckComment={this.handleQCCheckComment}
            goToRVDProgLevel={this.goToRVDProgLevel}
            goToPPCProgLevel={this.goToPPCProgLevel}
          />

        ) : (
          <Row className="access_denied_container">
            <Col md={12} xs={12}>
              <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
              <div className="access_denied_content">
                <h1>Access Denied</h1>
                <p>
                  You don't have the necessary user privileges to view the client that you are
                  attempting to access.
                </p>
              </div>
            </Col>
          </Row>
        )
      ) : (
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }

  return DashboardContainer;
})();

export default connect(
  (state) => {
    return {
      ClientConfigurationData: state.ClientConfigurationData,
      SharedData: state.SharedData,
      DashboardData: state.DashboardData,
      HomeData: state.HomeData,
      UserInformation: state.userInformation,
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getClientList,
          onClickFavorite,
          onSearch,
          showClientDropdown,
          setSelectedClient,
          explicitSetClientList,
          setToasterParam,
          unsetClientDetails,
          showLeftSideBar
        }
      ),
      dispatch
    )
)(withRouter(Dashboard));
