import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  GrooveIconSvg,
  GrooveInput,
  GrooveButton,
  GrooveCheckboxlist,
} from 'tfo-groove';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HttpGet, HttpPost, HttpPostText, GetSubMenuId } from '../../Utilities/HTTPUtil';
// icons
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import editIcon from './../../Static/images/360cvm/EditIcon.svg';
import deleteIcon from './../../Static/images/360cvm/TrashBin.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CVMTable from '../Shared/CVMTable/CVMTable';
import CVMModal from '../Shared/CVMModal/CVMModal';
import { setToasterParam } from '../../Actions/Shared.Action';
import CVMAutoMultiSelectDropdownWithCheckBoxAndPaste from '../Shared/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste/CVMAutoMultiSelectDropdownWithCheckBoxAndPaste';
import CVMToasterNotifUndo from '../Shared/CVMToasterNotifUndo/CVMToasterNotifUndo';

import './UserManagement.css';
import { LogUserSiteAction } from '../../Utilities/AccessManagementUtil';
import { USER_MANAGEMENT } from '../../Constants/Modules';
import { IsValidStr, IsArrayEmpty, IsObjEmpty, IsArray, HasValue } from "../../Utilities/Validations";
import { UnRefObj } from '../../Utilities/Formatter';
import DeleteMappedUser from './Modals/DeleteMappedUser'
import { datalist } from 'react-dom-factories';
import { toPixelData } from 'html-to-image';

const UserManagement = (function () {
  class UserManagementPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <CVMToasterNotifUndo />
          <CVMModal
            title={this.props.isEdit ? 'Update User' : 'Add New User'}
            show={this.props.show}
            onCloseModal={this.props.onCloseModal}
            size={this.props.size}
            content={
              <ModalContent
                onChangeUserDetails={this.props.onChangeUserDetails}
                userDetails={this.props.userDetails}
                prevUserDetails={this.props.prevUserDetails}
                profileOptions={this.props.profileOptions}
                offeringOptions={this.props.offeringOptions}
                subOfferingOptions={this.props.subOfferingOptions}
                clientOptions={this.props.clientOptions}
                dashboardClientOptions={this.props.dashboardClientOptions}
                generalErrorMessage={this.props.generalErrorMessage}
                profileSelection={this.props.profileSelection}
                profileToAdd={this.props.profileToAdd}
                onDeleteProfile={this.props.onDeleteProfile}
                onEditProfile={this.props.onEditProfile}
                onCloseModal={this.props.onCloseModal}
                onAddProfile={this.props.onAddProfile}
                saveUserManagement={this.props.saveUserManagement}
                selectedProfile={this.props.selectedProfile}
                qcListInOfferingClient={this.props.qcListInOfferingClient}
                isProfileEmpty={this.props.isProfileEmpty}
                setQCValid={this.props.setQCValid}
                disableSave={this.props.disableSave}
                isQCValid={this.props.isQCValid}
                setDuplicateClients={this.props.setDuplicateClients}
                duplicateClients={this.props.duplicateClients}
                handleDeleteMappedOnEdit={this.props.handleDeleteMappedOnEdit}
                handleCheckForMappings={this.props.handleCheckForMappings}
                mappedUserDeletionData={this.props.mappedUserDeletionData}
                resetMappedUserDeletionData={this.props.resetMappedUserDeletionData}
                isEdit={this.props.isEdit}
              />
            }
          />
          <div className="search-bar-container">
            <div className="search-bar label14-regular-midnight">
              <GrooveIconSvg
                customClassName="close-modal-btn"
                size="large"
                name="search"
                iconStyle="solid"
                iconColor="stat-neutral"
              />
              <input
                type="text"
                name="name"
                autocomplete="off"
                placeholder="Search User Name"
                id="searchBox"
                maxlength="255"
                onChange={(e) => {
                  this.props.searchFunc(e);
                }}
              />
            </div>
            <GrooveButton
              id="primry-btn-2"
              hasIcon={true}
              type="solid"
              colorClass="stat-alternate"
              text="Add New User"
              callbackFunction={this.props.onAddNewUser}
              iconAlignment="right"
              iconSize="small"
              iconName="plus"
              iconStyle="solid"
              iconId="btn-icon-01"
            />
          </div>
          <div className="management-table-container">
            <CVMModal
              title={''}
              show={this.props.showDelConfirmationModal}
              onCloseModal={this.props.onConfirmClose}
              customclass={'select-project-modal'}
              content={
                <WarningModal
                  //redux
                  onCloseModal={this.props.onConfirmClose}
                  onCloseProject={this.props.onConfirmDelete}
                />
              }
            />
            {this.props.tableProperties.data.length > 0 ? (
              <CVMTable
                tableProperties={this.props.tableProperties}
                getGridApi={this.props.getGridApi}
                filteredData={this.props.filteredData}
              />
            ) : (
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {/* Outside Deletion Modal */}
            <MappedUserDeletionModal
              show={this.props.mappedUserDeletionData.showDeleteUserMapping}
              handleYes={this.props.handleDeleteUserMappings}
              handleCancel={this.props.handleDeleteUserMappings}
              clientList={this.props.mappedUserDeletionData.clients}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class ModalContent extends Component {
    constructor() {
      super();
      this.state = {};
    }

    validateProfiles = () => {
      try {
        let allValid = true;
        let profiles = this.props.userDetails;
        let isQCValid = this.props.isQCValid; // don't remove for the future purposes to disable the save button when qc error is displayed

        this.props.selectedProfile.forEach(x => {
          if (x.clientAgnostic) {
            // check for blank values first
            profiles.clientAgnostic.map((p) => {
              if (x.value === p.value) {
                if (p.offerings.length === 0 || p.subofferings.length === 0 || p.hasClientDuplicate || p.isEmpty.suboffering) {
                  allValid = false;
                }
              }
            });
            // if no blank values, check for duplicate values
            if (allValid) {
              profiles.clientAgnostic.map((op, i) => {
                if (x.value === op.value) {
                  if (allValid) {
                    profiles.clientAgnostic.map((ip, indx) => {
                      if (x.value === ip.value) {
                        if (op.value !== 16 && ip.value !== 16) {
                          if (op.value === ip.value && i !== indx) {
                            allValid = false;
                          }
                          op.offerings.map((oo) => {
                            if (allValid) {
                              ip.offerings.map((io) => {
                                if (oo.value === io.value && i !== indx) {
                                  allValid = false;
                                }
                              });
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            }
          } else {
            // check for blank values first
            profiles.clientSpecific.map((p) => {
              if (x.value === p.value)
                if ((p.clients || []).length === 0 || p.hasClientDuplicate) {
                  allValid = false;
                }
            });
            // if no blank values, check for duplicate values
            if (allValid) {
              profiles.clientSpecific.map((op, i) => {
                if (x.value === op.value) {
                  if (allValid) {
                    profiles.clientSpecific.map((ip, indx) => {
                      if (x.value === ip.value) {
                        if (op.value === ip.value && i !== indx) {
                          allValid = false;
                        }
                        op.clients.map((oc) => {
                          if (allValid) {
                            ip.clients.map((ic) => {
                              // Allow mapping of BA(15) and PPC(12) profiles to same client
                              if (oc.value === ic.value && i !== indx
                                && !((op.value === 15 && ip.value === 12) || (op.value === 12 && ip.value === 15))
                              ) {
                                allValid = false;
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                }

              });
            }
          }
        })

        return allValid;
        // && isQCValid - will be commented for now to be able to save qc profile that is already mapped to another users
      }
      catch (e) {
        console.log(e);
      }

    };

    render() {
      const checkbox = [
        {
          value: 'IsActive',
          label: 'Is Active',
          isChecked: this.props.userDetails.isActive,
          isDisabled: false,
        },
        {
          value: 'IsGlobalUser',
          label: 'Global Guest User',
          isChecked: this.props.userDetails.isGlobalUser,
          isDisabled: true,
        },
      ];

      let allValid = this.validateProfiles();
      let clientSpecificDemoUser = this.props.userDetails.clientSpecific.filter(
        (x) => x.value === 14
      );
      let profileOptions = this.props.profileOptions.filter((x) => x.value !== 14);

      return (
        <React.Fragment>
          <div className="add-new-program-container">
            <Row>
              <Col lg={6} md={6} sm={6}>
                <GrooveInput
                  id="userName"
                  name="username"
                  placeholder={
                    this.props.userDetails.userName.value !== '' ? 'User Name *' : 'Enter User Name *'
                  }
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.userDetails.userName.value}
                  callbackFunction={(e) => this.props.onChangeUserDetails(e, 'username')}
                  customClassName={
                    !this.props.userDetails.userName.valid ? ' dropdown-invalid' : ''
                  }
                  maxLength={255}
                />
                {!this.props.userDetails.userName.valid ? (
                  <span className="invalid-selection">{this.props.userDetails.userName.msg}</span>
                ) : (
                  ''
                )}
              </Col>
              <Col lg={6} md={6} sm={6}>
                <GrooveInput
                  id="emailID"
                  name="emailid"
                  placeholder={
                    this.props.userDetails.emailID.value !== '' ? 'Email ID *' : 'Enter Email ID *'
                  }
                  type="text"
                  isAutocomplete="false"
                  inputValue={this.props.userDetails.emailID.value}
                  callbackFunction={(e) => this.props.onChangeUserDetails(e, 'emailid')}
                  customClassName={!this.props.userDetails.emailID.valid ? ' dropdown-invalid' : ''}
                  maxLength={255}
                />
                {!this.props.userDetails.emailID.valid ? (
                  <span className="invalid-selection">{this.props.userDetails.emailID.msg}</span>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <GrooveCheckboxlist
                  id="enableClient"
                  checkboxlistData={checkbox}
                  isDisabled={false}
                  callbackFunction={(e) => this.props.onChangeUserDetails(e, 'isactive')}
                />
              </Col>
            </Row>
            <div className="divider-horizontal"></div>
            <div className="client-steakholder-title bodytext18-medium-slate">Configuration</div>
            <Row>
              <Col lg={12} md={12} sm={12} className="select-profile">
                <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                  key='profile'
                  name="selectprofile"
                  popdirection="pop-bottom"
                  isDisabled={false}
                  placeholder={(this.props.selectedProfile || []).length > 0 ? 'Profile *' : 'Select Profile *'}
                  options={this.props.profileOptions}
                  id="profile"
                  onChange={(e) => this.props.handleCheckForMappings(e, 'profile')}
                  customClassName={
                    this.props.isProfileEmpty ? ' dropdown-invalid' : ''
                  }
                  value={this.props.selectedProfile}
                  activePaste={false}
                />
                {this.props.isProfileEmpty ? (
                  <span className="invalid-selection">
                    Missing required field
                  </span>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            {
              this.props.selectedProfile.map(x => {
                return (
                  x.clientAgnostic ?
                    this.props.userDetails.clientAgnostic.map((profile, i) => {
                      let prevProfile = IsArrayEmpty(this.props.prevUserDetails.clientAgnostic.filter((a) => profile.value === a.value)) ? [] :
                        this.props.prevUserDetails.clientAgnostic.filter((a) => profile.value === a.value)[0]
                      if (profile.value === x.value) {
                        let offerings = profile.offerings;
                        let subOfferingOptions = [];

                        offerings.map((o) => {
                          if (o.value !== '*')
                            o.subofferings.map((s) => {
                              subOfferingOptions.push({
                                value: s.subOfferingId,
                                label: s.subOfferingName,
                                text: s.subOfferingName,
                                offeringID: o.value,
                              });
                            });
                        });

                        return (
                          <RowProfileRender
                            key={i + '-a'}
                            profile={profile}
                            prevProfile={prevProfile}
                            index={i}
                            userDetails={this.props.userDetails}
                            clientType={x.clientAgnostic && 1}
                            profileOptions={this.props.profileOptions}
                            offeringOptions={this.props.offeringOptions}
                            subOfferingOptions={subOfferingOptions}
                            clientOptions={this.props.clientOptions}
                            onDeleteProfile={this.props.onDeleteProfile}
                            onEditProfile={this.props.onEditProfile}
                            selectedProfile={this.props.selectedProfile}
                            qcListInOfferingClient={this.props.qcListInOfferingClient}
                            setQCValid={this.props.setQCValid}
                            setDuplicateClients={this.props.setDuplicateClients}
                            duplicateClients={this.props.duplicateClients}
                            handleDeleteMappedOnEdit={this.props.handleDeleteMappedOnEdit}
                            mappedUserDeletionData={this.props.mappedUserDeletionData}
                            resetMappedUserDeletionData={this.props.resetMappedUserDeletionData}
                            isEdit={this.props.isEdit}
                          />
                        );
                      }
                    })
                    :
                    this.props.userDetails.clientSpecific.map((profile, i) => {
                      let prevProfile = IsArrayEmpty(this.props.prevUserDetails.clientSpecific.filter((a) => profile.value === a.value)) ?
                        [] : this.props.prevUserDetails.clientSpecific.filter((a) => profile.value === a.value)[0];
                      if (profile.value === x.value)
                        return (
                          <RowProfileRender
                            key={i + '-s'}
                            profile={profile}
                            prevProfile={prevProfile}
                            index={i}
                            userDetails={this.props.userDetails}
                            clientType={!x.clientAgnostic && 2}
                            profileOptions={profileOptions}
                            offeringOptions={this.props.offeringOptions}
                            clientOptions={this.props.clientOptions}
                            onDeleteProfile={this.props.onDeleteProfile}
                            onEditProfile={this.props.onEditProfile}
                            selectedProfile={this.props.selectedProfile}
                            setQCValid={this.props.setQCValid}
                            setDuplicateClients={this.props.setDuplicateClients}
                            duplicateClients={this.props.duplicateClients}
                            handleDeleteMappedOnEdit={this.props.handleDeleteMappedOnEdit}
                            mappedUserDeletionData={this.props.mappedUserDeletionData}
                            resetMappedUserDeletionData={this.props.resetMappedUserDeletionData}
                          />
                        );
                    })
                )
              })
            }
            <div className="divider-horizontal"></div>
            <div className="client-steakholder-title bodytext18-medium-slate">Dashboard</div>

            <Row>
              <Col lg={12} md={12} sm={12} className="dashboard-dropdown">
                <div className="Multi-Select-BT">
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    key='dashboard'
                    label=""
                    id="dashboard"
                    placeholder={(this.props.userDetails.dashboard.value || []).length > 0 ? 'Clients *' : 'Select Clients *'}
                    options={this.props.dashboardClientOptions}
                    onChange={(e, f, g, action) =>
                      this.props.onChangeUserDetails(e, 'dashboard', action)
                    }
                    value={this.props.userDetails.dashboard.value}
                    isDisabled={clientSpecificDemoUser.length > 0
                      && this.props.userDetails.clientType === 2
                      ? true : false}
                    customClassName={
                      !this.props.userDetails.dashboard.valid
                        ? ' dropdown-invalid'
                        : clientSpecificDemoUser.length > 0
                          && this.props.userDetails.clientType === 2
                          ? 'disabled'
                          : ''
                    }
                    activePaste={true}
                  />
                  {!this.props.userDetails.dashboard.valid ? (
                    <span className="invalid-selection">
                      {this.props.userDetails.dashboard.msg}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <div className="add-program-buttons">
            <div>
              <p class="missing">{this.props.generalErrorMessage}</p>
            </div>
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={!allValid || this.props.disableSave ||this.props.isEdit&&this.props.userDetails.dashboard.value.length<1}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Save"
              callbackFunction={this.props.saveUserManagement}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class BtnCellRenderer extends Component {
    constructor(props) {
      super(props);
      this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
      this.btnDeleteClickedHandler = this.btnDeleteClickedHandler.bind(this);
    }
    btnEditClickedHandler() {
      this.props.edit(this.props.data);
    }
    btnDeleteClickedHandler() {
      this.props.delete(this.props.data, 'tableDelete');
    }

    render() {
      return (
        <div className="table-action-buttons">
          <img
            className="edit-btn"
            onClick={this.btnEditClickedHandler}
            src={editIcon}
            alt="edit"
          />
          <img
            className="delete-btn"
            onClick={this.btnDeleteClickedHandler}
            src={deleteIcon}
            alt="delete"
          />
        </div>
      );
    }
  }

  class RowProfileRender extends Component {
    constructor(props) {
      super(props);
      this.state = {
        clientList: [],
        IOAClientList: [],
        QCCLientList: [],
        QCOfferingError: false,
        QCOfferingClientList: [],
        mappedQCfferingList: [],
        mappedUserDeletionData: {
          showDeleteUserMapping: false,
          clients: "",
          deleteOnEdit: {},
          originData: {},
        },
        resetDisablement: false
      };
    }

    btnDeleteClickedHandler = () => {
      try {
        this.props.onProfileSectionDelete(this.props.id);
      }
      catch (e) {
        console.log(e)
      }
    };

    componentDidMount() {
      try {
        this.validateSelectedClients();
        this.setState({
          clientList: UnRefObj(this.props.clientOptions)
        }, () => {
          if (this.props.profile.value === 8) {
            let clientList = this.state.clientList;
            let clientOffering = [];
            let selectedOfferingId = [];
            if (this.props.profile.offerings.length > 0) {
              this.props.profile.offerings.map(o => {
                selectedOfferingId.push(o.value);
              });
            }

            if (selectedOfferingId.length > 0) {
              this.props.offeringOptions.filter(oOps => {
                return selectedOfferingId.some(sOff => {
                  return oOps.value === sOff;
                });
              }).map(m => {
                m.clients.map(c => {
                  if (clientOffering.filter(x => x.value === c.ClientId).length === 0) {
                    clientOffering.push({
                      value: c.ClientId,
                      label: c.ClientName,
                      text: c.ClientName,
                      isMapped: c.isMapped
                    });
                  }
                });
              });
            }

            if (clientOffering.length > 0) {
              clientList.filter(cl => {
                return !clientOffering.some(co => {
                  return co.value === cl.value
                })
              }).map(m => m.isFixed = true);
            }
            this.setState({
              IOAClientList: clientList
            });
          }

          if (this.props.profile.value === 16) {
            let clientList = this.state.clientList;
            let clientOffering = [];
            let selectedOfferingId = [];
            if (this.props.profile.offerings.length > 0) {
              this.props.profile.offerings.map(o => {
                selectedOfferingId.push(o.value);
              });
            }

            if (selectedOfferingId.length > 0) {
              this.props.offeringOptions.filter(oOps => {
                return selectedOfferingId.some(sOff => {
                  return oOps.value === sOff;
                });
              }).map(m => {
                m.clients.map(c => {
                  if (clientOffering.filter(x => x.value === c.ClientId).length === 0) {
                    clientOffering.push({
                      value: c.ClientId,
                      label: c.ClientName,
                      text: c.ClientName
                    });
                  }
                });
              });
            }

            if (clientOffering.length > 0) {
              clientList.filter(cl => {
                return !clientOffering.some(co => {
                  return co.value === cl.value
                })
              }).map(m => m.isFixed = true);
            }
            this.setState({
              QCCLientList: clientList
            });
          }
        });
      } catch (e) {
        console.log(e)
      }
    }

    validateSelectedClients = () => {
      try {
        const selectedProfiles = this.props.selectedProfile;
        let userDetails = this.props.userDetails;
        let clientListDuplicated = [];

        // ----------------- Client Validations ---------------------
        if (selectedProfiles.length > 0) {
          selectedProfiles.map(sp => {
            let getCurrentClientList = sp.clientAgnostic ? userDetails.clientAgnostic.filter(p => p.value === sp.value)[0] : userDetails.clientSpecific.filter(p => p.value === sp.value)[0];

            let clientAgnosticOtherProfiles = userDetails.clientAgnostic.filter(p => {
              if (sp.value === 4 || sp.value === 7) { // OAL or CX, remove IO and QC
                return [8, 16].indexOf(p.value) === -1 && p.value !== sp.value
              } else if (sp.value === 8) { // IO, remove CX, OAL and QC
                return [4, 7, 16].indexOf(p.value) === -1 && p.value !== sp.value
              } else if (sp.value === 12) { // PPC, remove BA
                return p.value !== 15 && p.value !== sp.value
              } else if (sp.value === 15) { // BA, remove PPC
                return p.value !== 12 && p.value !== sp.value
              } else if (sp.value === 16) { // QC, get PPC and BA
                return [12, 15].indexOf(p.value) > -1 && p.value !== sp.value
              }
            });
            let clientSpecificOtherProfiles = userDetails.clientSpecific.filter(p => {
              if (sp.value === 4 || sp.value === 7) { // OAL or CX, remove IO and QC
                return [8, 16].indexOf(p.value) === -1 && p.value !== sp.value
              } else if (sp.value === 8) { // IO, remove CX, OAL and QC
                return [4, 7, 16].indexOf(p.value) === -1 && p.value !== sp.value
              } else if (sp.value === 12) { // PPC, remove BA
                return p.value !== 15 && p.value !== sp.value
              } else if (sp.value === 15) { // BA, remove PPC
                return p.value !== 12 && p.value !== sp.value
              } else if (sp.value === 16) { // QC, get PPC and BA
                return [12, 15].indexOf(p.value) > -1 && p.value !== sp.value
              }
            });
            let isAgnosticDuplicate = false;
            let isSpecificDuplicate = false;

            (clientAgnosticOtherProfiles || []).map(item => {
              let duplicatedClients = (item.clients || []).filter(x => {
                return ((getCurrentClientList.clients ? getCurrentClientList.clients : []) || []).some(y => y.value === x.value)
              })
              if (duplicatedClients.length > 0) {
                duplicatedClients.forEach(dpc => {
                  if (clientListDuplicated.indexOf(dpc.label + " ") === -1) {
                    clientListDuplicated.push(dpc.label + " ");
                  }
                })

                isAgnosticDuplicate = true;
              }
            });

            (clientSpecificOtherProfiles || []).map(item => {
              let duplicatedClients = (item.clients || []).filter(x => {
                return ((getCurrentClientList.clients ? getCurrentClientList.clients : []) || []).some(y => y.value === x.value)
              })
              if (duplicatedClients.length > 0) {
                duplicatedClients.forEach(dpc => {
                  if (clientListDuplicated.indexOf(dpc.label + " ") === -1) {
                    clientListDuplicated.push(dpc.label + " ");
                  }
                })

                isSpecificDuplicate = true;
              }
            });

            getCurrentClientList.hasClientDuplicate = isAgnosticDuplicate || isSpecificDuplicate;

            if (sp.value === 16) {
              this.validateQC(getCurrentClientList.offerings, getCurrentClientList.clients);
            }
          })

          this.props.setDuplicateClients(clientListDuplicated);
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    handleDeleteUserMappings = async (origin) => {
      try {
        let data = {
          value: [],
          field: ""
        }
        let root = "";
        if (origin === 'delete') {
          data = this.state.mappedUserDeletionData.deleteOnEdit;
        } else {
          data = this.state.mappedUserDeletionData.originData;
          root = "cancel"
        }
        await this.onChangeDetails(data.value, data.field, root)
        this.setState({
          mappedUserDeletionData: {
            ...this.state.mappedUserDeletionData,
            showDeleteUserMapping: false,
            clients: "",
            deleteOnEdit: {},
            originData: {},
          },
        })
      }
      catch (e) {
        console.log(e)
      }
    }

    handleCheckForMappings = (value, field) => {
      try {
        let profile = this.props.profile;
        let prevProfile = this.props.prevProfile;
        let isDeleted = false;

        switch (field) {
          case 'offering':
            if (value.length < profile.offerings.length) { //removed 
              let tRem = profile.offerings.filter((a) => !value.find((b) => a.value === b.value))
              let removed = IsArrayEmpty(prevProfile) ? [] : prevProfile.offerings.filter((a) => tRem.find((b) => a.value === b.value))
              if (!IsArrayEmpty(removed.filter((a) => a.isMapped))) {
                isDeleted = true;
                let tData = [];

                removed.map((of) => {
                  of.clients.map((cl) => {
                    if (cl.isMapped && IsArrayEmpty(tData.filter((a) => a.ClientId === cl.ClientId))) {
                      tData.push(cl);
                    }
                  })
                })

                let data = {
                  clientList: tData.map((a) => { return a.ClientName }).toString().replace(/,/g, ", "),
                  value: value,
                  field: field
                }

                this.setState({
                  ...this.state,
                  mappedUserDeletionData: {
                    ...this.state.mappedUserDeletionData,
                    showDeleteUserMapping: true,
                    clients: data.clientList,
                    deleteOnEdit: {
                      value: data.value,
                      field: data.field,
                    },
                    originData: {
                      value: profile.offerings,
                      field: data.field,
                    }
                  },
                });
              }
            }
            break;
          case 'client':
            if (value.length < profile.clients.length) { //removed 
              let tRem = profile.clients.filter((a) => !value.find((b) => a.value === b.value))
              let removed = IsArrayEmpty(prevProfile) ? [] : prevProfile.clients.filter((a) => tRem.find((b) => a.value === b.value))
              if (!IsArrayEmpty(removed.filter((a) => a.isMapped))) {
                isDeleted = true;
                let data = {
                  clientList: removed.map((a) => { return a.text }).toString().replace(/,/g, ", "),
                  value: value,
                  field: field
                }

                this.setState({
                  ...this.state,
                  mappedUserDeletionData: {
                    ...this.state.mappedUserDeletionData,
                    showDeleteUserMapping: true,
                    clients: data.clientList,
                    deleteOnEdit: {
                      value: data.value,
                      field: data.field,
                    },
                    originData: {
                      value: profile.clients,
                      field: data.field,
                    }
                  },
                });
              }
            }
            break;
          default:
            break;
        }
        let fromCheck = isDeleted ? "checker" : "";
        this.onChangeDetails(value, field, fromCheck)
        return;
      }
      catch (e) {
        console.log(e)
      }
    }

    onChangeDetails = async (value, field, origin) => {
      try {
        let profile = this.props.profile;
        let prevProfile = this.props.prevProfile;
        let tVal = [];

        switch (field) {
          case 'offering':
            let selectClients = []
            let clientListFromSelectedOfferings = [];

            tVal = value.map((v) => {
              let tData = !IsArrayEmpty(prevProfile) ? !IsObjEmpty(prevProfile.offerings.find((a) => a.value === v.value)) ?
                prevProfile.offerings.find((a) => a.value === v.value) : v
                : v;
              return {
                ...v,
                isMapped: tData.isMapped,
                mappedOff: IsArrayEmpty(tData.mappedOff) ? [] : tData.mappedOff,
              }
            })

            profile.offerings = tVal;

            let tempSubOff = [];
            let tempSubOffRef = [];
            let tSubOffForMap = IsValidStr(origin) && origin === "cancel" ? prevProfile.subofferings : profile.subofferings;
            tSubOffForMap.map((s) => {
              tVal.map((o) => {
                o.subofferings.map((so) => {
                  if (so.subOfferingId === s.value && tempSubOffRef.indexOf(s.value) === -1) {
                    tempSubOff.push(s);
                    tempSubOffRef.push(s.value);
                  }
                });
              });
            });

            if (origin !== 'checker') {
              profile.subofferings = tempSubOff;
            }


            //-------- auto-populate clients ---------------

            // collect all client from selected offerings
            tVal.forEach(c => {
              c.clients.filter(x => {
                return !(clientListFromSelectedOfferings || []).some(cl => {
                  return x.ClientId === cl.value
                })
              }).forEach(c1 => {
                clientListFromSelectedOfferings.push({
                  label: c1.ClientName,
                  text: c1.ClientName,
                  value: c1.ClientId,
                })
              })
            });
            // check if clientListFromSelectedOfferings is greater than profile.clients
            // if it is greater than then Add else Subtract
            // Add = true | Subtract = false
            const isAdd = clientListFromSelectedOfferings.length > (profile.clients || []).length;

            clientListFromSelectedOfferings.filter(cl => {
              if (isAdd) {
                // Add to new/not duplicate selected client
                return !(profile.clients || []).some(pc => {
                  return pc.value === cl.value
                })
              } else {
                // remove client from unselected offerings
                return (profile.clients || []).some(pc => {
                  return pc.value === cl.value
                })
              }

            }).forEach(x => {
              selectClients.push(x)
            });

            if (origin !== 'checker') {
              profile.clients = clientListFromSelectedOfferings.length > 0 ? isAdd ? (profile.clients || []).concat(selectClients) : selectClients : selectClients
            }


            // disable the client that is not mapped to the selected offerings if the selected offering is IOA or QC
            if (this.props.profile.value === 8) { // IOA profile
              let clientOpts = this.state.IOAClientList;

              if (this.props.isEdit || this.state.resetDisablement) {
                clientOpts.filter(o => clientListFromSelectedOfferings.some(x => o.value === x.value)).forEach(m => m.isFixed = false);
              } else {
                clientOpts.filter(o => !clientListFromSelectedOfferings.some(x => o.value === x.value)).forEach(m => m.isFixed = true);
              }
              this.setState({
                IOAClientList: clientOpts,
                resetDisablement: true
              })
            }

            if (this.props.profile.value === 16) { // QC profile
              let clientOpts = this.state.QCCLientList;

              if (this.props.isEdit || this.state.resetDisablement) {
                clientOpts.filter(o => clientListFromSelectedOfferings.some(x => o.value === x.value)).forEach(m => m.isFixed = false);
              } else {
                clientOpts.filter(o => !clientListFromSelectedOfferings.some(x => o.value === x.value)).forEach(m => m.isFixed = true);
              }
              this.setState({
                QCCLientList: clientOpts,
                resetDisablement: true
              })
            }
            let hasNoSuboffering = this.validateSubOffering(tVal, profile.subofferings);
            profile.originalData.subOffering = profile.suboffering;


            if (origin !== 'checker') {
              profile.isEmpty.offerings = tVal.length === 0
              profile.isEmpty.clients = clientListFromSelectedOfferings.length === 0
              profile.isEmpty.suboffering = (tVal.length === 0 ? tVal.length === 0 : profile.subofferings.length === 0) || hasNoSuboffering
            }

            break;
          case 'suboffering':
            let hasNoSubOffering = this.validateSubOffering(profile.offerings, value);

            profile.subofferings = value;
            profile.isEmpty.suboffering = value.length === 0 || hasNoSubOffering
            break;
          case 'client':
            tVal = value.map((v) => {
              let tData = !IsArrayEmpty(prevProfile) ?
                !IsObjEmpty(prevProfile.clients.find((a) => a.value === v.value)) ?
                  prevProfile.clients.find((a) => a.value === v.value) : v
                : v;
              return {
                ...v,
                isMapped: tData.isMapped,
              }
            })

            profile.clients = tVal;
            profile.isEmpty.clients = tVal.length === 0
            break;
          default:
            break;
        }
        this.props.onEditProfile(profile, this.props.index);
        this.validateSelectedClients();
      }
      catch (e) {
        console.log(e)
      }
    };

    validateSubOffering = (offering, subOffering) => {
      try {
        let isEmpty = false;
        offering.forEach(o => {
          let result = o.subofferings.filter(s => {
            return subOffering.filter(x => x.offeringID === o.value).some(so => so.subOfferingId === s.value)
          }).length === 0

          if (result) {
            isEmpty = result;
          }
        })

        return isEmpty;
      }
      catch (err) {
        console.log(err);
      }
    }

    validateQC = (offering, selectedClients) => {
      try {
        let qcListInOfferingClient = IsArrayEmpty(this.props.qcListInOfferingClient) ? [] : this.props.qcListInOfferingClient;
        let offeringList = qcListInOfferingClient.filter(o => offering.some(x => o.OfferingID === x.value)); // get the offering that is equal to the selected offering
        let mappedQCfferingList = [];
        let clientList = [];
        let userID = this.props.userDetails.userID;
        let isValid = true;

        (offeringList || []).map(i => {
          let offeringClient = i.QCClientList.filter(c => offering.some(x => x.clients.some(oc => oc.ClientId === c.ClientId && c.QCUserID !== userID)));
          let client = selectedClients.filter(o => offeringClient.some(x => o.label === x.ClientName))
          if (client.length > 0) {
            client.forEach(x => {
              clientList.push(x.ClientName)
            })
            offering.forEach(o => {
              if (mappedQCfferingList.indexOf(o.label) === -1) {
                mappedQCfferingList.push(o.label)
              }
            })
          }
        })

        isValid = mappedQCfferingList.length > 0 ? false : true;

        this.setState({
          QCOfferingError: !isValid,
          QCOfferingClientList: clientList,
          mappedQCfferingList: mappedQCfferingList
        }, () => {
          this.props.setQCValid(isValid);
        })
      }
      catch (err) {
        console.log(err);
      }
    }

    render() {
      const colSize = 4;
      return (
        <>
          <MappedUserDeletionModal
            show={this.state.mappedUserDeletionData.showDeleteUserMapping}
            handleYes={this.handleDeleteUserMappings}
            handleCancel={this.handleDeleteUserMappings}
            clientList={this.state.mappedUserDeletionData.clients}
          />
          <Row>
            <Col lg={12} md={12} sm={12} className="profile-header">
              {this.props.profile.label}
            </Col>
          </Row>
          <Row className="added-profiles">
            {this.props.clientType === 1 ? ( //agnostic
              <>
                <Col lg={colSize} md={4} sm={4} className="selected-offerings">
                  {this.state.QCOfferingError}
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    key={'offering-' + this.props.index}
                    label=""
                    id="offerings"
                    popdirection="pop-bottom"
                    placeholder={(this.props.profile.offerings || []).length > 0 ? 'Offerings *' : 'Select Offerings *'}
                    options={this.props.offeringOptions || []}
                    onChange={(e) => this.handleCheckForMappings(e, 'offering')}
                    value={this.props.profile.offerings || []}
                    customClassName={
                      (this.state.QCOfferingError && this.props.profile.value === 16) ||
                        this.props.profile.isEmpty.offerings
                        ? ' dropdown-invalid'
                        : ''
                    }
                    activePaste={false}
                  />
                  {(this.state.QCOfferingError && this.props.profile.value === 16) || this.props.profile.isEmpty.offerings ? (
                    <span className={`invalid-selection error-on-profile ${!this.props.profile.isEmpty.offerings ? 'another-qc-error' : ''}`}>
                      {
                        this.props.profile.isEmpty.offerings ?
                          'Missing required field'
                          :
                          this.props.profile.value === 16 ?
                            <>
                              <OverlayTrigger
                                rootClose
                                placement="bottom"
                                overlay={<Tooltip id={'offering-' + this.props.index}>{`${this.state.mappedQCfferingList.length > 1 ? 'Offerings' : 'Offering'} already selected: ${this.state.mappedQCfferingList.toString()}`}</Tooltip>}
                              >
                                <span>
                                  <GrooveIconSvg
                                    size="large"
                                    name="exclamation"
                                    iconStyle="solid"
                                    iconColor="stat-alternate"
                                    className="duplicate-client"
                                  />
                                </span>
                              </OverlayTrigger>
                              Another QC is already mapped to this Client - Offering.
                            </>
                            :
                            ''
                      }
                    </span>
                  ) : (
                    ''
                  )}
                </Col>
                <Col lg={colSize} md={4} sm={4} className="selected-sub-offerings">
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    key={'subOffering-' + this.props.index}
                    label=""
                    id="sub-offerings"
                    popdirection="pop-bottom"
                    placeholder={(this.props.profile.subofferings || []).length > 0 ? 'Sub-Offerings *' : 'Select Sub-Offerings *'}
                    options={this.props.subOfferingOptions || []}
                    onChange={(e) => this.onChangeDetails(e, 'suboffering')}
                    value={this.props.profile.subofferings || []}
                    customClassName={
                      this.props.profile.isEmpty.suboffering
                        ? ' dropdown-invalid'
                        : ''
                    }
                    activePaste={false}
                  />
                  {this.props.profile.isEmpty.suboffering
                    ? (
                      <span className="invalid-selection error-on-profile">
                        {
                          'Missing required field'
                        }
                      </span>
                    ) : (
                      ''
                    )}
                </Col>
                <Col lg={colSize} md={4} sm={4}>
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    key={'clientOffering-' + this.props.index}
                    label=""
                    id="clients"
                    popdirection="pop-bottom"
                    placeholder={(this.props.profile.clients || []).length > 0 ? 'Clients *' : 'Select Clients *'}
                    options={this.props.profile.value === 8 ? this.state.IOAClientList : this.props.profile.value === 16 ? this.state.QCCLientList : this.state.clientList}
                    onChange={(e) => this.handleCheckForMappings(e, 'client')}
                    value={this.props.profile.clients || []}
                    customClassName={
                      this.props.profile.hasClientDuplicate
                        || this.props.profile.isEmpty.clients
                        ? ' dropdown-invalid'
                        : ''
                    }
                    isDisabled={this.props.profile.value === 14 ? true : false}
                    activePaste={true}
                  />
                  {this.props.profile.hasClientDuplicate
                    || this.props.profile.isEmpty.clients
                    ? (
                      <span className={`invalid-selection error-on-profile ${!this.props.profile.isEmpty.clients ? 'client-error' : ''}`}>
                        {this.props.profile.isEmpty.clients
                          ? 'Missing required field'
                          : <>
                            <OverlayTrigger
                              rootClose
                              placement="bottom"
                              overlay={<Tooltip id={'clientOffering-' + this.props.index}>{`${this.props.duplicateClients.length > 1 ? 'Clients' : 'Client'} already selected: ${this.props.duplicateClients.toString()}`}</Tooltip>}
                            >
                              <span>
                                <GrooveIconSvg
                                  size="large"
                                  name="exclamation"
                                  iconStyle="solid"
                                  iconColor="stat-alternate"
                                  className="duplicate-client"
                                />
                              </span>
                            </OverlayTrigger>
                            {`${this.props.duplicateClients.length > 1 ? 'Clients' : 'Client'} already selected`}
                          </>}
                      </span>
                    ) : (
                      ''
                    )}
                </Col>
              </>
            ) : (
              <>
                <Col lg={12} md={12} sm={12}>
                  <CVMAutoMultiSelectDropdownWithCheckBoxAndPaste
                    key={'client-' + this.props.index}
                    label=""
                    id="clients"
                    popdirection="pop-bottom"
                    placeholder={(this.props.profile.clients || []).length > 0 ? 'Clients *' : 'Select Clients *'}
                    options={this.state.clientList}
                    onChange={(e) => this.handleCheckForMappings(e, 'client')}
                    value={this.props.profile.clients || []}
                    customClassName={
                      this.props.profile.hasClientDuplicate
                        || this.props.profile.isEmpty.clients
                        ? ' dropdown-invalid'
                        : this.props.profile.value === 14
                          ? 'disabled'
                          : ''
                    }
                    isDisabled={this.props.profile.value === 14 ? true : false}
                    activePaste={true}
                  />
                  {this.props.profile.hasClientDuplicate
                    || this.props.profile.isEmpty.clients
                    ? (
                      <span className={`invalid-selection error-on-profile ${!this.props.profile.isEmpty.clients ? 'client-error' : ''}`}>
                        {this.props.profile.isEmpty.clients
                          ? 'Missing required field'
                          : <>
                            <OverlayTrigger
                              rootClose
                              placement="bottom"
                              overlay={<Tooltip id={'client-' + this.props.index}>{`${this.props.duplicateClients.length > 1 ? 'Clients' : 'Client'} already selected: ${this.props.duplicateClients.toString()}`}</Tooltip>}
                            >
                              <span>
                                <GrooveIconSvg
                                  size="large"
                                  name="exclamation"
                                  iconStyle="solid"
                                  iconColor="stat-alternate"
                                  className="duplicate-client"
                                />
                              </span>
                            </OverlayTrigger>
                            {`${this.props.duplicateClients.length > 1 ? 'Clients' : 'Client'} already selected`}
                          </>}
                      </span>
                    ) : (
                      ''
                    )}
                </Col>
              </>
            )}
          </Row>
        </>
      );
    }
  }

  class UserManagementContainer extends Component {
    constructor() {
      super();
      this.getDetailsofUserManagement = this.getDetailsofUserManagement.bind(this);
      this.state = {
        tableProperties: {
          columns: [
            { field: 'username', headerName: 'User Name', tooltipField: 'username' },
            {
              field: 'emailid',
              headerName: 'Email ID',
              tooltipField: 'emailid',
              suppressFilter: true,
              getQuickFilterText: () => '',
            },
            {
              field: 'isactive',
              headerName: 'Is Active',
              tooltipField: 'isactive',
              suppressFilter: true,
              getQuickFilterText: () => '',
            },
            {
              field: 'actions',
              headerName: 'Actions',
              cellRenderer: 'btnCellRenderer',
              cellRendererParams: {
                edit: (field) => {
                  this.getDetailsofUserManagement(field.id);
                },
                delete: (field, origin) => {
                  this.deleteUserManagementData(field, origin);
                },
              },
            },
          ],
          cellRenderer: {
            btnCellRenderer: BtnCellRenderer,
          },
          data: [],
          pagination: true,
          paginationPageSize: 10,
          profielFlagID: 0,
          sortable: true,
        },
        show: false,
        size: 'md',
        gridApi: () => { },
        isEdit: false,
        editIndex: 0,

        // new states
        userDetails: {
          userID: 0,
          userName: { value: '', valid: true, msg: '' },
          emailID: { value: '', valid: true, msg: '' },
          isActive: false,
          clientType: 0,
          clientAgnostic: [],
          clientSpecific: [],
          dashboard: { value: [], valid: true, msg: '' },
          oldEmail: '',
          isGlobalUser: false,
        },
        prevUserDetails: {
          userID: 0,
          userName: { value: '', valid: true, msg: '' },
          emailID: { value: '', valid: true, msg: '' },
          isActive: false,
          clientType: 0,
          clientAgnostic: [],
          clientSpecific: [],
          dashboard: { value: [], valid: true, msg: '' },
          oldEmail: '',
          isGlobalUser: false,
        },
        profileOptions: [],
        offeringOptions: [],
        subOfferingOptions: [],
        clientOptions: [],
        dashboardClientOptions: [],
        generalErrorMessage: '',
        profileToAdd: {
          profile: { value: {}, valid: true, msg: '' },
          offering: { value: [], valid: true, msg: '' },
          subOffering: { value: [], valid: true, msg: '' },
          client: { value: [], valid: true, msg: '' },
        },
        showDelConfirmationModal: false,
        filteredData: [],
        selectedProfile: [],
        qcListInOfferingClient: [],
        isProfileEmpty: false,
        isQCValid: true,
        disableSave: false,
        duplicateClients: [],
        mappedUserDeletionData: {
          showDeleteUserMapping: false,
          clients: "",
          deleteOnEdit: {},
          originData: {},
          origin: ""
        },
        toBeDeleted: {},
        prevSelectedProfile: [],
      };
    }

    componentDidMount() {
      try {
        LogUserSiteAction({ module: USER_MANAGEMENT });
      }
      catch (e) {
        console.log(e)
      }

    }
    getGridApi = (api) => {
      try {
        this.setState({
          gridApi: api,
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    componentWillMount() {
      try {
        this.initialCall();
      } catch (e) {
        console.log(e);
      }
    }

    resetState = () => {
      try {
        this.setState({
          ...this.state,
          userDetails: {
            userID: 0,
            userName: { value: '', valid: true, msg: '' },
            emailID: { value: '', valid: true, msg: '' },
            isActive: false,
            clientType: 0,
            clientAgnostic: [],
            clientSpecific: [],
            dashboard: { value: [], valid: true, msg: '' },
            oldEmail: '',
            isGlobalUser: false,
          },
          generalErrorMessage: '',
          profileToAdd: {
            profile: { value: {}, valid: true, msg: '' },
            offering: { value: [], valid: true, msg: '' },
            subOffering: { value: [], valid: true, msg: '' },
            client: { value: [], valid: true, msg: '' },
          },
          selectedProfile: [],
          isProfileEmpty: false,
          disableSave: false,
          duplicateClients: []
        });
      } catch (e) {
        console.log(e);
      }
    };

    initialCall = async () => {
      try {
        await this.resetState();
        await this.getUserManagementList();
        await this.getAllClientList();
        await this.getAllOfferingList();
        await this.getQCListInOfferingClient();
      } catch (e) {
        console.log(e);
      }
    };

    getAllProfieList = async () => {
      try {
        let subMenuId = GetSubMenuId("User Management")
        const profileList = await HttpGet(`User/GetProfilesList?SubMenuID=${subMenuId}`);

        let profileOptions = [];
        if (profileList != null && profileList.length > 0) {
          profileList.forEach(i => {
            profileOptions.push({
              value: i.Id,
              label: i.ProfileName,
              text: i.ProfileName,
              clientAgnostic: i.ClientAgnostic
            });
          })
        }

        this.setState({
          profileOptions: profileOptions,
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    getAllOfferingList = async () => {
      try {
        let subMenuId = GetSubMenuId("User Management")
        const res = await HttpGet(`User/GetOfferingList?SubMenuID=${subMenuId}`);
        if (res != null && res.length > 0) {
          let offeringOptions = [];
          for (let i = 0; i < res.length; i++) {
            offeringOptions.push({
              value: res[i].Id,
              label: res[i].OfferingLevel2,
              text: res[i].OfferingLevel2,
              subofferings: res[i].SubOfferings,
              clients: res[i].clients,
            });
          }
          this.setState({
            offeringOptions: offeringOptions,
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    };

    getAllClientList = async () => {
      try {
        let subMenuId = GetSubMenuId("User Management")
        const res = await HttpGet(`ClientConfiguration/getclientslist?SubMenuID=${subMenuId}`);
        if (res != null && res.length > 0) {
          const clientOptions = res.map((o) => {
            return {
              value: o.Id,
              label: o.ClientMasterName,
              text: o.ClientMasterName,
              isFixed: false,
            };
          });

          this.setState({
            clientOptions: clientOptions,
            dashboardClientOptions: JSON.parse(JSON.stringify(clientOptions)),
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    };

    getUserManagementList = async () => {
      try {
        let subMenuId = GetSubMenuId("User Management");
        const res = await HttpGet(`User/GetAllUsers?SubMenuID=${subMenuId}`);
        if (res != null && res.length > 0) {
          let data = [];
          for (let i = 0; i < res.length; i++) {
            // let mappedClients = "ABBOTT LABORATORIES,Test Client A";
            let clientList = IsValidStr(res[i].MappedToClients) ? res[i].MappedToClients.replace(/,/g, ", ") : ""; //ClientMasterName
            // let clientList = IsValidStr(mappedClients) ? mappedClients.replace(",", ", ") : ""; //ClientMasterName
            data.push({
              id: res[i].Id,
              username: res[i].UserName,
              emailid: res[i].UserEmailID,
              isactive: res[i].IsActive ? 'Yes' : 'No',
              clientList: clientList,
              isMapped: IsValidStr(clientList),
            });
          }
          this.setState({
            tableProperties: { ...this.state.tableProperties, data: data },
            filteredData: data
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    getQCListInOfferingClient = async () => {
      try {
        const result = await HttpGet(`User/GetQualityCheckerListInOfferingClient`);

        if (result !== null && result.length > 0) {
          this.setState({
            qcListInOfferingClient: result
          })
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    onTablSearchTextChanged = (e) => {
      try {
        let filteredData = this.state.tableProperties.data.filter(x => x.username.toLowerCase().includes(e.target.value.toLowerCase()))

        if (IsValidStr(e.target.value)) {
          this.setState({
            filteredData: filteredData
          })
        } else {
          this.setState({
            filteredData: this.state.tableProperties.data
          })
        }

        this.state.gridApi.setQuickFilter(e.target.value);
      } catch (e) {
        console.log(e);
      }
    };

    toFixedDashboardClients = () => {
      try {
        let userDetails = this.state.userDetails;
        let dashboardClients = userDetails.dashboard.value;
        let dashboardClientOptions = this.state.dashboardClientOptions;

        //remove isFixed true, to remove client associated with deleted profile
        dashboardClients = dashboardClients.filter((dc) => !dc.isFixed);

        let offeringOptions = JSON.parse(JSON.stringify(this.state.offeringOptions));

        //GET ALL CLIENTS THAT ARE AUTO-SELECTED BASED ON OFFERING
        let clients = [];
        let clientRef = [];
        if (userDetails.clientType === 1) {
          (userDetails.clientAgnostic || []).forEach((cs) => {
            (cs.clients || [])
              .filter((cs2) => clientRef.indexOf(cs2.value) === -1)
              .forEach((c) => {
                clients.push({ id: c.value, name: c.label });
                clientRef.push(c.value);
              });
          });
        } else if (userDetails.clientType === 2) {
          const qcProfile = userDetails.clientAgnostic.find(ca => ca.value === 16) // QC Profile ID: 16
          if (qcProfile) {
            qcProfile.offerings.forEach((off) => {
              offeringOptions
                .filter((off1) => off.value === off1.value)
                .forEach((offering) => {
                  offering.clients
                    .filter((c1) => {
                      return clientRef.indexOf(c1.ClientId) === -1 && Number(c1.ClientId) > 0;
                    })
                    .forEach((c) => {
                      clients.push({ id: c.ClientId, name: c.ClientName });
                      clientRef.push(c.ClientId);
                    });
                });
            });
          }
          (userDetails.clientSpecific || []).forEach((cs) => {
            (cs.clients || [])
              .filter((cs2) => clientRef.indexOf(cs2.value) === -1)
              .forEach((c) => {
                clients.push({ id: c.value, name: c.label });
                clientRef.push(c.value);
              });
          });
        } else {
          // ------------ Specific -------------------
          const qcProfile = userDetails.clientAgnostic.find(ca => ca.value === 16) // QC Profile ID: 16
          if (qcProfile) {
            qcProfile.offerings.forEach((off) => {
              offeringOptions
                .filter((off1) => off.value === off1.value)
                .forEach((offering) => {
                  offering.clients
                    .filter((c1) => {
                      return clientRef.indexOf(c1.ClientId) === -1 && Number(c1.ClientId) > 0;
                    })
                    .forEach((c) => {
                      clients.push({ id: c.ClientId, name: c.ClientName });
                      clientRef.push(c.ClientId);
                    });
                });
            });
          }
          (userDetails.clientSpecific || []).forEach((cs) => {
            (cs.clients || [])
              .filter((cs2) => clientRef.indexOf(cs2.value) === -1)
              .forEach((c) => {
                clients.push({ id: c.value, name: c.label });
                clientRef.push(c.value);
              });
          });

          // ------------ Agnostic -------------------
          (userDetails.clientAgnostic || []).forEach((cs) => {
            (cs.clients || [])
              .filter((cs2) => clientRef.indexOf(cs2.value) === -1)
              .forEach((c) => {
                clients.push({ id: c.value, name: c.label });
                clientRef.push(c.value);
              });
          });
        }

        //MERGE ALL AUTO-SELECTED-CLIENTS WITH SELECTED DBC, MAKE IT REQUIRED
        clients.forEach((client) => {
          const iDC = dashboardClients.findIndex((dc) => dc.value === client.id);
          if (iDC > -1) {
            dashboardClients[iDC] = {
              ...dashboardClients[iDC],
              isFixed: true,
              isDisabled: true,
            };
          }

          if (iDC === -1) {
            dashboardClients.push({
              value: client.id,
              label: client.name,
              text: client.name,
              isFixed: true,
              isDisabled: true,
            });
          }
        });

        dashboardClientOptions.forEach((dco) => {
          const iDC = dashboardClients.findIndex((dc) => dc.value === dco.value);
          if (iDC > -1) {
            dco.isFixed = dashboardClients[iDC].isFixed;
            dco.isDisabled = dashboardClients[iDC].isDisabled;
            dashboardClients[iDC] = dco;
          }

          if (iDC === -1) {
            dco.isFixed = false;
            dco.isDisabled = false;
          }
        });

        userDetails.dashboard = { value: [], valid: true, msg: '' };
        userDetails.dashboard.value =
          dashboardClients.length === dashboardClientOptions.length
            ? dashboardClientOptions
            : dashboardClients;
        this.setState({
          userDetails: userDetails,
          dashboardClientOptions: dashboardClientOptions,
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    getDetailsofUserManagement = async (id) => {
      try {
        this.resetState();
        let subMenuId = GetSubMenuId("User Management");

        let body = {
          userId: id,
        };
        let res = await HttpPostText(`User/GetUserConfigInfo?SubMenuID=${subMenuId}`, body);
        res = JSON.parse(res);

        if (res != null) {
          let userDetails = this.state.userDetails;
          let dashboardClientOptions = this.state.dashboardClientOptions;
          let offeringOptions = this.state.offeringOptions;
          userDetails.userID = res.UserID;
          userDetails.userName = { value: res.UserName, valid: true, msg: '' };
          userDetails.emailID = { value: res.UserEmailId, valid: true, msg: '' };
          userDetails.oldEmail = res.UserEmailId;
          userDetails.isActive = res.IsActive;
          userDetails.clientType =
            res.IsGlobalUser &&
              !res.IsActive &&
              !res.IsClientAgnostic &&
              res.clientSpecific.length === 0 &&
              res.clientDashoboard.length === 0
              ? 0
              : res.IsClientAgnostic && !res.IsBothSpecificAgnostic
                ? 1
                : 2;
          userDetails.isGlobalUser = res.IsGlobalUser;

          /*Dashboard Start*/
          let dashboardValueRef = [];
          let profile = [];

          if ((res.ClientDashboard != null && res.ClientDashboard.length > 0)) {
            if (res.IsBothSpecificAgnostic || !res.IsClientAgnostic) {//BOTH or SPECIFIC
              if (!res.AllClients) {
                res.ClientDashboard.map((f) => {
                  dashboardClientOptions.map((d) => {
                    if (f.ClientId === d.value && dashboardValueRef.indexOf(d.value) === -1) {
                      userDetails.dashboard.value.push(d);
                      dashboardValueRef.push(d.value);
                    }
                  });
                });
              } else {
                dashboardClientOptions.map((d) => {
                  if (d.value !== 'All') {
                    userDetails.dashboard.value.push(d);
                    dashboardValueRef.push(d.value);
                  }
                });
              }
            } else {// AGNOSTIC
              if (!res.AllClients) {
                res.ClientDashboard.map((f) => {
                  dashboardClientOptions.map((d) => {
                    if (f.ClientId === d.value && dashboardValueRef.indexOf(d.value) === -1) {
                      userDetails.dashboard.value.push(d);
                      dashboardValueRef.push(d.value);
                    }
                  });
                });
              } else {
                dashboardClientOptions.map((d) => {
                  if (d.value !== 'All') {
                    userDetails.dashboard.value.push(d);
                    dashboardValueRef.push(d.value);
                  }
                });
              }
            }
          }
          /* Dashboard End*/

          /* Profile Data Start */
          await this.getAllProfieList();
          if (res.clientAgnostic !== null && res.clientAgnostic.length > 0) {
            userDetails.clientAgnostic = [];
            res.clientAgnostic.map((p, pInd) => {
              let offerings = [];
              let subofferings = [];
              let clients = [];
              let mappedProf = {
                profClientList: [],
                profClients: ""
              };
              p.offeringsList.map((m, mInd) => {
                let offeringSubOff = [];
                let clientsPerOff = [];
                let mappedOff = {
                  offClientList: [],
                  offClients: ""
                };
                offeringOptions.map((oo) => {
                  if (oo.value === m.OfferingId) {
                    offeringSubOff = oo.subofferings;
                  }
                });
                m.clientsList.map((c, cInd) => {
                  //for overall offering clients
                  if (IsArrayEmpty(clients.filter((a) => a.value === c.ClientId))) {
                    clients.push({
                      text: c.ClientName,
                      label: c.ClientName,
                      value: c.ClientId,
                      isMapped: c.IsMapped === 1,
                    })

                    //for overall mapped clients under offering
                    if (c.IsMapped === 1 && IsArrayEmpty(mappedProf.profClientList.filter((a) => a.ClientId === c.ClientId))) {
                      mappedProf.profClientList.push({
                        ClientName: c.ClientName,
                        ClientId: c.ClientId,
                      })
                    }
                  }

                  //for clients per offering
                  clientsPerOff.push({
                    ClientName: c.ClientName,
                    ClientId: c.ClientId,
                    isMapped: c.IsMapped === 1,
                  })

                  //for mapped clients under offering
                  if (c.IsMapped === 1 &&
                    IsArrayEmpty(mappedOff.offClientList.filter((a) => a.ClientId === c.ClientId)))
                  // mappedOff.offClientList.indexOf(c.ClientId) === -1)
                  {
                    mappedOff.offClientList.push({
                      ClientName: c.ClientName,
                      ClientId: c.ClientId,
                      isMapped: c.IsMapped === 1
                    })
                  }
                });
                mappedOff.OffClients = mappedOff.offClientList.map((a) => { return a.ClientName }).toString().replace(/,/g, ", ");
                offerings.push({
                  value: m.OfferingId,
                  label: m.OfferingLevel1 + '-' + m.OfferingLevel2,
                  text: m.OfferingLevel1 + '-' + m.OfferingLevel2,
                  subofferings: offeringSubOff,
                  clients: clientsPerOff,
                  isMapped: !IsArrayEmpty(mappedOff.offClientList),
                  mappedOff: mappedOff,
                });
                m.SubOfferings.map((s) => {
                  subofferings.push({
                    value: s.subOfferingId,
                    label: s.subOfferingName,
                    text: s.subOfferingName,
                    offeringID: m.OfferingId,
                  });
                });
              });

              mappedProf.profClients = mappedProf.profClientList.map((a) => { return a.ClientName }).toString().replace(/,/g, ", ");
              userDetails.clientAgnostic.push({
                value: p.ProfileId,
                label: p.ProfileName,
                text: p.ProfileName,
                offerings: offerings,
                subofferings: subofferings,
                hasClientDuplicate: false,
                isEmpty: {
                  offerings: false,
                  suboffering: false,
                  clients: false
                },
                clientAgnostic: true,
                clients: clients,
                isMapped: !IsArrayEmpty(mappedProf.profClientList),
                mappedProf: mappedProf,
                originalData: {
                  offerings: offerings,
                  subofferings: subofferings,
                  clients: clients,
                }
              });

              profile.push({
                value: p.ProfileId,
                label: p.ProfileName,
                text: p.ProfileName,
                clientAgnostic: true,
                isMapped: !IsArrayEmpty(mappedProf.profClientList),
              })
            });
          }

          //client specific
          if (res.clientSpecific !== null && res.clientSpecific.length > 0) {
            userDetails.clientSpecific = [];
            res.clientSpecific.map((p) => {
              let clients = [];
              let mappedProf = {
                profClientList: [],
                profClients: ""
              };
              p.clientsList.map((c) => {
                clients.push({
                  value: c.ClientId,
                  label: c.ClientName,
                  text: c.ClientName,
                  isMapped: c.IsMapped === 1
                });

                //for overall mapped clients under offering
                if (c.IsMapped === 1 &&
                  IsArrayEmpty(mappedProf.profClientList.filter((a) => a.ClientId === c.ClientId))) {
                  mappedProf.profClientList.push({
                    ClientName: c.ClientName,
                    ClientId: c.ClientId,
                    isMapped: c.IsMapped === 1
                  })
                }
              });
              mappedProf.profClients = mappedProf.profClientList.map((a) => { return a.ClientName }).toString().replace(/,/g, ", ");
              userDetails.clientSpecific.push({
                value: p.ProfileId,
                label: p.ProfileName,
                text: p.ProfileName,
                clients: clients,
                hasClientDuplicate: false,
                isEmpty: {
                  clients: false
                },
                clientAgnostic: false,
                isMapped: !IsArrayEmpty(mappedProf.profClientList),
                mappedProf: mappedProf,
                originalData: {
                  clients: clients,
                }
              });

              profile.push({
                value: p.ProfileId,
                label: p.ProfileName,
                text: p.ProfileName,
                clientAgnostic: false,
                isMapped: !IsArrayEmpty(mappedProf.profClientList)
              })
            });
          }
          /* Profile Data End */
          userDetails.originalData = {
            clientAgnostic: userDetails.clientAgnostic,
            clientSpecific: userDetails.clientSpecific,
          }
          this.setState(
            {
              isEdit: true,
              userDetails: userDetails,
              prevUserDetails: JSON.parse(JSON.stringify(userDetails)),
              selectedProfile: profile
            },
            () => {
              this.toFixedDashboardClients();
              this.onCloseModal();
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    refreshDashboardOptions = () => {
      try {
        const dashboardClientOptions = this.state.dashboardClientOptions.map((c) => {
          return {
            ...c,
            isFixed: false,
            isDisabled: false,
          };
        });

        this.setState({
          dashboardClientOptions: dashboardClientOptions,
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    onCloseModal = () => {
      try {
        if (this.state.show) {
          this.refreshDashboardOptions();
        }
        this.setState({
          ...this.state,
          show: !this.state.show,
          isProfileEmpty:false
        });
      } catch (e) {
        console.log(e);
      }
    };

    onChangeUserDetails = (value, field, action) => {
      try {
        let user = this.state.userDetails;
        let profileToAdd = this.state.profileToAdd;
        let generalErrorMessage = this.state.generalErrorMessage;
        let dashboardClientOptions = this.state.dashboardClientOptions;
        switch (field) {
          case 'username':
            user.userName = { value: value.value, valid: true, msg: '' };
            generalErrorMessage = '';
            break;
          case 'emailid':
            user.emailID = { value: value.value, valid: true, msg: '' };
            generalErrorMessage = '';
            break;
          case 'isactive':
            user.isActive =
              value.length === 0 ? false : value[0].value === 'IsGlobalUser' ? false : true;
            break;
          case 'agnostic':
            user.clientAgnostic = value;
            break;
          case 'specific':
            user.clientSpecific = value;
            break;
          case 'dashboard':
            let clients = value;

            user.dashboard = {
              value: clients,
              valid: true,
              msg: '',
            };
            break;
          default:
            break;
        }
        this.setState(
          {
            userDetails: user,
            profileToAdd: profileToAdd,
            generalErrorMessage: generalErrorMessage,
            dashboardClientOptions: dashboardClientOptions,
          },
          () => {
            this.toFixedDashboardClients();
          }
        );
      }
      catch (e) {
        console.log(e)
      }
    };

    // profile to add
    profileSelection = (value, field, origin) => { //onchange dropdown profile
      try {
        let userDetails = this.state.userDetails;
        const currentSelectedProfile = origin === 'delete' ? this.state.prevSelectedProfile : this.state.selectedProfile;
        const profileList = this.state.profileOptions
        const newSelectedProfile = value.filter(x => !currentSelectedProfile.some(y => x.value === y.value))
        const isDeleteProfile = origin === 'delete' ? true : currentSelectedProfile.length > 0 && currentSelectedProfile.length > value.length
        const deleteProfile = isDeleteProfile && (value.length > 0 ? currentSelectedProfile.filter(x => {
          return !value.some(y => {
            return x.value === y.value
          })
        }) : currentSelectedProfile)

        const isAgnostic = deleteProfile.length > 0 && deleteProfile.length !== profileList.length ? deleteProfile[0].clientAgnostic : false
        const isSpecific = deleteProfile.length > 0 && deleteProfile.length !== profileList.length ? !deleteProfile[0].clientAgnostic : false

        //for ALL - change when new role is added in tool
        const isSelectAll = deleteProfile.length === profileList.length ? true : false

        const agnosticSpecificList = isSpecific ? this.state.userDetails.clientSpecific : isAgnostic ? this.state.userDetails.clientAgnostic : []
        let index = agnosticSpecificList.map(x => x.value).indexOf(deleteProfile.length > 0 && deleteProfile.length !== profileList.length ? deleteProfile[0].value : 0);

        userDetails.originalData = {
          clientAgnostic: userDetails.clientAgnostic,
          clientSpecific: userDetails.clientSpecific,
        }

        if (origin === "checker") {
          this.setState({
            // userDetails: userDetails,
            selectedProfile: value,
            prevSelectedProfile: this.state.selectedProfile
            // isProfileEmpty: value.length === 0,
          });
        } else {
          this.setState({
            userDetails: userDetails,
            selectedProfile: value,
            isProfileEmpty: value.length === 0,
            prevSelectedProfile: this.state.selectedProfile
          }, async () => {
            if (!isDeleteProfile) {
              await this.onAddProfile(origin);
            } else {
              await this.onDeleteProfile(index, isSelectAll ? 3 : isAgnostic ? 1 : 2);
            }
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    };

    handleCheckForMappings = (value, field) => {
      try {
        let userDetails = this.state.userDetails;
        let prevUserDetails = this.state.prevUserDetails;
        const isDeleteProfile = this.state.selectedProfile.length > 0 && this.state.selectedProfile.length > value.length
        const deleteProfile = isDeleteProfile && (value.length > 0 ? this.state.selectedProfile.filter(x => {
          return !value.some(y => {
            return x.value === y.value
          })
        }) : this.state.selectedProfile)
        let removedProfiles = [];
        let clientList = [];
        let deleteMapped = false;

        if (IsArray(deleteProfile)) {
          userDetails.clientAgnostic.map((ca) => {
            let prevProfile = IsArrayEmpty(prevUserDetails.clientAgnostic.filter((pca) => pca.value === ca.value)) ? [] :
              prevUserDetails.clientAgnostic.filter((pca) => pca.value === ca.value)[0];

            if (HasValue(prevProfile.isMapped) && prevProfile.isMapped
              && !IsArrayEmpty(deleteProfile.filter((a) => ca.value === a.value))) {
              removedProfiles.push(prevProfile)
            }
          })

          userDetails.clientSpecific.map((ca) => {
            let prevProfile = IsArrayEmpty(prevUserDetails.clientSpecific.filter((pcs) => pcs.value === ca.value)) ? [] :
              prevUserDetails.clientSpecific.filter((pcs) => pcs.value === ca.value)[0];

            if (HasValue(prevProfile.isMapped) && prevProfile.isMapped
              && !IsArrayEmpty(deleteProfile.filter((a) => ca.value === a.value))) {
              removedProfiles.push(prevProfile)
            }
          })

          removedProfiles.map((rem) => {
            rem.clients.map((cl) => {
              if (cl.isMapped && IsArrayEmpty(clientList.filter((a) => a.value === cl.value))) {
                clientList.push(cl);
              }
            })
          })

          deleteMapped = !IsArrayEmpty(removedProfiles);
        }

        this.setState({
          mappedUserDeletionData: {
            ...this.state.mappedUserDeletionData,
            showDeleteUserMapping: !IsArrayEmpty(clientList),
            clients: !IsArrayEmpty(clientList) ?
              clientList.map((a) => { return a.text }).toString().replace(/,/g, ", ")
              : this.state.mappedUserDeletionData.clientList,
            deleteOnEdit: {
              value: value,
              field: field,
            },
            originData: {
              value: this.state.selectedProfile,
              field: field,
            },
            origin: "",
          },
        })
        let fromCheck = deleteMapped ? "checker" : "";
        this.profileSelection(value, field, fromCheck)
      }
      catch (e) {
        console.log(e)
      }
    }

    onAddProfile = async (origin) => {
      try {
        const selectedProfile = this.state.selectedProfile;
        let userDetails = this.state.userDetails;
        let profile = this.state.profileToAdd;
        let offerings = [];
        let subofferings = profile.subOffering.value;
        let clients = profile.client.value;
        let isAgnostic = false
        let isSpecific = false

        if (origin === 'cancel') {
          userDetails.clientAgnostic = userDetails.originalData.clientAgnostic;
          userDetails.clientSpecific = userDetails.originalData.clientSpecific;
          this.setState({
            userDetails: userDetails
          },
            () => {
              this.toFixedDashboardClients();
            })
        } else {
          selectedProfile.forEach(x => {
            let profileExist = false;
            isAgnostic += x.clientAgnostic
            isSpecific += !x.clientAgnostic
            if (x.clientAgnostic) {
              // refresh table

              profile.offering.value.map((o) => {
                offerings.push({
                  value: o.value,
                  label: o.label,
                  text: o.text,
                  subofferings: o.subofferings,
                });
              });
              userDetails.clientAgnostic.map((p) => {
                if (p.value === x.value) {
                  profileExist = true;
                  profile.subOffering.value.map((s) => {
                    let subExist = false;
                    p.subofferings.map((ps) => {
                      if (ps.value === s.value) {
                        subExist = true;
                      }
                    });
                    if (!subExist) {
                      p.subofferings.push(s);
                    }
                  });
                  profile.offering.value.map((o) => {
                    let exist = false;
                    p.offerings.map((po) => {
                      if (po.value === o.value) {
                        exist = true;
                      }
                    });
                    if (!exist) {
                      p.offerings.push({
                        value: o.value,
                        label: o.label,
                        text: o.label,
                        subofferings: o.subofferings,
                      });
                    }
                  });
                }
              });
              if (!profileExist) {
                userDetails.clientAgnostic.push({
                  value: x.value,
                  label: x.label,
                  text: x.label,
                  offerings: offerings,
                  subofferings: subofferings,
                  clientAgnostic: x.clientAgnostic,
                  isEmpty: {
                    offerings: false,
                    suboffering: false,
                    clients: false
                  },
                  isMapped: false,
                  mappedProf: {
                    profClientList: [],
                    profClients: ""
                  },
                  originalData: {
                    offerings: offerings,
                    subofferings: subofferings,
                    clients: clients,
                  }
                });
              }
            }

            if (!x.clientAgnostic) {
              // let profileExist = false;
              userDetails.clientSpecific.map((p) => {
                if (p.value === x.value) {
                  profileExist = true;
                  profile.client.value.map((c) => {
                    let exist = false;
                    p.clients.map((pc) => {
                      if (pc.value === c.value) {
                        exist = true;
                      }
                    });
                    if (!exist) {
                      p.clients.push({
                        value: c.value,
                        label: c.label,
                        text: c.label,
                      });
                    }
                  });
                }
              });

              if (!profileExist) {
                userDetails.clientSpecific.push({
                  value: x.value,
                  label: x.label,
                  text: x.label,
                  clients: clients,
                  clientAgnostic: x.clientAgnostic,
                  isEmpty: {
                    clients: false
                  },
                  isMapped: false,
                  mappedProf: {
                    profClientList: [],
                    profClients: ""
                  },
                  originalData: {
                    clients: clients,
                  }
                });
              }
            }

            userDetails.clientType = isSpecific && isAgnostic ? 3 : isSpecific ? 2 : 1
          })
          this.setState(
            {
              userDetails: { ...this.state.userDetails, clientAgnostic: [], clientSpecific: [] },
            },
            () => {
              this.setState(
                {
                  userDetails: userDetails,
                  profileToAdd: {
                    profile: { value: {}, valid: true, msg: '' },
                    offering: { value: [], valid: true, msg: '' },
                    subOffering: { value: [], valid: true, msg: '' },
                    client: { value: [], valid: true, msg: '' },
                  },
                },
                () => {
                  this.toFixedDashboardClients();
                }
              );
            }
          );
        }
      }
      catch (e) {
        console.log(e)
      }
    };

    onDeleteProfile = (index, clientType) => {
      try {
        let userDetails = this.state.userDetails;
        let clientSpecific = userDetails.clientSpecific
        let clientAgnostic = userDetails.clientAgnostic

        if (clientType === 1) {
          userDetails.clientAgnostic.splice(index, 1);
          if (userDetails.clientAgnostic.filter(x => x.value === 16).length === 0) {
            this.setQCValid(true);
          }
        } else if (clientType === 2) {
          userDetails.clientSpecific.splice(index, 1);
        } else { // unselect ALL
          userDetails.clientSpecific.splice(0, clientSpecific.length);
          userDetails.clientAgnostic.splice(0, clientAgnostic.length);
        }

        this.setState(
          {
            userDetails: { ...this.state.userDetails, clientAgnostic: [], clientSpecific: [] },
          },
          () => {
            this.setState(
              {
                userDetails: userDetails,
              },
              () => {
                this.toFixedDashboardClients();
              }
            );
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    onEditProfile = (profile, index) => {
      try {
        let userDetails = this.state.userDetails;
        if (profile.clientAgnostic) {
          userDetails.clientAgnostic[index] = profile;
        } else {
          userDetails.clientSpecific[index] = profile;
        }
        this.setState(
          {
            userDetails: userDetails,
          },
          () => {
            this.toFixedDashboardClients();
          }
        );
      } catch (e) {
        console.log(e);
      }
    };

    getDashboardData = (data) => {
      try {
        let dashboardClientOptions = this.state.dashboardClientOptions;
        let returnData = [];
        if (data.length === 1 && data[0].value === 'All') {
          dashboardClientOptions
            .filter((t) => t.value !== '*')
            .forEach((c) => {
              return returnData.push({
                clientId: c.value,
                clientName: c.label,
              });
            });
        } else {
          data
            .filter((t) => t.value !== '*')
            .forEach((c) => {
              return returnData.push({ clientId: c.value, clientName: c.label });
            });
        }
        return returnData;
      }
      catch (e) {
        console.log(e)
      }
    };

    getProfileSpecificData = (data, type) => {
      try {
        let returnData = [];
        if (type === 1) {
          data.map((p) => {
            let offering = [];
            p.offerings
              .filter((t) => t.value !== '*')
              .map((o) => {
                let subofferings = [];
                let clients = []
                p.subofferings
                  .filter((t) => t.value !== '*')
                  .map((s) => {
                    if (s.offeringID === o.value) {
                      subofferings.push({
                        subOfferingId: s.value,
                        subOfferingName: s.label,
                      });
                    }
                  });
                p.clients
                  .filter((t) => t.value !== '*')
                  .map((s) => {
                    clients.push({
                      clientId: s.value,
                      clientName: s.label,
                    });
                  });
                offering.push({
                  offeringId: o.value,
                  offeringLevel1: o.label.split('-')[0],
                  offeringLevel2: o.label.split('-')[1],
                  subOfferings: subofferings,
                  clientsList: clients
                });
              });

            returnData.push({
              profileId: p.value,
              profileName: p.label,
              offeringsList: offering,
            });
          });
        } else {
          data.map((p) => {
            let clients = [];
            p.clients
              .filter((t) => t.value !== '*')
              .map((c) => {
                clients.push({
                  clientId: c.value,
                  clientName: c.label,
                });
              });

            returnData.push({
              profileId: p.value,
              profileName: p.label,
              clientsList: clients,
            });
          });
        }
        return returnData;
      }
      catch (e) {
        console.log(e)
      }
    };

    validateUserDetails = async () => {
      try {
        let userDetails = this.state.userDetails;
        let allValid = true;
        let leadingSpace = /^\s+/;
        let trailingSpace = /\s+$/;
        let betweenSpace = / +/g;
        const re =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const usernameRegex = /^[a-zA-Z._-]+$/;
        let emailArray = userDetails.emailID.value.split(' ').join('');
        let roleArray = []
        let tempArray = userDetails.emailID.value.split('@');
        let domain = tempArray.length > 1 ? tempArray[1].toLowerCase() : '';

        if (userDetails.userName.value == "") {
          userDetails.userName = { value: '', valid: false, msg: 'Missing required field' };
          allValid=false;
        }
        if (userDetails.userName.value !== "" && !usernameRegex.test(userDetails.userName.value.trim())) {
          userDetails.userName = { value: '', valid: false, msg: 'Invalid username' };
          allValid=false;
        }
        if (userDetails.emailID.value == "") {
            userDetails.emailID = { value: '', valid: false, msg: 'Missing required field' };
            allValid=false;
        }
        if(!re.test(userDetails.emailID.value.trim()) && userDetails.emailID.value != ""){
          userDetails.emailID = {
            value: emailArray.trim(),
            valid: false,
            msg: "Invalid Email",
          };
          allValid=false;
        }
        if (
          userDetails.userName.value.match(leadingSpace) ||
          userDetails.userName.value.match(trailingSpace)
        ) {
          userDetails.userName = { value: userDetails.userName.value.trim(), valid: true, msg: '' };
          allValid=false;;
        }
        if (
          userDetails.emailID.value.match(leadingSpace) ||
          userDetails.emailID.value.match(trailingSpace) ||
          userDetails.emailID.value.match(betweenSpace)
        ) {
          userDetails.emailID = { value: emailArray.trim(), valid: true, msg: '' };
          allValid=false;
        } else if (
          userDetails.emailID.value.split("@")[0].match(leadingSpace) ||
          userDetails.emailID.value.split("@")[0].match(trailingSpace) ||
          userDetails.emailID.value.split("@")[0].match(betweenSpace)
        ) {
          userDetails.emailID = { value: '', valid: true, msg: '' };
          allValid=false;
        } else if (domain.trim() !== 'accenture.com' && userDetails.emailID.value !== '') {
            userDetails.emailID = {
              value: emailArray.trim(),
              valid: false,
              msg: "Valid email ID should have '@accenture.com'",
            };
            allValid=false;
        } else {
          const data = await HttpPost(`User/validateEmail`,{
                isDomainAppend: true,
                users: [
                  userDetails.emailID.value
                ]});
          if(data[0].IsValid) {
            allValid=true;
          }
          else{
            userDetails.emailID = {
              value: "",
              valid: false,
              msg: "Invalid Accenture ID",
            };
            allValid=false;
          }
        }

        if (userDetails.dashboard.value.length === 0) {
          userDetails.dashboard = { value: [], valid: false, msg: 'Missing required field' };
          allValid=false;
        }

        if (userDetails.clientAgnostic.length > 0) {
          userDetails.clientAgnostic.map(x => {
            if ((x.clients || []).length === 0) {
              x.isEmpty.clients = true;
              allValid=false;
            } else {
              x.isEmpty.clients = false;
            }

            if (x.offerings.length === 0) {
              x.isEmpty.offerings = true;
              allValid=false;
            } else {
              x.isEmpty.offerings = false;
            }

            if (x.subofferings.length === 0) {
              x.isEmpty.suboffering = true;
              allValid=false;
            } else {
              x.isEmpty.suboffering = false;
            }

            if (x.isEmpty.clients === false && x.isEmpty.offerings === false && x.isEmpty.suboffering === false) {
              roleArray.push(x)
            }
          })
        }

        if (userDetails.clientSpecific.length > 0) {
          userDetails.clientSpecific.map(x => {
            if (x.clients.length === 0) {
              x.isEmpty.clients = true;
              allValid=false;
            } else {
              x.isEmpty.clients = false;
            }

            if (x.isEmpty.clients === false) {
              roleArray.push(x)
            }
          })
        }

        if (roleArray.length < 1) {
          allValid=false;
        }

        if (this.state.selectedProfile.length === 0) {
          allValid=false;
        }

        this.setState({
          userDetails: userDetails,
          isProfileEmpty: this.state.selectedProfile.length === 0
        });
        return allValid;
      }
      catch (e) {
        console.log(e)
      }
    };

    saveUserManagement = () => {
      try {
        this.setState({
          disableSave: true
        }, async () => {
          let valid = await this.validateUserDetails();
          const userDetails = this.state.userDetails;
          if (valid) {
            const dashboardClientOptions = this.state.dashboardClientOptions;
            const qcProfile = this.getProfileSpecificData(userDetails.clientAgnostic, 1)
              .find(profile => profile.profileId === 16); // QC Profile ID: 16
            const clientSpecific = await this.getProfileSpecificData(userDetails.clientSpecific, 2);
            const clientAgnostic = await this.getProfileSpecificData(userDetails.clientAgnostic, 1);
            const isBothSpecificAgnostic = clientAgnostic.length > 0 && clientSpecific.length > 0; //double negation since qcprofile is an object

            let bodyParam = {
              UserConfig: {
                userID: userDetails.userID,
                userName: userDetails.userName.value,
                userEmailId: userDetails.emailID.value,
                isActive: userDetails.isActive,
                isClientAgnostic: !isBothSpecificAgnostic ? (clientAgnostic.length > 0) : isBothSpecificAgnostic,
                IsBothSpecificAgnostic: isBothSpecificAgnostic,
                clientAgnostic: clientAgnostic,
                clientSpecific: clientSpecific,
                clientDashboard: this.getDashboardData(userDetails.dashboard.value),
                allClients: userDetails.dashboard.value.length === dashboardClientOptions.length,
              },
              oldEmail: userDetails.oldEmail === "" ? userDetails.emailID.value : userDetails.oldEmail,
            };
            let subMenuId = GetSubMenuId("User Management");
            const data = await HttpPost(`User/AddEditUserConfig?SubMenuID=${subMenuId}`, bodyParam);
            if (data !== null) {
              if (data.Code === '-1' || data.Code === '2') {
                this.setState({
                  generalErrorMessage: data.Message,
                });
              }
              else if (data.Code === '1') {
                this.onCloseModal();
                let returnMsg = 'New User has been added successfully!';
                if (userDetails.userID > 0) {
                  returnMsg = 'User has been updated successfully!';
                }

                this.props.setToasterParam({
                  show: true,
                  message: returnMsg,
                  type: 'success'
                })

                this.initialCall();
              }
            }
          }

          this.setState({
            disableSave: false
          })
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    onAddNewUser = () => {
      try {
        this.setState(
          {
            ...this.state,
            isEdit: false,
            userDetails: {
              userID: 0,
              userName: { value: '', valid: true, msg: '' },
              emailID: { value: '', valid: true, msg: '' },
              isActive: false,
              clientType: 1,
              clientAgnostic: [],
              clientSpecific: [],
              dashboard: { value: [], valid: true, msg: '' },
              oldEmail: '',
            },
            generalErrorMessage: '',
            profileToAdd: {
              profile: { value: {}, valid: true, msg: '' },
              offering: { value: [], valid: true, msg: '' },
              subOffering: { value: [], valid: true, msg: '' },
              client: { value: [], valid: true, msg: '' },
            },
            selectedProfile: []
          },
          () => {
            this.getAllProfieList();
            this.onCloseModal();
          }
        );
      }
      catch (e) {
        console.log(e)
      }
    };

    deleteUserManagementData = (data, origin) => {
      try {
        const { id } = data;
        let showDelConfirmationModal = this.state.showDelConfirmationModal;
        let showDeleteUserMapping = this.state.mappedUserDeletionData.showDeleteUserMapping;

        if (data != null && id > 0) {
          if (data.isMapped) {
            showDeleteUserMapping = true;
          } else {
            showDelConfirmationModal = !showDelConfirmationModal;
          }
          this.setState({
            ...this.state,
            showDelConfirmationModal: showDelConfirmationModal,
            mappedUserDeletionData: {
              ...this.state.mappedUserDeletionData,
              showDeleteUserMapping: showDeleteUserMapping,
              clients: data.clientList,
              origin: origin,
              deleteOnEdit: {},
              originData: {},
            },
            toBeDeleted: data,
            deleteID: id,
          });
        }
      }
      catch (e) {
        console.log(e)
      }
    };

    onConfirmClose = () => {
      try {
        this.setState({
          ...this.state,
          showDelConfirmationModal: !this.state.showDelConfirmationModal,
        });
      }
      catch (e) {
        console.log(e)
      }
    };

    onConfirmDelete = async () => {
      try {
        let subMenuId = GetSubMenuId("User Management");
        let body = {
          userId: this.state.deleteID,
        };
        const data = await HttpPostText(`User/DeleteUser?SubMenuID=${subMenuId}`,body);
        if (data === 'success') {
          let isMapped = this.state.toBeDeleted.isMapped
          let showDelConfirmationModal = this.state.showDelConfirmationModal;
          let showDeleteUserMapping = this.state.mappedUserDeletionData.showDeleteUserMapping;

          if (this.state.toBeDeleted.isMapped) {
            showDeleteUserMapping = false;
          } else {
            showDelConfirmationModal = !showDelConfirmationModal;
          }

          this.setState(
            {
              showDelConfirmationModal: showDelConfirmationModal,
              mappedUserDeletionData: {
                ...this.state.mappedUserDeletionData,
                showDeleteUserMapping: showDeleteUserMapping,
                clients: "",
                deleteOnEdit: {},
                originData: {},
                origin: ""
              },
              toBeDeleted: {},
              // deleteID: id,
            },
            () => {
              this.initialCall();
            }
          );
          this.props.setToasterParam({
            show: true,
            type: 'success',
            message: 'User has been removed',
            hasUndo: true,
            callback: () => this.undoDelete(this.state.deleteID)
          })
        }
      } catch (e) {
        console.log(e);
      }
    };

    undoDelete = async (deleteID) => {
      try {
        let subMenuId = GetSubMenuId("User Management");
        let body = {
          userId: deleteID,
        };
        const data = await HttpPostText(`User/UndoDeletedUser?SubMenuID=${subMenuId}`,body);
        if (data === 'success') {
          this.setState(
            {
              deleteID: 0,
            },
            () => {
              this.initialCall();
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    setQCValid = (isValid) => {
      try {
        this.setState({
          isQCValid: isValid
        })
      }
      catch (err) {
        console.log(err)
      }
    }

    setDuplicateClients = (list) => {
      try {
        this.setState({
          duplicateClients: list
        })
      }
      catch (err) {
        console.log(err);
      }
    }

    handleDeleteUserMappings = async (origin) => {
      try {
        let deleteFromMain = this.state.mappedUserDeletionData.origin === 'tableDelete';
        let data = {
          value: [],
          field: ""
        }
        let root = "";
        if (deleteFromMain) {
          if (origin === 'delete') {
            this.onConfirmDelete();
          } else {
            this.setState({
              mappedUserDeletionData: {
                ...this.state.mappedUserDeletionData,
                showDeleteUserMapping: false,
                clients: "",
                deleteOnEdit: {},
                originData: {},
                origin: ""
              },
              toBeDeleted: {},
            })
          }
        } else {
          if (origin === 'delete') {
            data = this.state.mappedUserDeletionData.deleteOnEdit;
            root = 'delete'
          } else {
            data = this.state.mappedUserDeletionData.originData;
            root = "cancel"
          }
          await this.profileSelection(data.value, data.field, root)
          this.setState({
            mappedUserDeletionData: {
              ...this.state.mappedUserDeletionData,
              showDeleteUserMapping: false,
              clients: "",
              deleteOnEdit: {},
              originData: {},
              origin: ""
            },
          })
        }
      }
      catch (e) {
        console.log(e)
      }
    }

    render() {
      return (
        <UserManagementPresentational
          tableProperties={this.state.tableProperties}
          show={this.state.show}
          onCloseModal={this.onCloseModal}
          onChangeUserDetails={this.onChangeUserDetails}
          userDetails={this.state.userDetails}
          profileOptions={this.state.profileOptions}
          offeringOptions={this.state.offeringOptions}
          subOfferingOptions={this.state.subOfferingOptions}
          clientOptions={this.state.clientOptions}
          dashboardClientOptions={this.state.dashboardClientOptions}
          generalErrorMessage={this.state.generalErrorMessage}
          profileToAdd={this.state.profileToAdd}
          profileSelection={this.profileSelection}
          onAddProfile={this.onAddProfile}
          onDeleteProfile={this.onDeleteProfile}
          onEditProfile={this.onEditProfile}
          size={this.state.size}
          getGridApi={this.getGridApi}
          searchFunc={this.onTablSearchTextChanged}
          onAddNewUser={this.onAddNewUser}
          saveUserManagement={this.saveUserManagement}
          isEdit={this.state.isEdit}
          showDelConfirmationModal={this.state.showDelConfirmationModal}
          onConfirmClose={this.onConfirmClose}
          onConfirmDelete={this.onConfirmDelete}
          undoDelete={this.undoDelete}
          filteredData={this.state.filteredData}
          selectedProfile={this.state.selectedProfile}
          qcListInOfferingClient={this.state.qcListInOfferingClient}
          isProfileEmpty={this.state.isProfileEmpty}
          setQCValid={this.setQCValid}
          disableSave={this.state.disableSave}
          isQCValid={this.state.isQCValid}
          setDuplicateClients={this.setDuplicateClients}
          duplicateClients={this.state.duplicateClients}
          handleDeleteUserMappings={this.handleDeleteUserMappings}
          mappedUserDeletionData={this.state.mappedUserDeletionData}
          handleDeleteMappedOnEdit={this.handleDeleteMappedOnEdit}
          resetMappedUserDeletionData={this.resetMappedUserDeletionData}
          handleCheckForMappings={this.handleCheckForMappings}
          prevUserDetails={this.state.prevUserDetails}

        />
      );
    }
  }

  class WarningModal extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="stepperClose-Alert">
            <div className="stepperClose-Alert-Content">
              <div className="stepperClose-Alert-Icon">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
              <div className="stepperClose-Alert-text">
                <h5>Are you sure you want to Delete?</h5>
              </div>
            </div>
          </div>
          <div className="notifyModal-buttons">
            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="outline"
              colorClass="stat-alternate"
              size="auto"
              text="Cancel"
              callbackFunction={this.props.onCloseModal}
            />

            <GrooveButton
              id="primry-btn-1"
              name="Solid Button Primary-Ops"
              isDisabled={false}
              hasIcon={false}
              type="solid"
              colorClass="stat-alternate"
              size="auto"
              text="Yes sure"
              callbackFunction={this.props.onCloseProject}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  class MappedUserDeletionModal extends Component {
    render() {
      return (
        <React.Fragment>
          <CVMModal
            title={'Delete User?'}
            show={this.props.show}
            customclass={'select-project-modal delete-mappings-modal'}
            special={true}
            content={
              <DeleteMappedUser
                handleYes={this.props.handleYes}
                handleCancel={this.props.handleCancel}
                clientList={this.props.clientList}
              />
            }
          />
        </React.Fragment>
      );
    }
  }

  return UserManagementContainer;
})();
export default connect(
  (state) => {
    return {
      UserManagementData: state.UserManagementData,
    };
  },
  (dispatch) => bindActionCreators(Object.assign({}, { setToasterParam }), dispatch)
)(UserManagement);
