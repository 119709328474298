import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { GrooveInput, GrooveTextBox } from 'tfo-groove';
import { Row, Col } from "react-bootstrap";
import Stepper from '../Stepper'
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgramProjectHeader from '../ProgramProjectHeader'
import Envelope from "../../../Static/images/360cvm/mailpdf.svg";
import Eye from "../../../Static/images/360cvm/eye.svg";
import Download from "../../../Static/images/360cvm/download-purple.svg";
import { getProgramCADetails } from '../../../Actions/ProgramConfiguration.Action'
import { HasProperty } from "../../../Utilities/Validations";
import { HttpGetDownloadFile, GetSubMenuId } from "../../../Utilities/HTTPUtil";
import { FormDateDDMMMYYY } from "../../../Utilities/Formatter";
import { ACC_PROG, PROG_ID_VAL_CA } from "../../../Constants/Modules";
import { LogUserSiteAction } from "../../../Utilities/AccessManagementUtil";


const ProgramClientAlignment = (function () {
  class ProgramClientAlignmentPresentational extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="BT-Lead-Landing-Page">
            <ProgramProjectHeader type='Program' />
            <Stepper type="Program" />
            {this.props.PnPComponents.program.clntAlgnmnt ?
              <Row className="access_denied_container">
                <Col md={12} xs={12}>
                  <div className="glyphicon glyphicon-ban-circle access_denied_logo"></div>
                  <div className="access_denied_content">
                    <h1>Access Denied</h1>
                    <p>You don't have the necessary user privileges to view the page that you are attempting to access.</p>
                  </div>
                </Col>
              </Row>
              :
              <div className="confirmation-document BT-container">
                <div className="bodytext18-medium-midnight table-title">
                  Client Confimation Document(s)
                </div>
                <Row>
                  {
                    this.props.programCAFileDetails.length > 0 && this.props.selectedProgramDetails.StatusId === 7 ?
                      this.props.programCAFileDetails.map((item, index) => {
                        return (
                          <Col sm={4} md={4} lg={4}>
                            <div className="document-box">
                              <div className="envelope">
                                <img src={Envelope} alt="envelope" />
                                <div className="document-name">
                                  {item.fileName}
                                </div>
                              </div>
                              <div className="icons">
                                {/* <span className="icon-bottom">
                                <img src={Eye} alt="eye" />
                              </span> */}
                                {
                                  this.props.PnPComponents.program.downloadAttchmnt ? "" :
                                    <span className="icon-bottom">
                                      <img src={Download} alt="download" onClick={() => this.props.onClickDownloadFile(item)} />
                                    </span>
                                }
                              </div>
                            </div>
                          </Col>
                        )
                      })
                      : //show when status is configured - yet to be aligned (statusID = 8)
                      <Col sm={12} md={12} lg={12}>
                        <div className="no-confirmation-doc-container">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          <div className="hero32-medium-midnight label-cont">No Confirmation Document Added</div>
                          <div>
                            The approver {HasProperty(this.props.programCADetails, 'approvedBy') ? this.props.programCADetails.approvedBy : ""} has not uploaded the document yet.
                          </div>
                        </div>
                      </Col>
                  }

                </Row>
                <Row className="client-alignment-fields-container">
                  <Col lg={6} md={6} sm={12}>
                    <div className="input">
                      <GrooveInput
                        id="programName"
                        name="programName"
                        placeholder="Approved By"
                        inputValue={HasProperty(this.props.programCADetails, 'approvedBy') ? this.props.programCADetails.approvedBy : ""}
                        type="text"
                        isAutocomplete="false"
                        isDisabled="true"
                        customClassName="approve-box"
                      />
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={12} >
                    <div className="input">
                      <GrooveInput
                        id="programName"
                        name="programName"
                        placeholder="Approved on"
                        inputValue={HasProperty(this.props.programCADetails, 'approvedOn') ? FormDateDDMMMYYY(this.props.programCADetails.approvedOn) : ""}
                        type="text"
                        isAutocomplete="false"
                        isDisabled="true"
                        customClassName="approve-box"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="row row-cols-2">
                  <Col lg={12} md={12} sm={12}>
                    <div id="cal-comments" className="comments">
                      <GrooveTextBox
                        id="comments"
                        name="comments"
                        type="text"
                        placeholder="Approver comment"
                        textValue={HasProperty(this.props.programCADetails, 'approverComments') ? this.props.programCADetails.approverComments : ""}
                        isDisabled="true"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            }
          </div>
        </React.Fragment >
      );
    }
  }

  class ProgramClientAlignmentContainer extends Component {
    constructor() {
      super();
      this.state = {
        show: true,
        filename: "",
      };
    }

    componentDidMount = () => {
      LogUserSiteAction({
        module: PROG_ID_VAL_CA,
        ShouldLogPPCID: true,
        ClientProgramID: this.props.ProgramConfigurationData.selectedProgram.value,
        ClientProjectID: null,
        progStatus: ACC_PROG
      })
      //change once flow is final
      //to be called by on click of program, on SFA or draft (?), onchange of dropdown
      this.props.getProgramCADetails();
      this.changeTextAreaClass()
    }

    componentDidUpdate = () => {
      this.changeTextAreaClass()
    }

    changeTextAreaClass = () => {
      const classGrooveTextBox = document.querySelectorAll('div.groove-textbox-custom-label')
      classGrooveTextBox.forEach(classGroove => {
        classGroove.classList.remove("groove-textbox-custom-label")
        classGroove.classList.add("groove-input-custom-label")
      });
    }

    onCloseModal = () => {
      this.setState({ show: !this.state.show });
    };

    onSelectOption = (e) => {
      this.setState({ value: e })
    }

    onClickDownloadFile = (fileDetails) => {
      let data = {
        clientProgramID: this.props.ProgramConfigurationData.selectedProgramDetails.ClientProgramID,
        statusID: this.props.ProgramConfigurationData.selectedProgramDetails.StatusId
      }
      // let data = {
      // 	clientProgramID: 235,
      // 	statusID: 7
      // }
      let subMenuId = GetSubMenuId("Program Identified Value - Client Alignment");
      HttpGetDownloadFile(`ProgramClientAlignment/DownloadProgramFiles?clientProgramID=${data.clientProgramID}&statusID=${data.statusID}&attachmentID=${fileDetails.attachmentID}&column=${fileDetails.column}&SubMenuID=${subMenuId}`, fileDetails.fileName)
    }

    render() {
      const {
        programCAFileDetails,
        programCADetails,
        selectedProgramDetails
      } = this.props.ProgramConfigurationData
      const { PnPComponents } = this.props.AccessValidationData;
      return <ProgramClientAlignmentPresentational
        offering={this.state.offering}
        subOffering={this.state.subOffering}
        onClickDownloadFile={this.onClickDownloadFile}
        //reducers
        programCAFileDetails={programCAFileDetails}
        programCADetails={programCADetails}
        PnPComponents={PnPComponents}
        selectedProgramDetails={selectedProgramDetails}
      />;
    }
  }

  return ProgramClientAlignmentContainer;
})();

export default connect(
  (state) => {
    return {
      ProgramConfigurationData: state.ProgramConfigurationData,
      AccessValidationData: state.AccessValidationData
    };
  },
  (dispatch) =>
    bindActionCreators(
      Object.assign(
        {},
        {
          getProgramCADetails,
        }
      ),
      dispatch
    )
)(ProgramClientAlignment);
