import {
  SET_NOTIF_LIST,
  GET_NOTIF_HISTORY_LIST,
  GET_NOTIF_HISTORY_COUNT,
  GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS,
  GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS,
  SET_NEW_NOTIF_LIST,
  SET_OLD_NOTIF_LIST,
  SET_NEWLY_ADDED_NOTIF,
  SET_NOTIF_COUNT,
  SHOULD_NOTIF_HIST_SHOW,
  GET_REMINDER_COUNT
} from '../Constants';
import {
  getUserTimeZone,
  TryGetObjValueAlt,
  notificationDuration,
  UnRefObj,
} from '../Utilities/Formatter';
import { GetSubMenuId, HttpGet, HttpGetText, HttpPostText } from '../Utilities/HTTPUtil';
import { HasProperty, IsValid } from '../Utilities/Validations';

export const setNotificationData = () => async (dispatch) => {
  try {
    let utcOffset = getUserTimeZone();
    let isNegative = utcOffset.includes('-');
    utcOffset = utcOffset.replace('-', '').replace('+', '');
    let data = await HttpGet(
      `InAppNotification/GetInAppNotificationContent?utcOffset=${utcOffset}&isNegative=${isNegative}`
    );

    if (Object.keys(data).length > 0) {

      dispatch({
        type: SHOULD_NOTIF_HIST_SHOW,
        showNotifHist: data.IsNotificationsHistory
      });

      dispatch({
        type: SET_NOTIF_LIST,
        notificationList: {
          NewInAppNotifications: data.NewInAppNotifications,
          OldInAppNotifications: data.OldInAppNotifications
        }
      });

      dispatch({
        type: SET_NEW_NOTIF_LIST,
        NewInAppNotifications: UnRefObj(data.NewInAppNotifications),
      });

      dispatch({
        type: SET_OLD_NOTIF_LIST,
        OldInAppNotifications: UnRefObj(data.OldInAppNotifications),
      });

      dispatch(updateTimeStamp());
    }
  } catch (err) {
    console.log(err);
  }
};

export const refreshNotif = () => async (dispatch, state) => {
  try {
    let currentList = state().NotificationData.notificationList;
    let newList;
    let utcOffset = getUserTimeZone();
    let isNegative = utcOffset.includes('-');
    utcOffset = utcOffset.replace('-', '').replace('+', '');
    let data = await HttpGet(
      `InAppNotification/GetInAppNotificationContent?utcOffset=${utcOffset}&isNegative=${isNegative}`
    );

    if (Object.keys(data).length > 0) {
      newList = data.NewInAppNotifications.filter((x) => {
        if (Object.keys(currentList).length > 0) {
          return !currentList.NewInAppNotifications.map((y) => y.ID).includes(x.ID);
        } else {
          return x;
        }
      });

      dispatch({
        type: SET_NEWLY_ADDED_NOTIF,
        newlyAddedList: UnRefObj(newList),
      });
      dispatch(updateNotificationList());
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateNotificationList = () => async (dispatch, state) => {
  try {
    let newlyAddedList = state().NotificationData.newlyAddedList;
    let newList = state().NotificationData.NewInAppNotifications;
    let orignalList = state().NotificationData.notificationList;
    let newInAppNotifList = HasProperty(orignalList, 'NewInAppNotifications')
      ? orignalList.NewInAppNotifications
      : [];

    newList = newlyAddedList.concat(newInAppNotifList);
    orignalList.NewInAppNotifications = newList;

    dispatch({
      type: SET_NOTIF_LIST,
      notificationList: orignalList,
    });

    dispatch({
      type: SET_NEW_NOTIF_LIST,
      NewInAppNotifications: UnRefObj(newList),
    });

    dispatch(updateTimeStamp());
  } catch (e) {
    console.log(e);
  }
};

export const clearNewlyAddedList = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_NEWLY_ADDED_NOTIF,
      newlyAddedList: [],
    });
  } catch (e) {
    console.log(e);
  }
};

export const markAsRead = (id) => async (dispatch, state) => {
  try {
    let orignalList = state().NotificationData.notificationList;
    let subMenuId = GetSubMenuId("Application Home Page")

    let data = await HttpPostText('InAppNotification/MarkNotificationsAsRead?SubMenuID=' + subMenuId, id);

    if (data === 'Success') {
      id.map((i) => {
        orignalList.NewInAppNotifications.map((x) => {
          if (!x.IsMarkAsRead && x.ID === i) {
            x.IsMarkAsRead = true;
          }
        });

        orignalList.OldInAppNotifications.map((x) => {
          if (!x.IsMarkAsRead && x.ID === i) {
            x.IsMarkAsRead = true;
          }
        });
      });

      dispatch({
        type: SET_NOTIF_LIST,
        notificationList: orignalList,
      });

      dispatch({
        type: SET_NEW_NOTIF_LIST,
        NewInAppNotifications: UnRefObj(orignalList.NewInAppNotifications),
      });

      dispatch({
        type: SET_OLD_NOTIF_LIST,
        OldInAppNotifications: UnRefObj(orignalList.OldInAppNotifications),
      });

      dispatch(updateTimeStamp());
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateTimeStamp = () => async (dispatch, state) => {
  try {
    const originalList = state().NotificationData.notificationList;
    let newList = state().NotificationData.NewInAppNotifications;
    let oldList = state().NotificationData.OldInAppNotifications;

    newList.map((x, i) => {
      x.NotificationSentTS = notificationDuration(
        originalList.NewInAppNotifications[i].NotificationSentTS
      );
    });

    oldList.map((x, i) => {
      x.NotificationSentTS = notificationDuration(
        originalList.OldInAppNotifications[i].NotificationSentTS
      );
    });

    dispatch({
      type: SET_NEW_NOTIF_LIST,
      NewInAppNotifications: UnRefObj(newList),
    });

    dispatch({
      type: SET_OLD_NOTIF_LIST,
      OldInAppNotifications: UnRefObj(oldList),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNotificationHistoryDataCount = () => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Application Home Page")
    let userID = state().userInformation.userID;

    let body = {
      userId: userID,
    };
    let data = await HttpPostText(`InAppNotification/GetInAppNotificationHistoryCount?SubMenuID=${subMenuId}`, body);
    data = JSON.parse(data);
    
    // let notifHistoryCount = 260;
    // let notifHistTotalPage = Math.ceil(notifHistoryCount / 100);
    let notifHistoryCount = 0;
    let notifHistTotalPage = 0;

    if (Object.keys(data).length > 0) {
      notifHistoryCount = data.TotalRows;
      notifHistTotalPage = Math.ceil(notifHistoryCount / 100);
    }

    dispatch({
      type: GET_NOTIF_HISTORY_COUNT,
      notifHistoryCount: notifHistoryCount,
      notifHistTotalPage: notifHistTotalPage,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getNotificationHistoryData = (currentPage) => async (dispatch, state) => {
  try {
    let userID = state().userInformation.userID;
    let utcOffset = getUserTimeZone();
    let isNegative = utcOffset.includes('-');
    utcOffset = utcOffset.replace('-', '').replace('+', '');
    let subMenuId = GetSubMenuId("Application Home Page")

    let notifHistoryList = state().NotificationData.notifHistoryList;
    
    let body = {
      userId: userID,
    };
    let data = await HttpPostText(`InAppNotification/GetInAppNotificationHistory?pageNo=${currentPage}&utcOffset=${utcOffset}&isNegative=${isNegative}&SubMenuID=${subMenuId}`, body);
    data = JSON.parse(data);

    if (Object.keys(data).length > 0) {
      data.map((notifHist) => {
        notifHistoryList.push({
          notifID: notifHist.InAppId,
          isNewNotif: notifHist.IsMarkAsRead === 0,
          isImportantNotif: notifHist.IsImportantUser === 1,
          notifHeader: notifHist.Notification,
          notifDetails: notifHist.Details,
          notifComments: notifHist.Comments,
          sender: notifHist.Sender,
          clientName: notifHist.ClientName,
          notifDateTime: notifHist.TimeStamp,
          notifAction: notifHist.TakeAction,
          notifActionURL: notifHist.TakeActionURL,
        });
      });
    }

    dispatch({
      type: GET_NOTIF_HISTORY_LIST,
      notifHistoryList: notifHistoryList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getNotificationHistoryFilterOptions = () => async (dispatch, state) => {
  try {
    let senderFilterOptions = [];
    let clientNameFilterOptions = [];
    let notifHistoryList = state().NotificationData.notifHistoryList;

    if (Object.keys(notifHistoryList).length > 0) {
      notifHistoryList.map((notifHist) => {
        if (senderFilterOptions.filter((n) => n.label === notifHist.sender).length === 0) {
          senderFilterOptions.push({
            isChecked: true,
            label: notifHist.sender,
            value: notifHist.sender,
          });
        }

        if (clientNameFilterOptions.filter((n) => n.label === notifHist.clientName).length === 0) {
          clientNameFilterOptions.push({
            isChecked: true,
            label: notifHist.clientName,
            value: notifHist.clientName,
          });
        }
      });
    }

    dispatch({
      type: GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS,
      senderFilterOptions: senderFilterOptions,
    });

    dispatch({
      type: GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS,
      clientNameFilterOptions: clientNameFilterOptions,
    });
    // return;
  } catch (err) {
    console.log(err);
  }
};

export const clearNotifHistoryData = () => async (dispatch, state) => {
  try {
    dispatch({
      type: GET_NOTIF_HISTORY_LIST,
      notifHistoryList: [],
    });

    dispatch({
      type: GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS,
      senderFilterOptions: [],
    });

    dispatch({
      type: GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS,
      clientNameFilterOptions: [],
    });

    dispatch({
      type: GET_NOTIF_HISTORY_COUNT,
      notifHistoryCount: 0,
      notifHistTotalPage: 0,
    });
    // return;
  } catch (err) {
    console.log(err);
  }
};

export const updateFilterOptions = (filteredData) => async (dispatch, state) => {
  try {
    let sOptions = state().NotificationData.senderFilterOptions;
    let cnOptions = state().NotificationData.clientNameFilterOptions;

    let senderFilterOptions = [];
    let clientNameFilterOptions = [];

    sOptions.map((tOp) => {
      let isSelected = filteredData.find((op) => op.sender === tOp.value) !== undefined;
      senderFilterOptions.push({
        isChecked: isSelected,
        label: tOp.label,
        value: tOp.value,
      });
    });

    cnOptions.map((tOp) => {
      let isSelected = filteredData.find((op) => op.clientName === tOp.value) !== undefined;
      clientNameFilterOptions.push({
        isChecked: isSelected,
        label: tOp.label,
        value: tOp.value,
      });
    });

    dispatch({
      type: GET_NOTIF_HISTORY_SENDER_FILTER_OPTIONS,
      senderFilterOptions: senderFilterOptions,
    });
    dispatch({
      type: GET_NOTIF_HISTORY_CLIENT_NAME_FILTER_OPTIONS,
      clientNameFilterOptions: clientNameFilterOptions,
    });
  } catch (err) {
    console.log(err);
  }
};

export const notifHistoryMarkAsRead = (notifID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Application Home Page")
    let data = await HttpPostText('InAppNotification/MarkNotificationsAsRead?SubMenuID=' + subMenuId, notifID);

    return;
  } catch (err) {
    console.log(err);
  }
};

export const getNotificationCount = () => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Application Home Page")
    let currentCount = state().NotificationData.notifCount;
    let data = await HttpGetText('InAppNotification/GetUserInAppNotificationCount&SubMenuID=' + subMenuId);
    data = parseInt(data);
    if (data !== 0) {
      dispatch({
        type: SET_NOTIF_COUNT,
        notifCount: data + currentCount,
      });
    }
  } catch (e) {
    console.log(e);
  }
};
export const getReminderCount = (params, view) => async (dispatch, state) => {
  try {
    let result
    if (view === "Program") {
      result = await HttpGet(
        `EmailService/GetReminderCounter?notifTypeID=${params.notifTypeID}&clientName=${params.ClientMasterName}&clientProgramID=${params.ClientProgramID}`
      );
    } else if (view === "PDE") {
      let isFinancial = IsValid(params.isFinancial) ? params.isFinancial : false
      result = await HttpGet(
        `EmailService/GetReminderCounter?notifTypeID=${params.notifTypeID}&clientName=${params.label}&projectName=${params.projectDetails.clientProjectName}&location=${params.projectDetails.deliveryCenterName}&clientProgramID=${params.programID}&clientProjectID=${params.projectDetails.projectID}&isFinancialUSD=${isFinancial}`
      );
    }

    dispatch({
      type: GET_REMINDER_COUNT,
      reminderCounter: result
    })
    return result
  } catch (error) {
    console.log(error);
  }
}
