import {
  SET_SHOULD_SHOW_TOASTER_NOTIF,
  ON_SELECT_PROJECT,
  ON_SAVE_SELECT_PROJECT,
  ON_CANCEL_SELECT_PROJECT,
  GET_PROGRAM_PROJECT,
  ON_CHANGE_PARAM,
  ON_CHANGE_TAB_FINANCIAL,
  ON_CLICK_RDV_SFA,
  ON_TILE_SELECT_PROJECT,
  GET_PROJECT_REPORTING_YEAR_LIST,
  GET_PROJECT_CREATION_YEAR_LIST,
  MAPPED_STAKE_HOLDERS,
  DIS_UI_ELEMENTS,
  UPDATE_BOI_LIST,
  BOI_IS_REFRESH,
  SELECTED_PROGRAM_PROJECT,
  SHOW_CUMULATIVE_MODAL,
  GET_AGGREGATED_DISABLED_BOIS,
  GET_CLIENT_STORY_FILE_HISTORY,
  GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY,
  SET_CLIENT_STORY_FILE,
  SET_CLIENT_ALIGNMENT_FILE,
  SET_VIRUS_FOUND,
  SET_UPLOAD_IS_COMPLETED,
  REMOVE_CLIENT_STORY_FILE,
  REMOVE_CLIENT_ALIGNMENT_FILE,
  SET_BUSINESS_ADVISOR_LIST,
  SET_RVD_BASIC_DETAILS,
  ON_SEND_REMINDER_PROJECT_DATA_ENTRY,
  ON_SELECT_CREATION_YEAR_OPTIONS,
  SET_CLIENT_STORY
} from '../Constants';
import { history } from '../store';
import { HttpGet, HttpPost, HttpPostText, HttpPostWithFileString, GetSubMenuId } from '../Utilities/HTTPUtil';
import {
  HasProperty,
  IsValidStr,
  IsValidNum,
  IsNumZeroesOnly,
  HasValue,
  IsValid,
  IsArrayEmpty,
} from '../Utilities/Validations';
import {
  parseField,
  FormDateDDMMMYYY,
  FormTimeAPM,
  FormatCVMParamValue,
  GetMonthMMM,
  GetQuarter,
  GetMonthIndex,
  getUserTimeZone
} from '../Utilities/Formatter';
import Utilities from './../Utilities/index';
import { onSelectReportingYearOptions, onSelectCreationYearOptions } from './DataIngestion.Action';
import { LogRVDprojectAction } from '../Utilities/AccessManagementUtil';
import { PROJ_DATA_ENTRY_REC, PROJ_DATA_APP_L1, PROJ_DATA_APP_L2, PDE } from '../Constants/Modules';
import { isProfileExisting, setEmailError } from './Shared.Action';
import pptxgen from "pptxgenjs";
import cs_ppt_profile_icon from '../Static/images/svg/cs_ppt_profile_icon.svg';
import keyBusinessIcon from '../Static/images/svg/Key Business.svg';
import businessOutcomeIcon from '../Static/images/svg/Business outcome.svg';
import innovationIcon from '../Static/images/svg/Innovation.svg';
import { htmlToPptxText } from "html2pptxgenjs";

export const onChangeSelectProject = (event, key) => async (dispatch, state) => {
  try {
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let fieldsValidation = state().ConfigureProgramData.fieldValidation;

    switch (key) {
      case 'reportingYear':
        selectedProject.reportingYear = event.label;
        selectedProject.reportingYearID = event.value;
        fieldsValidation.reportingYearValid = true;
        selectedProject.programName = '';
        selectedProject.programStatus = null;
        selectedProject.projectDetails.clientProjectName = '';
        selectedProject.projectDetails.deliveryCenterName = '';
        break;
      case 'valueMeterProgramName':
        selectedProject.programName = event.label;
        selectedProject.programID = event.value;
        selectedProject.programStatus = event.status;
        fieldsValidation.valueMeterProgramNameValid = true;
        selectedProject.projectDetails.clientProjectName = '';
        selectedProject.projectDetails.deliveryCenterName = '';
        break;
      case 'project':
        selectedProject.projectDetails.clientProjectID = event.value;
        selectedProject.projectDetails.clientProjectName = event.label;
        fieldsValidation.projectValid = true;
        selectedProject.projectDetails.deliveryCenterName = '';
        break;
      case 'deliveryLocation':
        selectedProject.projectDetails.deliveryCenterName = event.label;
        selectedProject.projectDetails.deliveryCenterID = event.value;
        fieldsValidation.deliveryLocationValid = true;
        break;
      case 'lastReportingDate':
        let day = new Date(event.end).getDate();
        let month = new Date(event.end).getMonth();
        let year = new Date(event.end).getFullYear(); //yymmdd
        let lastReportingDate = `${year}-${String(month + 1).length === 1 ? '0' : ''}${month + 1}-${String(day).length === 1 ? '0' : ''
          }${day}`;
        selectedProject.projectDetails.lastReportingDate = lastReportingDate;
        fieldsValidation.reportingPeriodValid = true;
      default:
        selectedProject = selectedProject;
    }
    dispatch({
      type: ON_SELECT_PROJECT,
      mainSelectedProject: selectedProject,
      fieldValidation: fieldsValidation,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onSaveSelectProject = (event, key) => async (dispatch, state) => {
  try {
    let fieldsValidation = state().ConfigureProgramData.fieldValidation;
    let fields = state().ConfigureProgramData.mainSelectedProject;
    let shouldSaveProject = true;

    if (fields.programName === undefined || fields.programName === '') {
      fieldsValidation.valueMeterProgramNameValid = false;
      shouldSaveProject = false;
    }
    if (
      fields.projectDetails.clientProjectName === undefined ||
      fields.projectDetails.clientProjectName === ''
    ) {
      fieldsValidation.projectValid = false;
      shouldSaveProject = false;
    }
    if (
      fields.projectDetails.deliveryCenterName === undefined ||
      fields.projectDetails.deliveryCenterName === ''
    ) {
      fieldsValidation.deliveryLocationValid = false;
      shouldSaveProject = false;
    }
    if (
      fields.projectDetails.lastReportingDate === undefined ||
      fields.projectDetails.lastReportingDate === ''
    ) {
      fieldsValidation.reportingPeriodValid = false;
      shouldSaveProject = false;
    }
    if (!shouldSaveProject) {
      dispatch({
        type: ON_SAVE_SELECT_PROJECT,
        fieldValidation: fieldsValidation,
        mainSelectedProject: fields,
        showSelectProject: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const onCancelSelectProject = (event, key) => async (dispatch, state) => {
  try {
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    mainSelectedProject.projectDetails.deliveryCenterName = '';
    mainSelectedProject.projectDetails.deliveryCenterID = '';
    mainSelectedProject.projectDetails.lastReportingDate = '';
    let fieldVal = {
      reportingYearValid: true,
      valueMeterProgramNameValid: true,
      projectValid: true,
      deliveryLocationValid: true,
      reportingPeriodValid: true,
    };
    dispatch({
      type: ON_SELECT_PROJECT,
      mainSelectedProject: mainSelectedProject,
      fieldValidation: fieldVal,
    });
    dispatch({
      type: ON_CANCEL_SELECT_PROJECT,
      showSelectProject: true,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProgramProjectReportingYear = () => async (dispatch, state) => {
  let clientID = state().SharedData.selectedClient.value;
  try {
    let data = await HttpGet(
      `ReportValueDelivered/GetReportingYearofClientConfigureComplete?ClientID=${clientID}`
    );
    if (Object.keys(data).length > 0) {
      let yearList = [];
      let list = [];
      data.forEach((data) => {
        if (yearList.indexOf(data.ReportingYear) < 0) {
          yearList.push(data.ReportingYear);
          list.push({
            text: data.ReportingYear,
            label: data.ReportingYear,
            value: data.ReportingYear,
          });
        }
      });
      dispatch(onSelectReportingYearOptions(list[0]));
      dispatch({
        type: GET_PROJECT_REPORTING_YEAR_LIST,
        selectProjectReportingYearList: list,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getProgramProject = () => async (dispatch, state) => {
  let selectProjectDropdown = state().ConfigureProgramData.selectProjectDropdown;
  let clientID = state().SharedData.selectedClient.value;

  try {
    let subMenuId = GetSubMenuId("Project Selection")
    let data = await HttpGet(
      `ReportValueDelivered/GetprogramProjectlist/clientID?clientID=${clientID}&SubMenuID=${subMenuId}`
    );
    if (Object.keys(data).length > 0) {
      let tempProgramName = [];
      let tempProjectName = [];
      let tempProjectDelivery = [];
      for (var i = 0; i < data.length; i++) {
        tempProgramName.push({
          value: data[i].Id,
          label: data[i].ClientProgramName,
          text: data[i].ClientProgramName,
        });
        for (var j = 0; j < data[i].ClientProjects.length; j++) {
          tempProjectName.push(data[i].ClientProjects[j]);
          for (var k = 0; k < data[i].ClientProjects[j].ProjectsDeliveryCenter.length; k++) {
            tempProjectDelivery.push(data[i].ClientProjects[j].ProjectsDeliveryCenter[k]);
          }
        }
      }
      dispatch({
        type: GET_PROGRAM_PROJECT,
        selectProjectDropdown: {
          programNameList: tempProgramName,
          projectNameList: tempProjectName,
          projectDeliveryList: tempProjectDelivery,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleParameterChange = (data, tVal, action) => async (dispatch, state) => {
  if (tVal > -1) {
    try {
      let tProjectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
      let param =
        tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
          .boiList[data.boi.index].parameterList[data.parameter.index];
      let uom = data.parameter.item.parameterUOM;
      let fVal = '';
      let tHasChange = false;
      let decimal = '';
      let projectDimensionsList = [];
      let checkParamVal =
        parseFloat(tVal).toFixed(2) === parseFloat(param.parameterValue).toFixed(2);
      let isFinUSD = tProjectDimensionsList[data.dimension.index].dimensionID === 1 &&
        tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
          .boiList[data.boi.index].boiUOM.toLowerCase() === "usd";
      let limitNegativeZero = isFinUSD ? ["-", "0"].includes(tVal.toString()) : false;
      let value = isFinUSD ? parseField(tVal, 'positive-decimal') : tVal;

      //dollar, percentage, count, number -trill to +
      if (action === 'blur') {
        if (value !== '' && value === '000000000000') {
          fVal = '0.00';
          tHasChange = true;
        } else {
          fVal = param.parameterValue;
        }
      } else {
        if (value !== '' && value.trim() !== '' && !limitNegativeZero) {
          let valueArr = value.split('.');
          if (
            value.split('0').length >= 12 &&
            (uom === 'Percentage' || uom === '%' || uom === '#Count' || uom === 'Number') &&
            valueArr.length === 1
          ) {
            let valueInt = parseInt(value);
            if (valueArr[0].length === 10) {
              fVal = value;
            } else if (valueInt > 0) {
              fVal = valueInt.toString().substring(0, 12);
              param.isInvalid2 = true;
              param.isInvalid = true;
              param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
            } else {
              fVal = '0000000000';
            }
          } else if (
            value.split('0').length >= 12 &&
            (uom === 'usd' || uom === 'USD' || uom === 'Dollar' || uom === '$') &&
            valueArr.length === 1
          ) {
            let valueInt = parseInt(value);
            if (valueArr[0].length === 12) {
              fVal = value;
            } else if (valueInt > 0) {
              fVal = valueInt.toString().substring(0, 12);
              param.isInvalid2 = true;
              param.isInvalid = true;
              param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
            } else {
              fVal = '000000000000';
            }
          } else {
            if (uom === 'Percentage' || uom === '%' || uom === '#Count' || uom === 'Number') {
              fVal = parseField(value, 'negative-positive-decimal');
              if (fVal.split('-').length > 1 && parseFloat(fVal).toFixed(2) < -99999999999.99) {
                decimal =
                  parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                    ? '.99'
                    : fVal.slice(fVal.lastIndexOf('.'));
                fVal = fVal.substring(0, 12) + decimal;
                param.isInvalid2 = true;
                param.isInvalid = true;
                param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
              } else if (parseFloat(fVal).toFixed(2) > 999999999999.99) {
                decimal =
                  parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                    ? '.99'
                    : fVal.slice(fVal.lastIndexOf('.'));
                fVal = fVal.substring(0, 12) + decimal;
                param.isInvalid2 = true;
                param.isInvalid = true;
                param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
              } else {
                param.isInvalid2 = false;
              }
            } else if (uom === 'usd' || uom === 'USD' || uom === 'Dollar' || uom === '$') {
              fVal = parseField(value, 'negative-positive-decimal');
              if (fVal.split('-').length > 1 && parseFloat(fVal).toFixed(2) < -99999999999.99) {
                decimal =
                  parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                    ? '.99'
                    : fVal.slice(fVal.lastIndexOf('.'));
                fVal = fVal.substring(0, 12) + decimal;
                param.isInvalid2 = true;
                param.isInvalid = true;
                param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
              } else if (parseFloat(fVal).toFixed(2) > 999999999999.99) {
                decimal =
                  parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                    ? '.99'
                    : fVal.slice(fVal.lastIndexOf('.'));
                fVal = fVal.substring(0, 12) + decimal;
                param.isInvalid2 = true;
                param.isInvalid = true;
                param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
              } else {
                param.isInvalid2 = false;
              }
            } else {
              //days hours min 0 to + trill
              fVal = parseField(value, 'negative-positive-decimal');
              if (IsValidNum(fVal) && fVal !== '') {
                if (parseFloat(fVal).toFixed(2) > 999999999999.99) {
                  decimal =
                    parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                      ? '.99'
                      : fVal.slice(fVal.lastIndexOf('.'));
                  fVal = fVal.substring(0, 12) + decimal;
                  param.isInvalid2 = true;
                  param.isInvalid = true;
                  param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
                } else if (parseFloat(fVal).toFixed(2) < -99999999999.99) {
                  decimal =
                    parseFloat(fVal).toFixed(2).split('.')[1] === '00'
                      ? '.99'
                      : fVal.slice(fVal.lastIndexOf('.'));
                  fVal = fVal.substring(0, 12) + decimal;
                  param.isInvalid2 = true;
                  param.isInvalid = true;
                  param.invalidMsg = 'You have reached your maximum limit of characters allowed.';
                } else {
                  param.isInvalid2 = false;
                }
              }
            }
          }
        }
        tHasChange = true;
      }

      if (tHasChange && !param.isInvalid2) {
        param['hasChange'] = tHasChange;
        param['tempHasChange'] = tHasChange;
        delete param.isInvalid;
        delete param.invalidMsg;
      }

      param.parameterValue = fVal;

      delete tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
        .boiList[data.boi.index].realizedValInvalid;
      tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index].boiList[
        data.boi.index
      ].parameterList[data.parameter.index] = param;

      // set client value identified
      const dimID = data.dimension.item.dimensionID;
      const boiuom = data.boi.item.boiUOM;
      const subdimID = data.subDimension.item.subDimensionID;

      if (fVal === '') {
        let cviItem = {
          value: '',
          isInvalid: false,
          invalidMsg: '',
          disabled: true,
        };
        tProjectDimensionsList[data.dimension.index].subDimensionList[
          data.subDimension.index
        ].boiList[data.boi.index].clientValueIdentifiedTemp = cviItem;
      } else if (
        !checkParamVal &&
        !param.isInvalid2 &&
        dimID === 1 &&
        action !== 'blur' &&
        ['usd', 'dollar', '$'].indexOf(boiuom.toLowerCase()) > -1
      ) {
        let rate = 0.1;
        if (subdimID === 2) rate = 1;
        if (subdimID === 3) rate = 0.15;
        let cviValue = param.parameterValue / rate;

        let cviItem = FormatCVMParamValue('', cviValue.toFixed(2).toString(), 'USD');
        cviItem.disabled = false;
        cviItem.isInvalid = false;
        tProjectDimensionsList[data.dimension.index].subDimensionList[
          data.subDimension.index
        ].boiList[data.boi.index].clientValueIdentifiedTemp = cviItem;
      }

      projectDimensionsList = tProjectDimensionsList;
      dispatch({
        type: ON_CHANGE_PARAM,
        projectDimensionsList: projectDimensionsList,
      });
    } catch (err) {
      console.log(err);
    }
  }
};

export const handleClientValueIdentified = (projectDimensionsList) => async (dispatch, state) => {
  try {
    dispatch({
      type: ON_CHANGE_PARAM,
      projectDimensionsList: projectDimensionsList,
    });
  } catch (err) {
    console.log(err);
  }

};

export const getSFADetails = (origin) => async (dispatch, state) => {
  try {
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let projectID = selectedProject.projectDetails.clientProjectID;
    let delivID = selectedProject.projectDetails.deliveryCenterID;
    let userInfo = state().userInformation;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let receiverDetails = {};
    let isSuperAdmin = userInfo.isSuperAdmin;
    let dimensionList = state().ConfigureProgramData.projectDimensionsList;
    let isExisting = await dispatch(isProfileExisting([12, 15]));
    let assignedToRoleId = //superadmin = 12 else currentUserroleID
      userInfo.currentUserRoleID === 12 || //ppc
        userInfo.currentUserRoleID === 15 || //ba
        (userInfo.currentUserRoleID === 16 && isExisting) //qc
        ? userInfo.currentUserRoleID
        : basicDetails.assignedToRoleID;
    let totalRealizedValue = 0;

    if (
      ((selectedProject.projectDetails.status === 'Awaiting Approval' ||
        selectedProject.projectDetails.status === 'Approved') &&
        (userInfo.currentUserRoleID === 12 || //ppc
          userInfo.currentUserRoleID === 15 || //BA
          (userInfo.currentUserRoleID === 16 && isExisting)) && //QC
        basicDetails.assignedToRoleID > 0 &&
        basicDetails.assignedToRoleID !== userInfo.currentUserRoleID &&
        !isSuperAdmin)
    ) {
      if (origin === "sfa") {
        receiverDetails.userID = ""; //selectedProject.projectDetails.cxlLeadUserID; //not being used on API as per jemar
        receiverDetails.eid = ""; //email; to populate when onchange
        receiverDetails.role = 'CXLLead';
        receiverDetails.roleID = 7; //cx lead
        receiverDetails.comment = '';
        receiverDetails.isSkippedIOApproval = false;
        receiverDetails.dropdownVal = [];
        receiverDetails.dropdownList = selectedProject.projectDetails.cxLeadList;
      } else {
        receiverDetails.userID = selectedProject.projectDetails.cxlLeadUserID;
        receiverDetails.eid = selectedProject.projectDetails.cxLeadString;
        receiverDetails.role = 'CX Lead';
        receiverDetails.roleID = 7; //cx lead
        receiverDetails.comment = '';
        receiverDetails.isSkippedIOApproval = false;
        receiverDetails.dropdownVal = [{
          value: selectedProject.projectDetails.cxlLeadUserID,
          label: selectedProject.projectDetails.cxLeadString,
          text: selectedProject.projectDetails.cxLeadString,
        }];
        receiverDetails.dropdownList = [{
          value: selectedProject.projectDetails.cxlLeadUserID,
          label: selectedProject.projectDetails.cxLeadString,
          text: selectedProject.projectDetails.cxLeadString,
        }];
      }

      dispatch({
        type: ON_CLICK_RDV_SFA,
        receiverDetails: receiverDetails,
      });
      return true;
    } else {
      //check for total realized value
      dimensionList.map((dim) => {
        dim.subDimensionList.map((sd) => {
          sd.boiList.map((boi) => {
            if (
              boi.boiUOM.toLowerCase() === 'usd' &&
              dim.dimensionName.toLowerCase() === 'financial'
            ) {
              totalRealizedValue += parseFloat(boi.boiRealizedValue); // removing this code "- boi.boiApprovedRealizedValue" because of this bug 226172
            }
          });
        });
      });
      let subMenuId = GetSubMenuId("Project Data Entry");
      let data = await HttpGet(
        `ProjectDataEntry/GetMailReceiverDetail?projectID=${projectID}&deliveryCenterID=${delivID}&totalRealizedValue=${totalRealizedValue}&assignedToRoleId=${assignedToRoleId}&SubMenuID=${subMenuId}`
      );
      if (Object.keys(data).length > 0 && HasProperty(data, 'MailReceiverUserId')) {
        let tDDString = data.MailReceiverEmailId;
        let tDIDString = data.MailReceiverUserId;

        let mailReceiver = origin === "sfa" && (data.MailReceiverRoleId === 7 || data.MailReceiverRoleId === 4) ? "" : data.MailReceiverEmailId;
        let mailReceiverID = origin === "sfa" && (data.MailReceiverRoleId === 7 || data.MailReceiverRoleId === 4) ? "" : !HasValue(data.MailReceiverUserId) ? data.MailReceiverUserId : data.MailReceiverUserId.toString();
        let dropdownList = [];

        if (origin === "sfa" && (data.MailReceiverRoleId === 7 || data.MailReceiverRoleId === 4)) {
          tDIDString.split(",").map((id, idI) => {
            tDDString.split(",").map((email, ddI) => {
              if (idI === ddI) {
                dropdownList.push({
                  value: id,
                  label: email,
                  text: email,
                })
              }
            });
          })
        } else {
          dropdownList = [{
            value: data.MailReceiverUserId,
            label: data.MailReceiverEmailId,
            text: data.MailReceiverEmailId,
          }];
        }


        let ddVal = origin === "sfa" && (data.MailReceiverRoleId === 7 || data.MailReceiverRoleId === 4) ? [] :
          {
            value: data.MailReceiverUserId,
            label: data.MailReceiverEmailId,
            text: data.MailReceiverEmailId,
          }

        if (HasProperty(data, 'RejectedByEmailId') && IsValid(data.RejectedByEmailId)) {
          receiverDetails.userID = mailReceiverID; //not being used on API as per jemar
          receiverDetails.eid = mailReceiver;
          receiverDetails.role = data.MailReceiverRole;
          receiverDetails.roleID = data.MailReceiverRoleId;
          receiverDetails.comment = '';
          receiverDetails.rejectedBy = data.RejectedByEmailId;
          receiverDetails.isSkippedIOApproval = data.isSkippedIOApproval;
          receiverDetails.dropdownVal = ddVal;
          receiverDetails.dropdownList = dropdownList;
        } else {
          receiverDetails.userID = mailReceiverID; //not being used on API as per jemar; will be used as per roanne
          receiverDetails.eid = mailReceiver;
          receiverDetails.role = data.MailReceiverRole;
          receiverDetails.roleID = data.MailReceiverRoleId;
          receiverDetails.comment = '';
          receiverDetails.isSkippedIOApproval = data.isSkippedIOApproval;
          receiverDetails.dropdownVal = ddVal;
          receiverDetails.dropdownList = dropdownList;
        };
        dispatch({
          type: ON_CLICK_RDV_SFA,
          receiverDetails: receiverDetails,
        });
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleSFACommentChange = (event) => async (dispatch, state) => {
  try {
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    receiverDetails.comment = event.value;

    dispatch({
      type: ON_CLICK_RDV_SFA,
      receiverDetails: receiverDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const validatePDEForm = (shouldCompute) => async (dispatch, state) => {
  const projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
  const projectDetails = state().ConfigureProgramData.mainSelectedProject.projectDetails;
  const currentUserRoleID = state().userInformation.currentUserRoleID;
  const isSuperAdmin = state().userInformation.isSuperAdmin;
  let isExisting = await dispatch(isProfileExisting([12, 15]));

  let result = {
    error: '',
    result: true,
  };

  projectDimensionsList.forEach((dItem) => {
    dItem.subDimensionList.forEach((sdItem) => {
      sdItem.boiList.forEach((boiItem, index) => {
        if (
          shouldCompute ||
          [null, '--', ''].indexOf(boiItem.boiRealizedValue) > -1 ||
          [null, '--', ''].indexOf(boiItem.boiValueDiff) > -1 ||
          boiItem.hasChange || //updated target value on PPC
          boiItem.parameterList.some((e) => {
            return HasProperty(e, 'hasChange') && e.hasChange;
          })
        ) {
          dispatch({
            type: SET_SHOULD_SHOW_TOASTER_NOTIF,
            toasterParam: {
              show: true,
              type: 'error',
              message: 'Please compute realized value.',
            },
          });
          result.error = 'compute';
          result.result = false;
          return result;
        }
      });
    });
  });

  if (
    result.result &&
    (projectDetails.isNewBoiAddedOnPPCModule || projectDetails.isTargetValueUpdatedOnPPC) &&
    !state().ConfigureProgramData.isRefreshed
  ) {
    dispatch({
      type: SET_SHOULD_SHOW_TOASTER_NOTIF,
      toasterParam: {
        show: true,
        type: 'error',
        message:
          'You have not entered values for all identified BOIs. Kindly use “View Refreshed list” link to view latest list of BOIs and report delivered value against the same.',
      },
    });
    result.error = 'refresh';
    result.result = false;
  }


  return result;
};

export const onClickSelectProjectSFA = (clientApproverRole) => async (dispatch, state) => {
  try {
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    const clientStory = state().ConfigureProgramData.clientStory
    const { keyBusinessChallenges, innovationEnablers, businessOutcomes } = clientStory;
    const enterpriseID = state().userInformation.enterpriseID;
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let userInfo = state().userInformation;
    let userID = userInfo.userID;
    let userName = state().userInformation.userName;
    let selectedClient = state().SharedData.selectedClient;
    let projectEntryList = [];
    let isSuperAdmin = userInfo.isSuperAdmin;
    let clientStoryFile = state().ConfigureProgramData.clientStoryFile;
    let clientAlignmentFiles = state().ConfigureProgramData.clientAlignmentFiles;
    let businessAdvisorList = state().ConfigureProgramData.businessAdvisorList
    const creationDate = selectedProject.projectDetails.creationDate;
    const lastReportingDate = selectedProject.projectDetails.creationDate;
    let body = {};
    const recentlyUploaded =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientStory;
    const recentlyUploadedCA =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment;
    let removeClientStoryFileId = state().ConfigureProgramData.removeClientStoryFileId;
    let removeClientAlignmentFileId = state().ConfigureProgramData.removeClientAlignmentFileId;
    let isExisting = await dispatch(isProfileExisting([12, 15]));
    let files = [];
    let businessAdvisor = businessAdvisorList.filter((ba) => ba.value !== 0).map(x => x.text).join(';')
    let isNotApplicable = businessAdvisorList.find((ba) => ba.value === 0) !== undefined;
    let cxLeadList = selectedProject.projectDetails.cxLeadList;
    //set data
    projectDimensionsList.map((dItem, dIndex) => {
      dItem.subDimensionList.map((sdItem, sdIndex) => {
        sdItem.boiList.map((boiItem, boiIndex) => {
          isSuperAdmin && basicDetails.overallStatus == 'Approved' ?

            projectEntryList.push({
              projectDataEntryID: boiItem.boiProjectDataEntryID,
              projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
              clientProjectTargetID: boiItem.boiClientProjectTargetID,
              boiid: boiItem.boiID,
              uom: boiItem.boiUOM,
              boiName: boiItem.boiName,
              boiTarget: boiItem.boiTarget,
              boiIdentifiedValue:
                boiItem.boiTargetApproved !== null ? boiItem.boiTargetApproved : boiItem.boiTarget,
              boiRealizedValue: boiItem.boiRealizedValue,
              clientValueIdentified:
                [null, ''].indexOf(boiItem.clientValueIdentified) > -1
                  ? 0
                  : boiItem.clientValueIdentified,
              boiValueDifference: boiItem.boiValueDiff,
              boiStatus: boiItem.boiStatus,
              // boiReason: boiItem.boiReason,
              boiReason: '', //send as empty for SFA as per suman
              createdBy: userName,
              CumulativeValue: boiItem.finUSDData.cumulativeBOIApprovedRealizedValue,
              parameterList: boiItem.parameterList.map((param, paramIndex) => {
                return {
                  parameterMasterID: param.parameterMasterID,
                  parameterName: param.parameterName,
                  oldParameterValue: 0,
                  parameterValue: param.parameterValue,
                  parameterUOM: param.parameterUOM,
                  projectParameterValueID: param.parameterValueID,
                };
              }),
              ProjectBoiCommentList: boiItem.RVDCommentHistory.map((item, itemIndex) => {
                return {
                  id: item.Id,
                  projectDataEntryID: boiItem.projectDataEntryID,
                  clientProgramID: selectedProject.programID,
                  clientProjectID: selectedProject.projectDetails.clientProjectID,
                  boiid: boiItem.boiID,
                  comment: item.Comment,
                  isEdited: item.IsEdited,
                  isProgramLevel: true, //false
                  isActive: true,
                  createdUser: item.CreatedUser,
                  createdTS: item.CreatedTS,
                  updatedUser: item.CreatedUser,
                  updatedTS: item.CreatedTS,
                  clientProgramTargetID: 0, //update to used clientprogramtarget
                };
              }).reverse(),
            }) :
            projectEntryList.push({
              projectDataEntryID: boiItem.boiProjectDataEntryID,
              projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
              clientProjectTargetID: boiItem.boiClientProjectTargetID,
              boiid: boiItem.boiID,
              uom: boiItem.boiUOM,
              boiName: boiItem.boiName,
              boiTarget: boiItem.boiTarget,
              boiIdentifiedValue:
                boiItem.boiTargetApproved !== null ? boiItem.boiTargetApproved : boiItem.boiTarget,
              boiRealizedValue: boiItem.boiRealizedValue,
              clientValueIdentified:
                [null, ''].indexOf(boiItem.clientValueIdentified) > -1
                  ? 0
                  : boiItem.clientValueIdentified,
              boiValueDifference: boiItem.boiValueDiff,
              boiStatus: boiItem.boiStatus,
              // boiReason: boiItem.boiReason,
              boiReason: '', //send as empty for SFA as per suman
              createdBy: userName,
              parameterList: boiItem.parameterList.map((param, paramIndex) => {
                return {
                  parameterMasterID: param.parameterMasterID,
                  parameterName: param.parameterName,
                  oldParameterValue: 0,
                  parameterValue: param.parameterValue,
                  parameterUOM: param.parameterUOM,
                  projectParameterValueID: param.parameterValueID,
                };
              }),
              ProjectBoiCommentList: boiItem.RVDCommentHistory.map((item, itemIndex) => {
                return {
                  id: item.Id,
                  projectDataEntryID: boiItem.projectDataEntryID,
                  clientProgramID: selectedProject.programID,
                  clientProjectID: selectedProject.projectDetails.clientProjectID,
                  boiid: boiItem.boiID,
                  comment: item.Comment,
                  isEdited: item.IsEdited,
                  isProgramLevel: true, //false
                  isActive: true,
                  createdUser: item.CreatedUser,
                  createdTS: item.CreatedTS,
                  updatedUser: item.CreatedUser,
                  updatedTS: item.CreatedTS,
                  clientProgramTargetID: 0, //update to used clientprogramtarget
                };
              }).reverse(),
            });

        });
      });
    });
    if (
      ((userInfo.currentUserRoleID === 12 ||
        userInfo.currentUserRoleID === 15 ||
        (userInfo.currentUserRoleID === 16 && isExisting)) &&
        basicDetails.assignedToRoleID > 0 &&
        basicDetails.assignedToRoleID !== userInfo.currentUserRoleID &&
        !isSuperAdmin) ||
      (isSuperAdmin && selectedProject.projectDetails.status === 'Approved')
    ) {
      body = {
        clientID: selectedClient.value,
        clientName: selectedClient.label,
        programName: selectedProject.programName,
        projectName: selectedProject.projectDetails.clientProjectName,
        deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
        clientProgramID: selectedProject.programID,
        clientProjectID: selectedProject.projectDetails.clientProjectID,
        deliveredBy: basicDetails.deliveredBy,
        projectLead: basicDetails.projectLead,
        projectDeliveryCenterID: basicDetails.projectDeliveryCenterID, //primary key
        deliveryCenterID: selectedProject.projectDetails.deliveryCenterID, //project delivery center
        mailReceiverUserID: selectedProject.projectDetails.cxlLeadUserID, //not being used on API as per jemar
        mailReceiverEmailId: selectedProject.projectDetails.cxLead,
        mailReceiverRole: 'CX Lead',
        mailReceiverRoleId: 7, //cxlead
        comment: receiverDetails.comment,
        assignedTo: selectedProject.projectDetails.cxLead, //currently selected CX Lead
        assignedToRoleId: 7, //cxlead
        loggedInUserRoleId:
          isSuperAdmin && selectedProject.projectDetails.status === 'Approved'
            ? 12 // in case superadmin edits PDE that is approved, pass 12 or 15
            : userInfo.currentUserRoleID === ''
              ? 0
              : userInfo.currentUserRoleID,
        isEditingModebyPPCUser: true,
        createdBy: userName,
        isSendingApproval: true,
        rejectedByRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID, //update
        startReportingDate: creationDate,
        lastReportingDate: lastReportingDate,
        projectDataEntryList: projectEntryList,
        dataSubmitter: userInfo.isSuperAdmin
          ? basicDetails.projectCreator.toLowerCase() === 'system generated'
            ? userInfo.emailAddress
            : basicDetails.projectCreator
          : userInfo.emailAddress,
        isSkippedIOApproval: HasValue(receiverDetails.isSkippedIOApproval)
          ? receiverDetails.isSkippedIOApproval
          : false,
        clientStoryFileToUpload: {
          id: !HasValue(recentlyUploaded)
            ? clientStoryFile.id
            : removeClientStoryFileId > 0
              ? 0
              : recentlyUploaded.Id,
          fileUploadedBy: clientStoryFile.fileUploadedBy,
          createdUser: clientStoryFile.createdUser,
        },
        ClientStory: {
          ClientProjectID: basicDetails.projectID,
          ProjectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
          KeyBusinessChallenges: keyBusinessChallenges,
          InnovationEnablers: innovationEnablers,
          BusinessOutcomes: businessOutcomes,
          CreatedUser: enterpriseID,
        },
        ISClientStoryEdited: ISClientStoryEdited(clientStory),
        RemoveClientStoryFileId: removeClientStoryFileId ? removeClientStoryFileId : 0,
        BusinessAdvisor: businessAdvisor,
        IsBANotApplicable: isNotApplicable,
        ClientApproverRole: clientApproverRole.otherSpecify === "" ? clientApproverRole.clientApproverRole.label : clientApproverRole.otherSpecify,
        clientAlignmentFileAttachment: {
          deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
          createdUser: clientAlignmentFiles.createdUser,
          createdTS: clientAlignmentFiles.uploadedDateTime,
          updatedUser: clientAlignmentFiles.fileUploadedBy,
          updatedTS: clientAlignmentFiles.fileUploadedDate,
          removeClientAlignmentFileIds: removeClientAlignmentFileId,
          isApproved: false
        },
        isComputedOnce: selectedProject.projectDetails.isComputedOnce,

      };
    } else {
      body = {
        clientID: selectedClient.value,
        clientName: selectedClient.label,
        programName: selectedProject.programName,
        projectName: selectedProject.projectDetails.clientProjectName,
        deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
        clientProgramID: selectedProject.programID,
        clientProjectID: selectedProject.projectDetails.clientProjectID,
        deliveredBy: basicDetails.deliveredBy,
        projectLead: basicDetails.projectLead,
        projectDeliveryCenterID: basicDetails.projectDeliveryCenterID, //
        deliveryCenterID: selectedProject.projectDetails.deliveryCenterID, //
        mailReceiverUserID: receiverDetails.userID,
        mailReceiverEmailId: receiverDetails.eid, //
        mailReceiverRole: receiverDetails.role,
        mailReceiverRoleId: receiverDetails.roleID,
        comment: receiverDetails.comment,
        assignedTo: receiverDetails.eid, //
        assignedToRoleId: receiverDetails.roleID,
        createdBy: userName,
        isSendingApproval: true,
        projectDataEntryList: projectEntryList,
        dataSubmitter:
          userInfo.isSuperAdmin ||
            !IsArrayEmpty(cxLeadList.filter((a) => a.label === userInfo.emailAddress))
            ||
            basicDetails.ioApprover === userInfo.emailAddress
            ? basicDetails.dataSubmitter === null
              ? basicDetails.projectCreator.toLowerCase() === 'system generated'
                ? userInfo.emailAddress
                : basicDetails.projectCreator
              : basicDetails.dataSubmitter
            : userInfo.emailAddress,
        rejectedByRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID, //update
        startReportingDate: creationDate,
        lastReportingDate: lastReportingDate,
        loggedInUserRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID,
        isEditingModebyPPCUser: false,
        isSkippedIOApproval: HasValue(receiverDetails.isSkippedIOApproval)
          ? receiverDetails.isSkippedIOApproval
          : false,
        clientStoryFileToUpload: {
          id: !HasValue(recentlyUploaded)
            ? clientStoryFile.id
            : removeClientStoryFileId > 0
              ? 0
              : recentlyUploaded.Id,
          fileUploadedBy: clientStoryFile.fileUploadedBy,
          createdUser: clientStoryFile.createdUser,
        },
        ClientStory: {
          ClientProjectID: basicDetails.projectID,
          ProjectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
          KeyBusinessChallenges: keyBusinessChallenges,
          InnovationEnablers: innovationEnablers,
          BusinessOutcomes: businessOutcomes,
          CreatedUser: enterpriseID,
        },
        ISClientStoryEdited: ISClientStoryEdited(clientStory),
        RemoveClientStoryFileId: removeClientStoryFileId ? removeClientStoryFileId : 0,
        BusinessAdvisor: businessAdvisor,
        IsBANotApplicable: isNotApplicable,
        ClientApproverRole: clientApproverRole.otherSpecify === "" ? clientApproverRole.clientApproverRole.label : clientApproverRole.otherSpecify,
        clientAlignmentFileAttachment: {
          deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
          createdUser: clientAlignmentFiles.createdUser,
          createdTS: clientAlignmentFiles.uploadedDateTime,
          updatedUser: clientAlignmentFiles.fileUploadedBy,
          updatedTS: clientAlignmentFiles.fileUploadedDate,
          removeClientAlignmentFileIds: removeClientAlignmentFileId,
          isApproved: false
        },
        isComputedOnce: selectedProject.projectDetails.isComputedOnce,
      };
    }
    const pptData = {
      projectName: basicDetails.projectName,
      businessAdvisor,
      operationsAccountLead: basicDetails.oaLead,
      cxlLead: basicDetails.cxLead,
      projectCreator: basicDetails.projectCreator,
      keyBusinessChallenges,
      innovationEnablers,
      businessOutcomes,
      projectDeliveredBy: basicDetails.projectDeliveredBy
    }

    const formData = new FormData();
    const ppt = await generateCSPPT(pptData);
    formData.append("Files", ppt);

    if (HasValue(clientAlignmentFiles.file)) {
      clientAlignmentFiles.file.map((x) => {
        formData.append('Files', x);
      });
    }

    formData.append('objSendApproval', JSON.stringify(body));
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data;
    if (isSuperAdmin && basicDetails.overallStatus == 'Approved') {
      data = await HttpPostWithFileString(
        `ProjectDataEntry/UpdateProjectDataEntryByAdmin?SubMenuID=${subMenuId}`,
        formData
      );

    } else {
      data = await HttpPostWithFileString(
        `ProjectDataEntry/SendForApprovalProjectDataEntry?SubMenuID=${subMenuId}`,
        formData
      )
    }



    if (data === 'success') {
      history.push('/DataIngestion');
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'success',
          message: 'Notification sent successfully!',
        },
      });

      await LogRVDprojectAction({
        clientMasterID: selectedClient.value,
        clientProgramID: basicDetails.programId,
        clientProjectID: basicDetails.projectID,
        clientProjectStatus:
          receiverDetails.role.toLowerCase() === 'cxllead'
            ? PROJ_DATA_ENTRY_REC
            : receiverDetails.role.toLowerCase() === 'io approver'
              ? PROJ_DATA_APP_L1
              : receiverDetails.role.toLowerCase() === 'operationsaccountlead'
                ? PROJ_DATA_APP_L2
                : PROJ_DATA_ENTRY_REC,
        module: PDE,
      });
      dispatch({
        //clear
        type: ON_TILE_SELECT_PROJECT,
        basicDetails: {},
        projectDimensionsList: [],
        buttonState: {
          isSelectProjectDisabled: false,
          isCommentDisabled: false,
          isSaveAsDraftDisabled: false,
        },
        isProjectSelected: false,
        isWriteAccess: false,
        mainSelectedProject: selectedProject,
        isDraft: false,
        isExistingDataEntry: false,
      });
      dispatch({
        type: SET_VIRUS_FOUND,
        virusFoundError: false,
      });
      dispatch({
        type: SET_CLIENT_STORY_FILE,
        clientStoryFile: {
          id: 0,
          file: [],
          fileUploadedBy: '',
          createdUser: '',
          fileUploadedDate: '',
        },
      });
      dispatch({
        type: SET_CLIENT_ALIGNMENT_FILE,
        clientAlignmentFiles: {
          id: 0,
          file: [],
          fileUploadedBy: '',
          createdUser: '',
          fileUploadedDate: '',
        }
      })
      dispatch(
        setEmailError({
          show: false,
          message: '',
        })
      );
    } else {
      let postData = JSON.parse(data)
      if (HasProperty(postData, 'Code') && postData.Code === "2") {
        dispatch(
          setEmailError({
            show: true,
            message: postData.Message,
          })
        );
      } else {
        dispatch({
          type: SET_VIRUS_FOUND,
          virusFoundError: true,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateHTMLStringForPPT = (htmlString = "") => {
  if (!htmlString || !htmlString.length) return "";
  let updatedStr = htmlString;
  updatedStr = updatedStr.replace(/<em>/g, "<i>");
  updatedStr = updatedStr.replace(/<\/em>/g, "</i>");
  updatedStr = updatedStr.replace(/<strong>/g, "<b>");
  updatedStr = updatedStr.replace(/<\/strong>/g, "</b>");
  updatedStr = updatedStr.replace(/<ins>/g, "<u>");
  updatedStr = updatedStr.replace(/<\/ins>/g, "</u>");
  updatedStr = updatedStr.replace(/(&nbsp;)\1+/g, '$1');
  return updatedStr
}
export const generatePPTXGenJSObjects = (htmlString = '') => {
  if (htmlString && htmlString.length) {
    let items = [];
    let updatedStr = updateHTMLStringForPPT(htmlString);

    items = htmlToPptxText(updatedStr);
    items = items.filter((obj, i, arr) => {
      if (obj.text === " " && ((arr[i - 1] && arr[i - 1].options.breakLine) || (arr[i - 2] && arr[i - 2].options.breakLine))) {
        return false
      }
      return true;
    })
    items = items.map((obj) => {
      const newOptions = { underline: obj.options.underline ? { style: 'sng' } : false, fontFace: obj.options && obj.options.bold ? "Graphik Semibold" : "Graphik", fontSize: 10, paraSpaceAfter: 6 };
      for (const key in obj.options) {
        if (key !== "strike" && key !== "fontFace" && key !== "fontSize" && key !== "underline" && key !== "hyperlink") {
          newOptions[key] = obj.options[key]
        }
        // if (key === 'hyperlink') {
        //   newOptions[key] = {
        //     url: obj.options[key].slide,
        //     tooltip: undefined
        //   }
        // }
      }
      return {
        text: obj.text,
        options: newOptions
      };
    });
    return items && items.length ? (items[0].text === "" || items[0].text === " ") ? items.splice(1, (items.length - 1)) : items : "";
  }
  return "";
}

export const eidGenerator = (emailId = '') => {
  const seperator = "@accenture.com";
  return emailId && emailId.length && emailId.includes(seperator) ? emailId.split(seperator)[0] : '';
}



const fixSlideObject = (slide, lastNumberedBulletItem) => {
  for (
    let columnIndex = 0;
    columnIndex < slide._slideObjects[0].arrTabRows[0].length;
    columnIndex++
  ) {
    const column = slide._slideObjects[0].arrTabRows[0][columnIndex];
    let isNumberedItemVisited = false;
    let newTextArr = [];
    newTextArr = column.text.map((textObj, i, arr) => {
      if (
        !isNumberedItemVisited &&
        textObj.options &&
        textObj.options.bullet
      ) {
        isNumberedItemVisited = true;
      } else if (
        isNumberedItemVisited &&
        textObj.options &&
        textObj.options.bullet
      ) {
        const newOptionObj = { ...textObj.options, bullet: false };
        textObj.options = newOptionObj;
      }
      if (!textObj.text.trim().length) {
        isNumberedItemVisited = false;
        lastNumberedBulletItem[columnIndex] = 0
      }
      if (textObj.options && textObj.options.bullet && !textObj.options.bullet.type) {
        const newOptionObj = { ...textObj.options, bullet: { indent: 12 } };
        textObj.options = newOptionObj;
      }
      if (textObj.options && textObj.options.bullet && textObj.options.bullet.type === "number") {
        lastNumberedBulletItem[columnIndex] = lastNumberedBulletItem[columnIndex] + 1;
        const newOptionObj = { ...textObj.options, bullet: { ...textObj.options.bullet, indent: 12, startAt: lastNumberedBulletItem[columnIndex] } };
        textObj.options = newOptionObj;
      }
      if (textObj.options && !textObj.options.bold && arr[i + 1] && arr[i + 1].options && arr[i + 1].options.bold) {
        const newText = `${textObj.text} `;
        textObj.text = newText
      }
      if (textObj.options && !textObj.options.bold && arr[i - 1] && arr[i - 1].options && arr[i - 1].options.bold) {
        const newText = ` ${textObj.text}`;
        textObj.text = newText
      }
      return textObj;
    });

    slide._slideObjects[0].arrTabRows[0][columnIndex].text = newTextArr;
  }
}

export const generateCSPPT = async ({
  projectName,
  businessAdvisor = '',
  operationsAccountLead = '',
  cxlLead, projectCreator = '',
  keyBusinessChallenges,
  innovationEnablers,
  businessOutcomes,
  projectDeliveredBy = '' }) => {
  const businessAdvsr = eidGenerator(businessAdvisor);
  const accLead = eidGenerator(operationsAccountLead);
  const sdl = eidGenerator(cxlLead);
  const beLead = eidGenerator(projectCreator);
  const keyBusinessChallengesItems = generatePPTXGenJSObjects(keyBusinessChallenges);
  const innovationEnablersItems = generatePPTXGenJSObjects(innovationEnablers);
  const businessOutcomesItems = generatePPTXGenJSObjects(businessOutcomes);

  try {
    let pptx = new pptxgen();
    pptx.layout = "LAYOUT_WIDE";
    const slideObj = [
      {
        text: {
          text: projectName,
          options: {
            x: 0.2,
            y: 0.3,
            fontFace: "Graphik Black",
            valign: "top",
            lineSpacingMultiple: 0.8,
            fontSize: 32,
            color: "#A100FF",
            align: "left",
            bold: true,
            w: "100%"
          }
        }
      },
      {
        text: {
          text: projectDeliveredBy,
          options: {
            shape: "rect",
            fill: { color: "#717171" },
            color: "#FFFFFF",
            fontFace: "Graphik Black",
            fontSize: 11,
            align: "left",
            w: "16%",
            h: "8%",
            x: 0.3,
            y: 1.2
          }
        }
      },
      {
        rect: {
          w: "20%",
          h: "8%",
          x: 2.48,
          y: 1.2,
          fill: { color: "#717171" }
        }
      },
      {
        image: { path: cs_ppt_profile_icon, x: 2.56, y: 1.4, h: '3%', w: "2%" }
      },
      {
        text: {
          text: `Business Advisor\n${businessAdvsr}`,
          options: {
            fill: { color: "#FFFF00" },
            color: "#FFFFFF",
            fontFace: "Graphik",
            fontSize: 9,
            align: "left",
            x: 2.77,
            y: 1.5,
            w: "20%"
          }
        }
      },
      {
        rect: {
          w: "20%",
          h: "8%",
          x: 5.18,
          y: 1.2,
          fill: { color: "#717171" }
        }
      },
      {
        image: { path: cs_ppt_profile_icon, x: 5.26, y: 1.4, h: '3%', w: "2%" }
      },
      {
        text: {
          text: `Account Lead\n${accLead}`,
          options: {
            color: "#FFFFFF",
            fontFace: "Graphik",
            fontSize: 9,
            align: "left",
            x: 5.47,
            y: 1.5,
            w: "20%"
          }
        }
      },
      {
        rect: {
          w: "20%",
          h: "8%",
          x: 7.87,
          y: 1.2,
          fill: { color: "#717171" }
        }
      },
      {
        image: { path: cs_ppt_profile_icon, x: 7.95, y: 1.4, h: '3%', w: "2%" }
      },
      {
        text: {
          text: `SDL\n${sdl}`,
          options: {
            color: "#FFFFFF",
            fontFace: "Graphik",
            fontSize: 9,
            align: "left",
            x: 8.16,
            y: 1.5,
            w: "20%"
          }
        }
      },
      {
        rect: {
          w: "20%",
          h: "8%",
          x: 10.55,
          y: 1.2,
          fill: { color: "#717171" }
        }
      },
      {
        image: { path: cs_ppt_profile_icon, x: 10.63, y: 1.4, h: '3%', w: "2%" }
      },
      {
        text: {
          text: `BE Lead\n${beLead}`,
          options: {
            color: "#FFFFFF",
            fontFace: "Graphik",
            fontSize: 9,
            align: "left",
            x: 10.84,
            y: 1.5,
            w: "20%"
          }
        }
      },
      {
        image: { path: keyBusinessIcon, x: 0.35, y: 1.85, h: '9%', w: "5%" }
      },
      {
        text: {
          text: "KEY BUSINESS CHALLENGES",
          options: {
            x: 1,
            y: 2,
            fontFace: "Graphik",
            valign: "top",
            fontSize: 14,
            w: "23%",
            color: "#A100FF",
            align: "left",
            bold: true,
            isTextBox: true,
            h: 0.6
          }
        }
      },
      {
        image: { path: innovationIcon, x: 4.6, y: 1.85, h: '9%', w: "5%" }
      },
      {
        text: {
          text: "INNOVATION ENABLERS",
          options: {
            x: 5.3,
            y: 2,
            fontFace: "Graphik",
            valign: "top",
            fontSize: 14,
            w: "23%",
            color: "#A100FF",
            align: "left",
            bold: true,
            isTextBox: true,
            h: 0.6
          }
        }
      },
      {
        image: { path: businessOutcomeIcon, x: 8.88, y: 1.85, h: '9%', w: "5%" }
      },
      {
        text: {
          text: "BUSINESS OUTCOMES",
          options: {
            x: 9.53,
            y: 2,
            fontFace: "Graphik",
            valign: "top",
            fontSize: 14,
            w: "23%",
            color: "#A100FF",
            align: "left",
            bold: true,
            isTextBox: true,
            h: 0.6
          }
        }
      }, {
        text: {
          text: "Copyright @ 2023 Accenture. All rights reserved",
          options: {
            x: 0.2,
            y: "96%",
            fontFace: "Graphik",
            valign: "top",
            fontSize: 8,
            color: "#ADADAD",
            align: "left",
            isTextBox: true,
            h: 0.2
          }
        },
      }, {
        text: {
          text: "Accenture Proprietary and Confidential Content is not approved/vetted for client RFS, RFIs, Meetings, Orals, Media, Sales, Interviews, Internal/External document beyond Delivery community.",
          options: {
            x: 3,
            y: "96%",
            fontFace: "Graphik",
            valign: "top",
            fontSize: 8,
            color: "#FF0000",
            align: "left",
            isTextBox: true,
            h: 0.2,
            w: "99%"
          }
        }
      }
    ];
    pptx.defineSlideMaster({
      title: "MASTER_SLIDE",
      background: { color: "FFFFFF" },
      objects: slideObj,
    });
    let slide = pptx.addSlide({ masterName: "MASTER_SLIDE" });
    const tableRows = [[
      { text: keyBusinessChallengesItems, options: { margin: [0, 0.2, 0.05, 0] } },
      { text: innovationEnablersItems, options: { margin: [0, 0.1, 0.05, 0] } },
      { text: businessOutcomesItems, options: { margin: [0, 0.1, 0.05, 0.08] } }
    ]];

    slide.addTable(tableRows, {
      x: 0.35,
      y: 2.55,
      w: 9.5,
      margin: [0, 0.1, 0.05, 0],
      colW: [4.3, 4.2, 4.3],
      autoPageLineWeight: 0.4,
      autoPage: true,
      autoPageSlideStartY: 2.55
    });
    const lastNumberedBulletItem = [0, 0, 0];
    const totalSlides = slide.newAutoPagedSlides && Array.isArray(slide.newAutoPagedSlides) && slide.newAutoPagedSlides.length ?
      [slide, ...slide.newAutoPagedSlides] : [slide];
    for (const slideParam of totalSlides) {
      fixSlideObject(slideParam, lastNumberedBulletItem);
      slideParam.addText(`Page ${slideParam._slideNum} of ${totalSlides.length}`, { x: "92.2%", y: "93%", w: "8%", h: 0.3, fontSize: 10, fontFace: "Graphik Semibold" })
    }
    const ppt = await pptx.stream();
    const blob = new Blob([ppt], { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" })
    return new File([blob], 'Client Story', { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" });
  } catch (error) {
    console.log("Error in PPT: ", error);
  }

}

export const handleUpdateClientStory = (existringReduxClass, isFromFinalApproval = false) => async (dispatch, state) => {
  const { clientStory, basicDetails, businessAdvisorList } = state().ConfigureProgramData;
  const enterpriseID = state().userInformation.enterpriseID;
  const businessAdvisor = businessAdvisorList.filter((ba) => ba.value !== 0).map(x => x.text).join(';');
  const { keyBusinessChallenges, innovationEnablers, businessOutcomes } = clientStory;

  try {
    const params = {
      projectName: basicDetails.projectName,
      businessAdvisor,
      operationsAccountLead: basicDetails.oaLead,
      cxlLead: basicDetails.cxLead,
      projectCreator: basicDetails.projectCreator,
      keyBusinessChallenges,
      innovationEnablers,
      businessOutcomes,
      projectDeliveredBy: basicDetails.projectDeliveredBy
    }

    const ppt = await generateCSPPT(params);
    const payload = {
      ClientProjectID: basicDetails.projectID,
      ProjectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
      KeyBusinessChallenges: keyBusinessChallenges,
      InnovationEnablers: innovationEnablers,
      BusinessOutcomes: businessOutcomes,
      CreatedUser: enterpriseID,
    }
    const formData = new FormData();
    formData.append("File", ppt)
    formData.append('ClientStoryData', JSON.stringify(payload));
    let subMenuId = GetSubMenuId("Project Data Entry");
    const data = await HttpPostWithFileString(
      `ProjectDataEntry/UpdateClientStory?SubMenuID=${subMenuId}`,
      formData
    );
    if (data === 'success') {
      const updatedClientStoryObj = {
        ...clientStory,
        exKeyBusinessChallenges: keyBusinessChallenges,
        exInnovationEnablers: innovationEnablers,
        exBusinessOutcomes: businessOutcomes
      }
      dispatch({
        type: SET_CLIENT_STORY,
        clientStory: updatedClientStoryObj
      })
      dispatch({
        type: SET_RVD_BASIC_DETAILS,
        basicDetails: { ...basicDetails, oldClientStoryFileExists: false },
      });
      if (!isFromFinalApproval) {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'success',
            message: 'Changes are updated successfully',
          },
        });
      } else {
        return true;
      }
    }
  } catch (error) {
    console.log("error", error)
  }
}

export const ISClientStoryEdited = (clientStory) => {
  const {
    keyBusinessChallenges, innovationEnablers, businessOutcomes,
    exKeyBusinessChallenges, exInnovationEnablers, exBusinessOutcomes
  } = clientStory;

  return (keyBusinessChallenges !== exKeyBusinessChallenges
    || innovationEnablers !== exInnovationEnablers
    || businessOutcomes !== exBusinessOutcomes)
}
export const handleSaveAsDraft = (clientApprover) => async (dispatch, state) => {
  try {
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let userName = state().userInformation.userName;
    let selectedClient = state().SharedData.selectedClient;
    let projectEntryList = [];
    let userInfo = state().userInformation;
    let isSuperAdmin = userInfo.isSuperAdmin;
    let clientStoryFile = state().ConfigureProgramData.clientStoryFile;
    const clientStory = state().ConfigureProgramData.clientStory;
    const { keyBusinessChallenges, innovationEnablers, businessOutcomes } = clientStory;
    const enterpriseID = state().userInformation.enterpriseID;
    let clientAlignmentFiles = state().ConfigureProgramData.clientAlignmentFiles;
    let businessAdvisorList = state().ConfigureProgramData.businessAdvisorList

    const creationDate = selectedProject.projectDetails.creationDate;
    const lastReportingDate = selectedProject.projectDetails.creationDate;
    let paramData = {};
    const recentlyUploaded =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientStory;
    const recentlyUploadedCA =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment;
    let removeClientStoryFileId = state().ConfigureProgramData.removeClientStoryFileId;
    let removeClientAlignmentFileId = state().ConfigureProgramData.removeClientAlignmentFileId;
    let isExisting = await dispatch(isProfileExisting([12, 15]));
    let files = [];
    let assignedToList = selectedProject.projectDetails.assignedToList;

    let isNotApplicable = businessAdvisorList.find((ba) => ba.value === 0) !== undefined;
    let businessAdvisor = businessAdvisorList.filter((ba) => ba.value !== 0).map((x) => x.text).join(';');
    //set data
    projectDimensionsList.map((dItem, dIndex) => {
      dItem.subDimensionList.map((sdItem, sdIndex) => {
        sdItem.boiList.map((boiItem, boiIndex) => {
          projectEntryList.push({
            projectDataEntryID: boiItem.boiProjectDataEntryID,
            projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
            clientProjectTargetID: boiItem.boiClientProjectTargetID,
            boiid: boiItem.boiID,
            uom: boiItem.boiUOM,
            boiName: boiItem.boiName,
            boiTarget: boiItem.boiTarget,
            boiIdentifiedValue:
              boiItem.boiTargetApproved !== null ? boiItem.boiTargetApproved : boiItem.boiTarget,
            boiRealizedValue: boiItem.boiRealizedValue,
            clientValueIdentified:
              [null, ''].indexOf(boiItem.clientValueIdentified) > -1
                ? 0
                : boiItem.clientValueIdentified,
            boiValueDifference: boiItem.boiValueDiff,
            boiStatus: boiItem.boiStatus,
            boiReason: boiItem.boiReason,
            createdBy: userName,
            parameterList: boiItem.parameterList.map((param, paramIndex) => {
              return {
                parameterMasterID: param.parameterMasterID,
                parameterName: param.parameterName,
                oldParameterValue: 0,
                parameterValue: param.parameterValue,
                parameterUOM: param.parameterUOM,
                projectParameterValueID: param.parameterValueID,
              };
            }),
            ProjectBoiCommentList: boiItem.RVDCommentHistory.map((item, itemIndex) => {
              return {
                id: item.Id,
                projectDataEntryID: boiItem.projectDataEntryID,
                clientProgramID: selectedProject.programID,
                clientProjectID: selectedProject.projectDetails.clientProjectID,
                boiid: boiItem.boiID,
                comment: item.Comment,
                isEdited: item.IsEdited,
                isProgramLevel: true,
                isActive: true,
                createdUser: item.CreatedUser,
                createdTS: item.CreatedTS,
                updatedUser: item.CreatedUser,
                updatedTS: item.CreatedTS,
                clientProgramTargetID: 0,
              };
            }).reverse(),
          });
        });
      });
    });

    if (
      ((userInfo.currentUserRoleID === 12 ||
        userInfo.currentUserRoleID === 15 ||
        (userInfo.currentUserRoleID === 16 && isExisting)) &&
        basicDetails.assignedToRoleID > 0 &&
        basicDetails.assignedToRoleID !== userInfo.currentUserRoleID) ||
      (isSuperAdmin && selectedProject.projectDetails.status === 'Approved')
    ) {
      paramData = {
        clientID: selectedClient.value,
        clientName: selectedClient.label,
        programName: selectedProject.programName,
        projectName: selectedProject.projectDetails.clientProjectName,
        deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
        clientProgramID: selectedProject.programID,
        clientProjectID: selectedProject.projectDetails.clientProjectID,
        deliveredBy: basicDetails.deliveredBy,
        projectLead: basicDetails.projectLead,
        projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
        deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
        mailReceiverUserID: 0,
        mailReceiverEmailId: '',
        mailReceiverRole: receiverDetails.role,
        mailReceiverRoleId: 0,
        comment: receiverDetails.comment,
        assignedTo:
          isSuperAdmin && selectedProject.projectDetails.status === 'Approved'
            ? basicDetails.dataSubmitter
            : userInfo.emailAddress,
        assignedToRoleId:
          isSuperAdmin && selectedProject.projectDetails.status === 'Approved'
            ? 12 // in case superadmin edits PDE that is approved, pass 12 or 15
            : userInfo.currentUserRoleID,
        loggedInUserRoleId:
          isSuperAdmin && selectedProject.projectDetails.status === 'Approved'
            ? 12
            : userInfo.currentUserRoleID === ''
              ? 0
              : userInfo.currentUserRoleID,
        isEditingModebyPPCUser: true,
        createdBy: userName,
        isSendingApproval: false,
        rejectedByRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID, //update
        startReportingDate: creationDate,
        lastReportingDate: lastReportingDate,
        projectDataEntryList: projectEntryList,
        dataSubmitter: userInfo.isSuperAdmin
          ? IsValidStr(basicDetails.projectCreator) && basicDetails.projectCreator.toLowerCase() === 'system generated'
            ? userInfo.emailAddress
            : basicDetails.projectCreator
          : !IsArrayEmpty(assignedToList.filter((a) => a.label === userInfo.emailAddress))
            ? basicDetails.dataSubmitter
            : userInfo.emailAddress,
        clientStoryFileToUpload: {
          id: !HasValue(recentlyUploaded)
            ? clientStoryFile.id
            : removeClientStoryFileId > 0
              ? 0
              : recentlyUploaded.Id,
          fileUploadedBy: clientStoryFile.fileUploadedBy,
          createdUser: clientStoryFile.createdUser,
        },
        RemoveClientStoryFileId: removeClientStoryFileId ? removeClientStoryFileId : 0,
        BusinessAdvisor: businessAdvisor,
        IsBANotApplicable: isNotApplicable,
        ClientApproverRole: clientApprover.otherSpecify === "" ? clientApprover.clientApproverRole.label : clientApprover.otherSpecify,
        clientAlignmentFileAttachment: {
          deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
          createdUser: clientAlignmentFiles.createdUser,
          createdTS: clientAlignmentFiles.uploadedDateTime,
          updatedUser: clientAlignmentFiles.fileUploadedBy,
          updatedTS: clientAlignmentFiles.fileUploadedDate,
          removeClientAlignmentFileIds: removeClientAlignmentFileId,
          isApproved: false
        },
        isComputedOnce: selectedProject.projectDetails.isComputedOnce,
        ClientStory: {
          ClientProjectID: basicDetails.projectID,
          ProjectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
          KeyBusinessChallenges: keyBusinessChallenges,
          InnovationEnablers: innovationEnablers,
          BusinessOutcomes: businessOutcomes,
          CreatedUser: enterpriseID,
        },
        ISClientStoryEdited: ISClientStoryEdited(clientStory)
      };
    } else {
      let userInfo = state().userInformation;
      let assignedToRoleID = basicDetails.assignedToRoleID;
      let status = basicDetails.overallStatus.toLowerCase();
      let assignedTo = IsValidStr(selectedProject.projectDetails.assignedTo)
        ? selectedProject.projectDetails.assignedTo
        : userInfo.emailAddress;

      if (isSuperAdmin) {
        if (["", "approved"].indexOf(status) !== -1) {
          assignedToRoleID = 12;
          assignedTo = basicDetails.projectCreator;
        } else {
          assignedToRoleID = basicDetails.assignedToRoleID;
          assignedTo = selectedProject.projectDetails.assignedTo;
        }
      } else if (userInfo.currentUserRoleID === 12 || userInfo.currentUserRoleID === 15) {
        if (["", "approved", "in progress", "awaiting approval", "rejected"].indexOf(status) !== -1) {
          assignedToRoleID = userInfo.currentUserRoleID;
          assignedTo = userInfo.emailAddress;
        }
      }


      paramData = {
        clientID: selectedClient.value,
        clientName: selectedClient.label,
        programName: selectedProject.programName,
        projectName: selectedProject.projectDetails.clientProjectName,
        deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
        clientProgramID: selectedProject.programID,
        clientProjectID: selectedProject.projectDetails.clientProjectID,
        deliveredBy: basicDetails.deliveredBy,
        projectLead: basicDetails.projectLead,
        projectDeliveryCenterID: basicDetails.projectDeliveryCenterID, //primary key
        deliveryCenterID: selectedProject.projectDetails.deliveryCenterID, //project delivery center
        mailReceiverUserID: receiverDetails.userID,
        mailReceiverEmailId: receiverDetails.eid,
        mailReceiverRole: receiverDetails.role,
        mailReceiverRoleId: receiverDetails.roleID,
        comment: receiverDetails.comment,
        // assignedTo: IsValidStr(selectedProject.projectDetails.assignedTo)
        //   ? selectedProject.projectDetails.assignedTo
        //   : state().userInformation.emailAddress,
        // assignedToRoleId: userInfo.currentUserRoleID === 12 ||
        //   userInfo.currentUserRoleID === 15 || isSuperAdmin ? 12 : basicDetails.assignedToRoleID,
        assignedTo: assignedTo,
        assignedToRoleId: assignedToRoleID,
        loggedInUserRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID,
        isEditingModebyPPCUser: false,
        createdBy: userName,
        isSendingApproval: false,
        rejectedByRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID, //update
        startReportingDate: creationDate,
        lastReportingDate: lastReportingDate,
        projectDataEntryList: projectEntryList,
        dataSubmitter: userInfo.isSuperAdmin
          ? IsValidStr(basicDetails.projectCreator) && basicDetails.projectCreator.toLowerCase() === 'system generated'
            ? userInfo.emailAddress
            : basicDetails.projectCreator
          : !IsArrayEmpty(assignedToList.filter((a) => a.label === userInfo.emailAddress))
            ? basicDetails.dataSubmitter
            : userInfo.emailAddress,
        clientStoryFileToUpload: {
          id: !HasValue(recentlyUploaded)
            ? clientStoryFile.id
            : removeClientStoryFileId > 0
              ? 0
              : recentlyUploaded.Id,
          fileUploadedBy: clientStoryFile.fileUploadedBy,
          createdUser: clientStoryFile.createdUser,
        },
        RemoveClientStoryFileId: removeClientStoryFileId ? removeClientStoryFileId : 0,
        BusinessAdvisor: businessAdvisor,
        IsBANotApplicable: isNotApplicable,
        ClientApproverRole: clientApprover.otherSpecify === "" ? clientApprover.clientApproverRole.label : clientApprover.otherSpecify,
        clientAlignmentFileAttachment: {
          deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
          createdUser: clientAlignmentFiles.createdUser,
          createdTS: clientAlignmentFiles.uploadedDateTime,
          updatedUser: clientAlignmentFiles.fileUploadedBy,
          updatedTS: clientAlignmentFiles.fileUploadedDate,
          removeClientAlignmentFileIds: removeClientAlignmentFileId,
          isApproved: false
        },
        ClientStory: {
          ClientProjectID: basicDetails.projectID,
          ProjectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
          KeyBusinessChallenges: keyBusinessChallenges,
          InnovationEnablers: innovationEnablers,
          BusinessOutcomes: businessOutcomes,
          CreatedUser: enterpriseID,
        },
        ISClientStoryEdited: ISClientStoryEdited(clientStory),
        isComputedOnce: selectedProject.projectDetails.isComputedOnce,
      };
    }

    const formData = new FormData();
    const pptData = {
      projectName: basicDetails.projectName,
      businessAdvisor,
      operationsAccountLead: basicDetails.oaLead,
      cxlLead: basicDetails.cxLead,
      projectCreator: basicDetails.projectCreator,
      keyBusinessChallenges,
      innovationEnablers,
      businessOutcomes,
      projectDeliveredBy: basicDetails.projectDeliveredBy
    }

    const ppt = await generateCSPPT(pptData);
    formData.append("Files", ppt);

    if (HasValue(clientAlignmentFiles.file)) {
      clientAlignmentFiles.file.map((x) => {
        formData.append('Files', x);
      });
    }
    // if (HasValue(clientStoryFile.file[0])) formData.append('Files', clientStoryFile.file[0]);
    formData.append('objSendApproval', JSON.stringify(paramData));
    let subMenuId = GetSubMenuId("Project Data Entry");
    const data = await HttpPostWithFileString(
      `ProjectDataEntry/SaveAsDraftProjectDataEntry?SubMenuID=${subMenuId}`,
      formData
    );
    if (data === 'success') {
      history.push('/DataIngestion');
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'success',
          message: 'Project data Entry is saved as draft successfully',
        },
      });
      await LogRVDprojectAction({
        clientMasterID: selectedClient.value,
        clientProgramID: basicDetails.programId,
        clientProjectID: basicDetails.projectID,
        clientProjectStatus: PROJ_DATA_ENTRY_REC,
        module: PDE,
      });

      dispatch({
        //clear
        type: ON_TILE_SELECT_PROJECT,
        basicDetails: {},
        projectDimensionsList: [],
        buttonState: {
          isSelectProjectDisabled: false,
          isCommentDisabled: false,
          isSaveAsDraftDisabled: false,
        },
        isWriteAccess: false,
        isProjectSelected: false,
        mainSelectedProject: selectedProject,
        isDraft: false,
        isExistingDataEntry: false,
      });

      dispatch({
        type: SET_VIRUS_FOUND,
        virusFoundError: false,
      });
      dispatch({
        type: SET_CLIENT_STORY_FILE,
        clientStoryFile: {
          id: 0,
          file: [],
          fileUploadedBy: '',
          createdUser: '',
          fileUploadedDate: '',
        },
      });
      dispatch({
        type: SET_CLIENT_ALIGNMENT_FILE,
        clientAlignmentFiles: {
          id: 0,
          file: [],
          fileUploadedBy: '',
          createdUser: '',
          fileUploadedDate: '',
        },
      });
      dispatch({
        type: REMOVE_CLIENT_STORY_FILE,
        removeClientStoryFileId: 0,
      });
      dispatch({
        type: REMOVE_CLIENT_ALIGNMENT_FILE,
        removeClientAlignmentFileId: []
      })
    } else {
      let postData = JSON.parse(data)
      if (HasProperty(postData, 'Code') && postData.Code === "2") {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'error',
            message: postData.Message,
          },
        });
      }
      else {
        dispatch({
          type: SET_VIRUS_FOUND,
          virusFoundError: true,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const handleResetAll = () => async (dispatch, state) => {
  try {
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let isComputedOnce = state().ConfigureProgramData.mainSelectedProject.projectDetails.isComputedOnce;

    projectDimensionsList.forEach((dItem) => {
      dItem.subDimensionList.forEach((sDItem) => {
        sDItem.boiList.forEach((bItem) => {
          bItem.boiRealizedValue = '';
          bItem.boiValueDiff = '';
          if (dItem.dimensionID === 1 && bItem.boiUOM.toLowerCase() === 'usd') {
            bItem.clientValueIdentified = '';
            bItem.clientValueIdentifiedTemp.value = '';
            bItem.clientValueIdentifiedTemp.isInvalid = false;
            bItem.clientValueIdentifiedTemp.invalidMsg = '';
            bItem.clientValueIdentifiedTemp.disabled = true;
          }

          bItem.parameterList.forEach((pItem) => {
            pItem.parameterValue = '';
          });

          let isFinUSD = dItem.dimensionID === 1 && bItem.boiUOM.toLowerCase() === "usd";

          let cumulativeClientValueIdentified = isComputedOnce && IsValidStr(bItem.clientValueIdentifiedTemp.value) ?
            Number(bItem.clientValueIdentifiedTemp.value) : 0; //CVI//

          let cumulativeBOITarget = isComputedOnce && IsValidStr(bItem.boiApprovedRealizedValue) ?
            Number(bItem.boiApprovedRealizedValue) : 0; //TV unused//

          let cumulativeBOIApprovedRealizedValue = isComputedOnce &&
            !IsArrayEmpty(bItem.parameterList) &&
            IsValidStr(bItem.parameterList[0].parameterValue) ?
            Number(bItem.parameterList[0].parameterValue)
            : 0; //VIC approved once//

          let cumulativeBOITargetApprovedValue = isComputedOnce && IsValidStr(bItem.boiApprovedRealizedValue) ?
            Number(bItem.boiApprovedRealizedValue) : 0; //TV used

          if (isFinUSD && !IsArrayEmpty(bItem.boiDeliveredValueList)) {
            bItem.boiDeliveredValueList = bItem.boiDeliveredValueList
              .map((e) => {
                e.reportingQuarters.map((e2) => {
                  e2.reportingMonth.map((e3) => {
                    cumulativeClientValueIdentified += e3.clientValueIdentified; //
                    cumulativeBOITarget += e3.boiTargetApprovedValue; //
                    cumulativeBOIApprovedRealizedValue += e3.boiApprovedRealizedValue; //
                    cumulativeBOITargetApprovedValue += e3.boiTargetApprovedValue; //

                    return { ...e3 }
                  })
                  return { ...e2 };
                });
                return { ...e };
              })

            bItem.finUSDData.cumulativeBOIApprovedRealizedValue = cumulativeBOIApprovedRealizedValue;
            bItem.finUSDData.cumulativeBOITarget = cumulativeBOITarget;
            bItem.finUSDData.cumulativeBOITargetApprovedValue = cumulativeBOITargetApprovedValue;
            bItem.finUSDData.cumulativeClientValueIdentified = cumulativeClientValueIdentified;
          }

        });
      });
    });

    dispatch({
      type: ON_CHANGE_PARAM,
      projectDimensionsList: projectDimensionsList,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setRVDSelectedTabs = (selectedTabs) => async (dispatch, state) => {
  try {
    dispatch({
      type: ON_CHANGE_TAB_FINANCIAL,
      rvdSelectedTabs: selectedTabs,
    });
  } catch (err) {
    console.log(err);
  }
};

export const handleComputeAll = (isFromCompute, onExpandCollaps) => async (dispatch, state) => {
  try {
    const rvdSelectedTabs = state().ConfigureProgramData.rvdSelectedTabs;
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    let userID = state().userInformation.userName;
    const isExistingDataEntry = state().ConfigureProgramData.isExistingDataEntry;
    let body = [];
    let showCumulativeModal = false;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let userInfo = state().userInformation;
    let isSuperAdmin = userInfo.isSuperAdmin;
    const rvdSelectedTabsTemp = {
      capital: null,
      opex: null,
      revenue: null,
    };
    let isComputedOnce = mainSelectedProject.projectDetails.isComputedOnce;
    projectDimensionsList.map((dItem, dIndex) => {
      dItem.subDimensionList.map((sDItem, sDIndex) => {
        let hasInvalidUSD = false;
        let hasInvalidNotUSD = false;
        sDItem.boiList.map((bItem, bIndex) => {
          let paramList = [];
          let isDenom = bItem.parameterList.length >= 2 ? true : false;
          let isZeroValid = isDenom ? IsNumZeroesOnly(bItem.parameterList[1].parameterValue) : true;
          let cviTemp = bItem.clientValueIdentifiedTemp.value;
          let isZeroValidCVI = IsNumZeroesOnly(cviTemp);

          //VALIDATION
          if (bItem.boiTargetApprovedValue === null && bItem.isAgnosticBOI) {
            bItem['isZeroTarget'] = true;
            bItem['zeroTargetMessage'] = 'Please update targets at Program level';
          }
          if (
            bItem.parameterList.some((e) => {
              return !IsValidStr(e.parameterValue) || !IsValidNum(e.parameterValue) || !isZeroValid;
            }) ||
            (dItem.dimensionID === 1 &&
              ['usd', 'dollar', '$'].indexOf(bItem.boiUOM.toLowerCase()) > -1 &&
              (!IsValidStr(cviTemp) || !IsValidNum(cviTemp) || !isZeroValidCVI))
          ) {
            if (
              dItem.dimensionID === 1 &&
              ['usd', 'dollar', '$'].indexOf(bItem.boiUOM.toLowerCase()) > -1
            ) {
              let cviMsg =
                !IsValidStr(cviTemp) || !isZeroValidCVI
                  ? 'Missing Required Field'
                  : !IsValidNum(cviTemp)
                    ? 'Invalid Input'
                    : 'Missing Required Field';
              hasInvalidUSD = true;
              bItem.clientValueIdentifiedTemp.invalidMsg = cviMsg;
              bItem.clientValueIdentifiedTemp.isInvalid = true;
            } else {
              hasInvalidNotUSD = true;
            }

            bItem.parameterList = bItem.parameterList.map((item, index) => {
              let tItem = item;

              if (index === 1) {
                if (
                  !IsValidStr(tItem.parameterValue) ||
                  !IsValidNum(tItem.parameterValue) ||
                  !isZeroValid
                )
                  tItem['isInvalid'] = true;
                tItem['invalidMsg'] =
                  tItem.parameterValue === '' || tItem.parameterValue === null
                    ? 'Missing Required Field'
                    : !IsValidNum(tItem.parameterValue) || !isZeroValid
                      ? 'Invalid Input'
                      : 'Missing Required Field';
              } else {
                if (!IsValidStr(tItem.parameterValue) || !IsValidNum(tItem.parameterValue))
                  tItem['isInvalid'] = true;
                tItem['invalidMsg'] =
                  tItem.parameterValue === '' || tItem.parameterValue === null
                    ? 'Missing Required Field'
                    : !IsValidNum(tItem.parameterValue)
                      ? 'Invalid Input'
                      : 'Missing Required Field';
              }

              return tItem;
            });
            if (onExpandCollaps &&
              bItem.parameterList &&
              Array.isArray(bItem.parameterList) &&
              bItem.parameterList.length &&
              bItem.parameterList.some((obj) => obj.isInvalid)
            ) {
              const obj = { currentTarget: { id: "expandAll" } }
              onExpandCollaps(obj)
            }
            bItem.hasChange = false;
            projectDimensionsList[dIndex].subDimensionList[sDIndex].boiList[bIndex] = bItem;
            dispatch({
              type: ON_CHANGE_PARAM,
              projectDimensionsList: projectDimensionsList,
            });
            return;
          }

          // update client identified value
          if (!bItem.clientValueIdentifiedTemp.isInvalid) {
            bItem.clientValueIdentified = bItem.clientValueIdentifiedTemp.value;
          }

          //set Data
          paramList = bItem.parameterList.map((item) => {
            return {
              parameterMasterID: item.parameterMasterID,
              parameterName: item.parameterName,
              parameterValue: item.parameterValue,
              parameterUOM: item.parameterUOM,
              projectParameterValueID: item.parameterValueID,
            };
          });

          let year = mainSelectedProject.projectDetails.lastReportingDate !== null ? new Date(mainSelectedProject.projectDetails.lastReportingDate) : new Date();


          body.push({
            clientProjectID: mainSelectedProject.projectDetails.clientProjectID,
            clientProjectTargetID: bItem.boiClientProjectTargetID,
            deliveryCenterID: mainSelectedProject.projectDetails.deliveryCenterID,
            clientProgramID: mainSelectedProject.programID,
            boiid: bItem.boiID,
            uom: bItem.boiUOM,
            createdBy: userID,
            projectDataEntryID: bItem.boiProjectDataEntryID,
            leadingIndicator: bItem.leadingIndicator,
            parameterList: paramList,
            reportingyear: year.getFullYear(),
          });
        });
        if (dItem.dimensionID === 1) {
          if (hasInvalidUSD) {
            rvdSelectedTabsTemp[sDItem.subDimensionname.toLowerCase()] = 0;
          } else if (hasInvalidNotUSD) {
            rvdSelectedTabsTemp[sDItem.subDimensionname.toLowerCase()] = 1;
          }
        }
      });
    });
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data = await HttpPost(`ProjectDataEntry/ComputeBOIRealizedValue?SubMenuID=${subMenuId}`, body);
    if (data.status === undefined) {
      projectDimensionsList.forEach((d) => {
        d.subDimensionList.forEach((sD,index1) => {
          sD.boiList.forEach((b,index) => {
            data.forEach((res) => {

              if (b.boiClientProjectTargetID === res.ClientProjectTargetID) {
                b.boiRealizedValue =
                  res.RealizedValue.toString().split('.').length > 1
                    ? res.RealizedValue.toFixed(2)
                    : res.RealizedValue;
                let diff =
                  b.boiTargetApproved !== null
                    ? b.boiTargetApproved - res.RealizedValue
                    : b.boiTarget - res.RealizedValue;
                b.finUSDData.cumulativeBOIApprovedRealizedValue = res.RealizedValue
                b.boiValueDiff = diff.toString().split('.').length > 1 ? diff.toFixed(2) : diff;
                b.hasChange = false;
                b.parameterList = b.parameterList.map((item) => {
                  item['paramHasChange'] = item.tempHasChange;
                  delete item.hasChange;
                  delete item.isInvalid;
                  delete item.invalidMsg;
                  return item;
                });

                /* if (
                  HasValue(b.boiApprovedRealizedValue) &&
                  b.leadingIndicator.toLowerCase() === 'maximum' &&
                  b.boiApprovedRealizedValue > res.RealizedValue
                ) {
                  showCumulativeModal = true;
                  b['realizedValInvalid'] = true;
                } else */
                /* if (
                  HasValue(b.boiApprovedRealizedValue) &&
                  b.leadingIndicator.toLowerCase() === 'minimum' &&
                  b.boiApprovedRealizedValue < res.RealizedValue
                ) {
                  showCumulativeModal = true;
                  b['realizedValInvalid'] = true;
                } */
              }
            });

            let isFinUSD = d.dimensionID === 1 && b.boiUOM.toLowerCase() === "usd";
            let param = b.parameterList;
            let shouldCompute = !IsArrayEmpty(param) && param[0].parameterValue !== param[0].oldParamValue || !isComputedOnce

            let cumulativeClientValueIdentified = 0; //CVI//
            let cumulativeBOITarget = 0; //TV unused//
            let cumulativeBOIApprovedRealizedValue = 0; //VIC approved once//
            let cumulativeBOITargetApprovedValue = 0;

            if (isFinUSD && !IsArrayEmpty(b.boiDeliveredValueList) && shouldCompute) {
              cumulativeClientValueIdentified = IsValidStr(b.clientValueIdentifiedTemp.value) ?
                Number(b.clientValueIdentifiedTemp.value) : 0; //CVI//

              cumulativeBOITarget = IsValidStr(b.boiApprovedRealizedValue) ?
                Number(b.boiTargetApprovedValue) : 0; //TV unused//

              cumulativeBOIApprovedRealizedValue = IsValidStr(param[0].parameterValue) ?
                Number(param[0].parameterValue) : 0; //VIC approved once//
              cumulativeBOITargetApprovedValue = IsValidStr(b.boiTargetApprovedValue) ?
                Number(b.boiTargetApprovedValue) : 0; //TV used
              b.boiDeliveredValueList = b.boiDeliveredValueList
                .map((e) => {
                  e.reportingQuarters.map((e2) => {
                    e2.reportingMonth.map((e3) => {
                      cumulativeClientValueIdentified += e3.clientValueIdentified; //CVI
                      cumulativeBOITarget += e3.boiTargetApprovedValue; //Target
                      cumulativeBOIApprovedRealizedValue += e3.boiApprovedRealizedValue;// VIC
                      cumulativeBOITargetApprovedValue += e3.boiTargetApprovedValue; //TV used

                      return { ...e3 }
                    })
                    return { ...e2 };
                  });
                  return { ...e };
                })


              b.finUSDData.cumulativeBOIApprovedRealizedValue = cumulativeBOIApprovedRealizedValue
              isSuperAdmin && basicDetails.overallStatus == 'Approved' && (
                b.finUSDData.cumulativeBOIApprovedRealizedValue = cumulativeBOIApprovedRealizedValue - data[index>index1?index:index1].PreviouslyApprovedBOIRealizedValue);
             
              //VIC
              b.finUSDData.cumulativeBOITarget = cumulativeBOITarget; //TV unused OK
              b.finUSDData.cumulativeBOITargetApprovedValue = cumulativeBOITargetApprovedValue; //TV always approved used 
              b.finUSDData.cumulativeClientValueIdentified = cumulativeClientValueIdentified; //CVI
            }
          });
        });
      });

      dispatch({
        type: ON_CHANGE_PARAM,
        projectDimensionsList: projectDimensionsList,
      });

      if (isFromCompute) {
        dispatch({
          type: SHOW_CUMULATIVE_MODAL,
          showCumulativeModal: showCumulativeModal,
        });
      }

      mainSelectedProject.projectDetails.isComputedOnce = true;

      dispatch({
        type: SELECTED_PROGRAM_PROJECT,
        mainSelectedProject: mainSelectedProject,
      });

    }

    Object.keys(rvdSelectedTabsTemp).forEach((key) => {
      if (rvdSelectedTabsTemp[key] === null) rvdSelectedTabsTemp[key] = rvdSelectedTabs[key];
    });

    dispatch({
      type: ON_CHANGE_TAB_FINANCIAL,
      rvdSelectedTabs: rvdSelectedTabsTemp,
    });



  } catch (err) {
    console.log(err);
  }
};

export const clearMainSelectedDetails = (event, key) => async (dispatch, state) => {
  try {
    dispatch({
      //clear
      type: ON_TILE_SELECT_PROJECT,
      basicDetails: {},
      projectDimensionsList: [],
      buttonState: {
        isSelectProjectDisabled: false,
        isCommentDisabled: false,
        isSaveAsDraftDisabled: false,
      },
      isWriteAccess: false,
      isProjectSelected: false,
      mainSelectedProject: {
        creationYear: '',
        programID: '',
        programName: '',
        programStatus: '',
        projectDetails: {
          ID: '',
          projectID: '',
          assignedTo: '',
          clientProjectID: '',
          clientProjectName: '',
          deliveryCenterID: '',
          deliveryCenterName: '',
          status: '',
          assignedToRoleId: '',
          createdBy: '',
          creationDate: '',
          deliveryLocations: [],
          reportedBy: '',
          reportedMonth: '',
          statusID: '',
          lastReportingDate: '',
          pdeModifiedDate: '',
        },
      },
      isDraft: false,
      isExistingDataEntry: false,
    });
  } catch (err) {
    console.log(err);
  }
};

export const submitFinalApproval = (form, isSuccess) => async (dispatch, state) => {
  try {
    const fields = state().ConfigureProgramData;
    const clientStory = fields.clientStory;
    const selectedProject = fields.mainSelectedProject;
    let businessAdvisor = fields.businessAdvisorList
      .filter((ba) => ba.value !== 0)
      .map((x) => x.text)
      .join(';');
    let isNotApplicable =
      fields.businessAdvisorList.find((ba) => ba.value === 0) !== undefined;

    const data = {
      clientProgramID: fields.mainSelectedProject.programID, //
      clientProjectID: fields.mainSelectedProject.projectDetails.clientProjectID, //
      projectDeliveryCenterID: fields.basicDetails.projectDeliveryCenterID, //
      updatedUser: state().userInformation.emailAddress, //
      clientID: state().SharedData.selectedClient.value, //
      clientName: state().SharedData.selectedClient.label, //
      programName: fields.mainSelectedProject.programName, //
      projectName: fields.mainSelectedProject.projectDetails.clientProjectName, //
      deliverCenterName: fields.mainSelectedProject.projectDetails.deliveryCenterName, //
      DeliveryCenterID: fields.mainSelectedProject.projectDetails.deliveryCenterID, //
      projectDataEntryList: [], //
      lastReportingDate: selectedProject.projectDetails.creationDate, //
      pdeModifiedDate: selectedProject.projectDetails.pdeModifiedDate, //
      comment: form.comment,
      businessAdvisor: businessAdvisor,
      IsBANotApplicable: isNotApplicable
    };

    if (Object.keys(fields.projectDimensionsList).length > 0) {
      fields.projectDimensionsList.map((item) => {
        if (Object.keys(item.subDimensionList).length > 0) {
          item.subDimensionList.map((subItem) => {
            (Object.keys(subItem.boiList).length > 0 ? subItem.boiList : []).map((boi) => {

              let isFinUSD = item.dimensionID === 1 && boi.boiUOM.toLowerCase() === "usd";

              let currentYearVCI = !IsArrayEmpty(boi.boiDeliveredValueList) ?
                !IsArrayEmpty(boi.boiDeliveredValueList.filter((a) => a.reportingYear === boi.finUSDData.latestDate.year)) ?
                  boi.boiDeliveredValueList.filter((a) => a.reportingYear === boi.finUSDData.latestDate.year)[0].totalPerYrBOIApprovedRealizedValue
                  + (!IsArrayEmpty(boi.parameterList) ? boi.parameterList[0].parameterValue : 0)
                  : (!IsArrayEmpty(boi.parameterList) ? boi.parameterList[0].parameterValue : 0)
                : 0;

              let cumulativeValue = isFinUSD ? IsArrayEmpty(boi.boiDeliveredValueList) ?
                !IsArrayEmpty(boi.parameterList) ? boi.parameterList[0].parameterValue : 0 :
                currentYearVCI : 0;

              data.projectDataEntryList.push({
                projectDataEntryID: boi.boiProjectDataEntryID, //
                projectDeliveryCenterID: fields.basicDetails.projectDeliveryCenterID, //
                clientProjectTargetID: boi.boiClientProjectTargetID, //
                boiStatus: boi.boiStatus, //
                boiReason: boi.boiReason, //
                boiRealizedValue: boi.boiRealizedValue, //
                clientValueIdentified:
                  boi.clientValueIdentified === null ? 0 : boi.clientValueIdentified,
                boiValueDifference: boi.boiValueDiff, //
                createdUser: state().userInformation.userName, //
                boiTarget: boi.boiTarget, //
                boiName: boi.boiName, //x
                operationsAccountLead: fields.mainSelectedProject.projectDetails.assignedTo,
                requestForApprovalDate: null,
                CumulativeValue: cumulativeValue,
              });
            });
          });
        }
      });
    }

    let dataForm = new FormData();
    let subMenuId = GetSubMenuId("Project Data Entry")
    dataForm.append('ProjectDataEntrySendForFinalApproval', JSON.stringify(data));
    const result = await HttpPostText(`ReportValueDelivered/AddEditProjectFinalApproval?SubMenuID=${subMenuId}`, data);

    if (result === 'success') {
      if (ISClientStoryEdited(clientStory)) {
        const result = await handleUpdateClientStory(undefined, true)(dispatch, state);
        if (result) {
          dispatch({
            type: SET_SHOULD_SHOW_TOASTER_NOTIF,
            toasterParam: {
              show: true,
              type: 'success',
              message: 'Submitted successfully.',
            },
          });
          dispatch(
            setEmailError({
              show: false,
              message: '',
            })
          );
          isSuccess(true);
        } else {
          dispatch({
            type: SET_SHOULD_SHOW_TOASTER_NOTIF,
            toasterParam: {
              show: true,
              type: 'error',
              message: 'Something went wrong.',
            },
          });
          isSuccess(false);
        }
      } else {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'success',
            message: 'Submitted successfully.',
          },
        });
        dispatch(
          setEmailError({
            show: false,
            message: '',
          })
        );
        isSuccess(true);
      }
    } else if (data === 'Virus Found!') {
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'error',
          message: 'Invalid File.',
        },
      });
      isSuccess(false);
    } else {
      let postData = JSON.parse(result)
      if (HasProperty(postData, 'Code') && postData.Code === "2") {
        dispatch(
          setEmailError({
            show: true,
            message: postData.Message,
          })
        );
      } else {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'error',
            message: 'Something went wrong.',
          },
        });
      }
      isSuccess(false);
    }
  } catch (err) {
    console.log(err);
  }
};

let getMappedStakeHolders_Disable = false;
export const getMappedStakeHolders = (programId, module) => async (dispatch, state) => {
  try {
    if (!getMappedStakeHolders_Disable) {
      getMappedStakeHolders_Disable = true;
      const clientId = state().SharedData.selectedClient.value;
      let token = await Utilities.getToken();
      let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : ""

      await fetch(
        Utilities.getApiHttps() +
        'ReportValueDelivered/GetMappedStakeholders?clientID=' +
        clientId +
        '&programID=' +
        programId +
        (IsValidStr(module) ? ('&SubMenuID=' + subMenuId) : ''),
        {
          method: 'GET',
          headers: {
            'Cache-Control':'no-store',
            Pragma: 'no-cache',
            Expires: 0,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          getMappedStakeHolders_Disable = false;
          if (Object.keys(data).length > 0) {
            dispatch({
              type: MAPPED_STAKE_HOLDERS,
              stakeHolders: data,
            });
          }
        });
    }
  } catch (err) {
    getMappedStakeHolders_Disable = false;
    console.log(err);
  }
};

export const saveRejectionReason =
  (status, reason, indexes, shouldRetainComment) => async (dispatch, state) => {
    try {
      let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
      let boiList =
        state().ConfigureProgramData.projectDimensionsList[indexes.dimensionIndex].subDimensionList[
          indexes.subDimensionIndex
        ].boiList[indexes.boiIndex];

      boiList.boiReason = shouldRetainComment ? boiList.boiReason : reason;
      boiList.boiStatus = status;

      dispatch({
        //clear
        type: ON_CHANGE_PARAM,
        projectDimensionsList: projectDimensionsList,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const validateRejectionComment = () => async (dispatch, state) => {
  try {
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let result = false;

    projectDimensionsList.forEach((dItem) => {
      dItem.subDimensionList.forEach((sdItem) => {
        sdItem.boiList.forEach((boiItem) => {
          if (boiItem.boiStatus === 'Rejected' && !IsValidStr(boiItem.boiReason)) {
            result = true;
          }
        });
      });
    });

    return result;
  } catch (err) {
    console.log(err);
  }
};

export const handleSubmitRejection = (clientApproverRole) => async (dispatch, state) => {
  try {
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let stakeHolders = state().ConfigureProgramData.stakeHolders;
    let userID = state().userInformation.userID;
    let userName = state().userInformation.userName;
    let selectedClient = state().SharedData.selectedClient;
    let projectEntryList = [];
    let userInfo = state().userInformation;
    let cxllead = stakeHolders.find((element) => element.ProfileId == 7).Stakeholder;
    let currentRoleID = state().userInformation.isSuperAdmin
      ? state().ConfigureProgramData.mainSelectedProject.projectDetails.assignedToRoleId
      : state().userInformation.currentUserRoleID;

    const creationDate = selectedProject.projectDetails.creationDate;
    const lastReportingDate = selectedProject.projectDetails.creationDate;
    let businessAdvisorList = state().ConfigureProgramData.businessAdvisorList
    const isSuperAdmin = state().userInformation.isSuperAdmin;
    let businessAdvisor = businessAdvisorList
      .filter((ba) => ba.value !== 0)
      .map((x) => x.text)
      .join(';');
    let isNotApplicable = businessAdvisorList.find((ba) => ba.value === 0) !== undefined;

    //set data
    projectDimensionsList.map((dItem, dIndex) => {
      dItem.subDimensionList.map((sdItem, sdIndex) => {
        sdItem.boiList.map((boiItem, boiIndex) => {
          projectEntryList.push({
            projectDataEntryID: boiItem.boiProjectDataEntryID,
            projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
            clientProjectTargetID: boiItem.boiClientProjectTargetID,
            boiName: boiItem.boiName,
            boiTarget: boiItem.boiTarget,
            boiRealizedValue: boiItem.boiRealizedValue,
            clientValueIdentified:
              [null, ''].indexOf(boiItem.clientValueIdentified) > -1
                ? 0
                : boiItem.clientValueIdentified,
            boiValueDifference:
              boiItem.boiValueDiff !== '' ? parseFloat(boiItem.boiValueDiff) : boiItem.boiValueDiff,
            boiStatus: boiItem.boiStatus,
            boiReason: boiItem.boiReason,
            createdBy: userID,
          });

        });
      });
    });
    let paramData = {
      clientID: selectedClient.value,
      clientName: selectedClient.label,
      programName: selectedProject.programName,
      projectName: selectedProject.projectDetails.clientProjectName,
      deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
      clientProgramID: selectedProject.programID,
      clientProjectID: selectedProject.projectDetails.clientProjectID,
      deliveredBy: basicDetails.deliveredBy,
      projectLead: cxllead,
      projectDeliveryCenterID: basicDetails.projectDeliveryCenterID, //primary key
      deliveryCenterID: selectedProject.projectDetails.deliveryCenterID, //project delivery center
      mailReceiverUserID: receiverDetails.userID,
      mailReceiverEmailId: receiverDetails.eid,
      mailReceiverRole: receiverDetails.role,
      comment: receiverDetails.comment,
      assignedTo: receiverDetails.eid,
      assignedToRoleId: receiverDetails.roleID,
      createdBy: userName,
      isSendingApproval: true,
      rejectedByRoleId: currentRoleID,
      dataSubmitter: basicDetails.dataSubmitter,
      projectDataEntryList: projectEntryList,
      startReportingDate: creationDate,
      lastReportingDate: lastReportingDate,
      loggedInUserRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID,
      isEditingModebyPPCUser: false,
      BusinessAdvisor: businessAdvisor,
      IsBANotApplicable: isNotApplicable,
      ClientApproverRole: clientApproverRole.otherSpecify === "" ? clientApproverRole.clientApproverRole.label : clientApproverRole.otherSpecify
    };
    let subMenuId = GetSubMenuId("Project Data Entry");
    const data = await HttpPostText(`ProjectDataEntry/SubmitRejection?SubMenuID=${subMenuId}`, paramData);
    if (data === 'success') {
      history.push('/DataIngestion');
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'success',
          message: 'Notification sent successfully!',
        },
      });
      await LogRVDprojectAction({
        clientMasterID: selectedClient.value,
        clientProgramID: basicDetails.programId,
        clientProjectID: basicDetails.projectID,
        clientProjectStatus: PROJ_DATA_ENTRY_REC,
        module: PDE,
      });

      dispatch({
        //clear
        type: ON_TILE_SELECT_PROJECT,
        basicDetails: {},
        projectDimensionsList: [],
        buttonState: {
          isSelectProjectDisabled: false,
          isCommentDisabled: false,
          isSaveAsDraftDisabled: false,
        },
        isWriteAccess: false,
        isProjectSelected: false,
        mainSelectedProject: selectedProject,
        isDraft: false,
        isExistingDataEntry: false,
      });
      dispatch(
        setEmailError({
          show: false,
          message: '',
        })
      );
    } else {
      let Postdata = JSON.parse(data)
      if (HasProperty(Postdata, 'Code') && Postdata.Code === "2") {
        dispatch(
          setEmailError({
            show: true,
            message: Postdata.Message,
          })
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const setDefaultRadioValue = (status, indexes) => async (dispatch, state) => {
  try {
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let boiList =
      state().ConfigureProgramData.projectDimensionsList[indexes.dimensionIndex].subDimensionList[
        indexes.subDimensionIndex
      ].boiList[indexes.boiIndex];

    boiList.boiStatus = status;

    dispatch({
      //clear
      type: ON_CHANGE_PARAM,
      projectDimensionsList: projectDimensionsList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const DisableProgConfigUIElements = (elements) => async (dispatch, state) => {
  dispatch({
    type: DIS_UI_ELEMENTS,
    configProgUIElems: elements,
  });
};

export const getSRDetails = () => async (dispatch, state) => {
  //Submit Rejection
  try {
    let projectID = state().ConfigureProgramData.mainSelectedProject.projectDetails.clientProjectID;
    let currentRoleID = state().userInformation.isSuperAdmin
      ? state().ConfigureProgramData.mainSelectedProject.projectDetails.assignedToRoleId
      : state().userInformation.currentUserRoleID;
    let receiverDetails = {};
    let projectDelivCenterID = state().ConfigureProgramData.basicDetails.projectDeliveryCenterID;
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data = await HttpGet(
      `ProjectDataEntry/GetRejectedReceiverDetail?roleID=${currentRoleID}&clientProjectID=${projectID}&projectDeliveryCenterID=${projectDelivCenterID}&SubMenuID=${subMenuId}`
    );

    if (Object.keys(data).length > 0 && HasProperty(data, 'MailReceiverUserId')) {
      if (data.MailReceiverUserId !== null && data.RejectedByEmailId !== null) {
        receiverDetails.userID = data.MailReceiverUserId;
        receiverDetails.eid = data.MailReceiverEmailId;
        receiverDetails.role = data.MailReceiverRole;
        receiverDetails.roleID = data.MailReceiverRoleId;
        receiverDetails.comment = '';
        receiverDetails.rejectedBy = data.RejectedByEmailId;
        receiverDetails.dropdownVal = [{
          value: data.MailReceiverUserId === null ? 0 : data.MailReceiverUserId,
          label: data.MailReceiverEmailId,
          text: data.MailReceiverEmailId,
        }];
        receiverDetails.dropdownList = [{
          value: data.MailReceiverUserId === null ? 0 : data.MailReceiverUserId,
          label: data.MailReceiverEmailId,
          text: data.MailReceiverEmailId,
        }]
      } else {
        receiverDetails.userID = data.MailReceiverUserId === null ? '' : data.MailReceiverUserId;
        receiverDetails.eid = data.MailReceiverEmailId;
        receiverDetails.role = data.MailReceiverRole;
        receiverDetails.roleID = data.MailReceiverRoleId;
        receiverDetails.comment = '';
        receiverDetails.rejectedBy = data.RejectedByEmailId === null ? '' : data.RejectedByEmailId;
        receiverDetails.dropdownVal = [{
          value: data.MailReceiverUserId === null ? 0 : data.MailReceiverUserId,
          label: data.MailReceiverEmailId,
          text: data.MailReceiverEmailId,
        }];
        receiverDetails.dropdownList = [{
          value: data.MailReceiverUserId === null ? 0 : data.MailReceiverUserId,
          label: data.MailReceiverEmailId,
          text: data.MailReceiverEmailId,
        }]
      }
      dispatch({
        type: ON_CLICK_RDV_SFA,
        receiverDetails: receiverDetails,
      });
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUpdatedBOI = (program, project) => async (dispatch, state) => {
  try {
    let programId = program.programID;
    let projectID = project.clientProjectID;
    let deliveryCenterID = project.deliveryCenterID;
    let dimensionList = [];
    let oldDimensionList = state().ConfigureProgramData.projectDimensionsList;
    let projectDetails = state().ConfigureProgramData.mainSelectedProject.projectDetails;

    let subMenuId = GetSubMenuId("Project Data Entry");
    let result = await HttpGet(
      `ProjectDataEntry/GetProjectData?programId=${programId}&projectID=${projectID}&deliveryCenterID=${deliveryCenterID}&isRefresh=true&SubMenuID=${subMenuId}`
    );
    let data = [];
    let projectDimensionsList = [];
    let isSuperAdmin = state().userInformation.isSuperAdmin;
    let tz = getUserTimeZone();
    let isNegative = tz.includes('-');
    tz = tz.replace('-', '').replace('+', '');

    let isExisting = await dispatch(isProfileExisting([8]));
    let dataClientAlignment = await HttpGet(
      `ReportValueDelivered/RVDClientAcknowledgementHistory?clientProjectID=${project.clientProjectID}&projectDeliveryCenterID=${result.ProjectDeliveryCenterID}&isNegative=${isNegative}&UTCOffset=${tz}&SubMenuID=${subMenuId}`
    );

    let isComputedOnce = result.IsComputedOnce
    let mainSelectedProject = {
      creationYear: program.creationYear,
      programID: program.programID,
      programName: program.programName,
      programStatus: result.Status,
      projectDetails: {
        ID: project.ID,
        projectID: result.ProjectID,
        assignedTo: project.assignedTo, //
        assignedToList: projectDetails.assignedToList,
        assignedToRoleId: result.AssignedToRoleId, //current assigned
        clientProjectID: project.clientProjectID,
        clientProjectName: project.clientProjectName,
        deliveryCenterID: project.deliveryCenterID,
        deliveryCenterName: project.deliveryCenterName,
        status: result.ProjectOverallStatus,
        createdBy: project.createdBy,
        creationDate: result.StartReportingDate,
        deliveryLocations: project.deliveryLocations,
        reportedBy: project.reportedBy,
        reportedMonth: project.reportedMonth,
        statusID: project.statusID,
        lastReportingDate: projectDetails.lastReportingDate, //x
        pdeModifiedDate: projectDetails.pdeModifiedDate, //

        cxlLeadUserID: projectDetails.cxlLeadUserID, //not being used on API as per jemar
        cxLead: projectDetails.cxLead, //selectedCXL not data.CXLLead, flexib
        cxLeadList: projectDetails.cxLeadList,
        cxLeadString: projectDetails.oaLeadString,
        oaLead: projectDetails.oaLead, //selectedOAL not data.OperationsAccountLead, flexib
        oaLeadList: projectDetails.oaLeadList,
        oaLeadString: projectDetails.oaLeadString,
        //
        isNewBoiAddedOnPPCModule: result.IsNewBoiAddedOnPPCModule,
        isTargetValueUpdatedOnPPC: result.IsTargetValueUpdatedOnPPC,
        recentlyUploadedClientStory: result.RecentlyUploadedClientStory,
        recentlyUploadedClientAlignment: dataClientAlignment,
        projectDeliveryCenterID: result.ProjectDeliveryCenterID,
        isFirstSubmission: result.IsFirstSubmission,
        isComputedOnce: isComputedOnce, //project.isComputedOnce
        clientApproverRole: result.ClientApproverRole,
        isOMIDContractIDExisting: result.isOMIDContractIDExisting === 1
      },
    };

    result.DimensionList.map((item) => {
      if (oldDimensionList.some((oldItem) => oldItem.dimensionID === item.CoreDimensionID)) {
        data.unshift(item);
      } else {
        data.push(item);
      }
    });

    if (Object.keys(data).length > 0 && Object.keys(oldDimensionList).length > 0) {
      data.map((dimensionItem, dimensionIndex) => {
        oldDimensionList.map((oldDimensionItem, oldDimensionIndex) => {
          if (dimensionList.length > 0) {
            if (!dimensionList.some((item) => item.dimensionID === dimensionItem.CoreDimensionID)) {
              dimensionList.push({
                dimensionName: dimensionItem.CoreDimensionName,
                dimensionID: dimensionItem.CoreDimensionID,
                subDimensionList: [],
              });
            }
          } else {
            dimensionList.push({
              dimensionName: dimensionItem.CoreDimensionName,
              dimensionID: dimensionItem.CoreDimensionID,
              subDimensionList: [],
            });
          }
          dimensionItem.SubDimensionList.map((subDimensionItem, subDimensionIndex) => {
            oldDimensionItem.subDimensionList.map((oldSubDimensionItem, oldSubDimensionIndex) => {
              if (dimensionList[dimensionIndex].subDimensionList.length > 0) {
                if (
                  !dimensionList[dimensionIndex].subDimensionList.some(
                    (item) => item.subDimensionID === subDimensionItem.SubDimensionID
                  )
                ) {
                  dimensionList[dimensionIndex].subDimensionList.push({
                    subDimensionname: subDimensionItem.SubDimensionName,
                    subDimensionID: subDimensionItem.SubDimensionID,
                    boiList: [],
                  });
                }
              } else {
                dimensionList[dimensionIndex].subDimensionList.push({
                  subDimensionname: subDimensionItem.SubDimensionName,
                  subDimensionID: subDimensionItem.SubDimensionID,
                  boiList: [],
                });
              }
              //boi mapping start
              subDimensionItem.BOIList.map((boiItem, boiIndex) => {
                let currBOI = IsValid(oldSubDimensionItem.boiList.filter((a) => a.boiID === boiItem.BOIID)) ?
                  (oldSubDimensionItem.boiList.filter((a) => a.boiID === boiItem.BOIID)) : [];

                oldSubDimensionItem.boiList.map((oldBoiItem, oldBoiIndex) => {
                  //rvd num view start
                  let boiDeliveredValueList = [];
                  let isFinUSD = dimensionItem.CoreDimensionID === 1 && boiItem.UOM.toLowerCase() === "usd";
                  let date = new Date();
                  let latestDate = {
                    year: [8, 9, 10, 11].includes(date.getMonth()) ? (date).getFullYear() + 1 : (date).getFullYear(),
                    month: GetMonthMMM(date.getMonth()),
                    quarter: GetQuarter(date.getMonth()),
                  }
                  let cumulativeClientValueIdentified = isComputedOnce && IsValidStr(boiItem.ClientValueIdentified) ?
                    Number(boiItem.ClientValueIdentified) : 0; //CVI

                  let cumulativeBOITarget = isComputedOnce && IsValidStr(boiItem.BOITargetApprovedValue) ?
                    Number(boiItem.BOITargetApprovedValue) : 0; //TV unused

                  let cumulativeBOIApprovedRealizedValue = isComputedOnce &&
                    !IsArrayEmpty(boiItem.ParameterList) &&
                    IsValidStr(boiItem.ParameterList[0].ParameterValue) ?
                    Number(boiItem.ParameterList[0].ParameterValue)
                    : 0; //VIC approved once

                  let cumulativeBOITargetApprovedValue = isComputedOnce && IsValidStr(boiItem.BOITargetApprovedValue) ?
                    Number(boiItem.BOITargetApprovedValue) : 0; //TV used

                  if (isFinUSD) {
                    boiItem.BOIDeliveredValueLists.sort((a, b) => b.ReportingYear - a.ReportingYear)
                      .map((val, yInd) => {
                        let totalPerYrClientValueIdentified = 0;
                        let totalPerYrBOITarget = 0;
                        let totalPerYrBOIApprovedRealizedValue = 0;
                        let totalPerYrBOITargetApprovedValue = 0;
                        boiDeliveredValueList.push({
                          expandedYear: yInd === 0 && val.ReportingYear === latestDate.year,
                          reportingYear: val.ReportingYear,
                          reportingQuarters: val.ReportingQuarters.sort((a, b) => parseInt(b.Quarter) - parseInt(a.Quarter))
                            .map((quart, qInd) => {
                              let totalClientValueIdentified = 0;
                              let totalBOITarget = 0;
                              let totalBOIApprovedRealizedValue = 0;
                              let totalBOITargetApprovedValue = 0;
                              let tReportingMonthList = [];
                              quart.MonthlyReportings.sort((a, b) => GetMonthIndex(b.Month) - GetMonthIndex(a.Month))
                                .map((mon) => {
                                  totalClientValueIdentified += mon.ClientValueIdentifiedHist;
                                  totalBOITarget += mon.BOITargetHist;
                                  totalBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                  totalBOITargetApprovedValue += mon.BOITargetHist;

                                  cumulativeClientValueIdentified += mon.ClientValueIdentifiedHist;
                                  cumulativeBOITarget += mon.BOITargetHist;
                                  cumulativeBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                  cumulativeBOITargetApprovedValue += mon.BOITargetHist;

                                  totalPerYrClientValueIdentified += mon.ClientValueIdentifiedHist;
                                  totalPerYrBOITarget += mon.BOITargetHist;
                                  totalPerYrBOIApprovedRealizedValue += mon.BOIRealizedValueHist;
                                  totalPerYrBOITargetApprovedValue += mon.BOITargetHist; //BOITargetApprovedValue -> convert to Hist

                                  tReportingMonthList.push({
                                    month: mon.Month,
                                    boiTarget: mon.BOITargetHist, //taget
                                    clientValueIdentified: mon.ClientValueIdentifiedHist, //cvi
                                    boiRealizedValue: mon.BOIRealizedValueHist, //vic
                                    boiApprovedRealizedValue: mon.BOIRealizedValueHist, //BOIApprovedRealizedValue -> BOIRealizedValueHist
                                    boiTargetApprovedValue: mon.BOITargetHist, //removed
                                    rvdStatus: mon.RVDStatus,
                                  })
                                })
                              return {
                                expandedQuarter: yInd === 0 && val.ReportingYear === latestDate.year,
                                quarter: quart.Quarter,
                                reportingMonth: tReportingMonthList,
                                totalClientValueIdentified: totalClientValueIdentified, //cvi
                                totalBOITarget: totalBOITarget, //target
                                totalBOIApprovedRealizedValue: totalBOIApprovedRealizedValue, //p&l approved
                                totalBOITargetApprovedValue: totalBOITargetApprovedValue, //p&l new //will not be used?
                              }
                            }),
                          totalPerYrClientValueIdentified: totalPerYrClientValueIdentified, //cvi
                          totalPerYrBOITarget: totalPerYrBOITarget, //target
                          totalPerYrBOIApprovedRealizedValue: totalPerYrBOIApprovedRealizedValue, //p&l approved
                          totalPerYrBOITargetApprovedValue: totalPerYrBOITargetApprovedValue, //p&l new //will not be used?,
                        })
                      });
                  }
                  //rvd num view end

                  if (
                    dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList
                      .length > 0
                  ) {
                    if (
                      !dimensionList[dimensionIndex].subDimensionList[
                        subDimensionIndex
                      ].boiList.some((item) => item.boiID === boiItem.BOIID)
                    ) {
                      dimensionList[dimensionIndex].subDimensionList[
                        subDimensionIndex
                      ].boiList.push({
                        //rvd num view start
                        boiDeliveredValueList: boiDeliveredValueList,  //added 0830
                        finUSDData: {
                          cumulativeClientValueIdentified: cumulativeClientValueIdentified, //cvi
                          cumulativeBOITarget: cumulativeBOITarget, //target
                          cumulativeBOIApprovedRealizedValue: cumulativeBOIApprovedRealizedValue, //p&l approved
                          cumulativeBOITargetApprovedValue: cumulativeBOITargetApprovedValue, //p&l new //will not be used?,
                          latestDate: latestDate,
                          expandedQuarter: IsValid(currBOI.expandedQuarter) ? currBOI.expandedQuarter : true,  //added 0830
                          expandedYear: IsValid(currBOI.expandedQuarter) ? currBOI.expandedYear : true,  //added 0830
                        },
                        expandedBOI: IsValid(currBOI.expandedBOI) ? currBOI.expandedBOI : true,  //added 0830
                        //rvd num view end
                        boiID: boiItem.BOIID,
                        boiName: boiItem.BOIName,
                        boiDescription: boiItem.BOIDescription,
                        // new data origin start
                        boiRealizedValue: boiItem.BOIRealizedValue,
                        // clientValueIdentifiedTemp: {
                        //   value: boiItem.ClientValueIdentified,
                        //   isInvalid: false,
                        //   invalidMsg: '',
                        //   disabled: ['', null].indexOf(boiItem.ClientValueIdentified) > -1,
                        // },
                        // clientValueIdentified: boiItem.ClientValueIdentified,

                        clientValueIdentifiedTemp: !IsArrayEmpty(currBOI) &&
                          boiItem.ClientValueIdentified !== currBOI[0].clientValueIdentified
                          ? currBOI[0].clientValueIdentifiedTemp
                          : {
                            value: boiItem.ClientValueIdentified,
                            isInvalid: false,
                            invalidMsg: '',
                            disabled: ['', null].indexOf(boiItem.ClientValueIdentified) > -1,
                          },
                        clientValueIdentified: !IsArrayEmpty(currBOI) &&
                          boiItem.ClientValueIdentified !== currBOI[0].clientValueIdentified ?
                          currBOI[0].clientValueIdentified : boiItem.ClientValueIdentified, //

                        boiTarget: boiItem.BOITarget,
                        boiTargetApproved: boiItem.BOITargetApprovedValue,
                        boiApprovedRealizedValue: boiItem.BOIApprovedRealizedValue, //
                        boiValueDiff: boiItem.BOIValueDifference,
                        boiTargetApprovedValue: boiItem.BOITargetApprovedValue,
                        // new data origin end
                        boiReason: boiItem.BOIReason,
                        boiStatus: boiItem.BOIStatus,
                        boiClientProjectTargetID: boiItem.ClientProjectTargetID,
                        boiProjectDataEntryID: boiItem.ProjectDataEntryID,
                        boiRejectionComment: boiItem.RejectionComment,
                        boiUOM: boiItem.UOM,
                        parameterList: [],
                        hasChange: false,
                        IsCustom: boiItem.IsCustom,
                        isNewValue: boiItem.IsNewValue,
                        leadingIndicator: boiItem.LeadingIndicator,
                        RVDCommentHistory: boiItem.RVDCommentHistory,
                        offeringName: boiItem.OfferingName,
                        subofferingName: boiItem.SubOfferingName,
                        boiWheelVisivility: boiItem.WheelVisibility,
                        isUpdatedOnPPCModule: boiItem.IsUpdatedOnPPCModule,
                        projectBOIWheelVisibility: boiItem.ProjectBOIWheelVisibility === 'True' ? true : false, //ok //added validation for now due to PBI 148714 dependency
                        isAgnosticBOI: boiItem.IsAgnosticBOI, //
                        projectLevelBOIStatus: boiItem.ProjectLevelBOIStatus
                          ? boiItem.ProjectLevelBOIStatus
                          : '',
                      });
                    }
                  } else {
                    dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList.push({
                      //rvd num view start
                      boiDeliveredValueList: boiDeliveredValueList,  //added 0830
                      finUSDData: {
                        cumulativeClientValueIdentified: cumulativeClientValueIdentified, //cvi
                        cumulativeBOITarget: cumulativeBOITarget, //target
                        cumulativeBOIApprovedRealizedValue: cumulativeBOIApprovedRealizedValue, //p&l approved
                        cumulativeBOITargetApprovedValue: cumulativeBOITargetApprovedValue, //p&l new //will not be used?,
                        latestDate: latestDate,
                        expandedQuarter: IsValid(currBOI.expandedQuarter) ? currBOI.expandedQuarter : true,  //added 0830
                        expandedYear: IsValid(currBOI.expandedQuarter) ? currBOI.expandedYear : true,  //added 0830
                      },
                      expandedBOI: IsValid(currBOI.expandedBOI) ? currBOI.expandedBOI : true,  //added 0830
                      //rvd num view end
                      boiID: boiItem.BOIID,
                      boiName: boiItem.BOIName,
                      boiDescription: boiItem.BOIDescription,
                      // new data origin start
                      boiRealizedValue: boiItem.BOIRealizedValue,
                      // clientValueIdentifiedTemp: {
                      //   value: boiItem.ClientValueIdentified,
                      //   isInvalid: false,
                      //   invalidMsg: '',
                      //   disabled: ['', null].indexOf(boiItem.ClientValueIdentified) > -1,
                      // },
                      clientValueIdentifiedTemp: !IsArrayEmpty(currBOI) &&
                        boiItem.ClientValueIdentified !== currBOI[0].clientValueIdentified
                        ? currBOI[0].clientValueIdentifiedTemp
                        : {
                          value: boiItem.ClientValueIdentified,
                          isInvalid: false,
                          invalidMsg: '',
                          disabled: ['', null].indexOf(boiItem.ClientValueIdentified) > -1,
                        },
                      clientValueIdentified: !IsArrayEmpty(currBOI) &&
                        boiItem.ClientValueIdentified !== currBOI[0].clientValueIdentified ?
                        currBOI[0].clientValueIdentified : boiItem.ClientValueIdentified, //
                      boiTarget: boiItem.BOITarget,
                      boiTargetApproved: boiItem.BOITargetApprovedValue,
                      boiApprovedRealizedValue: boiItem.BOIApprovedRealizedValue, //
                      boiValueDiff: boiItem.BOIValueDifference,
                      boiTargetApprovedValue: boiItem.BOITargetApprovedValue,
                      // new data origin end
                      boiReason: boiItem.BOIReason,
                      boiStatus: boiItem.BOIStatus,
                      boiClientProjectTargetID: boiItem.ClientProjectTargetID,
                      boiProjectDataEntryID: boiItem.ProjectDataEntryID,
                      boiRejectionComment: boiItem.RejectionComment,
                      boiUOM: boiItem.UOM,
                      parameterList: [],
                      hasChange: boiItem.BOITargetApprovedValue !== oldBoiItem.boiTargetApproved, //false prev
                      IsCustom: boiItem.IsCustom,
                      isNewValue: boiItem.IsNewValue,
                      leadingIndicator: boiItem.LeadingIndicator,
                      RVDCommentHistory: boiItem.RVDCommentHistory,
                      offeringName: boiItem.OfferingName,
                      subofferingName: boiItem.SubOfferingName,
                      boiWheelVisivility: boiItem.WheelVisibility,
                      isUpdatedOnPPCModule: boiItem.IsUpdatedOnPPCModule,
                      projectBOIWheelVisibility: boiItem.ProjectBOIWheelVisibility === 'True' ? true : false, //ok //added validation for now due to PBI 148714 dependency
                      isAgnosticBOI: boiItem.IsAgnosticBOI, //
                      projectLevelBOIStatus: boiItem.ProjectLevelBOIStatus
                        ? boiItem.ProjectLevelBOIStatus
                        : '',
                    });
                  }
                  //boiList mapping end

                  //parameter mapping start
                  boiItem.ParameterList.map((paramItem, paramIndex) => {
                    oldBoiItem.parameterList.map((oldParamItem, oldParamIndex) => {
                      if (
                        dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList[
                          boiIndex
                        ].parameterList.length > 0
                      ) {
                        if (
                          !dimensionList[dimensionIndex].subDimensionList[
                            subDimensionIndex
                          ].boiList[boiIndex].parameterList.some(
                            (item) => item.parameterMasterID === paramItem.ParameterMasterID
                          )
                        ) {
                          if (
                            oldBoiItem.parameterList.some(
                              (item) => item.parameterMasterID === paramItem.ParameterMasterID
                            )
                          ) {
                            if (paramItem.ParameterMasterID === oldParamItem.parameterMasterID) {
                              if (oldParamItem.parameterValue != paramItem.ParameterValue) {
                                dimensionList[dimensionIndex].subDimensionList[
                                  subDimensionIndex
                                ].boiList[boiIndex].parameterList.push({
                                  parameterMasterID: paramItem.ParameterMasterID,
                                  parameterName: paramItem.ParameterName,
                                  parameterUOM: paramItem.ParameterUOM,
                                  parameterValue:
                                    oldParamItem.parameterValue != paramItem.ParameterValue
                                      ? oldParamItem.parameterValue
                                      : paramItem.ParameterValue,
                                  oldParamValue: oldParamItem.parameterValue != paramItem.ParameterValue
                                    ? oldParamItem.parameterValue
                                    : paramItem.ParameterValue,
                                  parameterValueID: paramItem.ProjectParameterValueID,
                                  parameterCustomCode: paramItem.ParameterCustomCode,
                                  parameterCode: paramItem.ParameterCode,
                                  hasChange: true,
                                  tempHasChange: true,
                                  isInvalid: false
                                });
                              } else {
                                dimensionList[dimensionIndex].subDimensionList[
                                  subDimensionIndex
                                ].boiList[boiIndex].parameterList.push({
                                  parameterMasterID: paramItem.ParameterMasterID,
                                  parameterName: paramItem.ParameterName,
                                  parameterUOM: paramItem.ParameterUOM,
                                  parameterValue:
                                    oldParamItem.parameterValue != paramItem.ParameterValue
                                      ? oldParamItem.parameterValue
                                      : paramItem.ParameterValue,
                                  oldParamValue: oldParamItem.parameterValue != paramItem.ParameterValue
                                    ? oldParamItem.parameterValue
                                    : paramItem.ParameterValue,
                                  parameterValueID: paramItem.ProjectParameterValueID,
                                  parameterCustomCode: paramItem.ParameterCustomCode,
                                  parameterCode: paramItem.ParameterCode,
                                  isInvalid: false
                                });
                              }
                            }
                          } else {
                            dimensionList[dimensionIndex].subDimensionList[
                              subDimensionIndex
                            ].boiList[boiIndex].parameterList.push({
                              parameterMasterID: paramItem.ParameterMasterID,
                              parameterName: paramItem.ParameterName,
                              parameterUOM: paramItem.ParameterUOM,
                              parameterValue: paramItem.ParameterValue,
                              oldParamValue: paramItem.parameterValue,
                              parameterValueID: paramItem.ProjectParameterValueID,
                              parameterCustomCode: paramItem.ParameterCustomCode,
                              parameterCode: paramItem.ParameterCode,
                              isInvalid: false
                            });
                          }
                        }
                      } else {
                        if (
                          oldDimensionItem.subDimensionList.some(
                            (item) => item.subDimensionID === subDimensionItem.SubDimensionID
                          )
                        ) {
                          if (
                            subDimensionItem.SubDimensionID === oldSubDimensionItem.subDimensionID
                          ) {
                            if (
                              oldSubDimensionItem.boiList.some(
                                (item) => item.boiID === boiItem.BOIID
                              )
                            ) {
                              if (
                                subDimensionItem.SubDimensionID ===
                                oldSubDimensionItem.subDimensionID &&
                                boiItem.BOIID === oldBoiItem.boiID
                              ) {
                                if (
                                  paramItem.ParameterMasterID === oldParamItem.parameterMasterID
                                ) {
                                  if (oldParamItem.parameterValue != paramItem.ParameterValue) {
                                    dimensionList[dimensionIndex].subDimensionList[
                                      subDimensionIndex
                                    ].boiList[boiIndex].parameterList.push({
                                      parameterMasterID: paramItem.ParameterMasterID,
                                      parameterName: paramItem.ParameterName,
                                      parameterUOM: paramItem.ParameterUOM,
                                      parameterValue:
                                        oldParamItem.parameterValue != paramItem.ParameterValue
                                          ? oldParamItem.parameterValue
                                          : paramItem.ParameterValue,
                                      oldParamValue: oldParamItem.parameterValue != paramItem.ParameterValue
                                        ? oldParamItem.parameterValue
                                        : paramItem.ParameterValue,
                                      parameterValueID: paramItem.ProjectParameterValueID,
                                      parameterCustomCode: paramItem.ParameterCustomCode,
                                      parameterCode: paramItem.ParameterCode,
                                      hasChange: true,
                                      tempHasChange: true,
                                      isInvalid: false
                                    });
                                  } else {
                                    dimensionList[dimensionIndex].subDimensionList[
                                      subDimensionIndex
                                    ].boiList[boiIndex].parameterList.push({
                                      parameterMasterID: paramItem.ParameterMasterID,
                                      parameterName: paramItem.ParameterName,
                                      parameterUOM: paramItem.ParameterUOM,
                                      parameterValue:
                                        oldParamItem.parameterValue != paramItem.ParameterValue
                                          ? oldParamItem.parameterValue
                                          : paramItem.ParameterValue,
                                      oldParamValue: oldParamItem.parameterValue != paramItem.ParameterValue
                                        ? oldParamItem.parameterValue
                                        : paramItem.ParameterValue,
                                      parameterValueID: paramItem.ProjectParameterValueID,
                                      parameterCustomCode: paramItem.ParameterCustomCode,
                                      parameterCode: paramItem.ParameterCode,
                                      isInvalid: false
                                    });
                                  }
                                }
                              }
                            } else {
                              dimensionList[dimensionIndex].subDimensionList[
                                subDimensionIndex
                              ].boiList[boiIndex].parameterList.push({
                                parameterMasterID: paramItem.ParameterMasterID,
                                parameterName: paramItem.ParameterName,
                                parameterUOM: paramItem.ParameterUOM,
                                parameterValue: paramItem.ParameterValue,
                                oldParamValue: paramItem.ParameterValue,
                                parameterValueID: paramItem.ProjectParameterValueID,
                                parameterCustomCode: paramItem.ParameterCustomCode,
                                parameterCode: paramItem.ParameterCode,
                                isInvalid: false
                              });
                            }
                          }
                        } else {
                          if (dimensionIndex < oldDimensionList.length) {
                            if (dimensionItem.CoreDimensionID === oldDimensionItem.dimensionID) {
                              dimensionList[dimensionIndex].subDimensionList[
                                subDimensionIndex
                              ].boiList[boiIndex].parameterList.push({
                                parameterMasterID: paramItem.ParameterMasterID,
                                parameterName: paramItem.ParameterName,
                                parameterUOM: paramItem.ParameterUOM,
                                parameterValue: paramItem.ParameterValue,
                                oldParamValue: paramItem.ParameterValue,
                                parameterValueID: paramItem.ProjectParameterValueID,
                                parameterCustomCode: paramItem.ParameterCustomCode,
                                parameterCode: paramItem.ParameterCode,
                                isInvalid: false
                              });
                            }
                          } else {
                            dimensionList[dimensionIndex].subDimensionList[
                              subDimensionIndex
                            ].boiList[boiIndex].parameterList.push({
                              parameterMasterID: paramItem.ParameterMasterID,
                              parameterName: paramItem.ParameterName,
                              parameterUOM: paramItem.ParameterUOM,
                              parameterValue: paramItem.ParameterValue,
                              oldParamValue: paramItem.ParameterValue,
                              parameterValueID: paramItem.ProjectParameterValueID,
                              parameterCustomCode: paramItem.ParameterCustomCode,
                              parameterCode: paramItem.ParameterCode,
                              isInvalid: false
                            });
                          }
                        }
                      }
                    });
                  });
                  //parameter mapping end
                });
              });
            });
          });
        });
      });

      //sort dimList: financial first, then the rest
      let assignedToRoleID = state().ConfigureProgramData.basicDetails.assignedToRoleID;
      let currentUserRoleID = state().userInformation.currentUserRoleID;

      if (
        (isSuperAdmin && (assignedToRoleID === 8 || assignedToRoleID === 16)) ||
        ((currentUserRoleID === 8 || (currentUserRoleID === 16 && isExisting)) &&
          assignedToRoleID === currentUserRoleID)
      ) {
        projectDimensionsList = dimensionList
          .filter((dim) => dim.dimensionName.toLowerCase() === 'financial')
          .map((e) => {
            let subDUSD = [];
            let subD = e.subDimensionList.map((e2) => {
              let tBoiL = e2.boiList.filter((e3) => e3.boiUOM.toLowerCase() === 'usd');
              if (tBoiL.length > 0) {
                subDUSD.push(e2.subDimensionID);
              }
              let boiList = tBoiL.concat(
                e2.boiList.filter((e3) => e3.boiUOM.toLowerCase() !== 'usd')
              );
              return { ...e2, boiList: boiList };
            });

            let finSubD = subD
              .filter((sub) => {
                if (subDUSD.indexOf(sub.subDimensionID) !== -1) return sub;
              })
              .concat(
                subD.filter((sub1) => {
                  if (subDUSD.indexOf(sub1.subDimensionID) === -1) return sub1;
                })
              );

            return { ...e, subDimensionList: finSubD };
          })
          .concat(dimensionList.filter((dim) => dim.dimensionName.toLowerCase() !== 'financial'));
      } else {
        projectDimensionsList = dimensionList;
      }
    }

    dispatch({
      type: SELECTED_PROGRAM_PROJECT,
      mainSelectedProject: mainSelectedProject,
    });

    dispatch({
      type: UPDATE_BOI_LIST,
      projectDimensionsList: projectDimensionsList,
    });

    dispatch({
      type: BOI_IS_REFRESH,
      isRefreshed: true,
    });

    dispatch({
      type: SET_SHOULD_SHOW_TOASTER_NOTIF,
      toasterParam: {
        show: true,
        type: 'success',
        message: 'Refreshed list has been loaded successfully!',
      },
    });
    return projectDimensionsList;
  } catch (err) {
    console.log(err);
  }
};

export const resetRefresh = () => (dispatch, state) => {
  dispatch({
    type: BOI_IS_REFRESH,
    isRefreshed: false,
  });
};

export const getProgramProjectCreationYear = (programID) => async (dispatch, state) => {
  let clientID = state().SharedData.selectedClient.value;
  try {
    // new API 08022021
    let subMenuId = GetSubMenuId("Value Delivered_Landing page")
    let data = await HttpGet(`ReportValueDelivered/GetCreationYear?ClientID=${clientID}&ProgramID=${programID}&SubMenuID=${subMenuId}`);
    if (Object.keys(data).length > 0) {
      const list = data.sort((a, b) => a.CreationYear - b.CreationYear).map((y) => {
        return {
          text: y.CreationYear,
          label: y.CreationYear,
          value: y.CreationYear,
        };
      });
      dispatch({
        type: GET_PROJECT_CREATION_YEAR_LIST,
        creationYearList: list,
      });
      dispatch(onSelectCreationYearOptions(list));
    } else {
      dispatch({
        type: GET_PROJECT_CREATION_YEAR_LIST,
        creationYearList: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setCumulativeModal = (showCumulativeModal) => async (dispatch, state) => {
  try {
    dispatch({
      type: SHOW_CUMULATIVE_MODAL,
      showCumulativeModal: showCumulativeModal,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAggregatedDisabledBOIs = (selectedProjectID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Project Identified Value - Account Leadership Approval");
    let data = await HttpGet(
      `ProjectConfiguration/GetProjectAggregation?projectId=${selectedProjectID}&SubMenuID=${subMenuId}`
    );
    let dimensionList = [];

    if (Object.keys(data).length > 0) {
      data.map((dimensionItem, dimensionIndex) => {
        dimensionList.push({
          dimensionName: dimensionItem.CoreDimensionName,
          dimensionID: dimensionItem.CoreDimensionID,
          dimensionReason: dimensionItem.CoreDimensionReason,
          dimensionStatus: dimensionItem.CoreDimensionStatus,
          dimensionTarget: dimensionItem.CoreDimensionTarget,
          subDimensionList: [],
        });

        if (Object.keys(dimensionItem.SubDimensionList).length > 0) {
          data[dimensionIndex].SubDimensionList.map((subDimensionItem, subDimensionIndex) => {
            dimensionList[dimensionIndex].subDimensionList.push({
              subDimensionName: subDimensionItem.SubDimensionName,
              subDimensionID: subDimensionItem.SubDimensionID,
              subDimensionReason: subDimensionItem.SubDimensionReason,
              subDimensionStatus: subDimensionItem.SubDimensionStatus,
              subDimensionTarget: subDimensionItem.SubDimensionTarget,
              boiList: [],
            });

            if (Object.keys(subDimensionItem.BOIList).length > 0) {
              data[dimensionIndex].SubDimensionList[subDimensionIndex].BOIList.map(
                (boiItem, boiIndex) => {
                  dimensionList[dimensionIndex].subDimensionList[subDimensionIndex].boiList.push({
                    boiID: boiItem.BOIID,
                    boiName: boiItem.BOIName,
                    paramValue: boiItem.BOITarget,
                    oldParamValue: boiItem.BOITarget,
                    uom: boiItem.UOM,
                    comment: boiItem.BOIReason,
                    description: boiItem.BOIDescription,
                    boiRealizedValue: boiItem.BOIRealizedValue,
                    boiStatus: boiItem.BOIStatus,
                    isActive: true,
                    boiClientProjectTargetID: boiItem.ClientProjectTargetID,
                    boiRejectionRecipient: boiItem.RejectionRecipient,
                    boiCode: boiItem.BOICode,
                    aggregationLogic: boiItem.AggregationLogic,
                    aggregationLogicMasterID: boiItem.AggregationLogicMasterID,
                    ClientName: boiItem.ClientName,
                    offeringName: boiItem.Offering,
                    leadingIndicator: boiItem.LeadingIndicator,
                    boiDefinition: boiItem.MetricCalculation,
                    boiDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                    IsCustom: boiItem.IsCustom,
                    isBOIUsedInRVD: boiItem.IsBOIUsedInRVD,
                    customParameterList: boiItem.CustomParameterList,
                    reasonForNoBOIDefinition: boiItem.ReasonForNoBOIDefinition,
                    reasonForNoAggregationLogic: boiItem.ReasonForNoAggregationLogic,
                    subofferingID: boiItem.SubOfferingID,
                    suboffering: boiItem.SubOfferingName,
                    boiAdditionComment: boiItem.BOIAdditionComment,
                    aggregatedDeliveredValue: boiItem.DeliveredValue,
                    parameterList: boiItem.paramlist,
                    programWheelVisibility: boiItem.ProgramWheelVisibility,
                  });
                }
              );
            }
          });
        }
      });
    }

    dispatch({
      type: GET_AGGREGATED_DISABLED_BOIS,
      aggregatedDisabledBOIs: dimensionList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClientStoryFileHistory = (selectedProjectID) => async (dispatch, state) => {
  try {
    let projectData = state().ConfigureProgramData.mainSelectedProject.projectDetails;
    let subMenuId = GetSubMenuId("Project Data Entry");
    let data = await HttpGet(
      `ProjectDataEntry/GetClientStoryFileHistory?clientProjectID=${projectData.clientProjectID}&projectDeliveryCenterID=${projectData.projectDeliveryCenterID}&SubMenuID=${subMenuId}`
    );
    let clientStoryFileHistory = [];

    if (Object.keys(data).length > 0) {
      data.map((file) => {
        var pDate = new Date(`${file.FileUploadDate.replace(' |', '')} UTC`);
        var newFileUploadDate = FormDateDDMMMYYY(pDate) + '  |  ' + FormTimeAPM(pDate);
        clientStoryFileHistory.push({
          ID: file.ID,
          fileName: file.FileName,
          uploadedBy: file.FileUploadedBy,
          uploadedDateTime: newFileUploadDate,
          selected: false,
        });
      });
    }

    dispatch({
      type: GET_CLIENT_STORY_FILE_HISTORY,
      clientStoryFileHistory: clientStoryFileHistory,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getClientAcknowledgementFileHistory =
  (selectedProjectID) => async (dispatch, state) => {
    try {
      let projectData = state().ConfigureProgramData.mainSelectedProject.projectDetails;
      let tz = getUserTimeZone();
      let isNegative = tz.includes('-');
      tz = tz.replace('-', '').replace('+', '');
      let subMenuId = GetSubMenuId("Project Data Entry")
      let data = await HttpGet(
        `ReportValueDelivered/RVDClientAcknowledgementHistory?clientProjectID=${projectData.clientProjectID}&projectDeliveryCenterID=${projectData.projectDeliveryCenterID}&isNegative=${isNegative}&UTCOffset=${tz}&SubMenuID=${subMenuId}`
      );
      let clientAcknowledgementFileHistory = [];

      if (Object.keys(data).length > 0) {
        data.map((file) => {
          // var tDate = new Date(`${file.FileUploadDate.replace(' |', '')} UTC`);
          // var newFileUploadDates = FormDateDDMMMYYY(tDate) + '  |  ' + FormTimeAPM(tDate);
          clientAcknowledgementFileHistory.push({
            ID: file.ID,
            fileName: file.FileName,
            uploadedBy: file.FileUploadedBy,
            uploadedDateTime: file.FileUploadDate,
            selected: false,
          });
        });
      }

      dispatch({
        type: GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY,
        clientAcknowledgementFileHistory: clientAcknowledgementFileHistory,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getClientFileHistory =
  (selectedProjectID) => async (dispatch, state) => {
    try {
      let projectData = state().ConfigureProgramData.mainSelectedProject.projectDetails;
      let subMenuId = GetSubMenuId("Project Data Entry");
      let data = await HttpGet(
        `ProjectDataEntry/GetProjectDataEntryFileHistory?clientProjectID=${projectData.clientProjectID}&projectDeliveryCenterID=${projectData.projectDeliveryCenterID}&SubMenuID=${subMenuId}`
      );
      let clientAcknowledgementFileHistory = [];

      if (Object.keys(data).length > 0) {
        data.map((file) => {
          var tDate = new Date(`${file.FileUploadDate.replace(' |', '')} UTC`);
          var newFileUploadDates = FormDateDDMMMYYY(tDate) + '  |  ' + FormTimeAPM(tDate);
          clientAcknowledgementFileHistory.push({
            ID: file.ID,
            fileName: file.FileName,
            uploadedBy: file.FileUploadedBy,
            uploadedDateTime: newFileUploadDates,
            selected: false,
            fileType: file.FileType
          });
        });
      }

      dispatch({
        type: GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY,
        clientAcknowledgementFileHistory: clientAcknowledgementFileHistory,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const onSelectFileEntry = (selectedProjectID) => async (dispatch, state) => {
  try {
    //do something
    console.log('onSelectFileEntry');
  } catch (err) {
    console.log(err);
  }
};

export const onSelectAllFileEntry = () => async (dispatch, state) => {
  try {
    //do something
    console.log('onSelectAllFileEntry');
  } catch (err) {
    console.log(err);
  }
};

export const onDownloadFileHistory = () => async (dispatch, state) => {
  try {
    //do something
    console.log('onDownloadFileHistory');
  } catch (err) {
    console.log(err);
  }
};

export const setClientStoryUpload = (file, date) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_CLIENT_STORY_FILE,
      clientStoryFile: {
        id: 0,
        file: file,
        fileUploadedBy: state().userInformation.emailAddress,
        createdUser: state().userInformation.enterpriseID,
        fileUploadedDate: date,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const setClientStory = (updatedClientStoryObj) => async (dispatch, state) => {
  try {
    // let clientStory = { ...state().ConfigureProgramData.clientStory};

    // clientStory[title] = updateHTMLStringForPPT(val);

    dispatch({
      type: SET_CLIENT_STORY,
      clientStory: updatedClientStoryObj
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeClientStoryUpload = () => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_CLIENT_STORY_FILE,
      clientStoryFile: {
        id: 0,
        file: [],
        fileUploadedBy: '',
        createdUser: '',
        fileUploadedDate: '',
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeRecentClientStoryUpload =
  (recentlyUploadedClientStory) => async (dispatch, state) => {
    try {
      // REMOVE_CLIENT_STORY_FILE
      let tempRemoveClientStoryFileId = 0;
      if (recentlyUploadedClientStory !== null && recentlyUploadedClientStory !== undefined) {
        tempRemoveClientStoryFileId = recentlyUploadedClientStory.Id;
      }
      dispatch({
        type: REMOVE_CLIENT_STORY_FILE,
        removeClientStoryFileId: tempRemoveClientStoryFileId,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const setClientAlignmentUpload = (file, date) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_CLIENT_ALIGNMENT_FILE,
      clientAlignmentFiles: {
        id: 0,
        file: file,
        fileUploadedBy: state().userInformation.emailAddress,
        createdUser: state().userInformation.enterpriseID,
        fileUploadedDate: date,
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeClientAlignmentUpload = () => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_CLIENT_ALIGNMENT_FILE,
      clientAlignmentFiles: {
        id: 0,
        file: [],
        fileUploadedBy: '',
        createdUser: '',
        fileUploadedDate: '',
      },
    });
    dispatch({
      type: REMOVE_CLIENT_ALIGNMENT_FILE,
      removeClientAlignmentFileId: [],
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeRecentClientAlignmentUpload =
  (recentlyUploadedClientAlignment) => async (dispatch, state) => {
    try {
      let removeClientAlignmentFileId = state().ConfigureProgramData.removeClientAlignmentFileId;
      if (recentlyUploadedClientAlignment !== null && recentlyUploadedClientAlignment !== undefined) {
        removeClientAlignmentFileId.push(recentlyUploadedClientAlignment.ID);
        dispatch({
          type: REMOVE_CLIENT_ALIGNMENT_FILE,
          removeClientAlignmentFileId: removeClientAlignmentFileId,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const clearClientModalDetails = () => async (dispatch, state) => {
  try {
    dispatch({
      type: GET_CLIENT_STORY_FILE_HISTORY,
      clientStoryFileHistory: [],
    });

    dispatch({
      type: GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY,
      clientAcknowledgementFileHistory: [],
    });
  } catch (e) {
    console.log(e);
  }
};

export const isUploadCompleted = (isCompleted) => async (dispatch) => {
  try {
    dispatch({
      type: SET_UPLOAD_IS_COMPLETED,
      isUploadCompleted: isCompleted,
    });
  } catch (e) {
    console.log(e);
  }
};

export const successClientDownload = () => (dispatch, state) => {
  console.log('CLEINT ACK TEST');
  dispatch({
    type: SET_SHOULD_SHOW_TOASTER_NOTIF,
    toasterParam: {
      show: true,
      type: 'success',
      message: 'File downloaded successfully',
    },
  });
};

export const failedClientDownload = () => (dispatch, state) => {
  dispatch({
    type: SET_SHOULD_SHOW_TOASTER_NOTIF,
    toasterParam: {
      show: true,
      type: 'error',
      message: 'File downloaded failed',
    },
  });
};

//Business Advisor R5.0S1

export const getBusinessAdvisorList = () => async (dispatch, state) => {
  try {
    let projectID = state().ConfigureProgramData.mainSelectedProject.projectDetails.clientProjectID
    let businessAdvisorList = [];

    let data = await HttpGet(`ProjectDataEntry/GetBusinessAdvisors?clientProjectID=${projectID}`);

    if (Object.keys(data).length > 0) {
      data.forEach((ba) => {
        businessAdvisorList.push({
          text: ba.userName,
          label: ba.userName,
          value: ba.ID,
        });
      });
      dispatch({
        type: SET_BUSINESS_ADVISOR_LIST,
        businessAdvisorList: businessAdvisorList,
      });
    } else {
      dispatch({
        type: SET_BUSINESS_ADVISOR_LIST,
        businessAdvisorList: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const onSaveBusinessAdvisor = (e) => async (dispatch, state) => {
  try {
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let businessAdvisorText = '';

    e.map((ba) => {
      if (businessAdvisorText === '') {
        businessAdvisorText = ba.text;
      } else {
        businessAdvisorText += ', ' + ba.text;
      }
    });

    basicDetails.businessAdvisor = e;
    basicDetails.businessAdvisorText = businessAdvisorText;

    dispatch({
      type: SET_RVD_BASIC_DETAILS,
      basicDetails: basicDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const onCPPointsYesNo = (param) => async (dispatch, state) => {
  try {
    const userEmail = state().userInformation.emailAddress.trim().toLowerCase();
    const projectID =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.clientProjectID;
    const deliveryID =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.projectDeliveryCenterID;
    const user = userEmail.split('@')[0];

    let body = {
      clientProjectsID: projectID,
      projectDCID: deliveryID,
      isFirstSubmission: param ? 1 : 0,
      createdUser: user,
    };
    let subMenuId = GetSubMenuId("Project Data Entry");
    await HttpPost(`ProjectDataEntry/CheckIsFirstSubmission?SubMenuID=${subMenuId}`, body);
  } catch (err) {
    console.log(err);
  }
};

// R10


export const updateExpandCollapseBOIView = (data, expandedBOI, action) => async (dispatch, state) => {
  try {
    let tProjectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let boi = {};
    let projectDimensionsList = [];

    if (action.toLowerCase().includes("all")) {
      let show = action.toLowerCase().includes("expand");
      projectDimensionsList = tProjectDimensionsList
        .map((e) => {
          let subD = e.subDimensionList.map((e2) => {
            let boiList = e2.boiList.map((e3) => {
              return { ...e3, expandedBOI: show }
            })
            return { ...e2, boiList: boiList };
          });
          return { ...e, subDimensionList: subD };
        })
    } else {
      boi = tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
        .boiList[data.boi.index];

      boi.expandedBOI = expandedBOI;

      tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index].boiList[
        data.boi.index] = boi;


      projectDimensionsList = tProjectDimensionsList;
    }
    dispatch({
      type: ON_CHANGE_PARAM,
      projectDimensionsList: projectDimensionsList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const handleExpandCollapseYearQtr = (data, delIn) => async (dispatch, state) => {
  try {
    let tProjectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let boi = tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
      .boiList[data.boi.index];

    let projectDimensionsList = [];

    switch (delIn.trigger) {
      case "qtr":
        boi.boiDeliveredValueList[delIn.index.year]
          .reportingQuarters[delIn.index.quarter]
          .expandedQuarter = !delIn.isExpanded;

        if (delIn.index.year === 0 && delIn.index.quarter === 0 &&
          boi.finUSDData.latestDate.year === delIn.year) {
          boi.finUSDData.expandedQuarter = !delIn.isExpanded;
        }

        break;
      case "year":
        boi.boiDeliveredValueList[delIn.index.year]
          .reportingQuarters = boi.boiDeliveredValueList[delIn.index.year].reportingQuarters
            .map((e) => {
              return { ...e, expandedQuarter: !delIn.isExpanded };
            })

        boi.boiDeliveredValueList[delIn.index.year]
          .expandedYear = !delIn.isExpanded;

        if (delIn.index.year === 0 && boi.finUSDData.latestDate.year === delIn.year) {
          boi.finUSDData.expandedQuarter = !delIn.isExpanded;
          boi.finUSDData.expandedYear = !delIn.isExpanded;
        }
        break;
      case "boi":
        if (delIn.isExpanded) { //to collapse
          boi.boiDeliveredValueList = boi.boiDeliveredValueList.map((e1) => {
            let reportingQuarters = e1.reportingQuarters.map((e2) => {
              return { ...e2, expandedQuarter: !delIn.isExpanded };
            })
            return {
              ...e1,
              reportingQuarters: reportingQuarters,
              expandedYear: !delIn.isExpanded
            };
          })
        }
        else { //to expand
          boi.boiDeliveredValueList[delIn.index.year]
            .reportingQuarters = boi.boiDeliveredValueList[delIn.index.year].reportingQuarters
              .map((e) => {
                return { ...e, expandedQuarter: !delIn.isExpanded };
              })

          boi.boiDeliveredValueList[delIn.index.year]
            .expandedYear = !delIn.isExpanded;

          if (delIn.index.year === 0) {
            boi.finUSDData.expandedQuarter = !delIn.isExpanded;
            boi.finUSDData.expandedYear = !delIn.isExpanded;
          }
        }
        break;
      default:
        break;
    }

    tProjectDimensionsList[data.dimension.index].subDimensionList[data.subDimension.index]
      .boiList[data.boi.index] = boi;


    projectDimensionsList = tProjectDimensionsList;

    dispatch({
      type: ON_CHANGE_PARAM,
      projectDimensionsList: projectDimensionsList,
    });
    return projectDimensionsList;
  } catch (err) {
    console.log(err);
  }
};

export const handleUpdateReceiverDetails = (data) => async (dispatch, state) => {
  try {
    let mainSelectedProject = state().ConfigureProgramData.mainSelectedProject;
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    let dataString = data.map((a) => { return a.label }).toString();
    let idString = data.map((a) => { return a.value }).toString();

    receiverDetails.eid = dataString;
    receiverDetails.dropdownVal = data;
    receiverDetails.userID = idString;

    if (receiverDetails.roleID === 7) { //cxl
      mainSelectedProject.projectDetails.cxLead = dataString;
    } else if (receiverDetails.roleID === 4) { //oal
      mainSelectedProject.projectDetails.oaLead = dataString;
    }

    dispatch({
      type: ON_CLICK_RDV_SFA,
      receiverDetails: receiverDetails,
    });

    dispatch({
      type: SELECTED_PROGRAM_PROJECT,
      mainSelectedProject: mainSelectedProject,
    });
  } catch (err) {
    console.log(err);
  }
};

export const handleSendReminderPDE = (param) => async (dispatch, state) => {
  try {
    let result;
    let projectDimensionsList = state().ConfigureProgramData.projectDimensionsList;
    let receiverDetails = state().ConfigureProgramData.receiverDetails;
    let selectedProject = state().ConfigureProgramData.mainSelectedProject;
    let basicDetails = state().ConfigureProgramData.basicDetails;
    let userInfo = state().userInformation;
    let userID = userInfo.userID;
    let userName = state().userInformation.userName;
    let selectedClient = state().SharedData.selectedClient;
    let projectEntryList = [];
    let isSuperAdmin = userInfo.isSuperAdmin;
    let clientStoryFile = state().ConfigureProgramData.clientStoryFile;
    let clientAlignmentFiles = state().ConfigureProgramData.clientAlignmentFiles;
    let businessAdvisorList = state().ConfigureProgramData.businessAdvisorList
    const creationDate = selectedProject.projectDetails.creationDate;
    const lastReportingDate = selectedProject.projectDetails.creationDate;
    const recentlyUploaded =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientStory;
    const recentlyUploadedCA =
      state().ConfigureProgramData.mainSelectedProject.projectDetails.recentlyUploadedClientAlignment;
    let removeClientStoryFileId = state().ConfigureProgramData.removeClientStoryFileId;
    let removeClientAlignmentFileId = state().ConfigureProgramData.removeClientAlignmentFileId;
    let isExisting = await dispatch(isProfileExisting([12, 15]));
    let files = [];
    let businessAdvisor = businessAdvisorList.filter((ba) => ba.value !== 0).map(x => x.text).join(';')
    let isNotApplicable = businessAdvisorList.find((ba) => ba.value === 0) !== undefined;
    let cxLeadList = selectedProject.projectDetails.cxLeadList;
    projectDimensionsList.map((dItem, dIndex) => {
      dItem.subDimensionList.map((sdItem, sdIndex) => {
        sdItem.boiList.map((boiItem, boiIndex) => {
          projectEntryList.push({
            projectDataEntryID: boiItem.boiProjectDataEntryID,
            projectDeliveryCenterID: basicDetails.projectDeliveryCenterID,
            clientProjectTargetID: boiItem.boiClientProjectTargetID,
            boiid: boiItem.boiID,
            uom: boiItem.boiUOM,
            boiName: boiItem.boiName,
            boiTarget: boiItem.boiTarget,
            boiIdentifiedValue:
              boiItem.boiTargetApproved !== null ? boiItem.boiTargetApproved : boiItem.boiTarget,
            boiRealizedValue: boiItem.boiRealizedValue,
            clientValueIdentified:
              [null, ''].indexOf(boiItem.clientValueIdentified) > -1
                ? 0
                : boiItem.clientValueIdentified,
            boiValueDifference: boiItem.boiValueDiff,
            boiStatus: boiItem.boiStatus,
            // boiReason: boiItem.boiReason,
            boiReason: '', //send as empty for SFA as per suman
            createdBy: userName,
            parameterList: boiItem.parameterList.map((param, paramIndex) => {
              return {
                parameterMasterID: param.parameterMasterID,
                parameterName: param.parameterName,
                oldParameterValue: 0,
                parameterValue: param.parameterValue,
                parameterUOM: param.parameterUOM,
                projectParameterValueID: param.parameterValueID,
              };
            }),
            ProjectBoiCommentList: boiItem.RVDCommentHistory.map((item, itemIndex) => {
              return {
                id: item.Id,
                projectDataEntryID: boiItem.projectDataEntryID,
                clientProgramID: selectedProject.programID,
                clientProjectID: selectedProject.projectDetails.clientProjectID,
                boiid: boiItem.boiID,
                comment: item.Comment,
                isEdited: item.IsEdited,
                isProgramLevel: true, //false
                isActive: true,
                createdUser: item.CreatedUser,
                createdTS: item.CreatedTS,
                updatedUser: item.CreatedUser,
                updatedTS: item.CreatedTS,
                clientProgramTargetID: 0, //update to used clientprogramtarget
              };
            }).reverse(),
          });
        });
      });
    });



    const formBody = {
      clientID: selectedClient.value,
      clientName: selectedClient.label,
      programName: selectedProject.programName,
      projectName: selectedProject.projectDetails.clientProjectName,
      deliverCenterName: selectedProject.projectDetails.deliveryCenterName,
      clientProgramID: selectedProject.programID,
      clientProjectID: selectedProject.projectDetails.clientProjectID,
      deliveredBy: basicDetails.deliveredBy,
      projectLead: basicDetails.projectLead,
      projectDeliveryCenterID: basicDetails.projectDeliveryCenterID, //primary key
      deliveryCenterID: selectedProject.projectDetails.deliveryCenterID, //project delivery center
      mailReceiverUserID: selectedProject.projectDetails.cxlLeadUserID, //not being used on API as per jemar
      mailReceiverEmailId: selectedProject.projectDetails.assignedTo,
      mailReceiverRole: receiverDetails.role,
      mailReceiverRoleId: selectedProject.projectDetails.assignedToRoleId, //cxlead
      comment: receiverDetails.comment,
      assignedTo: selectedProject.projectDetails.assignedTo, //currently selected CX Lead
      assignedToRoleId: selectedProject.projectDetails.assignedToRoleId, //cxlead
      loggedInUserRoleId: userInfo.currentUserRoleID === ''
        ? 0
        : userInfo.currentUserRoleID,
      isEditingModebyPPCUser: true,
      createdBy: userName,
      isSendingApproval: true,
      rejectedByRoleId: userInfo.currentUserRoleID === '' ? 0 : userInfo.currentUserRoleID, //update
      startReportingDate: creationDate,
      lastReportingDate: lastReportingDate,
      projectDataEntryList: projectEntryList,
      dataSubmitter: userInfo.emailAddress,
      isSkippedIOApproval: HasValue(receiverDetails.isSkippedIOApproval)
        ? receiverDetails.isSkippedIOApproval
        : false,
      clientStoryFileToUpload: {
        id: !HasValue(recentlyUploaded)
          ? clientStoryFile.id
          : removeClientStoryFileId > 0
            ? 0
            : recentlyUploaded.Id,
        fileUploadedBy: clientStoryFile.fileUploadedBy,
        createdUser: clientStoryFile.createdUser,
      },
      RemoveClientStoryFileId: removeClientStoryFileId ? removeClientStoryFileId : 0,
      BusinessAdvisor: businessAdvisor,
      IsBANotApplicable: isNotApplicable,
      clientAlignmentFileAttachment: {
        deliveryCenterID: selectedProject.projectDetails.deliveryCenterID,
        createdUser: clientAlignmentFiles.createdUser,
        createdTS: clientAlignmentFiles.uploadedDateTime,
        updatedUser: clientAlignmentFiles.fileUploadedBy,
        updatedTS: clientAlignmentFiles.fileUploadedDate,
        removeClientAlignmentFileIds: removeClientAlignmentFileId,
        isApproved: false
      }
    }

    const formData = new FormData();

    if (HasValue(clientAlignmentFiles.file)) {
      clientAlignmentFiles.file.map((x) => {
        formData.append('Files', x);
      });
    }
    if (HasValue(clientStoryFile.file[0])) formData.append('Files', clientStoryFile.file[0]);
    formData.append('objSendApproval', JSON.stringify(formBody));

    let subMenuId = GetSubMenuId("Project Data Entry");
    let postData = await HttpPostWithFileString(
      `ProjectDataEntry/SendReminderForProjectDataEntry?SubMenuID=${subMenuId}`,
      formData
    );
    dispatch({
      type: ON_SEND_REMINDER_PROJECT_DATA_ENTRY,
      sendReminderProjectDataEntry: {
        param: param,
        projectDataEntryData: postData
      }
    })
    result = postData
    history.push('/DataIngestion/ConfigureProgram')
    if (postData === 'success') {
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'success',
          message: param.message,
        },
      });
    } else {
      postData = JSON.parse(postData)
      if (HasProperty(postData, 'Code') && postData.Code === "2") {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'error',
            message: postData.Message,
          },
        });
      } else {
        dispatch({
          type: SET_SHOULD_SHOW_TOASTER_NOTIF,
          toasterParam: {
            show: true,
            type: 'error',
            message: "Reminder notification is not sent!",
          },
        });
      }
    }
    return result
  } catch (error) {
    console.log(error);
  }
}

export const clearRVDCreationYearList = () => (dispatch, state) => {
  try {
    dispatch({
      type: GET_PROJECT_CREATION_YEAR_LIST,
      creationYearList: [],
    });

    dispatch({
      type: ON_SELECT_CREATION_YEAR_OPTIONS,
      creationYearValue: [],
      creationYear: "",
    });
  } catch (e) {
    console.log(e);
  }
}