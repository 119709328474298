import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {
  GetApiGlobal,
  HttpGetText,
  HttpPostTextCusURL,
  GetWebGlobal
} from '../../Utilities/HTTPUtil';
import Utilities from '../../Utilities'
// import { history } from '../../store';
import { setUserInformation } from '../../Actions/index';
import { OnLoad } from '../../Utilities/WindowEvents'
import { getDashboardClientList } from '../../Actions/Dashboard.Action';
import { SetMenus } from '../../Actions/Shared.Action'
import Cipher from '../../Utilities/Cipher';
import {
  GetReferrer,
  GetDistiURL,
  GetGDURLParams
} from '../../Utilities/AccessManagementUtil';
import LoadingScreen from '../Shared/LoadingScreen/LoadingScreen';
import { version } from '../../../../package.json'


const _Authenticate = (function () {
  const url = window.location.href.toLowerCase().includes('dashboard/global')
  const disti = GetDistiURL().toLowerCase().includes('dashboard/global')
  const isGoingDG = disti || url;
  const fromGlobal = GetReferrer() === '' ? false : String(GetWebGlobal()).toLowerCase().includes(String(GetReferrer()).toLowerCase());

  class Authenticate extends Component {
    async componentWillMount() {
      this.validateUser();
    }
    componentDidMount(){
      
      // Hard Refresh after every release
      let currentVersion = localStorage.getItem("currentVersion"); // Replace with your application's version

      if (currentVersion !== version) {

        if ('caches' in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
              caches.delete(name);
            })
          });
          // Perform a hard refresh

        }
        localStorage.clear();
        localStorage.setItem('currentVersion', version);
        window.location.reload(true);

      }


    }

    validateUser = async () => {
      const isValidUser = await this.validateGlobalUser();
      if (isValidUser) {
        await this.getEID();
        OnLoad()
      } else this.props.history.push('/Accessdenied');
    };

    validateGlobalUser = async () => {
      let isValidUser = true;
      try {
        const urlParam = GetGDURLParams();

        //IF FROM GLOBAL AND IS TRYING TO ACCESS DASHBOARD GLOBAL THEN
        if (fromGlobal && isGoingDG) {
          const body = {
            clientProgramId: urlParam.programID,
            isPreview: urlParam.preview,
            masterClientId: urlParam.clientID
          };
          isValidUser = await HttpPostTextCusURL(GetApiGlobal() + 'Synops/userValidate', body);
          isValidUser = String(isValidUser).toLowerCase() === 'true';
          if (isValidUser)
            isValidUser = !isNaN(
              await HttpGetText('GlobalDashboard/AddUpdateGlobalUser')
            );
        } else if (isGoingDG) {
          isValidUser = false;
        }
      } catch {
        isValidUser = false;
      }
      return isValidUser;
    };

    getEID = async () => {
      let data = await HttpGetText('user/getUserInfo');
      let token = await Utilities.getToken();
      if (data !== '') {

        // Hard Refresh
        const isHardRefresh = sessionStorage.getItem("hard-refresh");
        if (!isHardRefresh) {
          sessionStorage.setItem("hard-refresh", true);
          window.location.reload(true);
        }

        


        // Your application code follows

        data = JSON.parse(data);
        if (String(data.UserID) !== '0' && data.IsActive && !data.IsDeleted) {
          this.props.SetUserInformation({
            userID: parseInt(data.UserID),
            userName: data.UserName,
            emailAddress: data.UserEmailID,
            enterpriseID: data.UserEnterpriseID,
            userProfiles: data.userProfiles !== null ? data.userProfiles : [],
            isSuperAdmin: data.IsSuperAdmin,
            // isSuperAdmin: data.IsSuperAdmin ||
            //   (data.userProfiles !== null ? data.userProfiles.some((x) => x.ProfileID === 8) : false),
            //isIOAdmin: data.userProfiles !== null ? data.userProfiles.some((x) => x.ProfileID === 8) : false,
            token: token,
            currentUserRole: '',
            currentUserRoleID: '',
            isGlobalUser: data.IsGlobalUser,
            fromGlobal: fromGlobal
          });
          this.props.SetMenus(data.userProfiles)

          const dashboardClients = window.location.href.toLowerCase().includes('dashboard/global')
            ? []
            : await this.props.GetDashboardClientList(parseInt(data.UserID));
          let hasNoAccess = true;
          if (data.userProfiles !== null) {
            data.userProfiles.map((profile) => {
              profile.AccessLevel.map((access) => {
                access.subMenu.map((subMenu) => {
                  if (subMenu.SubMenuName === 'Application Home Page') {
                    hasNoAccess = false;
                  }
                });
              });
            });
          }
          if (
            window.location.href.toLowerCase().includes('dashboard/global') &&
            data.IsGlobalUser
          ) {
          } else if (hasNoAccess && dashboardClients.length === 0) {
            this.props.history.push('/Accessdenied');
          }
        } else {
          this.props.history.push('/Accessdenied');
        }
      } else {
        this.props.history.push('/Accessdenied');
      }
    };

    render() {
      if (this.props.userInformation.userID > 0) {
        const ChildComponent = this.props.component;
        return <ChildComponent match={this.props.match} />;
      } else if (this.props.userInformation.userID > 0) {
        return <Redirect to="/AccessDenied" />;
      } else {
        return <LoadingScreen text="Verifying User" />;
      }
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      SetUserInformation: userInfo => dispatch(setUserInformation(userInfo)),
      GetDashboardClientList: userId => dispatch(getDashboardClientList(userId)),
      SetMenus: menu => dispatch(SetMenus(menu))
    };
  }
  function mapStateToProps(state) {
    return {
      userInformation: state.userInformation,
      DashboardData: state.DashboardData
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
})();

export default function AuthenticatedRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => <_Authenticate {...props} component={Component} />} />;
}