import {
  GET_CLIENT_DETAILS,
  FETCH_OFFERING,
  FETCH_SUB_OFFERING,
  FETCH_PROGRAM_DATA,
  FETCH_CXLEADLIST,
  FETCH_CONFIGURATORSLIST,
  FETCH_OALEADLIST,
  FETCH_IOA_APPROVERLIST,
  FETCH_CREATED_USER,
  GET_PROGRAM_ACCT_LEADERSHIP_DETAILS,
  GET_BOIMAPPING_LIST,
  GET_CUSTOMBOIDROPDOWN_LIST,
  GET_PROGRAM_BOI,
  SET_PROGRAM_LIST,
  SET_SELECTED_PROGRAM_DETAILS,
  SET_SELECTED_PROGRAM,
  SET_NEW_PROGRAM_DETAILS,
  GET_PROGRAM_CA_DETAILS,
  GET_CA_FILE_DATA,
  GET_REJECTED_DIMENSION,
  CLEAR_ALL_PROGRAM_PROJECT_FIELDS,
  SET_SFA_PARAM,
  SET_PROGRAM_SFA,
  SET_PROGRAM_SFA_PAYLOAD,
  SET_PROGRAM_RESEND_PAYLOAD,
  SET_PROGRAM_UPLOAD,
  SET_PROGRAM_UPLOAD_FILE,
  GET_PROGRAM_EDIT_BOI,
  GET_PROGRAM_STAKEHOLDERS,
  IS_PROGPROJ_DROPDOWN,
  GET_PROGRAM_DELETED_BOI,
  IS_CHECKED_ALL_OFFERINGS,
  GET_BOI_ALL_OFFERINGS,
  PREP_DROPDOWN_LIST_BY_OFFERING,
  PREP_DROPDOWN_LIST_ALL_OFFERING,
  SET_NEWLY_ADDED_STAKEHOLDER,
  SET_SHOULD_SHOW_TOASTER_NOTIF,
  ON_SEND_REMINDER_PROGRAM_APPROVAL
} from '../Constants';

import { history } from '../store';
import { HttpGet, HttpPostText, HttpPost, GetSubMenuId } from '../Utilities/HTTPUtil';
import { IsValid, HasProperty, IsValidStr } from '../Utilities/Validations';
import { formatAMPM, TryGetNodeAlt } from '../Utilities/Formatter';

import { setToasterParam, setEmailError } from './Shared.Action';
import { getProjectStakeHolders } from './ProjectConfiguration.Action';
import { LogPPCUserAction } from '../Utilities/AccessManagementUtil';
import { PPC_NEW_PROG, PROG_ID_VAL_SETT } from '../Constants/Modules'

export const getProgramClientDetails = (clientID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Config Module landing page")
    let data = await HttpGet(`ProgramConfiguration/GetClientDetail?ClientID=${clientID}&SubMenuID=${subMenuId}`);
    dispatch(getProjectStakeHolders(data.ClientMasterID));
    dispatch({
      type: GET_CLIENT_DETAILS,
      programClientDetails: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const programNameValidation = (programName) => async (dispatch, state) => {
  try {
    let clientID = state().SharedData.selectedClient.value
    let subMenuId = GetSubMenuId("Add New Program");
    let validation = await HttpGet(
      `ProgramConfiguration/CheckProgramName?ClientID=${clientID}&ProgramName=${programName}&SubMenuID=${subMenuId}`
    );
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    newProgramDetails.validation = validation;
    dispatch({
      type: SET_NEW_PROGRAM_DETAILS,
      newProgramDetails: newProgramDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchAddNewProgramDetails = (clientID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Config Module landing page");
    const data = !IsValid(clientID)
      ? []
      : await HttpGet(
        `ProgramConfiguration/GetOfferingSubOfferingandStakeHolders?clientID=${clientID}&SubMenuID=${subMenuId}`
      );
    let offeringList = [];
    let tempCXLeadList = [];
    let CXLeadList = [];
    // let tempBTLeadList = [];
    // let BTLeadList = [];
    let tempConfiguratorsList = [];
    let configuratorsList = [];
    let tempOALeadList = [];
    let OALeadList = [];
    let tempIOAList = [];
    let tempIOAListRef = [];
    let IOAList = [];
    let updatedUser = '';
    tempCXLeadList = data.ClientStakeHolders.filter((item) => item.ProfileID === 7);
    tempCXLeadList.map((item) => {
      CXLeadList.push({
        text: item.UserEmailID,
        label: item.UserEmailID,
        value: item.UserEmailID,
      });
    });

    tempIOAList = data.IOApproverMappedClients.filter((item) => item.ClientID === clientID);
    tempIOAList.map((item) => {
      IOAList.push({
        text: item.UserEmailID,
        label: item.UserEmailID,
        value: item.UserEmailID,
        subOfferingID: item.SubOfferingID,
      });
    });

    tempOALeadList = data.ClientStakeHolders.filter((item) => item.ProfileID === 4);
    tempOALeadList.map((item) => {
      OALeadList.push({
        text: item.UserEmailID,
        label: item.UserEmailID,
        value: item.UserEmailID,
      });
    });

    tempConfiguratorsList = data.ClientStakeHolders.filter((item) => item.ProfileID === 12 || item.ProfileID === 15);
    tempConfiguratorsList.map((item) => {
      configuratorsList.push({
        text: item.UserEmailID,
        label: item.UserEmailID,
        value: item.UserEmailID,
        id: item.ProfileID
      });
    });

    data.Offerings.map((item) => {
      offeringList.push({
        text: item.Offering,
        value: item.Offering,
        subOffering: item.SubOfferings,
        value: item.Id,
      });
    });

    updatedUser = state().userInformation.userName;
    dispatch({
      type: FETCH_OFFERING,
      offeringList: offeringList,
    });

    dispatch({
      type: FETCH_CXLEADLIST,
      CXLeadList: CXLeadList,
    });

    dispatch({
      type: FETCH_CONFIGURATORSLIST,
      ConfiguratorsList: configuratorsList.filter((ele, ind) => ind === configuratorsList.findIndex(elem => elem.text === ele.text)),
    });

    dispatch({
      type: FETCH_OALEADLIST,
      OALeadList: OALeadList,
    });

    dispatch({
      type: FETCH_IOA_APPROVERLIST,
      IOAApproverList: IOAList,
    });

    dispatch({
      type: FETCH_PROGRAM_DATA,
      programData: data,
    });

    dispatch({
      type: FETCH_CREATED_USER,
      updatedUser: updatedUser,
    });
  } catch (err) {
    console.log(err);
  }
};
export const setSubOfferingList = () => async (dispatch, state) => {
  try {
    let offeringId = state().ProgramConfigurationData.newProgramDetails.offering.value;
    let offering = state().ProgramConfigurationData.newProgramDetails.offering.text;
    let offerings = state().ProgramConfigurationData.offeringList;
    let initialSubOfferings = state().ProgramConfigurationData.newProgramDetails.subOffering;
    let isCreating =
      state().ProgramConfigurationData.selectedProgramDetails.Status !== 'In Progress' &&
      state().ProgramConfigurationData.selectedProgramDetails.Status !== undefined;
    let subOfferings = [];
    offerings
      .filter((off) => off.value === offeringId || offering === 'Overall')
      .forEach((off) => {
        off.subOffering.forEach((suboff) => {
          let fixSubOffering =
            initialSubOfferings.some((val) => suboff.ID === val.value) && isCreating;
          subOfferings.push({
            label: suboff.SubOfferingName,
            value: suboff.ID,
            isFixed: fixSubOffering,
            isDisabled: fixSubOffering,
          });
        });
      });

    dispatch({
      type: FETCH_SUB_OFFERING,
      subOfferingList: subOfferings,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setNewProgramDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_NEW_PROGRAM_DETAILS,
      newProgramDetails: data,
    });
    dispatch(setSubOfferingList());
  } catch (err) {
    console.log(err);
  }
};

export const getProgramBOI = (programID) => async (dispatch, state) => {
  try {
    let progID =
      programID === undefined
        ? state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID
        : programID;
    let subMenuId = GetSubMenuId("Config Module landing page");
    let progIdentifiedResData = await HttpGet(
      `ProgramConfiguration/GetProgramDimensionStatus?programID=${progID}&SubMenuID=${subMenuId}`
    );
    let dimensionList = [];
    let rejectedDimensionList = [];
    let conditionalDimensionList = [];
    let selectedProgramDetails = state().ProgramConfigurationData.selectedProgramDetails;
    let programSFA = [];
    let rejectedSFA = [];

    // program identified
    if (Object.keys(progIdentifiedResData).length > 0) {
      dimensionList = progIdentifiedResData.map((dim) => {
        return {
          dimensionName: dim.CoreDimensionName,
          dimensionID: dim.CoreDimensionID,
          dimensionReason: dim.CoreDimensionReason,
          dimensionStatus: dim.CoreDimensionStatus,
          dimensionTarget: dim.CoreDimensionTarget,
          subDimensionList: dim.SubDimensionList.map((sDim) => {
            return {
              subDimensionName: sDim.SubDimensionName,
              subDimensionID: sDim.SubDimensionID,
              subDimensionReason: sDim.SubDimensionReason,
              subDimensionStatus: sDim.SubDimensionStatus,
              subDimensionTarget: sDim.SubDimensionTarget,
              boiList: sDim.BOIList.map((boiItem) => {
                return {
                  boiID: boiItem.BOIID,
                  boiName: boiItem.BOIName,
                  paramValue: boiItem.BOITarget,
                  oldParamValue: boiItem.BOITarget,
                  uom: boiItem.UOM,
                  comment: boiItem.BOIReason,
                  description: boiItem.BOIDescription,
                  boiRealizedValue: boiItem.BOIRealizedValue,
                  boiStatus: boiItem.BOIStatus,
                  boiReason: boiItem.BOIReason,
                  isActive: boiItem.IsActive,
                  boiParentID: boiItem.BoiParentID,
                  boiClientProgramTargetID: boiItem.ClientProgramTargetID,
                  boiRejectionRecipient: boiItem.RejectionRecipient,
                  boiCode: boiItem.BOICode,
                  aggregationLogic: boiItem.AggregationLogic,
                  aggregationLogicMasterID: boiItem.AggregationLogicMasterID,
                  ClientName: boiItem.ClientName,
                  offeringName: boiItem.Offering,
                  leadingIndicator: boiItem.LeadingIndicator,
                  boiDefinition: boiItem.MetricCalculation,
                  boiDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                  IsCustom: boiItem.IsCustom,
                  isBOIUsedInProject: boiItem.IsBOIUsedInProject,
                  customParameterList: boiItem.CustomParameterList,
                  reasonForNoBOIDefinition: boiItem.ReasonForNoBOIDefinition,
                  reasonForNoAggregationLogic: boiItem.ReasonForNoAggregationLogic,
                  subofferingID: boiItem.SubOfferingID,
                  suboffering: boiItem.SubOfferingName,
                  boiAdditionComment: boiItem.BOIAdditionComment,
                  approver: '',
                  isCustomSubDimension: sDim.IsCustom,
                  boiDescription: boiItem.BOIDescription,
                  offeringID: boiItem.OfferingID,
                  subDimensionName: sDim.SubDimensionName,
                  noOfParameter: boiItem.NoOfParameter,
                  loggedInUserName: '',
                  metricSQL: '',
                  projectAggregationSQL: '',
                  programAggregationSQL: '',
                  metricDefinitionID: boiItem.MetricDefinitionID,
                  boiWheelVisibility: boiItem.BOIStatus.toLowerCase() === 'rejected' ? 0 : boiItem.WheelVisibility,
                  parameterList: boiItem.CustomParameterList ? boiItem.CustomParameterList : [],
                  boiParentID: boiItem.BoiParentID,
                  boiOldComment: boiItem.BOIReason, //do not change
                  isDashboardVisibiltyUpdated: boiItem.IsDashboardVisibiltyUpdated === 1,
                  isDisableUpdatingDimension: boiItem.IsDisableUpdatingDimension,
                  isModified: IsValid(boiItem.IsModified) ? boiItem.IsModified : false
                };
              }),
            };
          }),
        };
      });
    }

    let oal = state().ProgramConfigurationData.selectedProgramDetails.OperationsAccountLead;

    programSFA = {
      ProgramID: selectedProgramDetails.ClientProgramID,
      Comments: '',
      ProgramCreator: selectedProgramDetails.ProgramCreator,
      OperationsAccountLead: oal,
      UserName: state().userInformation.userName,
    };
    dispatch({
      type: GET_PROGRAM_BOI,
      dimensionList: dimensionList,
    });

    dispatch({
      type: GET_PROGRAM_EDIT_BOI,
      editDimensionList: dimensionList,
    });

    dispatch({
      type: GET_REJECTED_DIMENSION,
      rejectedDimensionList: dimensionList,
    });

    dispatch({
      type: SET_PROGRAM_SFA,
      programSFA: programSFA,
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProgramDeletedBOISAD = () => async (dispatch, state) => {
  try {
    //call api for getting deleted boi
    const programId = state().ProgramConfigurationData.selectedProgram.value;
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let deletedDimesionList = await HttpGet(
      `ProgramConfiguration/GetInActiveProgramDimensionStatus?programID=${programId}&SubMenuID=${subMenuId}`
    );
    let deletedDimensionResData = deletedDimesionList;
    let deletedDimensionList = [];
    let rejectedSFA = [];

    if (Object.keys(deletedDimensionResData).length > 0) {
      deletedDimensionResData.map((dimensionItem, dimensionIndex) => {
        deletedDimensionList.push({
          dimensionName: dimensionItem.CoreDimensionName,
          dimensionID: dimensionItem.CoreDimensionID,
          dimensionReason: dimensionItem.CoreDimensionReason,
          dimensionStatus: dimensionItem.CoreDimensionStatus,
          dimensionTarget: dimensionItem.CoreDimensionTarget,
          subDimensionList: [],
        });

        if (Object.keys(dimensionItem.SubDimensionList).length > 0) {
          deletedDimensionResData[dimensionIndex].SubDimensionList.map(
            (subDimensionItem, subDimensionIndex) => {
              deletedDimensionList[dimensionIndex].subDimensionList.push({
                subDimensionName: subDimensionItem.SubDimensionName,
                subDimensionID: subDimensionItem.SubDimensionID,
                subDimensionReason: subDimensionItem.SubDimensionReason,
                subDimensionStatus: subDimensionItem.SubDimensionStatus,
                subDimensionTarget: subDimensionItem.SubDimensionTarget,
                boiList: [],
              });

              if (Object.keys(subDimensionItem.BOIList).length > 0) {
                deletedDimensionResData[dimensionIndex].SubDimensionList[
                  subDimensionIndex
                ].BOIList.map((boiItem, boiIndex) => {
                  rejectedSFA.push({
                    ClientProgramTargetID: boiItem.ClientProgramTargetID,
                    CoreDimensionID: dimensionItem.CoreDimensionID,
                    SubDimensionID: subDimensionItem.SubDimensionID,
                    BOIID: boiItem.BOIID,
                    UOM: boiItem.UOM,
                    BOITarget: boiItem.BOITarget,
                    BOIStatus: boiItem.BOIStatus,
                    BOIReason: boiItem.BOIReason,
                    IsActive: boiItem.IsActive ? 1 : 0,

                    BOIName: boiItem.BOIName,
                    Description: boiItem.BOIDescription,
                    rejectionRecipient:
                      boiItem.RejectionRecipient === '' ? 0 : boiItem.RejectionRecipient, //RejectionRecipient
                    approver: '',
                    clientProgramTargetID: boiItem.ClientProgramTargetID,
                    IsCustom: boiItem.IsCustom,
                    isCustomSubDimension: subDimensionItem.IsCustom, ///???
                    boiDescription: boiItem.BOIDescription,
                    offeringID: boiItem.OfferingID,
                    subDimensionName: subDimensionItem.SubDimensionName,
                    boiDefinitionMasterID:
                      boiItem.BOIDefinitionMasterID === '' ? 0 : boiItem.BOIDefinitionMasterID,
                    aggregationLogicMasterID:
                      boiItem.AggregationLogicMasterID === ''
                        ? 0
                        : boiItem.AggregationLogicMasterID,
                    leadingIndicator: boiItem.LeadingIndicator,
                    boiAdditionComment: boiItem.BOIAdditionComment,
                    // boiDeletionReason: "",
                    reasonForNoBOIDefinition: boiItem.ReasonForNoBOIDefinition,
                    reasonForNoAggregationLogic: boiItem.ReasonForNoAggregationLogic,
                    noOfParameter: boiItem.NoOfParameter, ///
                    loggedInUserName: '',
                    metricSQL: '',
                    projectAggregationSQL: '',
                    programAggregationSQL: '',
                    // isDeletedCustomBOI: boiItem.isDeleted,
                    customParameterList: boiItem.CustomParameterList,
                    subOfferingID: boiItem.SubOfferingID,
                    metricDefinitionID: boiItem.MetricDefinitionID,
                    isDeletedOnSAD: true,
                    boiOldComment: boiItem.BOIReason
                  });
                  deletedDimensionList[dimensionIndex].subDimensionList[
                    subDimensionIndex
                  ].boiList.push({
                    boiID: boiItem.BOIID,
                    boiName: boiItem.BOIName,
                    paramValue: boiItem.BOITarget,
                    oldParamValue: boiItem.BOITarget,
                    uom: boiItem.UOM, //
                    comment: boiItem.BOIReason, //
                    description: boiItem.BOIDescription, //
                    boiRealizedValue: boiItem.BOIRealizedValue,
                    boiStatus: boiItem.BOIStatus, //
                    // boiTarget: boiItem.BOITarget, //
                    isActive: boiItem.IsActive ? 1 : 0,
                    boiClientProgramTargetID: boiItem.ClientProgramTargetID, //
                    boiRejectionRecipient: boiItem.RejectionRecipient, //
                    boiCode: boiItem.BOICode, //
                    aggregationLogic: boiItem.AggregationLogic,
                    aggregationLogicMasterID: boiItem.AggregationLogicMasterID,
                    ClientName: boiItem.ClientName,
                    offeringName: boiItem.Offering,
                    leadingIndicator: boiItem.LeadingIndicator,
                    boiDefinition: boiItem.MetricCalculation,
                    boiDefinitionMasterID: boiItem.BOIDefinitionMasterID,
                    IsCustom: boiItem.IsCustom,
                    isBOIUsedInProject: boiItem.IsBOIUsedInProject,
                    customParameterList: boiItem.CustomParameterList,
                    reasonForNoBOIDefinition: boiItem.ReasonForNoBOIDefinition,
                    reasonForNoAggregationLogic: boiItem.ReasonForNoAggregationLogic,
                    subofferingID: boiItem.SubOfferingID,
                    suboffering: boiItem.SubOfferingName,
                    boiAdditionComment: boiItem.BOIAdditionComment,
                    approver: '',
                    isCustomSubDimension: subDimensionItem.IsCustom, ///???
                    boiDescription: boiItem.BOIDescription,
                    offeringID: boiItem.OfferingID,
                    subDimensionName: subDimensionItem.SubDimensionName,
                    noOfParameter: boiItem.NoOfParameter, ///
                    loggedInUserName: '',
                    metricSQL: '',
                    projectAggregationSQL: '',
                    programAggregationSQL: '',
                    metricDefinitionID: boiItem.MetricDefinitionID,
                    isDeletedOnSAD: true,
                    boiOldComment: boiItem.BOIReason
                  });
                });
              }
            }
          );
        }
      });
    }

    dispatch({
      type: GET_PROGRAM_DELETED_BOI,
      deletedDimensionList: deletedDimensionList,
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setDimensionList = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAM_BOI,
      dimensionList: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProgramAcctLeadershipDetails = (clientID) => async (dispatch, state) => {
  try {
    let selectedProgData = state().ProgramConfigurationData.selectedProgramDetails; //change
    let programID = selectedProgData.ClientProgramID; //change
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval")
    let approvalReqResData = await HttpGet(
      `ProgramAccountLeaderShipApproval/GetProgramApprovalRequestStatus?programID=${programID}&SubMenuID=${subMenuId}`
    );
    let approvalReqData = [];

    // approval req
    approvalReqResData.map((item) => {
      if (HasProperty(item, 'ClientProgramID')) {
        let date = new Date(item.SubmittedTS);
        let _local = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - _local);
        let _date = `${String(date.getMonth()).length === 1 ? '0' : ''}${date.getMonth() + 1}/${String(date.getDate()).length === 1 ? '0' : ''
          }${date.getDate()}/${date.getFullYear()}`;
        let _time = formatAMPM(date);
        approvalReqData.push({
          pName: selectedProgData.ClientProgramName,
          lead1: item.RequestCreatedBy,
          lead2: item.OperationsAccountLead,
          submittedDate: _date,
          submittedTime: _time,
          status: item.StatusName,
          resendCounter: item.ResendCounter,
          requestorComment: item.RequestorComment,
        });
      }
    });

    dispatch({
      type: GET_PROGRAM_ACCT_LEADERSHIP_DETAILS,
      arsDetails: approvalReqData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getProgramList = (clientID) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Program Identified Value Settings");
    let data = await HttpGet(`ProgramConfiguration/GetClientProgramsDetail?ClientID=${clientID}&SubMenuID=${subMenuId}`);
    dispatch({
      type: SET_PROGRAM_LIST,
      programList: data,
    });
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const setProgramDetails = (data, module) => async (dispatch) => {
  try {
    dispatch(getProgramStakeholders(data.ClientProgramID, module));
    dispatch({
      type: SET_SELECTED_PROGRAM_DETAILS,
      selectedProgramDetails: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getBOIMappingList = (ProgramID) => async (dispatch, state) => {
  try {
    let data = await HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=false`
    );

    dispatch({
      type: GET_BOIMAPPING_LIST,
      boiMappingList: data,
    });

    dispatch(prepDropdownListByOffering(data, false));
  } catch (err) {
    console.log(err);
  }
};

export const getBOIMappingListAllOfferings = (ProgramID) => async (dispatch, state) => {
  try {

    let data0 = null;
    let data1 = null;
    let data2 = null;
    let data3 = null;
    let data4 = null;
    let data5 = null;

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=0`
    ).then((data) => {
      data0 = data;
      if (data1 !== null && data2 !== null && data3 !== null && data4 !== null && data5 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=1`
    ).then((data) => {
      data1 = data;
      if (data0 !== null && data2 !== null && data3 !== null && data4 !== null && data5 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=2`
    ).then((data) => {
      data2 = data;
      if (data0 !== null && data1 !== null && data3 !== null && data4 !== null && data5 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=3`
    ).then((data) => {
      data3 = data;
      if (data1 !== null && data0 !== null && data2 !== null && data4 !== null && data5 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=4`
    ).then((data) => {
      data4 = data;
      if (data1 !== null && data2 !== null && data0 !== null && data3 !== null && data5 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true&flag=5`
    ).then((data) => {
      data5 = data;
      if (data1 !== null && data2 !== null && data0 !== null && data3 !== null && data4 !== null) {
        data0.BIOMappingList = data1.BIOMappingList.concat(data2.BIOMappingList).concat(data3.BIOMappingList);
        data0.BOIList = data4.BOIList;
        data0.BOIUnitOfMeasureList = data5.BOIUnitOfMeasureList;
        dispatch({
          type: GET_BOI_ALL_OFFERINGS,
          boiMappingListAllOfferings: data0,
        });
        dispatch(prepDropdownListByOffering(data0, true));
      }
    });

    // let data = await HttpGet(
    //   `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingLists?programID=${ProgramID}&allOfferings=true`
    // );
    // dispatch({
    //   type: GET_BOI_ALL_OFFERINGS,
    //   boiMappingListAllOfferings: data,
    // });

    // dispatch(prepDropdownListByOffering(data, true));
  } catch (err) {
    console.log(err);
  }
};

export const prepDropdownListByOffering = (data, status) => async (dispatch, state) => {
  try {
    let templist = [];
    let boi = [];
    if (data.BOIList.length > 0) {
      data.BOIList.map((item) => {
        //filters out agnostic boi from standard boi dropdown list
        if (item.BoiParentID === 1) {
          if (templist.indexOf(item.BOIName) === -1) {
            boi.push({ label: item.BOIName, text: item.BOIName, value: item.BOIID });
            // saving on a templist to serve as a reference to avoid adding duplicates
            templist.push(item.BOIName);
          }
        }
      });
    }
    if (status) {
      dispatch({
        type: PREP_DROPDOWN_LIST_ALL_OFFERING,
        dropdownListAllOfferings: boi,
      });
    } else {
      dispatch({
        type: PREP_DROPDOWN_LIST_BY_OFFERING,
        dropdownListByOfferings: boi,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCustomBOIDropdownList = (ProgramID, module) => async (dispatch, state) => {
  try {
    let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : '';
    let data = await HttpGet(`CustomBOI/GetCustomBOIsMasterData?programID=${ProgramID}${IsValidStr(module) ? `&SubMenuID=${subMenuId}` : ''}`);
    dispatch({
      type: GET_CUSTOMBOIDROPDOWN_LIST,
      customBOIDropdownList: data,
    });
  } catch (err) {
    console.log(err);
  }
};

//Updated to sub offering
export const getBOIMappingListByOffering = (subOfferingId, module) => async (dispatch, state) => {
  try {
    let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : "";
    let data = await HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingListsofOffering?subofferingID=${subOfferingId}&allOfferings=false${IsValidStr(module) ? `&SubMenuID=${subMenuId}` : ''}`
    );

    dispatch({
      type: GET_BOIMAPPING_LIST,
      boiMappingList: data,
    });

    dispatch(prepDropdownListByOffering(data, false));
  } catch (err) {
    console.log(err);
  }
};

export const getBOIMappingListALLOffering = (subOfferingId, module) => async (dispatch, state) => {
  try {
    let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : "";
    let data = await HttpGet(
      `NewProgramIdentifiedValueSetting/GetBOIDimensionMappingListsofOffering?subofferingID=${subOfferingId}&allOfferings=true${IsValidStr(module) ? `&SubMenuID=${subMenuId}` : ''}`
    );

    dispatch({
      type: GET_BOI_ALL_OFFERINGS,
      boiMappingListAllOfferings: data,
    });

    dispatch(prepDropdownListByOffering(data, true));
  } catch (err) {
    console.log(err);
  }
};

export const setSelectedProgram = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_PROGRAM,
      selectedProgram: data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getProgramCADetails = (ProgramID) => async (dispatch, state) => {
  try {
    let programID = state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
    let statusID = state().ProgramConfigurationData.selectedProgramDetails.StatusId;
    let subMenuId = GetSubMenuId("Program Identified Value - Client Alignment");
    let data = await HttpGet(
      `ProgramClientAlignment/getClientAlignmentProgramDetails?clientProgramID=${programID}&statusID=${statusID}&SubMenuID=${subMenuId}`
    );
    let tDetails = {};
    let fileData = [];
    let date = '';

    if (data.programCALList !== undefined && Object.keys(data.programCALList).length > 0) {
      for (var i = 0; i < 3; i++) {
        let fileName =
          i === 0
            ? data.programCALList[0].FirstFileName
            : i === 1
              ? data.programCALList[0].SecondFileName
              : data.programCALList[0].ThirdFileName;
        if (fileName !== null) {
          fileData.push({
            column: i + 1,
            fileName: fileName,
            attachmentID: data.programCALList[0].AttachmentID,
          });
        }
      }

      if (HasProperty(data.programCALList[0], 'ApprovedOn')) {
        date = new Date(data.programCALList[0].ApprovedOn);
        let _local = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - _local);
        date = `${String(date.getMonth()).length === 1 ? '0' : ''}${date.getMonth() + 1}/${String(date.getDate()).length === 1 ? '0' : ''
          }${date.getDate()}/${date.getFullYear()}`;
      }

      tDetails.approvedBy =
        data.programCALList[0].ApprovedBy === null ? '' : data.programCALList[0].ApprovedBy;
      tDetails.approvedOn = data.programCALList[0].ApprovedOn === null ? '' : date;
      tDetails.approverComments =
        data.programCALList[0].Comment === null ? '' : data.programCALList[0].Comment;
    }

    dispatch({
      type: GET_CA_FILE_DATA,
      programCAFileDetails: fileData,
    });

    dispatch({
      type: GET_PROGRAM_CA_DETAILS,
      programCADetails: tDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeRejectedProgramValue = (data, type) => async (dispatch, state) => {
  try {
    let param = [];
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    data.map((dimension, dimensionIndex) => {
      dimension.subDimensionList.map((subDimension, subDimensionIndex) => {
        subDimension.boiList.map((boi, boiIndex) => {
          param.push({
            ClientProgramTargetID: boi.boiClientProgramTargetID,
            CoreDimensionID: dimension.dimensionID,
            SubDimensionID: subDimension.subDimensionID,
            BOIID: boi.boiID,
            BOITarget: boi.paramValue,
            BOIStatus: boi.boiStatus,
            BOIReason: boi.comment,
            UOM: boi.uom,
            IsActive: 1,
            BOIName: boi.boiName,
            Description: boi.description,
            rejectionRecipient: '',
            approver: '',
            clientProgramTargetID: boi.boiClientProgramTargetID,
            IsCustom: boi.IsCustom,
            isCustomSubDimension: subDimension.subDimensionID === 0 ? true : false,
            boiDescription: boi.description,
            offeringID:
              newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
            subDimensionName:
              subDimension.subDimensionName === 'Add new Subdimension'
                ? boi.newSubDimensionName
                : subDimension.subDimensionName,
            boiDefinitionMasterID: boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
            aggregationLogicMasterID:
              boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
            leadingIndicator: boi.leadingIndicator,
            boiAdditionComment: boi.comment,
            reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
            reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
            noOfParameter: boi.noOfParameter,
            loggedInUserName: '',
            metricSQL: '',
            projectAggregationSQL: '',
            programAggregationSQL: '',
            customParameterList: boi.customParameterList,
            subOfferingID: boi.subofferingID,
            metricDefinitionID: boi.metricDefinitionID,
            boiOldComment: boi.boiOldComment
          });
        });
      });
    });

    dispatch({
      type: GET_REJECTED_DIMENSION,
      rejectedDimensionList: data,
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: param,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findDeletedDimension = () => async (dispatch, state) => {
  try {
    let rejectedDimensionList = state().ProgramConfigurationData.editDimensionList;

    rejectedDimensionList = rejectedDimensionList
      .map((e) => {
        const subD = e.subDimensionList
          .map((e2) => {
            const tBoiL = e2.boiList.filter((e3) => {
              return e3.isActive === 0;
            });
            return { ...e2, boiList: tBoiL };
          })
          .filter((e22) => {
            return e22.boiList.length > 0;
          });

        return { ...e, subDimensionList: subD };
      })
      .filter((e4) => {
        return e4.subDimensionList.length > 0;
      });

    let editDimensionList = state().ProgramConfigurationData.editDimensionList;
    let rejectedSFA = state().ProgramConfigurationData.rejectedSFA;
    let deletedDimension = [];
    let eDimID = [];
    let _dim = [];
    let selectedProgram = state().ProgramConfigurationData.selectedProgramDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;

    editDimensionList.forEach((eDim) => {
      eDim.subDimensionList.forEach((eSubDim) => {
        eSubDim.boiList.forEach((eBoi) => {
          eDimID = [...eDimID, eBoi.boiClientProgramTargetID];
        });
      });
    });

    rejectedDimensionList.map((dim, dimIndex) => {
      dim.subDimensionList.map((subD, subDimIndex) => {
        subD.boiList.map((boi, boiIndex) => {
          rejectedSFA.push({
            ClientProgramID: selectedProgram.ClientProgramID ? selectedProgram.ClientProgramID : 0,
            CoreDimensionID: dim.dimensionID,
            SubDimensionID: subD.subDimensionID,
            BOIID: boi.boiID,
            UOM: boi.uom,
            BOITarget: boi.paramValue,
            BOIStatus: boi.boiStatus,
            BOIReason: boi.boiReason,
            IsActive: boi.isActive ? 1 : !boi.isActive ? 0 : boi.isActive,
            BOIName: boi.boiName,
            Description: boi.description,
            rejectionRecipient: '',
            approver: '',
            clientProgramTargetID: boi.boiClientProgramTargetID,
            IsCustom: boi.IsCustom,
            isCustomSubDimension: subD.subDimensionID === 0 ? true : false,
            boiDescription: boi.description,
            offeringID:
              newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
            subDimensionName: subD.subDimensionName,
            boiDefinitionMasterID: boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
            aggregationLogicMasterID:
              boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
            leadingIndicator: boi.leadingIndicator,
            boiAdditionComment: boi.comment,
            // boiDeletionReason: "",
            reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
            reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
            noOfParameter: boi.noOfParameter,
            loggedInUserName: '',
            metricSQL: '',
            projectAggregationSQL: '',
            programAggregationSQL: '',
            // isDeletedCustomBOI: boi.isDeleted,
            customParameterList: boi.customParameterList,
            subOfferingID: boi.subofferingID,
            metricDefinitionID: boi.metricDefinitionID,
            boiOldComment: boi.boiOldComment
          });
        });
      });
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateRejectedDimensions = (rejected, shouldUpdate) => async (dispatch, state) => {
  try {
    let dimensionList = [];
    let param = [];

    dimensionList = rejected
      .map((e) => {
        const subD = e.subDimensionList
          .map((e2) => {
            const tBoiL = e2.boiList.filter((e3) => {
              return e3.isActive === 0;
            });
            return { ...e2, boiList: tBoiL };
          })
          .filter((e22) => {
            return e22.boiList.length > 0;
          });

        return { ...e, subDimensionList: subD };
      })
      .filter((e4) => {
        return e4.subDimensionList.length > 0;
      });

    if (shouldUpdate) {
      dispatch({
        type: GET_REJECTED_DIMENSION,
        rejectedDimensionList: rejected,
      });
    }

    dispatch({
      type: GET_PROGRAM_EDIT_BOI,
      editDimensionList: rejected,
    });

    let selectedProgram = state().ProgramConfigurationData.selectedProgramDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;

    dimensionList.map((dimension, dimensionIndex) => {
      dimension.subDimensionList.map((subDimension, subDimensionIndex) => {
        subDimension.boiList.map((boi, boiIndex) => {
          param.push({
            ClientProgramID: selectedProgram.ClientProgramID ? selectedProgram.ClientProgramID : 0,
            CoreDimensionID: dimension.dimensionID,
            SubDimensionID: subDimension.subDimensionID,
            BOIID: boi.boiID,
            UOM: boi.uom,
            BOITarget: boi.paramValue,
            BOIStatus: boi.boiStatus,
            BOIReason: boi.boiReason,
            IsActive: boi.isActive,
            BOIName: boi.boiName,
            Description: boi.description,
            rejectionRecipient: '',
            approver: '',
            clientProgramTargetID: boi.boiClientProgramTargetID,
            IsCustom: boi.IsCustom,
            isCustomSubDimension: subDimension.subDimensionID === 0 ? true : false,
            boiDescription: boi.description,
            offeringID:
              newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
            subDimensionName:
              subDimension.subDimensionName === 'Add new Subdimension'
                ? boi.newSubDimensionName
                : subDimension.subDimensionName,
            boiDefinitionMasterID: boi.boiDefinitionMasterID === '' ? 0 : boi.boiDefinitionMasterID,
            aggregationLogicMasterID:
              boi.aggregationLogicMasterID === '' ? 0 : boi.aggregationLogicMasterID,
            leadingIndicator: boi.leadingIndicator,
            boiAdditionComment: boi.boiAdditionComment, //please don't change this to comment again
            // boiDeletionReason: "",
            reasonForNoBOIDefinition: boi.reasonForNoBOIDefinition,
            reasonForNoAggregationLogic: boi.reasonForNoAggregationLogic,
            noOfParameter: boi.noOfParameter,
            loggedInUserName: '',
            metricSQL: '',
            projectAggregationSQL: '',
            programAggregationSQL: '',
            // isDeletedCustomBOI: boi.isDeleted,
            customParameterList: boi.customParameterList,
            subOfferingID: boi.subofferingID,
            metricDefinitionID: boi.metricDefinitionID,
            boiOldComment: boi.boiOldComment
          });
        });
      });
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: param,
    });
    setTimeout(() => {
      if (document.getElementById('expandAll')) {
        document.getElementById('expandAll').click();
      }
    }, 300);
  } catch (err) {
    console.log(err);
  }
};

export const saveAsDraftProgramIdentified = (body) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Program Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProgramIdentifiedValueSetting/SaveAsDraftProgramIdentifiedValues?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      history.push('/ProgramProjectConfig');
      if (!IsValid(TryGetNodeAlt(state().ProgramConfigurationData.selectedProgramDetails, undefined, 'ClientProgramID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: Postdata.Code,
          progStatus: PPC_NEW_PROG,
          module: PROG_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );
      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      dispatch(
        setToasterParam({
          show: true,
          type: 'error',
          message: Postdata.Message,
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendForApprovalProgramIdentified = (body) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Program Identified Value Settings");
    let Postdata = await HttpPost(
      `NewProgramIdentifiedValueSetting/SendNotificationForApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata.Message.toLowerCase() === 'success') {
      history.push('/ProgramProjectConfig');
      if (!IsValid(TryGetNodeAlt(state().ProgramConfigurationData.selectedProgramDetails, undefined, 'ClientProgramID'))) {
        LogPPCUserAction({
          clientID: state().SharedData.selectedClient.value,
          ClientProgramID: Postdata.Code,
          progStatus: PPC_NEW_PROG,
          module: PROG_ID_VAL_SETT
        })
      }
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Program was sent for approval!',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      dispatch(
        setEmailError({
          show: true,
          message: Postdata.Message
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendEditApproved = (comment, list, approverEmails) => async (dispatch, state) => {
  try {
    let progSFAPayload = state().ProgramConfigurationData.progSFAPayload;
    progSFAPayload.Comments = comment;
    progSFAPayload.BOIDescrip = comment;
    let loggedInUserName = progSFAPayload.UserName;

    dispatch({
      type: SET_PROGRAM_SFA_PAYLOAD,
      progSFAPayload: progSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    let programDetails = state().ProgramConfigurationData.selectedProgramDetails;
    let userInfo = state().userInformation;

    let subOfferingList = state().ProgramConfigurationData.newProgramDetails.subOffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = programDetails.OperationsAccountLead;
    let configuratorsList = state().ProgramConfigurationData.ConfiguratorsList
    let progCreator = userInfo.isSuperAdmin
      ? newProgramDetails.progCreator.label
      : userInfo.emailAddress
    let profileiId = 12;
    if (configuratorsList) {
      const configuratorProfile = configuratorsList.find(x => x.label === newProgramDetails.progCreator.label);
      if (configuratorProfile) profileiId = configuratorProfile.id;
    }

    let body = {
      programID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,
      offeringID: newProgramDetails.offering.value,
      clientOfferingID: newProgramDetails.offering.value,
      clientProgramName: newProgramDetails.programName,
      reportingYear: 0,
      ProgramCreator: progCreator,
      programCreatorProfileId: profileiId,
      cxlLead: newProgramDetails.cxLead.label,
      cxlLead: newProgramDetails.cxLead.label,
      oaLead: newProgramDetails.oaLead.label,
      ioApprover: newProgramDetails.ioApprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProgramSubOfferingList: subOfferingListTemp,
      clientProgramTargetList: list,
      allOfferings: state().ProgramConfigurationData.isCheckedAllOfferings,
      MailTo: approverEmails.join(";")
    };
    body = {
      ...body,
      clientProgramTargetList: body.clientProgramTargetList.map((e) => {
        return {
          ...e,
          BOIStatus: e.BOIStatus,
          BOIReason: e.BOIReason,
        };
      }),
    };
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval")
    let Postdata = await HttpPostText(
      `NewProgramIdentifiedValueSetting/SendForApprovalForApprovedProgram?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      history.push('/ProgramProjectConfig');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Program was sent for approval!',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      let errorMessage = JSON.parse(Postdata);
      dispatch(
        setEmailError({
          show: true,
          message: errorMessage.Message,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const saveAsDraftApprovedProgram = (list) => async (dispatch, state) => {
  try {
    const comment = '';
    let progSFAPayload = state().ProgramConfigurationData.progSFAPayload;
    progSFAPayload.Comments = comment;
    progSFAPayload.BOIDescrip = comment;
    let loggedInUserName = progSFAPayload.UserName;

    dispatch({
      type: SET_PROGRAM_SFA_PAYLOAD,
      progSFAPayload: progSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    let programDetails = state().ProgramConfigurationData.selectedProgramDetails;
    let userInfo = state().userInformation;

    let subOfferingList = state().ProgramConfigurationData.newProgramDetails.subOffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = programDetails.OperationsAccountLead;
    let configuratorsList = state().ProgramConfigurationData.ConfiguratorsList
    let progCreator = userInfo.isSuperAdmin
      ? newProgramDetails.progCreator.label
      : userInfo.emailAddress
    let profileiId = 12;
    if (configuratorsList) {
      const configuratorProfile = configuratorsList.find(x => x.label === progCreator);
      if (configuratorProfile) profileiId = configuratorProfile.id;
    }

    let body = {
      programID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,
      offeringID: newProgramDetails.offering.value,
      clientOfferingID: newProgramDetails.offering.value,
      clientProgramName: newProgramDetails.programName,
      reportingYear: 0,
      ProgramCreator: progCreator,
      programCreatorProfileId: profileiId,
      cxlLead: newProgramDetails.cxLead.label,
      oaLead: newProgramDetails.oaLead.label,
      ioApprover: newProgramDetails.ioApprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProgramSubOfferingList: subOfferingListTemp,
      clientProgramTargetList: list,
      allOfferings: state().ProgramConfigurationData.isCheckedAllOfferings,
    };
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval")

    let Postdata = await HttpPostText(
      `NewProgramIdentifiedValueSetting/SaveAsDraftForApprovedProgram?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      history.push('/ProgramProjectConfig');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );
      dispatch(
        setEmailError({
          show: false,
          message: '',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      Postdata = JSON.parse(Postdata)
      if (HasProperty(Postdata, 'Code') && Postdata.Code === '2')
        dispatch(
          setEmailError({
            show: true,
            message: Postdata.Message,
          })
        );
    }
  } catch (err) {
    console.log(err);
  }
};

export const editProgram = (body) => async (dispatch, state) => {
  try {
    let subMenuId = GetSubMenuId("Program Identified Value Settings");
    let Postdata = await HttpPostText(`ProgramConfiguration/EditProgram?SubMenuID=${subMenuId}`, body);
    if (Postdata === 'success') {
      history.push('/ProgramProjectConfig');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Program was updated successfully!',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      Postdata = JSON.parse(Postdata)
      if (HasProperty(Postdata, 'Code') && Postdata.Code === "2") {
        dispatch(
          setToasterParam({
            show: true,
            type: 'error',
            message: Postdata.Message,
          })
        );
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const clearAllProgramProjectFields = () => async (dispatch, state) => {
  try {
    const data = state().ProgramConfigurationData;

    data.selectedProgramDetails = [];
    data.selectedProgram = [];
    data.boiMappingList = [];
    data.customBOIDropdownList = [];
    data.newProgramDetails = {
      offering: {},
      subOffering: [],
      programName: '',
      cxLead: [],
      oaLead: [],
      progCreator: {},
      validation: false,
    };
    data.dimensionList = [];
    dispatch({
      type: CLEAR_ALL_PROGRAM_PROJECT_FIELDS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setProgSFAPayload = () => async (dispatch, state) => {
  try {
    let progSFAPayload = state().ProgramConfigurationData.programSFA;
    let rejectedSFA = state().ProgramConfigurationData.rejectedSFA;

    progSFAPayload['ClientProgramTargetList'] = rejectedSFA;

    dispatch({
      type: SET_PROGRAM_SFA_PAYLOAD,
      progSFAPayload: progSFAPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setDeleteComment = (data, id) => async (dispatch, state) => {
  try {
    let rejectedSFA = state().ProgramConfigurationData.rejectedSFA;
    rejectedSFA.map((reject) => {
      if (reject.BOIID === id) {
        reject.BOIReason = data;
        reject.BOIDeletionReason = data;
      }
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendEditReject = (comment, list, approverEmails) => async (dispatch, state) => {
  // changes
  try {
    let progSFAPayload = state().ProgramConfigurationData.progSFAPayload;
    progSFAPayload.Comments = comment;
    progSFAPayload.BOIDescrip = comment;
    // let rejectedSFA = state().ProgramConfigurationData.rejectedSFA; // changes
    let loggedInUserName = progSFAPayload.UserName;

    dispatch({
      type: SET_PROGRAM_SFA_PAYLOAD,
      progSFAPayload: progSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    let programDetails = state().ProgramConfigurationData.selectedProgramDetails;
    let userInfo = state().userInformation;

    let subOfferingList = state().ProgramConfigurationData.newProgramDetails.subOffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = programDetails.OperationsAccountLead;
    let configuratorsList = state().ProgramConfigurationData.ConfiguratorsList
    let progCreator = userInfo.isSuperAdmin
      ? newProgramDetails.progCreator.label
      : userInfo.emailAddress
    let profileiId = 12;
    if (configuratorsList) {
      const configuratorProfile = configuratorsList.find(x => x.label === progCreator);
      if (configuratorProfile) profileiId = configuratorProfile.id;
    }

    let body = {
      programID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,
      offeringID: newProgramDetails.offering.value,
      clientOfferingID: newProgramDetails.offering.value,
      clientProgramName: newProgramDetails.programName,
      reportingYear: 0,
      programCreator: progCreator,
      programCreatorProfileId: profileiId,
      cxlLead: newProgramDetails.cxLead.label,
      oaLead: newProgramDetails.oaLead.label,
      // "ioApprover": newProgramDetails.ioApprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProgramSubOfferingList: subOfferingListTemp,
      clientProgramTargetList: list, // changes
      IsEdited: programDetails.IsEdited ? 1 : 0,
      MailTo: approverEmails.join(";")
    };
    body = {
      ...body,
      clientProgramTargetList: body.clientProgramTargetList.map((e) => {
        return {
          ...e,
          IsActive: e.IsActive === 0 ? false : true,
          // BOIStatus: e.BOIStatus === 'Rejected' ? '' : e.BOIStatus,
        };
      }),
    };
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProgramIdentifiedValueSetting/SendRejectedForApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      history.push('/ProgramProjectConfig');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Program was sent for approval!',
        })
      );

      dispatch(
        setEmailError({
          show: false,
          message: '',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      Postdata = JSON.parse(Postdata)
      if (HasProperty(Postdata, 'Code') && Postdata.Code === '2') {
        dispatch(
          setEmailError({
            show: true,
            message: Postdata.Message,
          })
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const sendSADEditReject = (list, historyProps) => async (dispatch, state) => {
  try {
    const comment = '';
    let progSFAPayload = state().ProgramConfigurationData.progSFAPayload;
    progSFAPayload.Comments = comment;
    progSFAPayload.BOIDescrip = comment;
    // const rejectedSFA = state().ProgramConfigurationData.rejectedSFA.map((e) => {
    //   let boi = { ...e };
    //   if (e.IsActive === 0) {
    //     boi = {
    //       ...e,
    //       BOIReason: '',
    //       BOIDeletionReason: '',
    //     };
    //   }
    //   return { ...boi };
    // });
    let loggedInUserName = progSFAPayload.UserName;

    dispatch({
      type: SET_PROGRAM_SFA_PAYLOAD,
      progSFAPayload: progSFAPayload,
    });

    let clientDetails = state().ProgramConfigurationData.programClientDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    let programDetails = state().ProgramConfigurationData.selectedProgramDetails;
    let userInfo = state().userInformation;

    let subOfferingList = state().ProgramConfigurationData.newProgramDetails.subOffering;
    let subOfferingListTemp = [];
    subOfferingList.map((item) => {
      subOfferingListTemp.push({
        clientProgramID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
        subOfferingID: item.value,
        isActive: true,
        createdUser: userInfo.enterpriseID,
        updatedUser: userInfo.enterpriseID,
      });
    });
    // make sure it has @accenture.com
    let opsAcctLead = programDetails.OperationsAccountLead;

    let progcreator = userInfo.isSuperAdmin? newProgramDetails.progCreator.label: userInfo.emailAddress
    let body = {
      programID: programDetails.ClientProgramID ? programDetails.ClientProgramID : 0,
      UserName: loggedInUserName,
      comments: comment,
      operationsAccountLead: opsAcctLead,
      isResend: false,
      clientBasicDetailsID: clientDetails.ClientBasicDetailID,

      ProgramCreator: progcreator,
      offeringID: newProgramDetails.offering.value,
      clientOfferingID: newProgramDetails.offering.value,
      clientProgramName: newProgramDetails.programName,
      reportingYear: 0,
      btLead: newProgramDetails.progCreator.label,
      cxlLead: newProgramDetails.cxLead.label,
      oaLead: newProgramDetails.oaLead.label,
      // "ioApprover": newProgramDetails.ioApprover.label,
      clientAdmin: clientDetails.ClientAdmin,
      isActive: true,
      createdUser: userInfo.enterpriseID,
      updatedUser: userInfo.enterpriseID,
      clientProgramSubOfferingList: subOfferingListTemp,
      clientProgramTargetList: list,
    };

    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `NewProgramIdentifiedValueSetting/SaveAsDraftRejectedProgramBOIsForApproval?SubMenuID=${subMenuId}`,
      body
    );
    if (Postdata === 'success') {
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Your Progress was saved as draft successfully!',
        })
      );
      historyProps.push('/ProgramProjectConfig');
      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      dispatch(
        setToasterParam({
          show: true,
          type: 'error',
          message: Postdata,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const setResendPayload = () => async (dispatch, state) => {
  try {
    let progResendPayload = {};
    let arsDetails = state().ProgramConfigurationData.arsDetails;
    progResendPayload.programID =
      state().ProgramConfigurationData.selectedProgramDetails.ClientProgramID;
    progResendPayload.bTLead = arsDetails[arsDetails.length - 1].lead1;
    progResendPayload.userName = state().userInformation.enterpriseID;
    progResendPayload.operationsAccountLead = arsDetails[arsDetails.length - 1].lead2;
    progResendPayload.status = state().ProgramConfigurationData.selectedProgramDetails.StatusId;
    progResendPayload.comments = '';

    dispatch({
      type: SET_PROGRAM_RESEND_PAYLOAD,
      progResendPayload: progResendPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setResendComment = (comment) => async (dispatch, state) => {
  try {
    let progResendPayload = state().ProgramConfigurationData.progResendPayload;

    progResendPayload.comments = comment;

    dispatch({
      type: SET_PROGRAM_RESEND_PAYLOAD,
      progResendPayload: progResendPayload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendProgResend = () => async (dispatch, state) => {
  try {
    let progResendPayload = state().ProgramConfigurationData.progResendPayload;
    let subMenuId = GetSubMenuId("Program Identified Value - Account Leadership Approval");
    let Postdata = await HttpPostText(
      `ProgramAccountLeaderShipApproval/RequestResendForApproval?SubMenuID=${subMenuId}`,
      progResendPayload
    );
    if (Postdata === 'success') {
      history.push('/ProgramProjectConfig');
      dispatch(
        setToasterParam({
          show: true,
          type: 'success',
          message: 'Program was re-sent for approval!',
        })
      );

      dispatch(
        getProgramList(state().ProgramConfigurationData.programClientDetails.ClientMasterID)
      );
    } else {
      dispatch(
        setToasterParam({
          show: true,
          type: 'error',
          message: Postdata,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const setProgUploadFlag = (flag) => async (dispatch, state) => {
  try {
    let progUpload = state().ProgramConfigurationData.progUpload;
    progUpload.Flag = flag;

    dispatch({
      type: SET_PROGRAM_UPLOAD,
      progUpload: progUpload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setProgUploadComment = (comment) => async (dispatch, state) => {
  try {
    let progUpload = state().ProgramConfigurationData.progUpload;
    progUpload.Comment = comment;

    dispatch({
      type: SET_PROGRAM_UPLOAD,
      progUpload: progUpload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setProgUploadFiles = (files) => async (dispatch, state) => {
  try {
    let file = [];
    for (var i = 0; i < files.length; i++) {
      file = file.concat(files[i]);
    }
    dispatch({
      type: SET_PROGRAM_UPLOAD_FILE,
      progUploadFile: file,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getProgramStakeholders = (programId, module) => async (dispatch, state) => {
  try {
    let subMenuId = IsValidStr(module) ? GetSubMenuId(module) : "";
    let data = await HttpGet(`EmailService/GetProgramEmailRecipients?clientProgramId=${programId}${IsValidStr(module) ? `&SubMenuID=${subMenuId}` : ''}`);
    dispatch({
      type: GET_PROGRAM_STAKEHOLDERS,
      programStakeholders: data.ProgramStakeholders,
    });
  } catch (err) {
    console.log(err);
  }
};

export const isProgramProjectFromDropdown = (isSelect) => async (dispatch, state) => {
  try {
    dispatch({
      type: IS_PROGPROJ_DROPDOWN,
      isProgramProjectFromDropdown: isSelect,
    });
  } catch (err) {
    console.log(err);
  }
};

export const paramValueOnChange = (dimensionList) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROGRAM_EDIT_BOI,
      editDimensionList: dimensionList,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setBOIChanges = () => async (dispatch, state) => {
  try {
    let selectedProgram = state().ProgramConfigurationData.selectedProgramDetails;
    let newProgramDetails = state().ProgramConfigurationData.newProgramDetails;
    let rejectedSFA = state().ProgramConfigurationData.rejectedSFA;
    let _editDimensionList = state().ProgramConfigurationData.editDimensionList;
    let newEditDimensionList = [];
    const dimensionList = state().ProgramConfigurationData.rejectedDimensionList;

    dimensionList.map((dim) => {
      dim.subDimensionList.map((subD) => {
        subD.boiList.map((boi) => {
          let editBOI = boi;

          if (editBOI.isActive) {
            rejectedSFA.push({
              ClientProgramID: selectedProgram.ClientProgramID
                ? selectedProgram.ClientProgramID
                : 0,
              CoreDimensionID: dim.dimensionID,
              SubDimensionID: subD.subDimensionID,
              BOIID: editBOI.boiID,
              UOM: editBOI.uom,
              BOITarget: editBOI.paramValue,
              BOIStatus: editBOI.boiStatus,
              BOIReason: editBOI.comment,
              IsActive: editBOI.isActive ? 1 : !editBOI.isActive ? 0 : editBOI.isActive,
              BOIName: editBOI.boiName,
              Description: editBOI.description,
              rejectionRecipient: '',
              approver: '',
              clientProgramTargetID: editBOI.boiClientProgramTargetID,
              IsCustom: editBOI.IsCustom,
              isCustomSubDimension: subD.subDimensionID === 0 ? true : false,
              boiDescription: editBOI.description,
              offeringID:
                newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
              subDimensionName: subD.subDimensionName,
              boiDefinitionMasterID:
                editBOI.boiDefinitionMasterID === '' ? 0 : editBOI.boiDefinitionMasterID,
              aggregationLogicMasterID:
                editBOI.aggregationLogicMasterID === '' ? 0 : editBOI.aggregationLogicMasterID,
              leadingIndicator: editBOI.leadingIndicator,
              boiAdditionComment: editBOI.boiAdditionComment, //change from boi.comment
              // boiDeletionReason: "",
              reasonForNoBOIDefinition: editBOI.reasonForNoBOIDefinition,
              reasonForNoAggregationLogic: editBOI.reasonForNoAggregationLogic,
              noOfParameter: editBOI.noOfParameter,
              loggedInUserName: '',
              metricSQL: '',
              projectAggregationSQL: '',
              programAggregationSQL: '',
              // isDeletedCustomBOI: boi.isDeleted,
              customParameterList: editBOI.customParameterList,
              subOfferingID: editBOI.subofferingID,
              metricDefinitionID: editBOI.metricDefinitionID,
              isDeletedOnSAD: false,
              wheelVisibility: editBOI.boiWheelVisibility ? 1 : 0,
              boiOldComment: editBOI.boiOldComment
            });
          }
        });
      });
    });

    //push boi from editdimensionlist to rejectedsfa
    newEditDimensionList.map((newDim) => {
      newDim.subDimensionList.map((newSubD) => {
        newSubD.boiList.map((newBoi) => {
          if (newBoi.isActive) {
            rejectedSFA.push({
              ClientProgramID: selectedProgram.ClientProgramID
                ? selectedProgram.ClientProgramID
                : 0,
              CoreDimensionID: newDim.dimensionID,
              SubDimensionID: newSubD.subDimensionID,
              BOIID: newBoi.boiID,
              UOM: newBoi.uom,
              BOITarget: newBoi.paramValue,
              BOIStatus: newBoi.boiStatus,
              BOIReason: newBoi.comment,
              IsActive: newBoi.isActive ? 1 : !newBoi.isActive ? 0 : newBoi.isActive,
              BOIName: newBoi.boiName,
              Description: newBoi.description,
              rejectionRecipient: '',
              approver: '',
              clientProgramTargetID: newBoi.boiClientProgramTargetID,
              IsCustom: newBoi.IsCustom,
              isCustomSubDimension: newSubD.subDimensionID === 0 ? true : false,
              boiDescription: newBoi.description,
              offeringID:
                newProgramDetails.offering.value === '' ? 0 : newProgramDetails.offering.value,
              subDimensionName: newSubD.subDimensionName,
              boiDefinitionMasterID:
                newBoi.boiDefinitionMasterID === '' ? 0 : newBoi.boiDefinitionMasterID,
              aggregationLogicMasterID:
                newBoi.aggregationLogicMasterID === '' ? 0 : newBoi.aggregationLogicMasterID,
              leadingIndicator: newBoi.leadingIndicator,
              boiAdditionComment: newBoi.boiAdditionComment, //change from newBoi.comment,
              // boiDeletionReason: "",
              reasonForNoBOIDefinition: newBoi.reasonForNoBOIDefinition,
              reasonForNoAggregationLogic: newBoi.reasonForNoAggregationLogic,
              noOfParameter: newBoi.noOfParameter,
              loggedInUserName: '',
              metricSQL: '',
              projectAggregationSQL: '',
              programAggregationSQL: '',
              // isDeletedCustomBOI: boi.isDeleted,
              customParameterList: newBoi.customParameterList,
              subOfferingID: newBoi.subofferingID,
              metricDefinitionID: newBoi.metricDefinitionID,
              isDeletedOnSAD: false,
              wheelVisibility: newBoi.boiWheelVisibility ? 1 : 0,
              boiOldComment: newBoi.boiOldComment
            });
          }
        });
      });
    });

    dispatch({
      type: SET_SFA_PARAM,
      rejectedSFA: rejectedSFA,
    });
  } catch (err) {
    console.log(err);
  }
};

export const handleCheckAllOfferings = (isChecked) => async (dispatch, state) => {
  try {
    dispatch({
      type: IS_CHECKED_ALL_OFFERINGS,
      isCheckedAllOfferings: isChecked,
    });

    return isChecked;
  } catch (err) {
    console.log(err);
  }
};

export const setOALeadList = (newValue) => async (dispatch, state) => {
  try {
    let OALeadList = state().ProgramConfigurationData.OALeadList;
    newValue.map(x => {
      OALeadList.push(x);
    });
    dispatch({
      type: FETCH_OALEADLIST,
      OALeadList: OALeadList,
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const setCXLeadList = (newValue) => async (dispatch, state) => {
  try {
    let CXLeadList = state().ProgramConfigurationData.CXLeadList;
    newValue.map(x => {
      CXLeadList.push(x);
    });
    dispatch({
      type: FETCH_CXLEADLIST,
      CXLeadList: CXLeadList,
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const removeNewlyAddedStakeholders = () => async (dispatch, state) => {
  try {
    let newStakeholders = state().ProgramConfigurationData.newlyAddedUserList;
    let OALeadList = state().ProgramConfigurationData.OALeadList;
    let CXLeadList = state().ProgramConfigurationData.CXLeadList;

    OALeadList.map((x, i) => {
      let index = newStakeholders.indexOf(x.label);

      if (index > -1) {
        OALeadList.splice(i, 1);
      }
    });

    CXLeadList.map((x, i) => {
      let index = newStakeholders.indexOf(x.label);

      if (index > -1) {
        CXLeadList.splice(i, 1);
      }
    });

    dispatch({
      type: FETCH_OALEADLIST,
      OALeadList: OALeadList,
    });

    dispatch({
      type: FETCH_CXLEADLIST,
      CXLeadList: CXLeadList,
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const setNewlyAddedStakeholders = (newStakeholder) => async (dispatch, state) => {
  try {
    dispatch({
      type: SET_NEWLY_ADDED_STAKEHOLDER,
      newlyAddedUserList: newStakeholder
    })
  }
  catch (err) {
    console.log(err);
  }
}

export const handleSendReminderProgram = (param) => async (dispatch, state) => {
  try {
    let result
    let subMenuId = GetSubMenuId("Program Identified Value Settings")
    let postData = await HttpPost(
      `NewProgramIdentifiedValueSetting/SendReminderForProgramApproval?SubMenuID=${subMenuId}`,
      param.body
    );

    result = postData
    history.push('/ProgramProjectConfig/ProgramIDValueSettings')
    dispatch({
      type: ON_SEND_REMINDER_PROGRAM_APPROVAL,
      sendReminderProgramApproval: {
        param: param,
        programData: postData
      }
    })
    if (postData.Code === '1') {
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'success',
          message: param.message,
        },
      });
    } else if (postData.Code === '2') {
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'error',
          message: postData.Message,
        },
      });
    } else {
      dispatch({
        type: SET_SHOULD_SHOW_TOASTER_NOTIF,
        toasterParam: {
          show: true,
          type: 'error',
          message: "Reminder notification is not sent!",
        },
      });
    }
    return result
  } catch (error) {
    console.log(error);
  }
}