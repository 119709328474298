import {
  ON_SELECT_PROJECT_ENTRY,
  ON_SELECT_PROJECT,
  ON_SAVE_SELECT_PROJECT,
  ON_CANCEL_SELECT_PROJECT,
  GET_PROGRAM_PROJECT,
  ON_TILE_SELECT_PROJECT,
  SET_BUTTON_STATE,
  ON_CHANGE_PARAM,
  ON_CHANGE_TAB_FINANCIAL,
  GET_FILE_DETAILS,
  ON_CLICK_RDV_SFA,
  ON_SFA_UPDATE_SUBMIT,
  GET_PROJECT_REPORTING_YEAR_LIST,
  SET_REJECTED_COMMENTS,
  MAPPED_STAKE_HOLDERS,
  DIS_UI_ELEMENTS,
  UPDATE_BOI_LIST,
  BOI_IS_REFRESH,
  SELECTED_PROGRAM_PROJECT,
  SHOW_CUMULATIVE_MODAL,
  GET_AGGREGATED_DISABLED_BOIS,
  GET_CLIENT_STORY_FILE_HISTORY,
  GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY,
  SET_CLIENT_STORY,
  SET_CLIENT_STORY_FILE,
  SET_CLIENT_ALIGNMENT_FILE,
  SET_VIRUS_FOUND,
  SET_UPLOAD_IS_COMPLETED,
  REMOVE_CLIENT_STORY_FILE,
  REMOVE_CLIENT_ALIGNMENT_FILE,
  SET_BUSINESS_ADVISOR_LIST,
  SET_RVD_BASIC_DETAILS,
  ON_SEND_REMINDER_PROJECT_DATA_ENTRY
} from '../Constants';

export const clientStoryInitialState = {
  exKeyBusinessChallenges: '',
  exInnovationEnablers: '',
  exBusinessOutcomes: '',
  keyBusinessChallenges: '',
  innovationEnablers: '',
  businessOutcomes: '',
  isExistingProject: false
}
const initialState = {
  //onselect modal
  fieldValidation: {
    reportingYearValid: true,
    valueMeterProgramNameValid: true,
    projectValid: true,
    deliveryLocationValid: true,
    reportingPeriodValid: true,
  },
  selectProjectDropdown: {
    programNameList: [],
    projectNameList: [],
    projectDeliveryList: [],
  },
  selectProjectReportingYearList: [],
  shouldSaveProject: false,
  showSelectProject: false,
  showToastNotification: false,
  isReportProjectSelected: false,

  //onselect tile/table
  basicDetails: {},
  projectDimensionsList: [],
  rvdSelectedTabs: {
    capital: 0,
    opex: 0,
    revenue: 0,
  },
  buttonState: {
    isSelectProjectDisabled: false,
    isCommentDisabled: false,
    isSaveAsDraftDisabled: false,
  },
  fileData: {
    iD: 0,
    clientProjectID: 0,
    deliveryCenterID: 0,
    fileName: '',
    fileUploadDate: '',
    fileUploadedBy: '',
  },
  isProjectSelected: false,
  mainSelectedProject: {},
  receiverDetails: {},
  SFASubmitData: {},
  rejectedComments: [],
  isWriteAccess: false,
  configProgUIElems: {
    sadBtn: false,
    sfaSubRejectionBtn: false,
    submitSubRejection: false,
    paramFields: false,
  },
  isDraft: false,
  isRefreshed: false,
  showCumulativeModal: false,
  aggregatedDisabledBOIs: [],
  clientStoryFileHistory: [],
  clientAcknowledgementFileHistory: [],
  clientStoryFile: {
    id: 0,
    file: [],
    fileUploadedBy: '',
    createdUser: '',
  },
  virusFoundError: false,
  isUploadCompleted: false,
  removeClientStoryFileId: 0,
  removeClientAlignmentFileId: [],
  businessAdvisorList: [],
  clientAlignmentFiles: {
    id: 0,
    file: [],
    fileUploadedBy: '',
    createdUser: '',
  },
  clientStory: clientStoryInitialState,
  sendReminderProjectDataEntry: [],
};

export const ConfigureProgramData = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SELECTED_PROGRAM_PROJECT:
      return {
        ...state,
        mainSelectedProject: action.mainSelectedProject,
      };
    case ON_SELECT_PROJECT_ENTRY:
      return {
        ...state,
        mainSelectedProject: action.mainSelectedProject,
        isReportProjectSelected: action.isReportProjectSelected,
      };
    case ON_SELECT_PROJECT:
      return {
        ...state,
        mainSelectedProject: action.mainSelectedProject,
        fieldValidation: action.fieldValidation,
      };
    case ON_SAVE_SELECT_PROJECT:
      return {
        ...state,
        mainSelectedProject: action.mainSelectedProject,
        fieldValidation: action.fieldValidation,
        showSelectProject: action.showSelectProject,
      };
    case ON_CANCEL_SELECT_PROJECT:
      return {
        ...state,
        showSelectProject: action.showSelectProject,
      };
    case GET_PROGRAM_PROJECT:
      return {
        ...state,
        selectProjectDropdown: action.selectProjectDropdown,
      };
    case GET_PROJECT_REPORTING_YEAR_LIST:
      return {
        ...state,
        selectProjectReportingYearList: action.selectProjectReportingYearList,
      };

    case ON_TILE_SELECT_PROJECT:
      return {
        ...state,
        basicDetails: action.basicDetails,
        projectDimensionsList: action.projectDimensionsList,
        buttonState: action.buttonState,
        isProjectSelected: action.isProjectSelected,
        mainSelectedProject: action.mainSelectedProject,
        isWriteAccess: action.isWriteAccess,
        isDraft: action.isDraft,
        isExistingDataEntry: action.isExistingDataEntry,
        clientStory: action.clientStory
      };
    case SET_BUTTON_STATE:
      return {
        ...state,
        buttonState: action.buttonState,
      };
    case ON_CHANGE_PARAM:
      return {
        ...state,
        projectDimensionsList: action.projectDimensionsList,
      };
    case ON_CHANGE_TAB_FINANCIAL:
      return {
        ...state,
        rvdSelectedTabs: action.rvdSelectedTabs,
      };
    case GET_FILE_DETAILS:
      return {
        ...state,
        fileData: action.fileData,
      };
    case ON_CLICK_RDV_SFA:
      return {
        ...state,
        receiverDetails: action.receiverDetails,
      };
    case ON_SFA_UPDATE_SUBMIT:
      return {
        ...state,
        SFASubmitData: action.SFASubmitData,
      };
    case SET_REJECTED_COMMENTS:
      return {
        ...state,
        rejectedComments: action.rejectedComments,
      };
    case MAPPED_STAKE_HOLDERS:
      return {
        ...state,
        stakeHolders: action.stakeHolders,
      };
    case DIS_UI_ELEMENTS:
      return {
        ...state,
        configProgUIElems: action.configProgUIElems,
      };
    case UPDATE_BOI_LIST:
      return {
        ...state,
        projectDimensionsList: action.projectDimensionsList,
      };
    case BOI_IS_REFRESH:
      return {
        ...state,
        isRefreshed: action.isRefreshed,
      };
    case SHOW_CUMULATIVE_MODAL:
      return {
        ...state,
        showCumulativeModal: action.showCumulativeModal,
      };
    case GET_AGGREGATED_DISABLED_BOIS:
      return {
        ...state,
        aggregatedDisabledBOIs: action.aggregatedDisabledBOIs,
      };
    case GET_CLIENT_STORY_FILE_HISTORY:
      return {
        ...state,
        clientStoryFileHistory: action.clientStoryFileHistory,
      };
    case REMOVE_CLIENT_STORY_FILE:
      return {
        ...state,
        removeClientStoryFileId: action.removeClientStoryFileId,
      };
    case REMOVE_CLIENT_ALIGNMENT_FILE:
      return {
        ...state,
        removeClientAlignmentFileId: action.removeClientAlignmentFileId,
      };
    case GET_CLIENT_ACKNOWLEDGEMENT_FILE_HISTORY:
      return {
        ...state,
        clientAcknowledgementFileHistory: action.clientAcknowledgementFileHistory,
      };
    case SET_CLIENT_STORY:
        return {
          ...state,
          clientStory: action.clientStory,
    };
    case SET_CLIENT_STORY_FILE:
      return {
        ...state,
        clientStoryFile: action.clientStoryFile,
      };
    case SET_CLIENT_ALIGNMENT_FILE:
      return {
        ...state,
        clientAlignmentFiles: action.clientAlignmentFiles
      }
    case SET_VIRUS_FOUND:
      return {
        ...state,
        virusFoundError: action.virusFoundError,
      };
    case SET_UPLOAD_IS_COMPLETED:
      return {
        ...state,
        isUploadCompleted: action.isUploadCompleted,
      };
    case SET_BUSINESS_ADVISOR_LIST:
      return {
        ...state,
        businessAdvisorList: action.businessAdvisorList,
      };
    case SET_RVD_BASIC_DETAILS:
      return {
        ...state,
        basicDetails: action.basicDetails,
      };
    case ON_SEND_REMINDER_PROJECT_DATA_ENTRY:
      return {
        ...state,
        sendReminderProjectDataEntry: action.sendReminderProjectDataEntry
      }
    default:
      return state;
  }
};
